import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { search } from 'store/report/reports'
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { retrieve } from 'store/report/steps'
import TableHeadCell from 'components/TableHeadCell'

import * as XLSX from 'xlsx'

import { FileDownloadIcon } from 'components/Icons'
import { useTheme } from '@emotion/react'
import ReportExportPDF from './PDF'

export default function ReportRun() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { reportId } = useParams()
  const { t } = useTranslation()
  const searchParams = new URLSearchParams(window.location.search)
  const searchParamsKeys = Array.from(searchParams.keys())

  const { list } = useSelector(state => state.report.reports)
  const { last: step } = useSelector(state => state.report.steps)

  const [selected, setSelected] = useState()
  const [cols, setCols] = useState()

  const getData = async () => {
    const payload = {}
    searchParams.forEach((value, key) => {
      payload[key] = value
    })
    payload.id = +reportId

    await dispatch(retrieve({ ...payload }))
    setSelected(null)
  }

  useEffect(() => {
    list || dispatch(search())
    step || getData()
  }, [])

  useEffect(() => {
    setCols(undefined)
  }, [reportId])

  useEffect(() => {
    if (step?.result?.length > 0) {
      setCols(Object.keys(step?.result[0]))
    }
  }, [step?.result])

  useEffect(() => {
    getData()
  }, [searchParamsKeys?.length])

  const ui = data => {
    switch (data?.ui) {
      case 'drowdown':
        return autocomplete(data)
      default:
        return autocomplete(data)
    }
  }

  const autocomplete = data => {
    return (
      <Autocomplete
        value={selected || null}
        options={data?.options.map(item => item.id) || []}
        onChange={(event, value) => {
          value ? setSelected(value) : null
        }}
        getOptionLabel={option => {
          return (
            (data?.options.find(item => item.id === option) &&
              `${data?.options.find(item => item.id === option)?.name}`) ||
            option
          )
        }}
        renderInput={params => <TextField {...params} fullWidth label={data?.name} />}
      />
    )
  }

  const goBack = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const keys = Array.from(searchParams.keys())

    if (keys.length > 0) {
      const lastKey = keys[keys.length - 1]
      searchParams.delete(lastKey)
    }

    const newQueryString = searchParams.toString()

    return `/report/run/${reportId}${newQueryString ? '?' + newQueryString : ''}`
  }

  const goNext = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const activeParams = Array.from(searchParams.entries())
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const newParam = `${step?.id}=${selected}`

    return `/report/run/${reportId}?${activeParams}${activeParams && '&'}${newParam}`
  }

  const goExport = () => {
    return `/report/export/${reportId}?course_session_id=${selected}`
  }

  const exportCSV = () => {
    if (step?.result) {
      const data = columnFilter(step?.result, cols)

      const headers = cols.map(header => header.replaceAll(/_/g, ' ').toUpperCase())

      const csvContent =
        'data:text/csv;charset=utf-8,' +
        [
          headers.join(','),
          ...data.map(row => headers.map(header => row[header.toLowerCase().replaceAll(/ /g, '_')]).join(',')),
        ].join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
  }

  const columnFilter = (data, cols) => {
    return data.map(row => {
      return Object.keys(row)
        .filter(key => cols.includes(key))
        .reduce((acc, key) => {
          acc[key] = row[key]
          return acc
        }, {})
    })
  }

  const exportXLS = () => {
    if (step?.result) {
      const data = columnFilter(step?.result, cols)

      const headers = cols.map(header => header.replaceAll(/_/g, ' ').toUpperCase())

      const ws = XLSX.utils.json_to_sheet(data, {
        header: headers,
        skipHeader: false,
      })

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Report')

      XLSX.writeFile(wb, 'report.xlsx')
    }
  }

  const getColumnOrder = () => {
    const data = step?.result
    if (data && data.length > 0) {
      return Object.keys(data[0])
    }
    return []
  }

  const column = col => {
    const columnOrder = getColumnOrder()
    if (!columnOrder.length) return

    if (cols && cols.includes(col) && cols.length >= 2) {
      const newCols = cols.filter(item => item !== col)
      setCols(columnOrder.filter(item => newCols.includes(item)))
    } else {
      const newCols = cols ? Array.from(new Set([...cols, col])) : [col]
      setCols(columnOrder.filter(item => newCols.includes(item)))
    }
  }

  if (!(list && step)) return null

  if (
    step &&
    step?.status === 'result' &&
    step?.result &&
    step?.result[0] &&
    step?.format?.length === 1 &&
    step?.format[0] === 'pdf'
  ) {
    return <ReportExportPDF data={step} />
  }

  if (step && step?.status !== 'result') {
    return (
      <Paper sx={{ width: '100%', minHeight: '500px', my: 2 }}>
        <Box sx={{ p: 6 }}>
          <Typography sx={{ py: 2 }}>{step?.description}</Typography>

          {ui(step)}
          <Stack sx={{ py: 3 }} flexDirection={'row'} justifyContent={'space-between'}>
            <Button
              component={NavLink}
              to={goBack()}
              size="small"
              variant="contained"
              color="warning"
              disabled={searchParamsKeys?.length === 0}
            >
              {t('Back')}
            </Button>

            {step?.status === 'result' ? (
              <Button
                component={NavLink}
                to={goExport()}
                size="small"
                variant="contained"
                color="warning"
                disabled={!selected}
              >
                {t('Export Now')}
              </Button>
            ) : (
              <Button
                component={NavLink}
                to={goNext()}
                size="small"
                variant="contained"
                color="warning"
                disabled={!selected}
              >
                {t('Next')}
              </Button>
            )}
          </Stack>
        </Box>
      </Paper>
    )
  }

  if (step && step?.result?.length > 0) {
    const tableHeaders = Object.keys(step?.result[0])
    return (
      <Paper sx={{ width: '100%', minHeight: '500px', my: 2 }}>
        <Box sx={{ p: 3 }}>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Typography sx={{ py: 3 }} variant="h6">
                {step?.description}
              </Typography>
            </Box>
            <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
              <Button
                startIcon={<FileDownloadIcon />}
                size="small"
                variant="contained"
                color="secondary"
                onClick={exportCSV}
              >
                CSV
              </Button>
              {/* <Button
                startIcon={<FileDownloadIcon />}
                size="small"
                variant="contained"
                color="error"
                onClick={exportPDF}
              >
                PDF
              </Button> */}
              <Button
                startIcon={<FileDownloadIcon />}
                size="small"
                variant="contained"
                color="primary"
                onClick={exportXLS}
              >
                XLS
              </Button>
            </Stack>
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((item, index) => (
                  <TableHeadCell key={index}>
                    <Typography
                      onClick={() => column(item)}
                      sx={{
                        fontSize: '80% !important',
                        fontStyle: cols?.includes(item) ? 'normal' : 'italic',
                        color: cols?.includes(item) ? theme.palette.grey[1000] : theme.palette.grey[500],
                        cursor: 'pointer',
                      }}
                      variant="button"
                    >
                      {item.replaceAll('_', ' ')}
                    </Typography>
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {step?.result &&
                step?.result.map((item, index) => (
                  <TableRow key={index} hover>
                    {tableHeaders.map(key => (
                      <TableCell sx={{ fontSize: '80% !important' }} key={key}>
                        {item[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    )
  }

  if (step && step?.result?.length === 0) {
    return (
      <Paper sx={{ width: '100%', minHeight: '500px', my: 2 }}>
        <Typography variant="h6" sx={{ p: 3 }}>
          {t('No data found to report')}
        </Typography>
      </Paper>
    )
  }
}
