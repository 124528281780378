import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'nurseVisit'
const initialState = {
    list: null,
  }

export const search = createApiAction('nurseVisit/search', client.search('/api/nurse/visit'))

export const destroy = createApiAction(
  'nurseVisit/delete',
  client.destroy('/api/nurse/visit/:id'),
)

export const get = createApiAction(
  'nurseVisit/get',
  client.retrieve(`/api/nurse/visit/:id`),
)

export const save = createApiAction(
  'nurseVisit/save',
  client.save('/api/nurse/visit'),
)

const update = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [...state.list, action.payload]
}

  const sessionSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(search.fulfilled, (state, action) => {
          state.list = action.payload})
       .addCase(get.fulfilled, update)
       .addCase(save.fulfilled, update)
        .addCase(destroy.fulfilled, (state, action) => {
          state.list = state.list.filter(item => item.id !== action.payload.id)
        })
    },
  })
  
  export default sessionSlice.reducer