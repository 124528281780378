import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function SchedulingEnrollStudentInfo({ selected }) {
  const { t } = useTranslation()
  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      sx={{ backgroundColor: 'rgba(255, 146, 21, 0.04)', mt: 2 }}
    >
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Grade Section')}</Typography>
        <Typography variant="body2">
          {selected?.academicYearEnrollment.gradeLevelId}
          {selected?.academicYearEnrollment.gradeSection}&nbsp;
        </Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('ESL')}</Typography>
        <Typography variant="body2">{selected?.esl}&nbsp;</Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('IEP')}</Typography>
        <Typography variant="body2">{selected?.iep}&nbsp;</Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('504')}</Typography>
        <Typography variant="body2">{selected?.fiveZeroFour}&nbsp;</Typography>
      </Box>
    </Stack>
  )
}
