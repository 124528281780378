import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar, MenuItem } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/bell-schedule-period'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { useBasePath } from 'components/Providers'
import { debounce, isInteger } from 'lodash'
import Confirm from 'components/Dialog'
import { destroy } from 'store/settings/bell-schedule-period'
import { useState } from 'react'
import { pick } from 'lodash'
import { useTranslation } from 'react-i18next'
import { resetBellSchedules } from 'store/lookup'

const days = [
  { key: 'Weekdays', value: 'wd' },
  { key: 'Monday', value: 1 },
  { key: 'Tuesday', value: 2 },
  { key: 'Wednesday', value: 3 },
  { key: 'Thursday', value: 4 },
  { key: 'Friday', value: 5 },
  { key: 'Saturday', value: 6 },
  { key: 'Sunday', value: 0 },
]

export default function PeriodEditForm({ period, bellScheduleTypes }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bellScheduleId } = useParams()
  const basePath = useBasePath()
  const [confirm, setConfirm] = useState(false)
  const { t } = useTranslation()

  let dayOptions

  if (!period.id) {
    dayOptions = days
  } else if ([0, 6].includes(period.dayOfWeek)) {
    dayOptions = days.filter(item => item.value === period.dayOfWeek)
  } else {
    dayOptions = days.filter(item => ['wd', period.dayOfWeek].includes(item.value))
  }

  const schema = yup.object().shape({
    type: yup.string(),
    name: yup.string(),
    dayOfWeek: yup
      .number()
      .nullable()
      .transform(value => {
        return isInteger(value) ? value : null
      }),
    startTime: yup
      .string()
      .required('Required')
      .transform(value => {
        return value.replace(/\s/g, '')
      }),
    endTime: yup
      .string()
      .required('Required')
      .transform(value => {
        return value.replace(/\s/g, '')
      }),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...pick(period, ['id', 'type', 'name', 'dayOfWeek', 'startTime', 'endTime']),
      dayOfWeek: dayOptions[0].value,
    },
  })

  const onSubmit = async data => {
    const payload = { ...data, bellScheduleId }
    const res = await dispatch(save(payload))
    if (res.payload) {
      dispatch(resetBellSchedules())
      navigate(`${basePath}`)
    }
  }

  const onError = errors => console.error(errors)

  const onConfirm = async () => {
    setConfirm(false)
    const res = await dispatch(destroy({ bellScheduleId, id: period.id }))
    if (res.payload) {
      dispatch(resetBellSchedules())
      navigate(`${basePath}`)
    }
  }
  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Header title={'Period'} close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Title")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  />
                )
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="type"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("Period type")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {bellScheduleTypes?.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {t(item?.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="dayOfWeek"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("Day")}
                    error={!!fieldState.error}
                    defaultValue={period.dayOfWeek}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {dayOptions?.map(item => (
                      <MenuItem key={item.key} value={item.value}>
                        {t(item?.key)}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Start time")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="endTime"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("End time")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {period?.id !== undefined && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setConfirm(true)
            }}
          >
            Delete
          </Button>
        )}
        <Button variant="outlined" onClick={() => navigate(`${basePath}`)}>
          {t("Cancel")}
        </Button>
        <Button onClick={debounce(() => {
          handleSubmit(onSubmit)()
        }, 200)} variant="contained" disabled={!formState.isDirty}>
          {t("Submit")}
        </Button>
      </CardActions>
      <Header />
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </Card>
  )
}
