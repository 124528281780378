import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  list: null,
}

export const search = createApiAction(
  'setting/bell-schedule-period/search',
  client.search('/api/setting/bell-schedule/:bellScheduleId/period'),
)
export const get = createApiAction(
  'setting/bell-schedule-period/get',
  client.retrieve(`/api/setting/bell-schedule/:bellScheduleId/period/:id`),
)
export const save = createApiAction(
  'setting/bell-schedule-period/save',
  client.save('/api/setting/bell-schedule/:bellScheduleId/period'),
)
export const destroy = createApiAction(
  'setting/bell-schedule-period/delete',
  client.destroy('/api/setting/bell-schedule/:bellScheduleId/period/:id'),
)

const update = (state, payload) => {
  const found = state.list.find(item => item.id === payload.id)
  state.list = found ? state.list.map(item => (found.id === item.id ? payload : item)) : [payload, ...state.list]
}

const updateSeries = (state, action) => {
  if (Array.isArray(action.payload)) {
    action.payload.forEach(element => {
      update(state, element)
    })
    return
  } else update(state, action.payload)
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: state => {
      state.list = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
        state.count = action.payload.length
      })
      .addCase(get.fulfilled, updateSeries)
      .addCase(save.fulfilled, updateSeries)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export const { reset } = sessionSlice.actions
export default sessionSlice.reducer
