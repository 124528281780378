import { Box, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Loading() {
  const { t } = useTranslation()

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" _minHeight="100vh">
      <Toolbar />
      <Box>{t('Loading...')}</Box>
    </Box>
  )
}
