import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow, TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TableHeadCell from 'components/TableHeadCell'
import { search } from 'store/settings/course'
import CourseListItem from './ListItem'
import { useTranslation } from 'react-i18next'

export default function CourseList() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { query, list, count } = useSelector(state => state.settings.course)

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }
  return (
    <Paper sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>{t("Course Name")}</TableHeadCell>
                    <TableHeadCell sx={{ width: '30%' }}>{t('Subject')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '10%' }}>{t('Suffix')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '4em' }}>&nbsp;</TableHeadCell>
                    <TableHeadCell sx={{ width: '6em' }}>{t('Enabled')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '4em' }}>&nbsp;</TableHeadCell>
                    <TableHeadCell sx={{ width: '8em' }}>&nbsp;</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(item => {
                    return <CourseListItem key={item.id || item.uid} item={item} />
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={count || 0}
                page={query?.limit ? query?.offset / query?.limit : 0}
                onPageChange={handleChangePage}
                rowsPerPage={query?.limit || 10}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
