import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/person/employment'
import { getClients } from 'store/lookup'
import ScheduleListIndex from './List'
import { Box, Select, FormControl, InputLabel, MenuItem, Grid } from '@mui/material'

export default function SchedulePage() {
  const dispatch = useDispatch()
  const { personId } = useParams()
  const employments = useSelector(state => state.person2.employments)
  const { clients } = useSelector(state => state.lookup)
  const serviceProviderDistrictId = useSelector(state => state.session.district.id)

  const [selected, setSelected] = useState()

  useEffect(() => {
    clients || dispatch(getClients())
  }, [])

  useEffect(() => {
    if (!employments || !employments[+personId]) {
      dispatch(search({ personId: +personId }))
    }
  }, [dispatch, personId])

  const handleSelect = event => {
    setSelected(event.target.value)
  }

  if (!(clients && employments && employments[+personId])) return null

  const options = employments[+personId]
    ?.filter(item => item.districtId !== serviceProviderDistrictId)
    ?.map(item => item.districtId)

  return (
    <Box>
      <Grid container>
        <Grid item xs={4} md={4} sx={{ m: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="school" sx={{ top: '-7px' }}>
              School
            </InputLabel>
            <Select size="small" labelId="school" id="schools" value={selected} label="School" onChange={handleSelect}>
              {options.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {clients.find(c => c?.client?.id === item)?.client?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <ScheduleListIndex districtId={selected} />
    </Box>
  )
}
