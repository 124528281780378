import { Button, Chip, Link, Box, Grid } from '@mui/material'
import {
  CheckCircleOutlineIcon,
  CloseIcon,
  HomeIcon,
  PhoneIcon,
  PhoneIphoneIcon,
  WorkIcon,
  DragIndicatorIcon,
} from 'components/Icons'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import FeildSkeleton from './FieldSkeleton'
import { useTranslation } from 'react-i18next'
import { FaLanguage } from 'react-icons/fa'

export default function ContactsListItem({ item, index, setMovedItemData, itemCount }) {
  const languages = useSelector(state => state.lookup.languages)
  const { t } = useTranslation()

  const dragStart = () => {
    setMovedItemData(index, -1, false, true, item.id)
  }

  const dragOver = e => {
    e.preventDefault()
    setMovedItemData(-1, index, false, true, item.id)
  }

  const dragEnd = e => {
    e.preventDefault()
    setMovedItemData(-1, -1, true, false, item.id)
  }

  return (
    <Box
      flexDirection={'row'}
      sx={{ pb: 1, height: '60px' }}
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      draggable={itemCount > 1 ? true : false}
    >
      <Grid container spacing={2}>
        <Grid item xs={0.5} md={0.5}>
          <Box sx={{ cursor: 'move' }}>
            <DragIndicatorIcon />
          </Box>
        </Grid>

        <Grid item xs={1.5} md={1.5}>
          {item.relationship.slice(0, 1).toUpperCase()}
          {item.relationship.slice(1)}
        </Grid>
        <Grid item xs={2.5} md={2.5}>
          <Link component={NavLink} underline="hover" color="inherit" to={`${item.id}`}>
            {item.contact.title && item.contact.title} {item.contact.firstName} {item.contact.middleName}{' '}
            {item.contact.lastName}
          </Link>
        </Grid>
        <Grid item xs={1.8} md={1.8}>
          {item.contact?.phones[0]?.number ? (
            <Chip
              icon={
                item.contact.phones[0]?.type === 'home' ? (
                  <HomeIcon />
                ) : item.contact.phones[0]?.type === 'mobile' ? (
                  <PhoneIphoneIcon />
                ) : item.contact.phones[0]?.type === 'work' ? (
                  <WorkIcon />
                ) : (
                  <PhoneIcon />
                )
              }
              label={item.contact.phones[0]?.number}
            />
          ) : (
            <FeildSkeleton />
          )}
        </Grid>

        <Grid item xs={1.7} md={1.7}>
          <Chip
            icon={<FaLanguage />}
            label={languages?.find(lang => lang.id === item.contact.preferredLanguage)?.name}
          />
        </Grid>

        {item.pickup && (
          <Grid item xs={1.3} md={1.3}>
            <Chip icon={<CheckCircleOutlineIcon />} label={t('Pickup')} color="success" />
          </Grid>
        )}

        {!item.pickup && (
          <Grid item xs={1.3} md={1.3}>
            <Chip icon={<CloseIcon />} label={t('Pickup')} color="error" />
          </Grid>
        )}

        {item.emergency && (
          <Grid item xs={1.7} md={1.7}>
            <Chip icon={<CheckCircleOutlineIcon />} label={t('Emergency Call')} color="success" />
          </Grid>
        )}
        {!item.emergency && (
          <Grid item xs={1.7} md={1.7}>
            <Chip icon={<CloseIcon />} label={t('Emergency Call')} color="error" />
          </Grid>
        )}
        <Grid item sx={{ textAlign: 'right' }} xs={1} md={1}>
          <Button color="warning" size="small" component={NavLink} variant="outlined" to={`${item.id}`}>
            {t('Edit')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
