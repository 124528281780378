import _ from 'lodash'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/schedule'
export const search = createApiAction('member/schedule/search', client.retrieve(basePath))

export default function setSchoolEnrollmentReducers(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    const list = state.list || []
    const { memberId } = action.meta.arg
    const found = list.find(item => item.id === memberId)
    const replace = _.extend({ id: memberId }, found, { schedule: action.payload })
    if (found) state.list = list.map(item => (item.id === found.id ? replace : item))
    else state.list = [...state.list, replace]
  })
}
