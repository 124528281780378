import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { EditIcon, DeleteIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/settings/marking-period'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { useParams } from 'react-router-dom'

export default function MarkingPeriodItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { academicYearId } = useParams()

  const onConfirm = () => {
    setConfirm(false)
    item.id && academicYearId && dispatch(destroy({ id: item.id, academicYearId }))
  }

  return (
    <TableRow key={item?.id}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item?.id}`}>
          {item?.name}
        </Link>
      </TableCell>
      <TableCell>{item?.startDate}</TableCell>
      <TableCell>{item?.endDate}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item?.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
