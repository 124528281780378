import { Route, Routes } from 'react-router-dom'
import EmploymentsPage from './Page'
import EmploymentsFormIndex from './Form'

export default function Employments() {
  return (
    <Routes>
      <Route path="/" element={<EmploymentsPage />}>
        <Route path="/:district/:form/:employmentId" element={<EmploymentsFormIndex />} />
        <Route path="/:employmentId/*" element={<EmploymentsFormIndex />} />
      </Route>
    </Routes>
  )
}
