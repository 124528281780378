import { useNavigate, useParams } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import CourseSessionList from './CourseSessionList'
import Detail from './detail'
import { BasePathProvider } from 'components/Providers'
import PageHeader from 'components/AppBar/PageHeader'

const baseUrl = '/academic/course-session'

export default function Index() {
  const { courseSessionId } = useParams()
  const navigate = useNavigate()
  const handleClose = () => {
    navigate(baseUrl)
  }

  return (
    <BasePathProvider value={baseUrl}>
      <Box sx={{ pb: 2 }}>
        <PageHeader title={'Course Session'} />
      </Box>
      <CourseSessionList />
      <Drawer anchor="right" open={Boolean(courseSessionId)} onClose={handleClose}>
        <BasePathProvider value={`${baseUrl}/${courseSessionId}`}>
          <Detail />
        </BasePathProvider>
      </Drawer>
    </BasePathProvider>
  )
}
