import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'marking-period'
const initialState = {
  count: null,
  list: null,
}

export const search = createApiAction(
  'settings/marking-period/search',
  client.search('/api/setting/academic-year/:academicYearId/marking-period'),
)

export const get = createApiAction(
  'settings/marking-period/get',
  client.retrieve(`/api/setting/academic-year/:academicYearId/marking-period/:id`),
)

export const save = createApiAction(
  'settings/marking-period/save',
  client.save('/api/setting/academic-year/:academicYearId/marking-period'),
)

export const destroy = createApiAction(
  'settings/marking-period/delete',
  client.destroy('/api/setting/academic-year/:academicYearId/marking-period/:id'),
)

const updateList = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list?.map(mp => (found.id === mp.id ? action.payload : mp))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearMarkingPeriods: state => {
      state.list = null
      state.count = 0
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.count = action.payload.length
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list?.filter(mp => mp.id.toString() !== action.payload.id)
      })
  },
})

export const { clearMarkingPeriods } = sessionSlice.actions

export default sessionSlice.reducer
