import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'course-session-enrollments'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'settings/course-session-enrollment/search',
  client.search('/api/setting/course-session/:courseSessionId/enrollment'),
)

export const save = createApiAction(
  'settings/course-session-enrollment/create',
  client.save('/api/setting/course-session/:courseSessionId/enrollment'),
)

export const get = createApiAction(
  'settings/course-session-enrollment/get',
  client.retrieve(`/api/setting/course-session/:courseSessionId/enrollment/:id`),
)

export const update = createApiAction(
  'settings/course-session-enrollment/save',
  client.save('/api/setting/course-session/:courseSessionId/enrollment'),
)

export const destroy = createApiAction(
  'settings/course-session-enrollment/delete',
  client.destroy('/api/setting/course-session/:courseSessionId/enrollment/:id'),
)

const updateList = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  if (found) {
    state.list?.map(person => (found.id === person.id ? action.payload : person))
  } else {
    if (state.list) {
      state.list = [...state.list, action.payload]
    } else {
      state.list = [action.payload]
    }
  }
  // state.list = found
  //   ? state.list?.map(person => (found.id === person.id ? action.payload : person))
  //   : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: state => {
      state.list = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(update.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state?.list?.filter(item => item.id !== action.payload.id)
      })
  },
})
export const { reset } = sessionSlice.actions
export default sessionSlice.reducer
