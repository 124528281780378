import { Route, Routes } from 'react-router-dom'
import DailyAttendancePage from './Page'
import DailyAttendanceFormIndex from './Form'

export default function index() {
  return (
    <Routes>
      <Route path="/" element={<DailyAttendancePage />}>
        <Route path="/:id" element={<DailyAttendanceFormIndex />} />
      </Route>
    </Routes>
  )
}
