import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'course-session-schedule'
const initialState = {
  list: null,
}

export const getSchedules = createApiAction(
  'course-session-schedule/search',
  client.search('/api/setting/course-session-schedule/:courseSessionId'),
)
export const setSchedules = createApiAction(
  'course-session-schedule/save',
  client.create('/api/setting/course-session-schedule/:courseSessionId'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: state => {
      state.list = null
    },
  },
  extraReducers(builder) {
    builder.addCase(getSchedules.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export const { reset } = sessionSlice.actions
export default sessionSlice.reducer
