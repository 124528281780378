import { createAsyncThunk } from '@reduxjs/toolkit'

export const createApiAction = (type, api, options) => {
  return createAsyncThunk(
    type,
    async payload => {
      const res = await api(payload)
      return res.data
    },
    {
      ...options,
      serializeError: err => {
        return {
          status: err.status,
          message: err.message,
          data: err.data,
          stack: err.stack,
        }
      },
    },
  )
}
