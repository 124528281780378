import { CardContent, Grid, Skeleton, Box, CardActions } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function FormSkeleton() {
  const { t } = useTranslation()
  return (
    <Box>
        <Header title={t('Personal Information')} small />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
          </Grid>
        </CardContent>

        <Header title={t('Work Related Information')} small />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}></CardActions>
    </Box>
  )
}
