import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { search } from 'store/academic/attendance-report'
import Attendance from 'components/Attendance'
import NotFound from 'components/NotFound'
import { useBasePath } from 'components/Providers'

export default function Index() {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    dispatch(search()).then(res => {
          res.error && setNotFound(true)
        })
  }, [dispatch])

  const attendanceReports = useSelector(state => state.academic.attendanceReport.list)

  let absenceRate =
    (attendanceReports?.daily?.absent * 100) /
    (attendanceReports?.daily?.absent + attendanceReports?.daily?.tardy + attendanceReports?.daily?.present)

  absenceRate = isNaN(absenceRate) || !isFinite(absenceRate) ? '-' : absenceRate

  if (notFound) return <NotFound basePath={`${basePath}`} />

  return <Attendance absenceRate={absenceRate} attendanceReports={attendanceReports} />
}
