import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  list: null,
  query: { limit: 10, offset: 0 },
  byDistrict: {
    count: null,
    list: null,
    query: { limit: 10, offset: 0 },
  },
}

export const search = createApiAction('course/search', client.search('/api/setting/course'))
export const get = createApiAction('course/get', client.retrieve(`/api/setting/course/:id`))
export const save = createApiAction('course/save', client.save('/api/setting/course'))
export const destroy = createApiAction('course/delete', client.destroy('/api/setting/course/:id'))

const updateList = (state, action) => {
  const found = state?.list?.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list?.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        if (!action.meta.arg?.export) {
          state.query = action.meta.arg || initialState.query
          state.count = action.payload.count
          state.list = action.payload.rows
        }
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(ay => ay.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
