import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const search = createApiAction(
  'academic/student/attendance/search',
  client.search('/api/academic/attendance'),
)
export default function setStudentAttendanceReducer(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    state.detail.attendance = action.payload
  })
}
