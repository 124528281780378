import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'room'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'room/search',
  client.search('/api/setting/room'),
)
export const get = createApiAction(
  'room/get',
  client.retrieve(`/api/setting/room/:id`),
)
export const save = createApiAction(
  'room/save',
  client.save('/api/setting/room'),
)
export const destroy = createApiAction(
  'room/delete',
  client.destroy('/api/setting/room/:id'),
)

const update = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, update)
      .addCase(save.fulfilled, update)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})
export default sessionSlice.reducer
