import { BasePathProvider } from 'components/Providers'
import { CourseSessionEnrolledList } from './CourseSessionEnrolledList'
import { search as getCourseSessionEnrollments } from 'store/settings/course-session-enrollments'
import { getCourseSessionsEnrollmentType } from 'store/lookup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const CourseSessionEnrollment = () => {
  const dispatch = useDispatch()
  const { courseSessionId } = useParams()

  const baseUrl = '/settings/course-session/' + courseSessionId + '/enrollment'

  const academicYearId = useSelector(state => state.session?.academicYear?.id)

  const enrolled = useSelector(state => state.settings?.courseSessionsEnrollments)

  const [enrolledStudent, setEnrolledStudent] = useState()
  const [enrolledStaff, setEnrolledStaff] = useState()

  useSelector(state => state.settings?.courseSessionsEnrollments?.list)?.filter(
    item => item.schoolEnrollment?.membership?.type === 'student',
  )
  useSelector(state => state.settings?.courseSessionsEnrollments?.list)?.filter(
    item => item.schoolEnrollment?.membership?.type === 'staff',
  )
  const courseSessionEnrollmentType = useSelector(state => state.lookup?.courseSessionsEnrollmentType)

  useEffect(() => {
    courseSessionEnrollmentType || dispatch(getCourseSessionsEnrollmentType())
  }, [dispatch])

  useEffect(() => {
    courseSessionId &&
      dispatch(getCourseSessionEnrollments({ courseSessionId, academicYearId }))
  }, [dispatch, courseSessionId])

  useEffect(() => {
    if (enrolled) {
      setEnrolledStudent(enrolled?.list?.filter(item => item.schoolEnrollment?.membership?.type === 'student'))
      setEnrolledStaff(enrolled?.list?.filter(item => item.schoolEnrollment?.membership?.type === 'staff'))
    }
  }, [enrolled])

  return (
    <BasePathProvider value={baseUrl}>
      {enrolledStaff && <CourseSessionEnrolledList typeTitle={'Teachers'} type={'staff'} enrolled={enrolledStaff} />}

      {enrolledStudent && (
        <CourseSessionEnrolledList
          typeTitle={`Students (${enrolledStudent.length})`}
          type={'student'}
          enrolled={enrolledStudent}
        />
      )}
    </BasePathProvider>
  )
}
