import { useMembershipType, usePerson } from 'components/Providers'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import CourseEnrollmentList from './List'
import { search, get } from 'store/course-session-enrollment'
import CoourseSessionEnrollmentForm from './Form'
import { BasePathProvider, useBasePath, useSession } from 'components/Providers'
import Header from 'components/Header'
import { Drawer, LinearProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function CourseSessionEnrolments() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const person = usePerson()
  const { academicYear } = useSession()
  const currentPath = useBasePath()
  const membershipType = useMembershipType()
  const { courseSessionEnrollmentId, personId } = useParams()
  const basePath = `${currentPath}/enrollments`

  const [courseEnrollment, setCourseEnrollment] = useState(null)

  const courseSessionEnrollmentFromStore = useSelector(state => state.courseSessionEnrollment.list)

  const [courseSessionEnrollments, setCourseSessionEnrollments] = useState(courseSessionEnrollmentFromStore)

  const schoolEnrollment = useSelector(state => state.settings.schoolEnrollment.selected)

  useEffect(() => {
    setCourseSessionEnrollments(courseSessionEnrollmentFromStore)
  }, [dispatch, courseSessionEnrollmentFromStore?.length])

  useEffect(() => {
    if (!courseSessionEnrollmentFromStore) {
      personId &&
        dispatch(
          search({
            personId,
            membershipType,
            schoolEnrollmentId: schoolEnrollment?.id,
            academicYearId: academicYear?.id,
          }),
        ).then(res => {
          setCourseSessionEnrollments(res.payload)
        })
    } else {
      setCourseSessionEnrollments(courseSessionEnrollmentFromStore)
    }
  }, [dispatch, schoolEnrollment?.id, membershipType])

  useEffect(() => {
    setCourseEnrollment(null)
    if (courseSessionEnrollmentId === 'new') {
      setCourseEnrollment({})
    } else if (courseSessionEnrollmentId) {
      dispatch(get({ membershipType, personId, id: courseSessionEnrollmentId })).then(res =>
        setCourseEnrollment(res.payload),
      )
    }
  }, [dispatch, membershipType, person?.id, courseSessionEnrollmentId])

  if (!courseSessionEnrollmentFromStore) {
    return <LinearProgress sx={{ m: 2 }} color="success" />
  }
  return (
    <>
      <BasePathProvider value={`${basePath}/course-session-enrollment`}>
        <Header
          small
          title={t(
            `Course Session Enrollments   ( Total : ${
              courseSessionEnrollments?.length === 0 ? '' : courseSessionEnrollments?.length
            }) `,
          )}
          add
        />
        <CourseEnrollmentList enrollments={courseSessionEnrollments} membershipType={membershipType} person={person} />
      </BasePathProvider>
      <BasePathProvider value={`${basePath}`}>
        <Drawer
          anchor="right"
          open={Boolean(courseEnrollment)}
          onClose={() => navigate(basePath)}
          sx={{ width: '1000px' }}
        >
          {schoolEnrollment && academicYear?.id && (
            <CoourseSessionEnrollmentForm
              courseEnrollment={courseEnrollment}
              schoolEnrollment={schoolEnrollment}
              membershipType={membershipType}
              person={person}
            />
          )}
        </Drawer>
      </BasePathProvider>
    </>
  )
}
