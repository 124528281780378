import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Route, Routes, useParams, Navigate } from 'react-router-dom'
import { search, reset } from 'store/settings/bell-schedule-period'
import PeriodList from './PeriodList'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { getBellScheduleType } from 'store/lookup'

export default function Periods() {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { bellScheduleId } = useParams()
  const bellScheduleTypes = useSelector(state => state.lookup.bellScheduleType)
  const periods = useSelector(state => state.settings.bellSchedulePeriod.list)

  useEffect(() => {
    bellScheduleTypes || dispatch(getBellScheduleType())
  }, [bellScheduleTypes, dispatch])

  useEffect(() => {
    if (bellScheduleId === 'new') {
      reset()
      return
    }
    if (+bellScheduleId) {
      dispatch(search({ bellScheduleId }))
    }
  }, [bellScheduleId, dispatch])

  return (
    <BasePathProvider value={`${basePath}/period`}>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route index element={periods && <PeriodList periods={periods} />} />
          <Route path="period" element={periods && <PeriodList periods={periods} />} />
          <Route
            path="period/:id"
            element={
              bellScheduleTypes && periods && <PeriodList periods={periods} bellScheduleTypes={bellScheduleTypes} />
            }
          />
          <Route path="*" element={<Navigate to={basePath} />} />
        </Route>
      </Routes>
    </BasePathProvider>
  )
}
