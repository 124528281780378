import { /* Toolbar, */ Box, Drawer } from '@mui/material'
import PersonDetailTabs from './Tabs'
import { styled } from '@mui/material/styles'
import PersonHeader from './PersonHeader'
import { BasePathProvider, useBasePath } from 'components/Providers'
import PersonEditForm from './Form/PersonForm'
import FormSkeleton from './Form/FormSkeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { retrieve as getPersonDetail } from 'store/person/index'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const MainBox = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  [theme.breakpoints.down('lg')]: {
    width: '100vw',
  },
}))

export default function PersonDetail() {
  const dispatch = useDispatch()

  const basePath = useBasePath()
  const { personId, '*': tabName } = useParams()
  const navigate = useNavigate()
  const person = useSelector(state => state.person2.details)

  const jobTitles = useSelector(state => state.settings.jobTitles?.list)
  const jobPositions = useSelector(state => state.settings.jobPosition?.list)
  const departments = useSelector(state => state.settings.departments?.list)
  const evaluations = useSelector(state => state.settings.evaluations?.list)

  const jobTitle = jobTitles?.find(item => item.id === person?.membership?.jobTitleId)

  useEffect(() => {
    personId && dispatch(getPersonDetail({ id: personId }))
  }, [personId])

  const [jobTitleData, setJobTitleData] = useState()

  useEffect(() => {
    setJobTitleData({
      jobTitle,
      jobPosition: jobPositions?.find(item => item.id === jobTitle?.jobPositionId),
      department: departments?.find(item => item.id === jobTitle?.departmentId),
      evaluation: evaluations?.find(item => item.id === jobTitle?.evaluationId),
    })
  }, [personId, jobTitle, jobTitles, jobPositions, departments, evaluations])

  if (!person) return <FormSkeleton />
  if (
    jobTitle?.id &&
    (!jobTitleData?.jobPosition?.id || !jobTitleData?.department?.id || !jobTitleData?.evaluation?.id)
  )
    return <FormSkeleton />

  return (
    <BasePathProvider value={`${basePath}/`}>
      <MainBox>
        {/* <Toolbar /> */}
        <PersonHeader />
        <PersonDetailTabs />
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(tabName === 'profile/details' && person?.id === +personId)}
          anchor="right"
          onClose={() => navigate(`${basePath}/${personId}/profile`)}
        >
          <PersonEditForm jobTitleData={jobTitleData} />
        </Drawer>
      </MainBox>
    </BasePathProvider>
  )
}
