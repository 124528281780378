import { Avatar } from '@mui/material'
import get from 'lodash/get'

const ParentStudents = ({ studentList, location, MenuItem }) => {
  return (
    <>
      {studentList?.map(student => {
        const page = {
          title: [student.firstName, student.lastName].join(' '),
          path: ['/academic/parent/student', student.id].join('/'),
          icon: (
            <Avatar sx={{ width: 32, height: 32 }} src={get(student, 'profilePicture.location')}>
              {[student.firstName[0], student.lastName[0]].join('')}
            </Avatar>
          ),
        }
        return <MenuItem key={page.path} page={page} location={location} />
      })}
    </>
  )
}
export default ParentStudents
