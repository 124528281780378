import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  years: [],
  periods: [],
  query: { limit: 10, offset: 0 },
}

export const search = createApiAction('settings/academic-year/search', client.search('/api/setting/academic-year'))
export const get = createApiAction('settings/academic-year/get', client.retrieve(`/api/setting/academic-year/:id`))
export const save = createApiAction('settings/academic-year/save', client.save('/api/setting/academic-year'))
export const destroy = createApiAction(
  'settings/academic-year/delete',
  client.destroy('/api/setting/academic-year/:id'),
)

/* export const destroyMP = createApiAction('marking-period/delete', client.destroy('/api/setting/marking-period/:id')) */

const updateAcademicYearList = (state, action) => {
  const found = state.years?.find(rec => rec.id === action.payload.id)
  state.years = found
    ? state.years?.map(person => (found.id === person.id ? action.payload : person))
    : [...state.years, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.query = action.meta.arg || initialState.count.query
        state.count = action.payload.count
        state.years = action.payload.rows
      })
      .addCase(get.fulfilled, updateAcademicYearList)
      .addCase(save.fulfilled, updateAcademicYearList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.years = state.years?.filter(ay => ay.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
