import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import SearchBar from './SearchBar'
import MemberList from './MemberList'

function MemberSearch() {
  const { list } = useSelector(state => state.member)

  return (
    <Paper sx={{ width: '100%', mb: 2, minHeight: '500px' }}>
      <SearchBar />
      {list ? <MemberList /> : <div>Skeleton</div>}
    </Paper>
  )
}

export default MemberSearch
