import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'

import Title from './Title'

import { Box, TextField } from '@mui/material'

import { mergeUser } from '../store/session'
import { Body } from './typo'
import MyAlert from './Alert'

export default function MergeUser() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({ open: false, status: '', message: '' })

  const schema = yup
    .object()
    .shape({
      username: yup.string().required('Required'),
      password: yup.string().required('Required'),
    })
    .required()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onSubmit = async data => {
    setState(prev => ({ ...prev, open: false }))
    const res = await dispatch(mergeUser(data))
    if (res.error) {
      setState({ open: true, status: 'error', message: res.error.data?.error || res.error.message })
    } else {
      setState({ open: true, status: 'success', message: 'Your account is merged!' })
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ p: 2 }}>
      <Title>Merge Your Accounts</Title>
      <Body>
        You can merge your other account into current. Please type the credentials of your other account you want to
        merge.
      </Body>
      <Controller
        name="username"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              autoFocus
              fullWidth
              margin="normal"
              // type="password"
              // variant="filled"
              autoComplete="none"
              label={t("EMail")}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...field}
            />
          )
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              fullWidth
              margin="normal"
              type="password"
              // variant="filled"
              label={t("Password")}
              autoComplete="new-password"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...field}
              sx={{ mb: 2 }}
            />
          )
        }}
      />
      <MyAlert open={state.open} status={state.status} title={t(state.message)} />
      <Button fullWidth type="submit" variant="contained" sx={{ mb: 2 }}>
        Merge
      </Button>
    </Box>
  )
}
