import { Route, Routes } from "react-router-dom";
import EnrollmentsPage from "./Page";
import EnrollmentsFormIndex from "./Form";

export default function Campuses() {
  return (
    <Routes>
      <Route path="/" element={<EnrollmentsPage />}>
      <Route path="/:enrollmentId/*" element={<EnrollmentsFormIndex />} />
      </Route>
    </Routes>
  )
}
