import client from 'utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from 'utils/redux-tool'
import { set } from 'lodash'

const name = 'academic-enrollment'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'academic/enrollment/search',
  client.search('/api/academic/course-session/:courseSessionId/enrollment'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        set(state, `list`, null)
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
  },
})

export default sessionSlice.reducer
