import { Link, TableCell, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

export const GradeItem = ({ item, grades, markingPeriodCount, cellWidth }) => {
  const permission = useSelector(state => state.session.permissions)

  const [final, setFinal] = useState(null)

  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  useEffect(() => {
    if (grades) {
      let sum = 0
      let count = 0
      grades.forEach(score => {
        if (score >= 0) {
          sum += score
          count++
        }
      })
      setFinal(Math.round(sum / count))
    }
  }, [grades])

  return (
    <TableRow hover>
      <TableCell sx={{ textAlign: 'left' }}>
        {hasCourseSessionViewPermission ? (
          <Link
            component={NavLink}
            to={`/settings/course-session/${item.courseSession.id}`}
            underline="hover"
            color="inherit"
          >
            {item.courseSession?.course?.name} - {item.courseSession?.name}
          </Link>
        ) : (
          <>
            {item.courseSession?.course?.name} - {item.courseSession?.name}
          </>
        )}
      </TableCell>
      {final ? (
        <>
          {grades?.map((el, index) => (
            <TableCell sx={{ textAlign: 'center', width: `${cellWidth}px` }} key={index}>
              {el}
            </TableCell>
          ))}
          <TableCell sx={{ textAlign: 'center', width: `${cellWidth}px` }}>{final}</TableCell>
        </>
      ) : (
        [...Array(markingPeriodCount + 1)].map((_, index) => (
          <TableCell sx={{ width: `${cellWidth}px` }} key={index}>
            {null}
          </TableCell>
        ))
      )}
    </TableRow>
  )
}
