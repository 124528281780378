import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import EmploymentsListIndex from './List'
import { Drawer } from '@mui/material'
import EmploymentsFormIndex from './Form'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/person/employment'
import { getClients } from 'store/lookup'

export default function EmploymentsPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { personId, employmentId } = useParams()
  const basePath = `/person/${personId}/employment`
  const employments = useSelector(state => state.person2.employments)
  const { clients } = useSelector(state => state.lookup)

  useEffect(() => {
    clients || dispatch(getClients())
  }, [])

  useEffect(() => {
    (employments && employments[+personId]) || dispatch(search({ personId: +personId }))
  }, [dispatch, personId])

  if (!(clients && employments && employments[+personId])) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <EmploymentsListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(employmentId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <EmploymentsFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
