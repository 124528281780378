import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import {
  Button,
  Container,
  Box,
  Paper,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import { useTranslation } from 'react-i18next'

import MyAlert from '../../components/Alert'
import { login } from '../../store/session'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export default function LoginForm() {
  const { t } = useTranslation()
  const [payload, setPayload] = useState({ username: '', password: '' })
  const [state, setState] = useState({ open: false, error: null })
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const schema = yup
    .object()
    .shape({
      username: yup.string().required().email(),
      password: yup.string().required(),
    })
    .required()

  const onFormSubmit = async event => {
    event.preventDefault()

    const sleep = ms => new Promise(r => setTimeout(r, ms))

    if (state.open) {
      setState(prev => ({ ...prev, open: false }))
      await sleep(500)
    }

    const isValid = await schema.isValid(payload)
    if (isValid) {
      const res = await dispatch(login(payload))
      if (res.error) {
        setState({
          open: true,
          error: res.error?.data?.error || res.error?.message,
        })
      } else {
        navigate('/')
      }
    } else {
      const err = await schema.validate(payload).catch(err => err)
      setState({
        open: true,
        error: err?.message,
      })
    }
  }

  const handleChange = event => {
    let { name, value } = event.target
    setPayload(prev => ({ ...prev, [name]: value }))
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{bgcolor: 'primary.main', borderRadius: '20px', borderBottom: 'solid 5px', borderRight: 'solid 5px', borderColor: 'primary.main', }}>
      <Card data-component="LoginForm" sx={{p: 2, borderRadius: '20px', }} >
        <CardContent>
          <Box component="form" onSubmit={onFormSubmit}>
            <Box
              sx={{
                mt: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
                <img src='/Logo.png'/ >
              <Typography component="h1" variant="h5" gutterBottom sx={{pt: 2}}>
                {t('Student Information System')}
              </Typography>
              <Typography component="h1" variant="body1" gutterBottom>
                {t('Sign in to your account')}
              </Typography>
            </Box>
            <TextField
              fullWidth
              margin="normal"
              name="username"
              autoComplete="username"
              label={t('Username')}
              value={payload.username}
              onChange={handleChange}
              autoFocus={true}
              data-label="login-username"
            ></TextField>
            <TextField
              fullWidth
              margin="normal"
              type={!passwordVisibility ? 'password' : 'text'}
              name="password"
              autoComplete="current-password"
              label={t('Password')}
              value={payload.password}
              onChange={handleChange}
              sx={{ mb: 2 }}
              data-label="login-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setPasswordVisibility(!passwordVisibility)} edge="end">
                      {!passwordVisibility ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MyAlert open={state.open} status="error" title={state.error} />
            <Button type="submit" variant="contained" fullWidth data-label="login-submit">
              {t('Login')}
            </Button>
          </Box>
        </CardContent>
        <CardActions sx={{ margin: 1, justifyContent: 'center'}}>
              <Link to="/auth/forgot" style={{textDecoration: 'none', color: 'primary.main' }}>{t('Forgot password?')}</Link>
        </CardActions>
      </Card>
      </Paper>
    </Container>
  )
}
