import { Route, Routes } from 'react-router-dom'
import LeaveRequestPage from './Page'
import LeaveRequestForm from './Form'

export default function index() {
  return (
    <Routes>
      <Route path="/*" element={<LeaveRequestPage />}></Route>
      <Route path="/:id" element={<LeaveRequestPage />}></Route>
      <Route path="/:id" element={<LeaveRequestForm />}></Route>
    </Routes>
  )
}
