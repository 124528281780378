import { Route, Routes } from 'react-router-dom'
import SchedulingEnrollStudentFormIndex from './Form'
import SchedulingEnrollStudentPage from './Page'

export default function SchedulingEnrollStudent() {
  return (
    <Routes>
      <Route path="/" element={<SchedulingEnrollStudentPage />}>
        <Route path="/:enrollId" element={<SchedulingEnrollStudentFormIndex />} />
      </Route>
    </Routes>
  )
}
