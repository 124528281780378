import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'calendar'
const initialState = {
  list: null,
}

const basePath = '/api/provider/calendar'
export const search = createApiAction('provider/calendar/search', client.search(basePath))
export const retrieve = createApiAction('provider/calendar/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('provider/calendar/save', client.save(basePath))
export const destroy = createApiAction('provider/calendar/destroy', client.destroy(`${basePath}/:id`))

const sortByStartDate = calendar => {
  return calendar.slice().sort((a, b) => {
    return new Date(a.startDate) - new Date(b.startDate)
  })
}

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        const sortedList = sortByStartDate(action.payload)
        state.list = sortedList
      })
      .addCase(save.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        let list = state.list.filter(calendar => calendar.id !== +id) || []
        list.push({ id: +id, ...action.payload })
        state.list = sortByStartDate(list)
      })
      .addCase(destroy.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        let list = state.list.filter(calendar => calendar.id !== +id) || []
        state.list = sortByStartDate(list)
      })
  },
})
export default sessionSlice.reducer
