// import { useState } from 'react'
import { Button, Stack, TableCell, TableRow, } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { useBasePath } from 'components/Providers'
import dayjs from 'dayjs'


export default function VisitListItem({ item }) {

  const basePath = useBasePath()
  const { t } = useTranslation()
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`${basePath}/${item.id}`, { state: { action: 'edit' } });
  };

  const handleEndVisitClick = () => {
    navigate(`${basePath}/${item.id}`, { state: { action: 'endVisit' } });
  };

  return (
    <TableRow hover>
      <TableCell>{item.person?.lastName}, {item.person?.firstName}</TableCell>
      <TableCell>{dayjs(item.startTime).format('hh:mm A')}</TableCell>
  
      <TableCell align="left">
      <Stack spacing={1} direction='row'>
        <Button
          variant='outlined'
          size='small'
          onClick={handleEditClick}
        >
          {t('Edit')}
        </Button>
        <Button
          variant='outlined'
          size='small'
          onClick={handleEndVisitClick}
        >
         {t('End Visit')}
        </Button>
      </Stack>
      </TableCell>
    </TableRow>
  )
}
