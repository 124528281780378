import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import SchedulingCoursesListItem from './ListItem'
import _ from 'lodash'
import { useSelector } from 'react-redux'

export default function SchedulingCoursesList({ handleChangePage, handleChangeRowsPerPage }) {
  const { t } = useTranslation()
  const { query, list, count } = useSelector(state => state.settings.course)

  if (!list) return null

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('SCED Code')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Subject Area')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Course Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Credits')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Actions')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              <SchedulingCoursesListItem key={index} item={item} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={_.uniq([query?.limit, 10, 15, 20, 25, 50, 100])}
        />
      </TableContainer>
    </Fragment>
  )
}
