import { combineReducers } from '@reduxjs/toolkit'

import nurseReducer from './nurse-health-condition'
import nurseVisitReducer from './nurse-visit'

const combinedReducer = combineReducers({
  healthCondition: nurseReducer,
  nurseVisit: nurseVisitReducer,
})

export default combinedReducer