import { Route, Routes } from 'react-router-dom'
import SchedulingCourseSessions from './CourseSessions'
import SchedulingCourses from './Courses'
import SchedulingTabs from './Tabs'
import { BasePathProvider } from 'components/Providers'
import SchedulingEnrollStudent from './EnrollStudent'
import SchedulingEnrollCourse from './EnrollCourse'
import { Box, Paper } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'

export default function Scheduling() {
  const basePath = '/settings/scheduling'

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ pb: 2 }}>
        <PageHeader title={'Scheduling'} />
      </Box>
      <Paper sx={{ minHeight: '500px', p: 2, mb: 2 }}>
        <SchedulingTabs />
        <Routes>
          <Route path="/">
            <Route path="" element={<SchedulingCourses />} />
            <Route path="/courses/*" element={<SchedulingCourses />} />
            <Route path="/course-sessions/*" element={<SchedulingCourseSessions />} />
            <Route path="/enroll-student/*" element={<SchedulingEnrollStudent />} />
            <Route path="/enroll-session/*" element={<SchedulingEnrollCourse />} />
          </Route>
        </Routes>
      </Paper>
    </BasePathProvider>
  )
}
