import client from '../../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../../utils/redux-tool'
import setStudentFinalGradesReducer from './final-grades'
import setStudentAttendanceReducer from './attendance'
import setStudentStateDateReducer from './state-data'
import setCourseEnrollmentReducers from './course-session-enrollment'

const name = 'academic-student'
const initialState = {
  list: null,
  count: null,
  detail: {
    schedule: null,
    finalGrades: null,
    attendance: null,
    stateData: null,
    courseSessionEnrollment: null,
  },
  query: {
    limit: 10,
    offset: 0,
  },
}

export const search = createApiAction('academic/student/search', client.search('/api/academic/student'))

export const get = createApiAction('academic/student/get', client.retrieve('/api/academic/student/:id'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows || []
        state.count = action.payload.count
        state.query = action.meta.arg
      })
      .addCase(get.pending, (state) => {
        state.detail = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.detail = action.payload
      })
    setStudentFinalGradesReducer(builder)
    setStudentAttendanceReducer(builder)
    setStudentStateDateReducer(builder)
    setCourseEnrollmentReducers(builder)
  },
})

export default sessionSlice.reducer
