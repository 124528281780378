import { Route, Routes } from 'react-router-dom'
import PeriodPage from './Page'

export default function Period() {
  return (
    <Routes>
      <Route path="/" element={<PeriodPage />} />
      <Route path="/:id" element={<PeriodPage />} />
    </Routes>
  )
}
