import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const search = createApiAction(
  'academic/student/course-session-enrollment/search',
  client.search('/api/academic/:type/:memberId/course-session-enrollment'),
)

export default function setCourseEnrollmentReducers(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    state.detail.courseSessionEnrollment = action.payload
  })
}
