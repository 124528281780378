import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search } from 'store/settings/job-position'
import JobPositionList from './List'
import JobPositionFormIndex from './Form'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import JobPositionListSkeleton from 'pages/Settings/JobPosition/List/ListSkeleton'

const baseUrl = '/settings/job-position'

export default function JobPosition() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jobPositions = useSelector(state => state.settings.jobPosition.list)
  const { id } = useParams()

  useEffect(() => {
    jobPositions?.length || dispatch(search())
  }, [dispatch, Boolean(jobPositions)])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'Job Position List'} add />
      {jobPositions ? (
        <JobPositionList jobPositions={jobPositions} />
      ) : (
        <JobPositionListSkeleton />
      )}
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <JobPositionFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
