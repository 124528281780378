import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { get } from 'store/settings/course-session'
import CourseSessionEditForm from './CourseSessionEditForm'
import FormSkeleton from './FormSkeleton'
import { getMarkingPeriods } from 'store/lookup'
import NotFound from 'components/NotFound'

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  width: theme.breakpoints.values.sm,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export default function CourseSessionEditFormWrapper({ query }) {
  const { courseSessionId: id } = useParams()
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const [notFound, setNotFound] = useState(false)

  let markingPeriods = useSelector(state => state.session.academicYear?.markingPeriods)

  if (markingPeriods === undefined || !markingPeriods) {
    markingPeriods = useSelector(state => state.lookup.markingPeriods?.markingPeriods)
  }
  const buildingRooms = useSelector(state => state.lookup.building)

  useEffect(() => {
    query.academicYearId && dispatch(getMarkingPeriods({ id: query.academicYearId }))
  }, [dispatch])

  const roomList = useMemo(() => {
    const newRoomList = []

    buildingRooms?.forEach(item => {
      item.buildingRooms?.forEach(room => {
        const obj = {
          id: item.id,
          buildingName: item.name,
          buildingId: item.id,
          doorNumber: room.doorNumber,
          type: room.type,
          roomName: room.name,
          buildingRoomId: room.id,
        }
        newRoomList.push(obj)
      })
    })

    return newRoomList
  }, [buildingRooms])

  const courseSession = useMemo(() => {
    if (!data) {
      return null
    }
    const mps = data.markingPeriods?.map(mp => mp?.id)

    return {
      ...data,
      buildingRooms,
      markingPeriods: markingPeriods?.map(mp => ({ ...mp, enabled: !data.id || mps.includes(mp.id) })),
    }
  }, [data, markingPeriods])

  useEffect(() => {
    if (!id) {
      setData(null)
      return
    }
    if (id === 'new') {
      setData({})
      return
    }
    dispatch(get({ id })).then(res => {
      res.payload && setData(res.payload)
      res.error && setNotFound(true)
    })
  }, [dispatch, id])

  return (
    <Root>
      {courseSession && markingPeriods ? (
        <CourseSessionEditForm data={courseSession} roomList={roomList} />
      ) : notFound ? (
        <NotFound title={'Course Session'} />
      ) : (
        <FormSkeleton />
      )}
    </Root>
  )
}
