import { Box, Drawer, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import Header from 'components/Header'
import TableHeadCell from 'components/TableHeadCell'
import { CourseSessionEnrolledItem } from './CourseSessionEnrolledItem'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider } from 'components/Providers'
import CourseSessionEnrolledEditFormWrapper from './CourseSessionEnrolledEditFormWrapper'

export const CourseSessionEnrolledList = ({ type, typeTitle, enrolled }) => {
  const navigate = useNavigate()
  const { enrollmentId: id } = useParams()
  const { courseSessionId } = useParams()

  const baseUrl = '/settings/course-session/' + courseSessionId + '/enrollment'

  const openForm = () => {
    navigate(baseUrl + '/' + (id || 'new') + '?type=' + type)
  }
  return (
    <BasePathProvider value={baseUrl}>
      <Box sx={{ pt: 4, pb: 0, pl: 2, pr: 2 }}>
        <Header
          title={typeTitle}
          sx={{ p: 0 }}
          small
          add={() => {
            openForm()
          }}
        />
        <Paper variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableHeadCell>Name</TableHeadCell>
                {type === 'staff' && <TableHeadCell>Roles</TableHeadCell>}
                <TableHeadCell>Start Date</TableHeadCell>
                <TableHeadCell>End Date</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enrolled?.map((item, index) => (
                <CourseSessionEnrolledItem key={index} data={item} type={type} />
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Drawer
          PaperProps={{
            sx: {
              width: 400,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(`/settings/course-session/${courseSessionId}`)}
        >
          <CourseSessionEnrolledEditFormWrapper />
        </Drawer>
      </Box>
    </BasePathProvider>
  )
}
