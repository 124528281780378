import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId'

export const getUserRoles = createApiAction('userrole/search', client.search(`${basePath}/role`))
export const setUserRoles = createApiAction('userrole/set', client.create(`${basePath}/role`))

export const getUserServiceRoles = createApiAction('servicerole/search', client.search(`${basePath}/service-role`))

export const setUserServiceRoles = createApiAction('servicerole/set', client.create(`${basePath}/service-role`))

function foundMemberIndex(state, action) {
  const list = state.list || []
  const { memberId } = action.meta.arg
  const member = list.find(item => item.id === memberId)
  const memberIndex = list.indexOf(member)
  if (member) return { memberIndex, member }
  else throw new Error(`Member with id ${memberId} not found.`)
}

const updatePersonRole = (state, action) => {
  const { member, memberIndex } = foundMemberIndex(state, action)
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  state.list[memberIndex].roles = action.payload
}
const updatePersonServiceRole = (state, action) => {
  const { member, memberIndex } = foundMemberIndex(state, action)
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  state.list[memberIndex].serviceRoles = action.payload
}

export default function setRolesReducers(builder) {
  builder
    .addCase(getUserRoles.fulfilled, updatePersonRole)
    .addCase(setUserRoles.fulfilled, updatePersonRole)
    .addCase(getUserServiceRoles.fulfilled, updatePersonServiceRole)
    .addCase(setUserServiceRoles.fulfilled, updatePersonServiceRole)
}
