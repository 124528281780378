import { Route, Routes } from 'react-router-dom'
import SchoolPage from './Page'
import SchoolFormIndex from './Form'

export default function School() {
  return (
    <Routes>
      <Route path="/" element={<SchoolPage />}>
        <Route path="/:id" element={<SchoolFormIndex />} />
      </Route>
    </Routes>
  )
}
