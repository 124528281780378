import { Route, Routes } from 'react-router-dom'
import SchedulingCoursesPage from './Page'
import SchedulingCoursesFormIndex from './Form'

export default function SchedulingCourses() {
  return (
    <Routes>
      <Route path="/" element={<SchedulingCoursesPage />}>
        <Route path="/courses" element={<SchedulingCoursesPage />} />
        <Route path="/:courseId" element={<SchedulingCoursesFormIndex />} />
      </Route>
    </Routes>
  )
}
