import { Box } from '@mui/material'
import StudentSearchBar from './Search'
import StudentAttendanceList from './List'

export default function StudentAttendanceListIndex({
  student,
  setStudent,
  setDateRange,
  dateRange,
}) {
  return (
    <Box>
      <StudentSearchBar
        student={student}
        setStudent={setStudent}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <StudentAttendanceList />
    </Box>
  )
}
