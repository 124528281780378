import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow, TablePagination } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import AcademicYearItem from './AYItem'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/academic-year'

export default function AcademicYearList() {
  const dispatch = useDispatch()

  const { query, years, count } = useSelector(state => state.academicYear)

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }

  return (
    <Paper sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Academic Year</TableHeadCell>
                    <TableHeadCell sx={{ width: '15em' }}>Start Date</TableHeadCell>
                    <TableHeadCell sx={{ width: '15em' }}>End Date</TableHeadCell>
                    <TableHeadCell sx={{ width: '14em' }}>&nbsp;</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {years?.map(item => {
                    return <AcademicYearItem key={item.id || item.uid} item={item} />
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={count || 0}
                page={query?.limit ? query?.offset / query?.limit : 0}
                onPageChange={handleChangePage}
                rowsPerPage={query?.limit || 10}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
