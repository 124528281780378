import { Grid } from '@mui/material'

import PhoneEditForm from './PhoneEditForm'

export default function PhoneList({ path, fields, control, append, ...props }) {
  return (
    <Grid container spacing={2}>
      {fields.map((item, index) => (
        <PhoneEditForm
          key={item.id}
          path={path}
          fields={fields}
          control={control}
          append={append}
          item={item}
          index={index}
          {...props}
        />
      ))}
    </Grid>
  )
}
