import { CardContent, Grid, Skeleton, Toolbar, Box, Stack, Button, CardActions, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from 'components/Buttons'
import { DeleteIcon } from 'components/Icons'

export default function ContactsFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Box sx={{ px: 3, py: 2, overflowY: 'scroll', border: 'none' }}>
      <Toolbar />
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        {t('Parent/Guardian Information')}
      </Typography>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={6} md={9}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={6} md={9}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={100} sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="contained" color="error" disabled>
                <DeleteIcon /> {t('DELETE CONTACT')}
              </Button>
              <SubmitButton color="primary" title={t('SAVE CHANGES')} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}></CardActions>
    </Box>
  )
}
