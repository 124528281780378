import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Toolbar,
  MenuItem,
  IconButton,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/academic/grading-policy'
import { getAssessmentType } from 'store/lookup'
import * as yup from 'yup'
import { useEffect } from 'react'
import { useBasePath } from 'components/Providers'
import { useParams } from 'react-router-dom'
import { omit } from 'lodash'
import InputAdornment from '@mui/material/InputAdornment'

import { CloseIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'

export default function GradingEditForm({ gradingPolicy }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { courseSessionId } = useParams()
  const { assessmentType } = useSelector(state => state.lookup)
  const { t } = useTranslation()

  const schema = yup.object().shape({
    type: yup.string().required(),
    weight: yup.number().integer().min(0).max(100).required(),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...gradingPolicy,
      weight: gradingPolicy.weight * 100,
    },
  })

  useEffect(() => {
    assessmentType || dispatch(getAssessmentType())
  }, [dispatch, assessmentType])

  const onSubmit = async data => {
    let weight = data.weight / 100
    const payload = { ...omit(data, 'weight', 'courseSession'), courseSessionId }
    const res = await dispatch(save({ ...payload, weight }))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <CardHeader
        title={t("Grading Policy")}
        subheader="create/edit"
        action={
          <IconButton onClick={() => navigate(basePath)}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="type"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    autoFocus
                    fullWidth
                    size="small"
                    label={t("type")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    {assessmentType?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>{' '}
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="weight"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("weight")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t("Cancel")}
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          {t("Submit")}
        </Button>
      </CardActions>
    </Card>
  )
}
