import { Card, CardContent, Grid, Skeleton, Toolbar, Box, CardActions, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

export default function FormSkeleton() {
  const MainBox = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.lg,
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
    },
  }))
  return (
    <MainBox>
      <Card>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid item xs={2} md={2}>
            <Skeleton variant="circular" height={120} width={'70%'} sx={{ mt: 3, ml: 2 }} />
          </Grid>
          <Divider />
          <Grid item xs={7} md={7}>
            <Skeleton variant="rounded" height={40} width={'50%'} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={30} width={'35%'} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={30} width={'20%'} sx={{ mt: 3 }} />
          </Grid>
          <Grid item xs={2} md={2}>
            <Skeleton variant="rounded" height={40} width={'80%'} sx={{ mt: 3, ml: 13 }} />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={300} width={'100%'} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton variant="rounded" height={300} width={'100%'} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton variant="rounded" height={300} width={'100%'} sx={{ mt: 1 }} />
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}></CardActions>
      </Card>
    </MainBox>
  )
}
