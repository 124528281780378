import { Fragment, useState } from 'react'
import { TextField, Grid, IconButton, MenuItem } from '@mui/material'
// import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import RemoveIcon from '@mui/icons-material/HighlightOff'
import Confirm from '../../../components/Dialog'
// import { deleteAddress } from '../../../store/person'
import { useMembershipType, usePerson } from '../../../components/Providers'

export default function AddressEditForm({ path, control, item, index, ...props }) {
  const membershipType = useMembershipType()
  const person = usePerson()
  // const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  async function remove(id, index, confirmed = false) {
    console.log({ membershipType, personId: person.id, id })
    try {
      if (!id) {
        props.remove(index)
        return
      }
      if (!confirmed) {
        setOpen(true)
        return
      }
      setOpen(false)
      // const res = await dispatch(deleteAddress({ membershipType, personId: person.id, id }))
      // if (res.error) {
      //   console.error(res)
      //   throw new Error('Error')
      // }
      props.remove(index)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Grid item md={12} key={item?.id}>
      <Grid container spacing={2}>
        <Fragment key={item?.uuid}>
          <Grid item xs={3} md={1.5}>
            <Controller
              name={`${path}.${index}.type`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    select
                    label={t('Address')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    <MenuItem value="home">{t('Home')}</MenuItem>
                    <MenuItem value="work">{t('Work')}</MenuItem>
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name={`${path}.${index}.street`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    label={t('Address 1')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name={`${path}.${index}.line2`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    label={t('Address 2')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Controller
              name={`${path}.${index}.city`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    label={t('City')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Controller
              name={`${path}.${index}.state`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    label={t('State')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Controller
              name={`${path}.${index}.zip`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    label={t('Postal Code')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={0.5}>
            <IconButton
              onClick={() => {
                remove(item.id, index)
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Fragment>
      </Grid>
      <Confirm open={open} onClose={() => setOpen(false)} onConfirm={() => remove(item.id, index, true)} />
    </Grid>
  )
}
