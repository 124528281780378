import {
    Card,
    CardContent,
    Grid,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from '@mui/material'
  import TableHeadCell from 'components/TableHeadCell'
  import { useTranslation } from 'react-i18next'
  
  export default function PeriodListSkeleton() {
    const { t } = useTranslation()
  
    return (
      <Paper data-component="PeriodList" sx={{ minHeight: '50%' }}>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item sm={12} xs={12} md={12}>
            <Card sx={{ minHeight: '600px' }}>
              <CardContent sx={{ pl: 0, pr: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell width={'20%'}>{t('Name')}</TableHeadCell>
                      <TableHeadCell width={'15%'}>{t('Type')}</TableHeadCell>
                      <TableHeadCell width={'15%'}>{t('Daily Attendance')}</TableHeadCell>
                      <TableHeadCell width={'15%'}>{t('Regular')}</TableHeadCell>
                      <TableHeadCell width={'15%'}>{t('Early Dismissal')}</TableHeadCell>
                      <TableHeadCell width={'15%'}>{t('Delayed Opening')}</TableHeadCell>
                      <TableHeadCell width={'5%'}></TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    )
  }
  