import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'
import dayjs from 'dayjs'

const name = 'settings-attendance'

const initialState = {
  list: null,
  selected: null,
  query: { limit: 25, offset: 0 },
}

export const search = createApiAction('setting/attendance/search', client.search('/api/setting/attendance'))

export const get = createApiAction('setting/attendance/get', client.search('/api/setting/attendance/:id'))

export const searchStudent = createApiAction(
  'academic/attendance/period/search',
  client.search('/api/academic/attendance/period'),
)

export const save = createApiAction('setting/attendance/save', client.save('/api/setting/attendance'))

export const update = createApiAction('setting/attendance/update', client.update('/api/setting/attendance/:id'))

export const destroy = createApiAction('setting/attendance/delete', client.destroy('/api/setting/attendance/:id'))

const updateList = (state, action) => {
  if (state?.list) {
    if (state.list.enrollmentList) {
      state.list.periodAttendance = action.payload.periodAttendance
    } else if (state.list.attendancesInRange) {
      const found = state.list.attendancesInRange.find(
        rec => dayjs(rec.date).format('MM-DD-YYYY') === dayjs(action.payload.date).format('MM-DD-YYYY'),
      )
      state.list.attendancesInRange = found
        ? state.list.attendancesInRange.map(item =>
            dayjs(found?.date).format('MM-DD-YYYY') === dayjs(item.date).format('MM-DD-YYYY')
              ? action.payload
              : item,
          )
        : [...state.list.attendancesInRange, action.payload]
    } else {
      const found = state.list.rows?.find(rec => rec.schoolEnrollmentId === action.payload.schoolEnrollmentId)
      state.list.rows = found
        ? state.list.rows.map(item => (found.schoolEnrollmentId === item.schoolEnrollmentId ? action.payload : item))
        : [...state.list.rows, action.payload]
    }
  }
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearAttendance: state => {
      state.list = null
    },
    setSearchLimit: (state, action) => {
      state.query.limit = action.payload
    },
    setSearchOffset: (state, action) => {
      state.query.offset = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        state.list = null
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        if (state.list.attendancesInRange) {
          const found = state.list.attendancesInRange?.find(rec => rec?.dailyAttendance?.id === action.payload.id)
          // eslint-disable-next-line no-unused-vars
          const newPeriodAttendace = found.periodAttendance?.map(item => (item = null))
          found.dailyAttendance = null
          found.periodAttendance = newPeriodAttendace
        } else {
          const found = state.list.rows?.find(rec => rec?.dailyAttendance?.id === action.payload.id)
          // eslint-disable-next-line no-unused-vars
          const newPeriodAttendace = found.periodAttendance?.map(item => (item = null))
          found.dailyAttendance = null
          found.periodAttendance = newPeriodAttendace
        }
      })
  },
})
export const { clearAttendance, setSearchLimit, setSearchOffset } = sessionSlice.actions

export default sessionSlice.reducer
