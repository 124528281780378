import MarkingPeriodList from './MPList'
import { BasePathProvider } from 'components/Providers'
import { useBasePath } from 'components/Providers'
import { useSelector } from 'react-redux'
import MarkingPeriodSkeleton from './MPSkeleton'

export default function MarkingPeriodListIndex() {
  const basePath = useBasePath()

  const markingPeriods = useSelector(state => state.markingPeriod.list)

  let isLoading = true
  if (!markingPeriods) {
    isLoading = true
  } else {
    isLoading = false
  }

  return (
    <BasePathProvider value={`${basePath}`}>
      {isLoading ? <MarkingPeriodSkeleton /> : <MarkingPeriodList />}
    </BasePathProvider>
  )
}
