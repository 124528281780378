import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'

import StaffSearch from './Search'
import Detail from './detail'

import { BasePathProvider, useMembershipType, useSession } from '../../components/Providers'
import { useTranslation } from 'react-i18next'
import { search } from '../../store/person'
import { capitalize } from 'lodash'
import Header from '../../components/Header'
import { clearSchoolEnrollments } from 'store/settings/school-enrollment'
import { clearCourseSessionEnrollments } from 'store/course-session-enrollment'
import { clearFinalGrades } from 'store/person-grade'
import { clearPersonAttendanceDaily } from 'store/person-attendance-daily'
import { clearPersonAttendancePeriod } from 'store/person-attendance-period'

function Person() {
  const membershipType = useMembershipType()
  const { academicYear } = useSession()
  const { personId } = useParams()
  const { query, count } = useSelector(state => state.person[membershipType])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    Number.isInteger(count) ||
      dispatch(search({ ...query, limit: 10, offset: 0, scope: academicYear ? 'academicYear' : 'district' }))
  }, [dispatch, count, query])

  const handleClose = () => {
    dispatch(clearSchoolEnrollments())
    dispatch(clearCourseSessionEnrollments())
    dispatch(clearFinalGrades())
    dispatch(clearPersonAttendanceDaily())
    dispatch(clearPersonAttendancePeriod())
    navigate(`/${membershipType}`)
  }

  return (
    <BasePathProvider value={`/${membershipType}`}>
      <Header title={t(`${capitalize(membershipType)} List`)} add />
      <StaffSearch />
      <Drawer anchor="right" open={Boolean(personId)} onClose={handleClose}>
        <Detail />
      </Drawer>
    </BasePathProvider>
  )
}

export default Person
