import { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as service from '../../../store/settings/role'

import UserRoles from './UserRoles'
import ServiceRoles from './ServiceRoles'

export default function Roles() {
  const permissions = useSelector(state => state.session.permissions)
  const { count } = useSelector(state => state.settings.role)
  const { district } = useSelector(state => state.session)
  const dispatch = useDispatch()

  useEffect(() => {
    count || dispatch(service.search())
  }, [dispatch, count])

  if (count === null) return null

  return (
    <Fragment>
      <UserRoles />
      {district.isServiceProvider && permissions['staff.service.role'] > 0 && <ServiceRoles />}
    </Fragment>
  )
}
