import { Route, Routes } from 'react-router-dom'
import Tabs from './Tabs'
import { BasePathProvider } from 'components/Providers'
import { Box } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import DailyAttendance from './Daily'
import StudentAttendance from './Student'
import TeacherAttendance from './Teacher'

export default function Attendance() {
  const basePath = '/attendance'

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Attendance'} />
      </Box>
      <Tabs />
      <Routes>
        <Route path="/">
          <Route path="" element={<DailyAttendance />} />
          <Route path="daily/*" element={<DailyAttendance />} />
          <Route path="student/*" element={<StudentAttendance />} />
          <Route path="teacher/*" element={<TeacherAttendance />} />
        </Route>
      </Routes>
    </BasePathProvider>
  )
}
