import { useState } from 'react'
import { Box, Drawer } from '@mui/material'
import { BasePathProvider } from 'components/Providers'
import HealthConditionsList from './List'
import NurseAddConditionFormIndex from './Form'
import { useNavigate, useParams } from 'react-router-dom'


const baseUrl = '/nurse/health-conditions'

export default function HealthConditionsPage() {
  const navigate = useNavigate()
  const [selected, setSelected] = useState()
  const { id } = useParams()

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
          <HealthConditionsList 
           selected={selected}
           setSelected={setSelected}
         /> 
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(baseUrl)}
        >
          <NurseAddConditionFormIndex selected={selected} /> 
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
