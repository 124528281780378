import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { omit } from 'lodash'
import RoleList from './RoleList'
import RoleEdit from './RoleEdit'
import ModuleList from './ModuleList'

import { useDispatch, useSelector } from 'react-redux'

import * as service from '../../../store/settings/role'
import { useParams } from 'react-router-dom'
import Loading from 'components/Loading'
import { BasePathProvider } from 'components/Providers'
import PageHeader from 'components/AppBar/PageHeader'

function Role() {
  const { id } = useParams()
  const [role, setRole] = useState(null)
  const { count, search: roles } = useSelector(state => state.settings.role)
  const dispatch = useDispatch()
  useEffect(() => {
    const initialize = async () => {
      if (count === null) await dispatch(service.search())
      if (id) {
        const { error, payload } = await dispatch(service.get({ id: parseInt(id) || 0 }))
        if (!error && payload) setRole(payload)
      } else {
        setRole(null)
      }
    }
    initialize()
  }, [dispatch, id, count])

  if (!roles)
    return <Loading />

  return (
    <BasePathProvider value="/settings/permission">
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Permissions'} add/>
      </Box>      <Grid container spacing={2}>
        <Grid item xs={12} md={role ? 3 : 12}>
          <RoleList roles={roles} />
        </Grid>
        <Grid item xs={12} md={9}>
          {role && <RoleEdit role={role} />}
          {role && role.modules && <ModuleList role={omit(role, 'modules')} modules={role.modules} />}
        </Grid>
      </Grid>
    </BasePathProvider>
  )
}

export default Role
