import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { Box } from '@mui/material'

import AppearanceControl from '../auth/appearance'

import Invitation from './Invitation'
import EMail from './EMail'

function Container() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
      <Outlet />
      <AppearanceControl />
    </Box>
  )
}

export default function IndexPage() {
  return (
    <Routes>
      <Route path="/" element={<Container />}>
        <Route path="invitation/:token" element={<Invitation />} />
        <Route path="email/:token" element={<EMail />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Route>
    </Routes>
  )
}
