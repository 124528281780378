import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import { search } from 'store/settings/grade-level-section'
import GradeSectionList from './List'
import GradeSectionEditFormIndex from './Form'
import { BasePathProvider } from 'components/Providers'
import GradeSectionListSkeleton from './List/ListSkeleton'

const baseUrl = '/settings_/grade-sections'

export default function GradeLevelSection() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const gradeSections = useSelector(state => state.settings.gradeLevelSection?.list)
  const { id } = useParams()

  useEffect(() => {
    gradeSections || dispatch(search())
  }, [dispatch, gradeSections])

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
        {gradeSections ? <GradeSectionList gradeSections={gradeSections} /> : <GradeSectionListSkeleton />}
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(baseUrl)}
        >
          <GradeSectionEditFormIndex />
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
