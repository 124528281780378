import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'academic/grading-policy/search',
  client.search('/api/academic/course-session/:courseSessionId/grading-policy'),
)
export const get = createApiAction(
  'academic/grading-policy/get',
  client.retrieve(`/api/academic/course-session/:courseSessionId/grading-policy/:id`),
)
export const save = createApiAction(
  'academic/grading-policy/save',
  client.save('/api/academic/course-session/:courseSessionId/grading-policy'),
)
export const destroy = createApiAction(
  'academic/grading-policy/delete',
  client.destroy('/api/academic/course-session/:courseSessionId/grading-policy/:id'),
)

const updateAcademicYearList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateAcademicYearList)
      .addCase(save.fulfilled, updateAcademicYearList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
