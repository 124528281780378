import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography, Box } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';
import palette from '../Setting/Calendar/List/CalendarColorPalette.json';
import { getCalendar, getCalendarEventType, } from 'store/lookup'
import { useTranslation } from 'react-i18next';


moment.locale('en-US');
const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const sessionAppearance = useSelector((state) => state.session.appearance);
  const appearance =
    sessionAppearance === '' || !sessionAppearance
      ? 'auto'
      : sessionAppearance === 'light'
      ? 'light'
      : 'dark';

  const [view, setView] = useState('month');
  const [currentDay, setCurrentDay] = useState(moment())
  const dispatch = useDispatch()
  const calendar = useSelector(state => state.lookup.calendar)
  const [eventsList, setEventsList] = useState()
  const calendarEventTypes = useSelector(state => state.lookup.calendarEventType)
  const { t } = useTranslation()

  useEffect(() => {
    calendarEventTypes || dispatch(getCalendarEventType())
  }, [dispatch, calendarEventTypes])

  useEffect(() => {
    if (currentDay) {
      const startOfMonth = moment(currentDay).startOf('month').toDate();
      const endOfMonth = moment(currentDay).endOf('month').toDate();
      const startDate = moment(startOfMonth).format('YYYY-MM-DD');
      const endDate = moment(endOfMonth).format('YYYY-MM-DD');
      dispatch(getCalendar({ startDate, endDate }));
    }
  }, [currentDay, dispatch]);

  useEffect(() => {
    if (calendar) {
      const temp = []
      calendar.forEach(cal => {
        const bgColor = calendarEventTypes?.find(item => item?.id === cal?.eventTypeId)?.bgColor
        const uiEffect = calendarEventTypes?.find(item => item?.id === cal?.eventTypeId)?.uiEffect

        const start = moment(cal.startDate).startOf('day'); 
        const end = moment(cal.endDate).endOf('day');

        temp.push({
          title: cal.name,
          start,
          end,
          bgColor,
          uiEffect
        })
      })
      setEventsList(temp)
    }
  }, [calendar, currentDay])

  function eventPropGetter(event) {
    const textColor = palette[appearance][event?.bgColor]?.text;
    const backgroundColor = palette[appearance][event?.bgColor]?.background;
    const borderColor = palette[appearance][event?.bgColor]?.border;
    const displayEffects = event?.uiEffect;
  
    const style = {
      borderRadius: '4px',
      position: 'relative', 
      overflow: 'hidden',
    };

    if (view === 'month') {
        style.backgroundColor = backgroundColor
        style.color = textColor
        style.borderLeft = `3px solid ${borderColor}`;
      }
  
    let className = '';

    if (displayEffects === 'line') {
        className = 'line'; 
      }
  
      if (displayEffects === 'star') {
        className = 'star'; 
      }
  
    return {
      style,
      className
    };
  }

  
  const CustomToolbar = ({ label, onNavigate }) => (
    <Box
      sx={{ borderRadius: '15px 15px 0px 0px', bgcolor: 'primary.main', border: '1px solid #000000', p: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button onClick={() => onNavigate('PREV')}>
          <ChevronLeftIcon sx={{ fontSize: 40, color: 'primary.light' }} />
        </Button>
        <Typography sx={{color: 'primary.contrastText' }} variant="h5">{t(label)}</Typography>
        <Button onClick={() => onNavigate('NEXT')}>
          <ChevronRightIcon sx={{ fontSize: 40, color: 'primary.light' }} />
        </Button>
      </Stack>
      <Stack paddingRight={2} direction="row" alignItems="center" spacing={1} flexWrap='wrap'>
        <Button sx={{color: 'primary.contrastText'}} variant="text" size="small" onClick={() => setView('month')}>{t('Month')}</Button>
        <Button sx={{color: 'primary.contrastText'}} variant="text" size="small" onClick={() => setView('agenda')}>{t('Agenda')}</Button>
      </Stack>
    </Box>
  );

  const handleNavigate = (direction) => {
    const newCurrentDay = moment(currentDay);
  
    if (direction === 'PREV') {
      newCurrentDay.subtract(1, 'month');
    } else if (direction === 'NEXT') {
      newCurrentDay.add(1, 'month');
    }
  
    setCurrentDay(newCurrentDay); 
  };

  return (
    <Calendar
      className={`${appearance}-district-calendar`}
      views={['month', 'agenda']}
      selectable
      localizer={localizer}
      events={eventsList}
      //defaultDate={new Date()}
      date={currentDay}
      view={view} 
      style={{ height: '80vh' }}
      components={{
        toolbar: (props) => <CustomToolbar {...props} onNavigate={handleNavigate} />
      }}
      onNavigate={handleNavigate}
      eventPropGetter={eventPropGetter}
    />
    
  );
};

export default CalendarPage;
