import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const specialAptitudeMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
}

export default function GiftedAndTalentedForm({
  control,
  giftedAndTalentedOption,
  setValue,
  getValues,
  specialAptitudeOption,
  noAndYesOption,
  academicAchievementOption,
}) {
  const { t } = useTranslation()
  const [special, setSpecial] = useState()
  const [academic, setAcademic] = useState()

  const handleClickSpecial = () => {
    setSpecial(true)
  }

  const handleDeleteSpecial = async selected => {
    const list = getValues()?.giftedAndTalented?.specialAptitude?.filter(item => item !== selected)
    await setValue('giftedAndTalented.specialAptitude', [...list], { shouldDirty: true })
  }

  const handleClickAcademic = () => {
    setAcademic(true)
  }
  const handleDeleteAcademic = async selected => {
    const list = getValues()?.giftedAndTalented?.academicAchievement?.filter(item => item !== selected)
    await setValue('giftedAndTalented.academicAchievement', [...list], { shouldDirty: true })
  }

  return (
    <>
      <Grid item sm={12} xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12}>
            <Controller
              name="giftedAndTalented.giftedAndTalented"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    label={t('Gifted and Talented')}
                    error={!!fieldState.error}
                    size="small"
                    fullWidth
                    value={value || ''}
                    {...field}
                  >
                    {giftedAndTalentedOption.map(item => (
                      <MenuItem key={item.code} value={item.code}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            <Controller
              name="giftedAndTalented.specialAptitude"
              control={control}
              render={({ field: { value }, fieldState }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="select-multiple-special-aptitude-label">Special Aptitude</InputLabel>
                    <Select
                      open={special}
                      onClose={() => setSpecial(false)}
                      onOpen={() => setSpecial(true)}
                      id="select-multiple-special-aptitude"
                      multiple
                      label={t('Special Aptitude')}
                      error={!!fieldState.error}
                      value={value || []}
                      onChange={e => {
                        const {
                          target: { value },
                        } = e
                        const val = typeof value === 'string' ? value.split(',') : value
                        setValue('giftedAndTalented.specialAptitude', val)
                      }}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map(value => (
                            <Chip
                              onClick={() => handleClickSpecial()}
                              onDelete={() => handleDeleteSpecial(value)}
                              onMouseDown={event => {
                                event.stopPropagation()
                              }}
                              key={value}
                              label={specialAptitudeOption.find(item => item.code === value)?.description}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={specialAptitudeMenuProps}
                    >
                      {specialAptitudeOption.map(item => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12}>
            <Controller
              name="giftedAndTalented.cognitiveAbility"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    disabled={true}
                    label={t('Cognitive Ability')}
                    error={!!fieldState.error}
                    size="small"
                    fullWidth
                    value={value || 'No'}
                    {...field}
                  >
                    {noAndYesOption.map(item => (
                      <MenuItem key={item.code} value={item.code}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            <Controller
              name="giftedAndTalented.academicAchievement"
              control={control}
              render={({ field: { value }, fieldState }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="select-multiple-academic-achievement-label">Academic Achievement</InputLabel>
                    <Select
                      open={academic}
                      onClose={() => setAcademic(false)}
                      onOpen={() => setAcademic(true)}
                      id="select-multiple-academic-achievement"
                      multiple
                      label={t('Academic Achievement')}
                      value={value || []}
                      error={!!fieldState.error}
                      onChange={e => {
                        const {
                          target: { value },
                        } = e
                        const val = typeof value === 'string' ? value.split(',') : value
                        setValue('giftedAndTalented.academicAchievement', val)
                      }}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map(value => (
                            <Chip
                              onClick={() => handleClickAcademic()}
                              onDelete={() => handleDeleteAcademic(value)}
                              onMouseDown={event => {
                                event.stopPropagation()
                              }}
                              key={value}
                              label={academicAchievementOption.find(item => item.code === value)?.description}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={specialAptitudeMenuProps}
                    >
                      {academicAchievementOption.map(item => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
