import { CardContent, Grid, Skeleton, Box, Toolbar, CardActions, Button } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function AcademicYearSkeleton() {
  const { t } = useTranslation()
  return (
    <Box component="form" noValidate>
      <Toolbar />
      <Header title={t("Marking Period")} small close />
      <CardContent>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Skeleton />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined">
          <Skeleton />
        </Button>
        <Button type="submit" variant="contained">
          <Skeleton />
        </Button>
      </CardActions>
    </Box>
  )
}
