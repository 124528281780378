import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getLanguages } from 'store/lookup'

export const evaluationOfStaff = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const highestLevelEducationCompleted = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const seProgramContractedServices = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const titleIProgramStaff = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const lepInstructorCredential = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export default function ExperienceCard({ data, district }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { personId } = useParams()

  const languages = useSelector(state => state.lookup.languages)

  useEffect(() => {
    languages || dispatch(getLanguages())
  }, [dispatch])

  if (!languages) return null

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mb: 3 }}>
        <PreviewCard
          title={t('Experience Information')}
          size="large"
          buttonNavigate={`/person/${personId}/employment/${district}/experience/${data?.id}`}
          buttonLabel={data?.stateData?.experience ? t('More') : t('Add')}
          buttonDisabled={data ? false : true}
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Evaluation of Staff')}</Typography>
              <Typography variant="subtitle1">
                {evaluationOfStaff.find(d => d.id === data?.stateData?.experience?.evaluationOfStaff)?.name} &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Languages Spoken by Teacher')}</Typography>
              <Typography variant="subtitle1">
                {languages.find(d => d.id === data?.stateData?.experience?.languagesSpokenTeacher)?.name} &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Title I Program Staff Category')}</Typography>
              <Typography variant="subtitle1">
                {titleIProgramStaff.find(d => d.id === data?.stateData?.experience?.titleIProgramStaff)?.name} &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Highest Level of Education Completed')}</Typography>
              <Typography variant="subtitle1">
                {
                  highestLevelEducationCompleted.find(
                    d => d.id === data?.stateData?.experience?.highestLevelEducationCompleted,
                  )?.name
                }
                &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('SE Program Contracted Services Category')}</Typography>
              <Typography variant="subtitle1">
                {
                  seProgramContractedServices.find(
                    d => d.id === data?.stateData?.experience?.seProgramContractedServices,
                  )?.name
                }
                &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('LEP Instructor Credential Type')}</Typography>
              <Typography variant="subtitle1">
                {lepInstructorCredential.find(d => d.id === data?.stateData?.experience?.lepInstructorCredential)?.name}
                &nbsp;
              </Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Years of Prior Experience')}</Typography>
              <Typography variant="subtitle1">{data?.stateData?.experience?.yearsPriorExperience} &nbsp;</Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Years in NJ')}</Typography>
              <Typography variant="subtitle1">{data?.stateData?.experience?.yearsInNJ} &nbsp;</Typography>
            </Grid>

            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Years in LEA')}</Typography>
              <Typography variant="subtitle1">{data?.stateData?.experience?.yearsInLea} &nbsp;</Typography>
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
