import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'course'
const initialState = {
  list: null,
  count: null,
  query: { limit: 10, offset: 0, subjectId: null },
}

const basePath = '/api/provider/course'
export const search = createApiAction('provider/course/search', client.search(basePath))

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows
        state.count = action.payload.count
        state.query = action.meta.arg
      })
      
  },
})
export default sessionSlice.reducer
