import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from '../../../components/TableHeadCell'
import BuildingItem from './BuildingItem'

export default function BuildingList({buildings}) {

  return (
    <Paper data-component="BuildingList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Building Name</TableHeadCell>
                    <TableHeadCell>Address</TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {buildings?.map(item => {
                    return <BuildingItem key={item.id || item.uid} building={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
