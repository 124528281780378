import { Grid, MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function Section504Form({ control, noAndYesOption }) {
  const { t } = useTranslation()

  return (
    <Grid item sm={12} xs={12} md={12}>
      <Controller
        name="section504.section504"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => {
          return (
            <TextField
              select
              label={t("Section 504")}
              error={!!fieldState.error}
              size="small"
              fullWidth
              value={value || ''}
              {...field}
            >
              {noAndYesOption.map(item => (
                <MenuItem key={item.code} value={item.code}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
          )
        }}
      />
    </Grid>
  )
}
