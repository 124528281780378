import { Avatar, IconButton, useMediaQuery } from '@mui/material'
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { enqueueSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import * as action from 'store/person/index'
import { useParams } from 'react-router-dom'

export default function ProfilePicture() {
  const { personId } = useParams()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:600px)')
  const person = useSelector(state => state.person2.details)

  const handleFile = async event => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('avatar', file)
    const url = `/api/person/${personId}/avatar`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    } else {
      dispatch(action.retrieve({ id: personId }))
    }
  }

  if (!person) return null

  if (person?.id !== +personId) return null

  const profilePicture = person?.profilePicture?.location

  return (
    <IconButton component="label" aria-label="person-avatar">
      <input hidden accept="image/*" type="file" onChange={handleFile} />
      {profilePicture ? (
        <Avatar
          sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: 30 }}
          src={profilePicture}
        >
          <AvatarIcon />
        </Avatar>
      ) : (
        <Avatar sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: '150%' }}>
          {[person?.firstName[0], person?.lastName[0]].join('')}
        </Avatar>
      )}
    </IconButton>
  )
}
