import { Link as NavLink } from 'react-router-dom'
import { Grid, Typography, Link, Card } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import PersonIcon from '@mui/icons-material/Person'

export default function StudentCard({ student }) {
  return (
    <Grid item xs={12} md={3} key={student.id}>
      <Link
        component={NavLink}
        underline="hover"
        color="inherit"
        sx={{ paddingLeft: '10px', paddingRight: '20px' }}
        to={`/academic/parent/student/${student.id}`}
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            image={student.profilePicture?.location || ''}
            title={`${student.firstName} ${student.lastName}`}
          >
            {student.profilePicture?.location ? null : <PersonIcon sx={{ fontSize: 150 }} />}
          </CardMedia>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {`${student.firstName} ${student.lastName}`}
            </Typography>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {`${student.academicYearEnrollment?.[0]?.academicYear.campus.name} `}
            </Typography>
            <Typography variant="body2" color="text.secondary"></Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between' }}></CardActions>
        </Card>
      </Link>
    </Grid>
  )
}
