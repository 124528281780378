import { useState, useEffect } from 'react'
import { Box, Tabs, Tab, Toolbar, Stack, Skeleton, LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import Profile from './Profile'
import Contacts from './Contacts'
import Documents from './Documents'
import Roles from './Roles'
import Enrolments from './Enrollments'
import Attendance from './Attendance'
import { get, initiliazePerson } from 'store/person'
import { Link, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BasePathProvider, useMembershipType, PersonProvider, useSession } from 'components/Providers'
import { capitalize } from 'lodash'
import Header from 'components/Header'
import { STUDENT } from 'constants'
import { useTranslation } from 'react-i18next'
import ProfilePicture from './Profile/ProfilePicture'
import { Schedule } from './Schedule'
import { Grades } from './Grades'
import { clearSchoolEnrollments } from 'store/settings/school-enrollment'
import { clearCourseSessionEnrollments } from 'store/course-session-enrollment'
import { clearFinalGrades } from 'store/person-grade'
import { clearPersonAttendanceDaily } from 'store/person-attendance-daily'
import { clearPersonAttendancePeriod } from 'store/person-attendance-period'


const PROFILE = 0
const CONTACTS = 1
const DOCUMENTS = 2
const ROLES = 3
const ENROLLMENTS = 4
const ATTENDANCE = 5
const SCHEDULE = 6
const FINALGRADES = 7

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  [theme.breakpoints.down('lg')]: {
    width: '100vw',
  },
}))

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = [
    'profile',
    'contacts',
    'documents',
    'roles',
    'enrollments',
    'attendance',
    'schedule',
    'final-grades',
  ].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function Detail() {
  const permissions = useSelector(state => state.session.permissions)
  const membershipType = useMembershipType()
  const { personId, '*': tabName } = useParams()
  const dispatch = useDispatch()
  const { academicYear } = useSession()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const people = useSelector(state =>
    membershipType === 'staff' ? state.person.staff.list : state.person.student.list,
  )
  const person = useSelector(state =>
    membershipType === 'staff' ? state.person.staff.selected : state.person.student.selected,
  )

  useEffect(() => {
    if (parseInt(personId)) {
      dispatch(get({ id: personId, membershipType }))
    } else {
      dispatch(initiliazePerson({ membershipType }))
    }
  }, [dispatch, personId])

  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))

  const url = `/${membershipType}/${personId}`

  const goNext = (step = 1) => {
    const ids = people.map(p => p.id)
    let n = ids.indexOf(person.id) + step
    if (n < 0) n = 0
    const id = ids[n % ids.length]
    const path = `/${membershipType}/${id}/${tabName}`
    dispatch(clearSchoolEnrollments())
    dispatch(clearCourseSessionEnrollments())
    dispatch(clearFinalGrades())
    dispatch(clearPersonAttendanceDaily())
    dispatch(clearPersonAttendancePeriod())

    navigate(path)
  }

  return (
    <BasePathProvider value={`/${membershipType}/${personId}`}>
      <PersonProvider person={person}>
        <Root id="person-detail">
          <Toolbar />
          {person?.id || String(personId) === 'new' ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ p: 2, displayPrint: 'none', height: '150px' }}
            >
              <ProfilePicture />

              <Header
                sx={{ flex: 1 }}
                title={
                  person?.id ? [person?.firstName, person?.lastName].filter(Boolean).join(' ') : `New ${membershipType}`
                }
                subtitle={`${t(capitalize(membershipType))}`}
                prev={() => goNext(-1)}
                next={() => goNext(1)}
                close
              />
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ p: 2, displayPrint: 'none', mt: 1, height: '142px' }}
            >
              <Skeleton sx={{ ml: 1 }} variant="circular" width={80} height={80} />
              <Stack>
                <Skeleton variant="rounded" width={210} height={60} sx={{ ml: 2 }} />
                <Skeleton variant="text" sx={{ ml: 2, fontSize: '1rem' }} />
              </Stack>
            </Stack>
          )}
          <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
            <Tabs value={tabIndex} onChange={(event, i) => setTabIndex(i)}>
              <Tab label={t('Profile')} id={PROFILE} component={Link} to={`${url}/profile`} />

              {permissions[`${membershipType}.contact`] && (
                <Tab
                  label={t('Contacts')}
                  value={CONTACTS}
                  component={Link}
                  to={`${url}/contacts`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {permissions[`${membershipType}.document`] && (
                <Tab
                  label={t('Documents')}
                  value={DOCUMENTS}
                  component={Link}
                  to={`${url}/documents`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {permissions[`${membershipType}.role`] && (
                <Tab
                  label={t('Roles')}
                  value={ROLES}
                  component={Link}
                  to={`${url}/roles`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {permissions[`${membershipType}.enrollment`] && (
                <Tab
                  label={t('Enrollments')}
                  value={ENROLLMENTS}
                  component={Link}
                  to={`${url}/enrollments`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {membershipType === STUDENT && academicYear?.id && (
                <Tab
                  label={t('Attendance')}
                  value={ATTENDANCE}
                  component={Link}
                  to={`${url}/attendance`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {academicYear?.id && (
                <Tab
                  label={t('Schedule')}
                  value={SCHEDULE}
                  component={Link}
                  to={`${url}/schedule`}
                  disabled={String(personId) === 'new'}
                />
              )}
              {membershipType === STUDENT && academicYear?.id && (
                <Tab
                  label={t('Final Grades')}
                  value={FINALGRADES}
                  component={Link}
                  to={`${url}/final-grades`}
                  disabled={String(personId) === 'new'}
                />
              )}
            </Tabs>
          </Box>
          {!person?.id && (!tabName || String(tabName) === 'profile') && personId !== 'new' && (
            <LinearProgress sx={{ m: 2 }} color="success" />
          )}
          <Routes>
            <Route path="" element={<Outlet />}>
              <Route index element={<Profile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="documents" element={<Documents />} />
              <Route path="documents/:id" element={<Documents />} />
              <Route path="roles" element={<Roles />} />
              <Route path="enrollments" element={<Enrolments />} />
              <Route path="enrollments/academic-year-enrollment/:academicYearEnrollmentId" element={<Enrolments />} />
              <Route path="enrollments/school-enrollment/:schoolEnrollmentId" element={<Enrolments />} />
              <Route path="enrollments/course-session-enrollment/:courseSessionEnrollmentId" element={<Enrolments />} />
              <Route path="attendance" element={<Attendance />} />
              <Route path="schedule" element={<Schedule />} />
              <Route path="final-grades" element={<Grades />} />
            </Route>
          </Routes>
          {/*
                <TabPanel index={PROFILE} value={tabIndex} keepInBack><Profile /></TabPanel>
                <TabPanel index={CONTACTS} value={tabIndex}><Contacts /></TabPanel>
                <TabPanel index={DOCUMENTS} value={tabIndex}><Documents /></TabPanel>
                <TabPanel index={ROLES} value={tabIndex}><Roles /></TabPanel>
                <TabPanel index={ENROLLMENTS} value={tabIndex}><Enrolments /></TabPanel>
                <TabPanel index={ACADEMIC} value={tabIndex}><AcademicYearEnrollment /></TabPanel>
                */}
        </Root>
      </PersonProvider>
    </BasePathProvider>
  )
}
