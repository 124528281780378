import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PersonalForm from './Form/PersonalForm'
import FormSkeleton from './Form/FormSkeleton'
import { getRaceTypes, getGradeLevels, getEthnicityTypes, getGenderTypes } from 'store/lookup'
import { search } from 'store/settings/grade-level-section'
import { search as searchJobPosition } from 'store/settings/job-position'
import NotFound from '../../component/FormNotFound'
import { useBasePath } from 'components/Providers'

export default function Personal() {
  const dispatch = useDispatch()
  const [notFound, setNotFound] = useState(false)
  const basePath = useBasePath()

  const { raceTypes, gradeLevels, ethnicityTypes, genderTypes } = useSelector(state => state.lookup)
  const gradeLevelSection = useSelector(state => state.settings.gradeLevelSection.list)
  const jobPositions = useSelector(state => state.settings.jobPosition.list)

  useEffect(() => {
    const fetchData = async () => {
      const requests = [
        !raceTypes && dispatch(getRaceTypes()),
        !gradeLevels && dispatch(getGradeLevels()),
        !gradeLevelSection && dispatch(search()),
        !ethnicityTypes && dispatch(getEthnicityTypes()),
        !genderTypes && dispatch(getGenderTypes()),
        !jobPositions && dispatch(searchJobPosition()),
      ]

      const responses = await Promise.all(requests)

      responses.forEach(res => {
        if (res?.error) {
          setNotFound(true)
        }
      })
    }

    fetchData()
  }, [dispatch])

  if (notFound) return <NotFound basePath={`${basePath}/person`} title={'Personal Information'} />

  if(!(raceTypes && gradeLevels && gradeLevelSection && ethnicityTypes && genderTypes && jobPositions)) return null

  if (raceTypes && gradeLevels && gradeLevelSection && ethnicityTypes) return <PersonalForm />

  return <FormSkeleton />
}
