// import { useState } from 'react'
import { Button, TableCell, TableRow, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import dayjs from 'dayjs'
import { getNurseDispositionTypes } from 'store/lookup'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'


export default function DischargedListItem({ item }) {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const nurseDispositionType = useSelector(state => state.lookup.nurse.dispositionType)
  const navigate = useNavigate();

  const dispositionTypeName = nurseDispositionType?.find(
    visit => visit.id === item.dispositionTypeId
  )?.name;

  const startTime = item.startTime;
  const endTime = item.endTime;

  const calculateDuration = (start, end) => {
    const duration = dayjs(end).diff(dayjs(start)); 
    if (duration < 0) return 'Invalid Duration';
    const hours = Math.floor(duration / (1000 * 60 * 60)); 
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} hr ${minutes} min`; 
  };

  useEffect(() => {
    nurseDispositionType ||
      dispatch(getNurseDispositionTypes()).catch(error => {
        console.error(error)
      })
  }, [nurseDispositionType, dispatch])

  const handleEndVisitClick = () => {
    navigate(`${basePath}/${item.id}`, { state: { action: 'endVisit' } });
  };

  return (
    <TableRow hover>
      <TableCell>{item.person?.lastName}, {item.person?.firstName}</TableCell>
      <TableCell>{dayjs(startTime).format('hh:mm A')}</TableCell>
      <TableCell>{dayjs(endTime).format('hh:mm A')}</TableCell>
      <TableCell>{calculateDuration(startTime, endTime)}</TableCell>
      <TableCell>{dispositionTypeName || item.dispositionTypeId}</TableCell>
      <TableCell align="left">
        <Stack spacing={1} direction='row'>
          <Button
            variant='outlined'
            size="small"
            onClick={handleEndVisitClick}
            >
            {t('Edit')}
          </Button>
          </Stack>
      </TableCell>
    </TableRow>
  )
}
