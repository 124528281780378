import { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, CircularProgress, Container } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { SubTitle, Title } from '../../components/typo'

import { validateEMail } from '../../store/session'

import { useTranslation } from 'react-i18next'

function EMail() {
  const { t } = useTranslation()

  const { token } = useParams()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    dispatch(validateEMail({ token })).then(res => {
      setLoading(false)
      setSuccess(res.payload?.status === 'ok')
    })
  }, [token, dispatch])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Container component="main" maxWidth="sm">
      <Card>
        <CardContent>
          <Title>Thank you</Title>
          <SubTitle>
            {success ? t('Your e-mail is validated.') : t("We couldn't validate your email, please try again later.")}
          </SubTitle>
        </CardContent>
        <CardActions>
          <Button component={Link} to="/">
            Continue
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default EMail
