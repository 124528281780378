import { useDispatch } from 'react-redux'
import JobPositionEditForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/settings/job-position'
import JobPositionFormSkeleton from './FormSkeleton'

export default function JobPositionFormIndex() {
  const dispatch = useDispatch()
  const [jobPosition, setJobPosition] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setJobPosition(res.payload)
      })
    } else {
      setJobPosition(null)
    }
  }, [id])

  if (id && id !== 'new' && !jobPosition) return <JobPositionFormSkeleton />
  if (jobPosition || id === 'new') return <JobPositionEditForm jobPosition={jobPosition} />
}
