import {
  Button,
  IconButton,
  Box,
  TextField,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Toolbar,
  Autocomplete,
} from '@mui/material'
import { pick } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useBasePath, useSession } from 'components/Providers'
import { save } from 'store/course-session-enrollment'
import { useNavigate } from 'react-router-dom'
import { CloseIcon } from 'components/Icons'
import { omit } from 'lodash'
import { useDispatch } from 'react-redux'
import { getCourseSessions } from 'store/lookup'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from '@mui/material/utils'

export default function CourseSessionEnrollmentForm({ courseEnrollment, schoolEnrollment, membershipType, person }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { academicYear } = useSession()
  const { t } = useTranslation()
  const [selected, setSelected] = useState(courseEnrollment)
  const [courseSessionsList, setCourseSessionsList] = useState()
  const [backupCourseSessionsList, setBackupCourseSessionsList] = useState()

  const schema = yup.object().shape({
    schoolEnrollmentId: yup.number().required('Required'),
    courseSessionId: yup.number().required('Required'),
    startDate: yup.date().required('Required'),
    endDate: yup
      .date()
      .nullable()
      .test('valid-dates', 'End date should be later than start date', function (value) {
        return value ? this.parent.startDate < value : true
      }),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      schoolEnrollmentId: schoolEnrollment.id,
      courseSessionId: courseEnrollment?.courseSession?.id || '',
      startDate: '',
      endDate: null,
      ...pick(courseEnrollment, 'id', 'startDate', 'endDate'),
    },
  })

  useEffect(() => {
    if (academicYear?.id) {
      dispatch(getCourseSessions({ academicYearId: academicYear?.id })).then(
        res => (
          setCourseSessionsList([
            ...res.payload.rows,
            {
              id: selected.courseSession?.id,
              name: `${selected.courseSession?.name} `,
              course: selected.courseSession?.course,
              academicYear: { id: academicYear.id, name: academicYear.name },
            },
          ]),
          setBackupCourseSessionsList(res.payload.rows)
        ),
      )
    }
  }, [dispatch, academicYear?.id])

  const fetch = useMemo(
    () =>
      debounce(async query => {
        const res = await dispatch(getCourseSessions({ name: query, academicYearId: academicYear?.id }))
        if (res.payload.rows.length) {
          setCourseSessionsList(res.payload.rows)
        }
      }, 400),
    [dispatch],
  )

  const searchCourseSessions = query => {
    if (query === '' || !query) {
      setCourseSessionsList(backupCourseSessionsList)
      return
    } else if (query) {
      fetch(query, () => {})
    }
  }

  const onSubmit = data => {
    if (data.startDate) {
      data.startDate = dayjs(data.startDate).format('YYYY-MM-DD')
    }
    if (data.endDate) {
      data.endDate = dayjs(data.endDate).format('YYYY-MM-DD')
    }
    if (data.id) {
      let payload = omit(data, ['courseSessionId', 'schoolEnrollmentId'])
      dispatch(save({ ...payload, personId: person.id, membershipType })).then(({ payload }) => {
        if (payload) {
          navigate(basePath)
        }
      })
    } else {
      dispatch(save({ ...data, personId: person.id, membershipType, schoolEnrollmentId: schoolEnrollment.id })).then(
        ({ payload }) => {
          if (payload) {
            navigate(basePath)
          }
        },
      )
    }
  }

  const onError = errors => console.error(errors)

  return (
    <>
      {courseSessionsList?.length && (
        <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
          <Toolbar />
          <CardHeader
            title={t('Course Session Enrollments')}
            action={
              <IconButton onClick={() => navigate(basePath)}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Controller
                  name="courseSessionId"
                  control={control}
                  render={({ field: { ref, onChange, ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        size="small"
                        openOnFocus
                        autoHighlight
                        disablePortal
                        onChange={(event, value) => {
                          setSelected(value)
                          onChange(value?.id)
                        }}
                        value={selected?.id ? selected : null}
                        options={courseSessionsList}
                        getOptionLabel={option =>
                          option?.courseSession
                            ? `${option?.courseSession?.course?.name} / ${option?.courseSession.name}`
                            : option?.course
                            ? `${option?.course?.name} / ${option?.name}`
                            : ''
                        }
                        readOnly={!isNaN(parseInt(courseEnrollment?.id))}
                        onInputChange={(event, value, reason) => {
                          switch (reason) {
                            case 'reset':
                              break
                            case 'clear':
                              setSelected(null)
                              searchCourseSessions(null)
                              setCourseSessionsList(backupCourseSessionsList)

                              break
                            case 'input':
                              searchCourseSessions(value)
                              break
                            default:
                              setCourseSessionsList(value)
                              setCourseSessionsList(backupCourseSessionsList)
                              break
                          }
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option?.id === value?.courseSession?.id
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('Course Sessions')}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <DatePicker
                        fullWidth
                        label={t('Start Date')}
                        value={field.value ? dayjs(field.value) : ''}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        minDate={dayjs(academicYear.startDate)}
                        maxDate={dayjs(academicYear.endDate)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            error: !!fieldState.error,
                            helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                          },
                        }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <DatePicker
                        fullWidth
                        label={t('End Date')}
                        defaultValue={null}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        minDate={dayjs(academicYear.startDate)}
                        maxDate={dayjs(academicYear.endDate)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            error: !!fieldState.error,
                            helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                          },
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
            <Button variant="outlined" onClick={() => navigate(basePath)}>
              {t('Cancel')}
            </Button>
            <Button type="submit" variant="contained">
              {t('Submit')}
            </Button>
          </CardActions>
        </Box>
      )}
    </>
  )
}
