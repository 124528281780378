import { Route, Routes } from 'react-router-dom'
import GradeSectionsPage from './Page'

export default function GradeSections() {
  return (
    <Routes>
      <Route path="/" element={<GradeSectionsPage />} />
      <Route path="/:id" element={<GradeSectionsPage />} />
    </Routes>
  )
}
