import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import ReportListItem from './ListItem'
import { useTranslation } from 'react-i18next'
export default function ReportList({ list }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ p: 1 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell sx={{ width: '75%' }}>{t('Report')}</TableHeadCell>
            <TableHeadCell sx={{ width: '10%' }}>{t('Favorite')}</TableHeadCell>
            <TableHeadCell sx={{ width: '15%' }}>{t('Last Viewed')}</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => (
            <ReportListItem key={index} item={item} />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
