import { useDispatch } from 'react-redux'
import GradeSectionEditForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/settings/grade-level-section'
import GradeSectionFormSkeleton from './FormSkeleton'
import { BasePathProvider, useBasePath } from 'components/Providers'

export default function GradeSectionsFormIndex() {
  const dispatch = useDispatch()
  const [gradeSection, setGradeSection] = useState(null)
  const basePath = useBasePath()

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setGradeSection(res.payload)
      })
    } else {
      setGradeSection(null)
    }
  }, [id])

  if (gradeSection || id === 'new') {
    return (
      <BasePathProvider value={`${basePath}`}>
        <GradeSectionEditForm gradeSection={gradeSection} />
      </BasePathProvider>
    )
  }

  if (!gradeSection) {
    return <GradeSectionFormSkeleton />
  }
}
