
import { combineReducers } from '@reduxjs/toolkit'

import gradingPolicyReducer from './grading-policy'
import courseSessionReducer from './course-session'
import finalGradeSessionReducer from './final-grades'
import studentReducer from './student/teacher-student'
import parentStudentReducer from './parent/parent-student'
import schduleReducer from './schedule'
import attendanceDailyReducer from './attendance-daily'
import attendancePeriodReducer from './attendance-period'
import attendanceReportReducer from './attendance-report'
import courseSessionEnrollmentReducer from './course-session-enrollment'
import finalGradeReducer from './grade-finalGrade'
import parentalAcademicYearReducer from './parental-academic-year'
import parentalStudentCourseSessionReducer from './parental-course-session'
import parentalStudentAttendanceDailyReducer from './parental-attendance-daily'
import parentalGradingScalesReducer from './parental-grading-scales'
import gradingScalesReducer from './grading-scales'

const combinedReducer = combineReducers({
  courseSession: courseSessionReducer,
  gradingPolicy: gradingPolicyReducer,
  finalGrades: finalGradeSessionReducer,
  student: studentReducer,
  courseSessionEnrollment: courseSessionEnrollmentReducer,
  schedule: schduleReducer,
  attendanceDaily: attendanceDailyReducer,
  attendancePeriod: attendancePeriodReducer,
  grade_finalGrade: finalGradeReducer,
  parentalStudentCourseSession: parentalStudentCourseSessionReducer,
  parentalAcademicYear: parentalAcademicYearReducer,
  parentalStudentAttendanceDaily: parentalStudentAttendanceDailyReducer,
  parentalGradingScales: parentalGradingScalesReducer,
  attendanceReport: attendanceReportReducer,
  gradingScales: gradingScalesReducer,
  parentStudent: parentStudentReducer,
})

export default combinedReducer
