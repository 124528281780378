import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { DeleteIcon } from 'components/Icons'
import { EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/settings/course-session-period'
import { Link as NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { DAYS as days } from 'constants'
import { useState } from 'react'

export default function ScheduleItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const { courseSessionId } = useParams()
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ courseSessionId, id: item.id }))
  }

  return (
    <TableRow>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item?.id}`}>
          {days[item?.period?.dayOfWeek]}
        </Link>
      </TableCell>
      <TableCell>{item?.period?.startTime}</TableCell>
      <TableCell>{item?.period?.endTime}</TableCell>
      <TableCell align="center">{item.room?.doorNumber}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item?.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>

      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
