import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'grading-coefficient'
const initialState = {
  coefficient: null,
}

const basePath = '/api/setting/grading-coefficient'
export const get = createApiAction('setting/grading-coefficient/get', client.search(basePath))
export const save = createApiAction('setting/grading-coefficient/save', client.save(basePath))

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(get.fulfilled, (state, action) => {
        state.coefficient = action.payload
      })
      .addCase(save.fulfilled, (state, action) => {
        state.coefficient = action.payload
      })
  },
})
export default sessionSlice.reducer
