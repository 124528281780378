import { Box, Button, Card, Grid, Stack, TextField, Typography } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { SubmitButton } from 'components/Buttons'
import { SaveSharp } from '@mui/icons-material'
import { AddIcon, CloseIcon, DeleteIcon, EditIcon, RemoveIcon } from 'components/Icons'
// import { CloseIcon, DeleteIcon, EditIcon, RemoveIcon } from 'components/Icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/grading-scale'
import { save as saveGradingLetter, destroy as destroyGradingLetter } from 'store/settings/grading-letter'
import { useNavigate, useParams } from 'react-router-dom'
import Confirm from 'components/Dialog'

const initialValues = {
  id: null,
  letterGrade: null,
  minGrade: null,
  maxGrade: null,
  gpa: null,
}

const emptyScale = {
  id: 0,
  letterGrade: '',
  minGrade: 0,
  maxGrade: 0,
  gpa: 0,
}

// const dummy = [
//   {
//     id: 1,
//     letterGrade: 'A',
//     minGrade: 90,
//     maxGrade: 100,
//     gpa: 4,
//   },
//   {
//     id: 2,
//     letterGrade: 'B',
//     minGrade: 80,
//     maxGrade: 89,
//     gpa: 3,
//   },
//   {
//     id: 3,
//     letterGrade: 'C',
//     minGrade: 70,
//     maxGrade: 79,
//     gpa: 2,
//   },
//   {
//     id: 4,
//     letterGrade: 'F',
//     minGrade: 0,
//     maxGrade: 69,
//     gpa: 0,
//   },
// ]

export default function GradingFormScales({ data, getScaleDetail }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const { id } = useParams()
  const appearance = useSelector(state => state.session.appearance)

  const [edit, setEdit] = useState(-1)
  const [scales, setScales] = useState()
  const [confirm, setConfirm] = useState(false)

  const { t } = useTranslation()
  const basePath = '/settings_/grading/'

  const schema = yup.object().shape({
    letterGrade: yup.string().trim().max(2).required(),
    gpa: yup.number().integer().min(0).max(4).required(),
    minGrade: yup.number().integer().min(0).required().lessThan(yup.ref('maxGrade'), t('')),
    maxGrade: yup.number().integer().max(100).required(),
  })

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (data) {
      setScales(data?.gradingLetters)
      setName(data?.name)
    }
  }, [data])

  const addRow = () => {
    if (!scales.find(item => item.id === 0)) {
      reset(initialValues)
      setScales([...scales, emptyScale])
      setEdit(0)
    }
  }

  const onSubmit = async formData => {
    const payload = {
      gradingScaleId: +id,
      letterGrade: formData?.letterGrade,
      minGrade: +formData?.minGrade,
      maxGrade: +formData?.maxGrade,
      gpa: +formData?.gpa,
    }
    if (formData?.id === 0 || formData?.id === null) {
      const res = await dispatch(saveGradingLetter({ ...payload }))
      if (!res.error) {
        setEdit(-1)
        await getScaleDetail()
      }
    } else if (formData?.id > 0) {
      const res = await dispatch(saveGradingLetter({ id: formData?.id, ...payload }))
      if (!res.error) {
        setEdit(-1)
        await getScaleDetail()
      }
    }
  }

  const onError = errors => console.error(errors)

  const onConfirm = async () => {
    await deleteRow(confirm?.id)
    setConfirm({ status: false, id: null })
  }

  const deleteRow = async gradingLetterId => {
    await dispatch(destroyGradingLetter({ id: gradingLetterId }))
    setEdit(-1)
    await getScaleDetail()
  }

  const closeRow = id => {
    if (id === 0) {
      setScales([...scales.filter(item => item.id > 0)])
    } else if (id > 0) {
      setEdit(-1)
    }
  }

  const [name, setName] = useState()

  const saveScale = async () => {
    if (name) {
      const scale = { name, enabled: data?.enabled || true }
      if (data?.id) {
        scale.id = data?.id
      }
      const res = await dispatch(save({ ...scale }))
      if (id === 'new' && res?.payload?.id) {
        navigate(`${basePath}scales/${res?.payload?.id}`)
      } else if (+id && res?.payload?.id) {
        navigate(`${basePath}`)
      }
    }
  }

  const activate = async enabled => {
    if (data?.id) {
      const scale = { id: data?.id, name, enabled }
      await dispatch(save({ ...scale }))
      navigate(`${basePath}`)
    }
  }

  return (
    <BasePathProvider value={`${basePath}`}>
      <Header title={t('Edit Grading Scale')} small close borderBottom />
      <Card sx={{ minHeight: '100%', p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField value={name} onChange={e => setName(e.target.value)} fullWidth size="small" label={t('Name')} />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              ml: 2,
              borderRadius: '3px 3px 0 0',
              border: `1px solid ${theme.palette.action.disabled}`,
              borderBottom: 0,
            }}
          >
            <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ mr: 2 }}>
              <Button disabled={id === 'new'} size="small" color="warning" variant="outlined" onClick={addRow}>
                {t('Add Row')}
              </Button>
            </Stack>

            <Grid
              container
              sx={{ mt: 0.4, borderBottom: `1px solid ${theme.palette.action.disabled}`, p: 1, pb: 2 }}
              spacing={2}
            >
              <Grid item xs={2.4} md={2.4}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Roboto, sans-serif' }}>
                  {t('Letter Grade')}
                </Typography>
              </Grid>
              <Grid item xs={2.4} md={2.4}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Roboto, sans-serif' }}>
                  {t('Min Point')}
                </Typography>
              </Grid>
              <Grid item xs={2.4} md={2.4}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Roboto, sans-serif' }}>
                  {t('Max Point')}
                </Typography>
              </Grid>
              <Grid item xs={2.4} md={2.4}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Roboto, sans-serif' }}>
                  {t('Double Value')}
                </Typography>
              </Grid>
              <Grid item xs={2.4} md={2.4}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Roboto, sans-serif' }}>
                  {t('Action')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, onError)}
          sx={{ border: `1px solid ${theme.palette.action.disabled}`, borderTop: 0, borderRadius: '0 0 3px 3px' }}
        >
          {scales?.map((item, index) => (
            <Stack
              key={index}
              flexDirection={'row'}
              sx={{
                borderBottom: scales.length > index + 1 && `1px solid ${theme.palette.action.disabled}`,
                boxShadow:
                  edit === item.id && appearance === 'dark'
                    ? '0px 4px 6px rgba(255, 255, 255, 0.1)'
                    : edit === item.id && appearance !== 'dark' && '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box sx={{ width: '20%', p: 2 }}>
                {item.id === edit ? (
                  <Controller
                    name="letterGrade"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return <TextField size="small" error={!!fieldState.error} value={value || ''} {...field} />
                    }}
                  />
                ) : (
                  <Typography variant="body2">{item.letterGrade}</Typography>
                )}
              </Box>
              <Box sx={{ width: '20%', p: 2 }}>
                {item.id === edit ? (
                  <Controller
                    name="minGrade"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return <TextField size="small" error={!!fieldState.error} value={value || ''} {...field} />
                    }}
                  />
                ) : (
                  <Typography variant="body2">{item.minGrade}</Typography>
                )}
              </Box>
              <Box sx={{ width: '20%', p: 2 }}>
                {item.id === edit ? (
                  <Controller
                    name="maxGrade"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return <TextField size="small" error={!!fieldState.error} value={value || ''} {...field} />
                    }}
                  />
                ) : (
                  <Typography variant="body2">{item.maxGrade}</Typography>
                )}
              </Box>
              <Box sx={{ width: '20%', p: 2 }}>
                {item.id === edit ? (
                  <Controller
                    name="gpa"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return <TextField size="small" error={!!fieldState.error} value={value || ''} {...field} />
                    }}
                  />
                ) : (
                  <Typography variant="body2">{item.gpa}</Typography>
                )}
              </Box>
              {edit === item.id ? (
                <Stack flexDirection={'row'} alignItems={'center'}>
                  {/* <SaveSharp onClick={() => onSubmit(item.id)} sx={{ cursor: 'pointer' }} /> */}
                  <Button type="submit" color="inherit">
                    <SaveSharp />
                  </Button>
                  <CloseIcon
                    onClick={() => {
                      closeRow(item.id)
                      reset(initialValues)
                    }}
                    sx={{ ml: 1, cursor: 'pointer' }}
                  />
                </Stack>
              ) : (
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
                  <EditIcon
                    onClick={() => {
                      setEdit(item.id)
                      reset({
                        id: item.id || 0,
                        letterGrade: item?.letterGrade,
                        minGrade: item?.minGrade,
                        maxGrade: item?.maxGrade,
                        gpa: item?.gpa,
                      })
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                  <DeleteIcon
                    onClick={() => {
                      setConfirm({ status: true, id: item?.id })
                    }}
                    sx={{ ml: 1, cursor: 'pointer' }}
                  />
                </Stack>
              )}
            </Stack>
          ))}
        </Box>
        <Stack sx={{ mt: 2 }} flexDirection={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
          {data?.id && data?.enabled && (
            <SubmitButton
              onClick={() => activate(false)}
              sx={{ mr: 2 }}
              title={t('Deactivate')}
              size="small"
              color="primary"
              variant="contained"
              icon={<RemoveIcon />}
            />
          )}

          {data?.id && !data?.enabled && (
            <SubmitButton
              onClick={() => activate(true)}
              sx={{ mr: 2 }}
              title={t('Activate')}
              size="small"
              color="primary"
              variant="contained"
              icon={<AddIcon />}
            />
          )}

          <SubmitButton
            onClick={saveScale}
            disabled={!name || data?.name === name}
            title={t('Save')}
            size="small"
            color="warning"
            variant="contained"
            icon={<SaveSharp />}
          />
        </Stack>
      </Card>
      <Confirm open={confirm?.status} onClose={() => setConfirm({ status: false, id: null })} onConfirm={onConfirm} />
    </BasePathProvider>
  )
}
