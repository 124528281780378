import { useState } from 'react'
import { TextField, Grid, IconButton, MenuItem, InputAdornment } from '@mui/material'
// import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import RemoveIcon from '@mui/icons-material/HighlightOff'
import Confirm from '../../../components/Dialog'
// import { deletePhone } from '../../../store/person'
import { usePerson } from '../../../components/Providers'

export default function PhoneEditForm({ path, control, item, index, ...props }) {
  // const membershipType = useMembershipType()
  const person = usePerson()
  // const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  async function remove(id, index, confirmed = false) {
    console.log('---remove-phone-->', id, person)
    try {
      if (!id) {
        props.remove(index)
        return
      }
      if (!confirmed) {
        setOpen(true)
        return
      }
      setOpen(false)
      // const res = await dispatch(deletePhone({ membershipType, personId: person.id, id }))
      // if (res.error) {
      //   console.error(res)
      //   throw new Error('Error')
      // }
      props.remove(index)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Grid item md={6} key={item?.id}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Controller
            name={`${path}.${index}.type`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  select
                  label={t('Phone')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  <MenuItem value="mobile">{t('Mobile')}</MenuItem>
                  <MenuItem value="home">{t('Home')}</MenuItem>
                  <MenuItem value="work">{t('Work')}</MenuItem>
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item md={9}>
          <Controller
            name={`${path}.${index}.number`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  label={t('Number')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => remove(item.id, index)} edge="end">
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      <Confirm open={open} onClose={() => setOpen(false)} onConfirm={() => remove(item.id, index, true)} />
    </Grid>
  )
}
