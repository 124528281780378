import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'academic-attendance-daily'
const initialState = {}

export const search = createApiAction(
  'academic/attendance/daily/search',
  client.search('/api/academic/attendance/daily'),
)
export const save = createApiAction('academic/attendance/daily/save', client.save('/api/academic/attendance/daily'))

export const update = createApiAction(
  'academic/attendance/daily/update',
  client.update('/api/academic/attendance/daily/:id'),
)

export const destroy = createApiAction(
  'academic/attendance/daily/delete',
  client.destroy('/api/academic/attendance/daily/:id'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.status = action.payload.status
    })
    builder.addCase(save.fulfilled, (state, action) => {
      state.status = action.payload.status
    })
  },
})

export default sessionSlice.reducer
