import { Route, Routes } from 'react-router-dom'
import StudentAttendancePage from './Page'
import StudentAttendanceFormIndex from './Form'

export default function StudentAttendance() {
  return (
    <Routes>
      <Route path="/" element={<StudentAttendancePage />}>
        <Route path="/:id" element={<StudentAttendanceFormIndex />} />
      </Route>
    </Routes>
  )
}
