import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

import { Box } from '@mui/material'

import LoginForm from './Login'
import RegisterForm from './Register'
import ForgotPasswordForm from './Forgot'
import ResetPasswordForm from './Reset'

import AppearanceControl from './appearance'

function AuthPage() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
      <Outlet />
      <AppearanceControl />
    </Box>
  )
}

export default function IndexPage() {
  return (
    <Routes>
      <Route path="/" element={<AuthPage />}>
        <Route index element={<LoginForm />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="register" element={<RegisterForm />} />
        <Route path="forgot" element={<ForgotPasswordForm />} />
        <Route path="reset/:token" element={<ResetPasswordForm />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}
