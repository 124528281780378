import { TableCell, TableRow, Link, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export default function SchedulingCoursesListItem({ item }) {
  const { t } = useTranslation()
  const basePath = '/settings/scheduling/courses'

  const generateScedCode = () => {
    if (item.scedSubjectAreaCode === null && item.scedCourseIdentifier === null) {
      return 'N/A'
    } else {
      return `${item.scedSubjectAreaCode}${item.scedCourseIdentifier}`
    }
  }

  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {generateScedCode()} - ({item?.id})
        </Link>
      </TableCell>
      <TableCell>{item?.courseSubject?.name}</TableCell>
      <TableCell>{item?.name}</TableCell>
      <TableCell>{item?.credit}</TableCell>
      <TableCell>
        <Button variant="outlined" component={NavLink} to={`${basePath}/${item.id}`} color="warning" size="small">
          {t('More')}
        </Button>
      </TableCell>
    </TableRow>
  )
}
