import { BasePathProvider } from 'components/Providers'
import SchedulingCoursesListIndex from './List'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import SchedulingCoursesFormIndex from './Form'

export default function SchedulingCoursesPage() {
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/courses/`
  const { courseId } = useParams()

  return (
    <BasePathProvider value={`${basePath}`}>
      <SchedulingCoursesListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(courseId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <SchedulingCoursesFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
