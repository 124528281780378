import _ from 'lodash'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/school-enrollment'
export const get = createApiAction('member/school-enrollment/get', client.retrieve(basePath))
export const update = createApiAction('member/school-enrollment/update', client.update(basePath))

export default function setSchoolEnrollmentReducers(builder) {
  builder.addCase(update.fulfilled, (state, action) => {
    const list = state.list || []
    const { memberId } = action.meta.arg
    let found = list.find(item => item.id === memberId)
    const foundIndex = list.indexOf(found)
    const replace = _.assign({ id: memberId }, found.schoolEnrollment, action.payload)
    if (foundIndex > -1) {
      state.list[foundIndex].schoolEnrollment = replace
    } else {
      list.push({ id: memberId, schoolEnrollment: { ...action.payload } })
      state.list = list
    }
  })
}
