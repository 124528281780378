import { useEffect } from 'react'
import { Drawer, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Title } from 'components/typo'
// import CourseList from './CourseList'
// import CourseEditFormWrapper from './CourseEditFormWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { search } from 'store/settings/course'
import { getGradeLevels, getGradingMethods } from 'store/lookup'
// import SearchBar from './SearchBar'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import CourseListSkeleton from './List/ListSkeleton'
import CourseListIndex from './List'
import CourseFormIndex from './Form'

const basePath = '/settings/course'

export default function CoursePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const courses = useSelector(state => state.settings.course.list)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const gradingMethods = useSelector(state => state.lookup.gradingMethods)
  const { query } = useSelector(state => state.settings.course)

  useEffect(() => {
    if (!courses) {
      dispatch(search({ ...query, offset: 0 }))
    }
  }, [dispatch, courses])

  useEffect(() => {
    if (!gradeLevels) {
      dispatch(getGradeLevels())
    }
  }, [dispatch, gradeLevels])

  useEffect(() => {
    if (!gradingMethods) {
      dispatch(getGradingMethods())
    }
  }, [dispatch, gradingMethods])


  if (!gradingMethods || !gradeLevels || !courses) {
    return <CourseListSkeleton />
  }
  return (
    <Box>
      <BasePathProvider value={`${basePath}`}>
        <Title sx={{ position: 'relative' }}>
          <Header title={'Course List'} add />
        </Title>

        {/* <CourseList query={query} list={list} count={count} /> */}
        <CourseListIndex />
        <Drawer
          PaperProps={{
            sx: {
              width: 700,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(basePath)}
        >
          <BasePathProvider value={`${basePath}/${id}`}>
            <CourseFormIndex />
          </BasePathProvider>
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
