import { useDispatch } from 'react-redux'
import GradeLevelSectionEditForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/settings/grade-level-section'
import GradeLevelSectionFormSkeleton from './FormSkeleton'

export default function GradeLevelSectionFormIndex() {
  const dispatch = useDispatch()
  const [gradeLevelSection, setGradeLevelSection] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => { 
        setGradeLevelSection(res.payload)
      })
    } else {
      setGradeLevelSection(null)
    }
  }, [id])

  if (id && id !== 'new' && !gradeLevelSection) return <GradeLevelSectionFormSkeleton />
  if (gradeLevelSection || id === 'new') return <GradeLevelSectionEditForm gradeLevelSection={gradeLevelSection} />
}
