import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar, Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers'
import { save } from 'store/settings/academic-year'
import { useDispatch } from 'react-redux'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { omit } from 'lodash'
import Header from 'components/Header'
import MarkingPeriod from '../MarkingPeriods/index'
import AYFormSkeleton from './AYFormSkeleton'
import { useTranslation } from 'react-i18next'

export default function AcademicYearEditForm({ academicYear }) {
  const { academicYearId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup.string().trim().required('Required'),
    startDate: yup.date().required('Required'),
    endDate: yup
      .date()
      .required('Required')
      .test('valid-dates', 'End date should be later than start date', function (value) {
        return this.parent.startDate < value
      }),
    schoolId: yup
      .number()
      .nullable()
      .transform(value => value || null),
  })

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...academicYear,
      schoolId: academicYear?.school?.id || '',
      name: academicYear?.name || '',
      startDate: academicYear?.startDate || '',
      endDate: academicYear?.endDate || '',
    },
  })

  const onSubmit = async (data, event) => {
    event.stopPropagation()
    if (data.startDate) {
      data.startDate = dayjs(data.startDate).format('YYYY-MM-DD')
    }
    if (data.endDate) {
      data.endDate = dayjs(data.endDate).format('YYYY-MM-DD')
    }
    if (data.markingPeriods) {
      data.markingPeriods = data.markingPeriods.map(mp => ({
        ...mp,
        startDate: dayjs(mp.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(mp.endDate).format('YYYY-MM-DD'),
      }))
    }

    let schoolId = data.school.id

    const payload = { ...omit(data, 'school'), schoolId }

    const res = await dispatch(save(payload))

    if (res.payload) {
      reset(res.payload)
      if (res.payload.markingPeriods.length) {
        navigate(basePath)
      } else {
        navigate(`${basePath}/${res.payload.id}`)
      }
    }
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/${academicYearId}`}>
      {academicYear ? (
        <Box>
          <Card>
            <Toolbar />
            <Header title={t('Academic Year')} close small />
            <CardContent
              component="form"
              onSubmit={handleSubmit(onSubmit, onError)}
              noValidate
              sx={{ minHeight: '100%' }}
            >
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <Controller
                    name="school.name"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextField
                          disabled
                          autoFocus
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextField
                          autoFocus
                          fullWidth
                          size="small"
                          label={t('Academic Year')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <DatePicker
                          fullWidth
                          label={t('Start Date')}
                          value={field.value ? dayjs(field.value) : ''}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!fieldState.error,
                              helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                            },
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <DatePicker
                          fullWidth
                          label={t('End Date')}
                          value={field.value && dayjs(field.value)}
                          onChange={field.onChange}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!fieldState.error,
                              helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                            },
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="outlined" onClick={() => navigate(basePath)}>
                  {t('Cancel')}
                </Button>
                <Button type="submit" variant="contained" disabled={!formState.isDirty}>
                  {t('Submit')}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {parseInt(academicYearId) ? <MarkingPeriod /> : <></>}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <AYFormSkeleton />
      )}
    </BasePathProvider>
  )
}
