import { Avatar, Button } from "@mui/material";
import { useSession } from "components/Providers"
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { enqueueSnackbar } from 'notistack'
import { useState } from "react"
import { get } from 'lodash'

export default function ProfilePicture() {
  const session = useSession()
  const [image, setImage] = useState(get(session, 'user.avatar.location'))

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('avatar', file)
    const url = `/api/session/avatar`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()
    setImage(data.location)
  }

  return (
    <Button component="label" aria-label="person-avatar" sx={{ width: '100%', aspectRatio: '3/4' }}>
      <input hidden accept="image/*" type="file" onChange={handleFile} />
      <Avatar sx={{ width: '100%', height: '100%' }} variant="rounded" src={image}>
        <AvatarIcon />
      </Avatar>
    </Button>
  )
}
