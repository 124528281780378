import CalendarList from './List'

export default function CalendarListIndex({ currentDay, prevMonth, nextMonth, districts, campuses, calendar }) {
  return (
    <CalendarList
      currentDay={currentDay}
      prevMonth={prevMonth}
      nextMonth={nextMonth}
      districts={districts}
      campuses={campuses}
      calendar={calendar}
    />
  )
}
