import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { capitalize, lowerCase } from 'lodash'
import { DeleteIcon } from 'components/Icons'
import { EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/settings/building-room'
import { Link as NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function RoomItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const { buildingId } = useParams()
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ buildingId, id: item.id }))
  }

  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item?.id}`}>
          {item.doorNumber}
        </Link>
      </TableCell>
      <TableCell>{capitalize(lowerCase(item.type))}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => setConfirm(true)}>
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item?.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
