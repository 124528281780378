import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import SchoolEnrollmentItem from './Item'
import { useTranslation } from 'react-i18next'

export default function SchoolEnrolmentList({ enrollments }) {
  const { t } = useTranslation()

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}> {t('School')} </TableCell>
            <TableCell sx={{ width: '16em' }}> {t('Enrollment Date')} </TableCell>
            <TableCell sx={{ width: '16em' }}> {t('UnEnrollment Date')} </TableCell>
            <TableCell sx={{ width: '8em' }}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enrollments?.map(enrollment => (
            <SchoolEnrollmentItem key={enrollment.id} enrollment={enrollment} />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
