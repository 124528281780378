import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EmploymentForm from './EmploymentForm'
import AssignmentForm from './AssignmentForm'
import ExperienceForm from './ExperienceForm'

export default function EmploymentsFormIndex() {
  const { form, personId, employmentId } = useParams()
  const data = useSelector(state => state.person2.employments)[+personId].find(item => item.id === +employmentId)

  const searchParams = new URLSearchParams(window.location.search)
  const index = searchParams.get('index')

  if (form === 'enrollment') {
    return <EmploymentForm data={data} />
  }

  if (form === 'experience') {
    return <ExperienceForm data={data} />
  }

  if (form === 'assignment') {
    if (index) {
      return <AssignmentForm data={data?.stateData?.assignments?.find(a => a.id === index)} />
    } else {
      return <AssignmentForm />
    }
  }
}
