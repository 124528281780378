import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'

import Header from 'components/Header'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { search, get } from 'store/academic/grading-policy'
import { getAssessmentType } from 'store/lookup'

import GradingList from './GradingList'
import GradingEditForm from './GradingEditForm'

export default function GradingPolicy() {
  const { courseSessionId, id } = useParams()
  const basePath = useBasePath()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { assessmentType } = useSelector(state => state.lookup)
  const [gradingPolicy, setGradingPolicy] = useState(null)

  useEffect(() => {
    courseSessionId && dispatch(search({ courseSessionId }))
    assessmentType || dispatch(getAssessmentType())
  }, [dispatch, courseSessionId, assessmentType])

  useEffect(() => {
    if (id === 'new') {
      setGradingPolicy({})
      return
    }
    if (id) {
      dispatch(get({ id, courseSessionId })).then(res => {
        setGradingPolicy(res.payload)
      })
      return
    }
    setGradingPolicy(null)
  }, [courseSessionId, dispatch, id])

  return (
    <BasePathProvider value={`${basePath}/grading-policy`}>
      <Header title={'Grading List'} small add />
      <GradingList />
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(basePath)}
      >
        {gradingPolicy && <GradingEditForm gradingPolicy={gradingPolicy} />}
      </Drawer>{' '}
    </BasePathProvider>
  )
}
