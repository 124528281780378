import { useSelector } from 'react-redux'
import GradingItem from './GradingItem'
import { Box, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'

export default function GradingList() {
  const gradingPolicy = useSelector(state => state.academic.gradingPolicy.list)

  return (
    <Box sx={{ p: 0 }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Type</TableHeadCell>
                <TableHeadCell>Weight</TableHeadCell>
                <TableHeadCell>Course Session Name</TableHeadCell>
                <TableHeadCell>Course Name</TableHeadCell>
                <TableHeadCell>Grade Level</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gradingPolicy?.map(item => {
                return <GradingItem key={item.id || item.uid} item={item} />
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  )
}
