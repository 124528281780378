import { BasePathProvider } from 'components/Providers'
import DailyAttendanceListIndex from './List'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import DailyAttendanceFormIndex from './Form'
import { useEffect, useState } from 'react'
import { getPeriods } from 'store/lookup'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

export default function DailyAttendancePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [student, setStudent] = useState()
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))

  const basePath = `/settings/attendance/daily`
  const periods = useSelector(state => state.lookup.periods)

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [periods])

  return (
    <BasePathProvider value={`${basePath}`}>
      <DailyAttendanceListIndex setStudent={setStudent} date={date} setDate={setDate} />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <DailyAttendanceFormIndex student={student} />
      </Drawer>
    </BasePathProvider>
  )
}
