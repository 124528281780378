import { Avatar, useMediaQuery } from '@mui/material'
import AvatarIcon from '@mui/icons-material/PersonOutlined'

export default function ProfilePicture({ student }) {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <>
      <input hidden accept="image/*" />
      {student?.memberships?.profilePicture?.location ? (
        <Avatar
          sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: 30 }}
          src={student?.memberships?.profilePicture?.location}
        >
          <AvatarIcon />
        </Avatar>
      ) : (
        <Avatar sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: '150%' }}>
          {[student?.firstName?.[0], student?.lastName?.[0]].join('')}
        </Avatar>
      )}
    </>
  )
}
