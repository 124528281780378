import { Drawer } from '@mui/material'
import { useEffect, useState } from 'react'
import AcademicYearEnrollmentEditForm from './EditForm'
import { getAcademicYears, getGradeLevels } from 'store/lookup'
import { search, get } from 'store/academic-year-enrollment'
import { useDispatch, useSelector } from 'react-redux'
import { BasePathProvider, useBasePath, useMembershipType, usePerson, useSession } from 'components/Providers'
import AcademicYearEnrollmentList from './List'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function AcademicYearEnrolments() {
  const { schoolEnrollment } = useSession()
  const [academicYears, setAcademicYears] = useState(null)
  const [enrollment, setEnrollment] = useState(null)
  const navigate = useNavigate()
  const currentPath = useBasePath()
  const basePath = `${currentPath}/enrollments`
  const { academicYearEnrollmentId } = useParams()
  const membershipType = useMembershipType()
  const person = usePerson()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const enrollments = useSelector(state => state.academicYearEnrollment.list)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const academicYearList = useSelector(state => state.lookup.academicYears)

  useEffect(() => {
    enrollments.length ||
      (schoolEnrollment?.id &&
        dispatch(search({ membershipType, personId: person.id, schoolEnrollmentId: schoolEnrollment?.id })).then(res =>
          setAcademicYears(res.payload),
        ))
  }, [dispatch, membershipType, person?.id, schoolEnrollment?.id])

  useEffect(() => {
    academicYearList || dispatch(getAcademicYears())
  }, [dispatch, schoolEnrollment?.id])

  useEffect(() => {
    gradeLevels?.length || dispatch(getGradeLevels())
  }, [dispatch, schoolEnrollment?.id, gradeLevels?.length])

  useEffect(() => {
    setEnrollment(null)
    if (academicYearEnrollmentId === 'new') {
      setEnrollment({})
    } else if (academicYearEnrollmentId) {
      dispatch(get({ membershipType, personId: person.id, id: academicYearEnrollmentId })).then(res =>
        setEnrollment(res.payload),
      )
    }
  }, [dispatch, membershipType, person?.id, academicYearEnrollmentId])

  if (!(academicYearList && gradeLevels)) {
    return null
  }

  return (
    <>
      <BasePathProvider value={`${basePath}/academic-year-enrollment`}>
        <Header small title={t('Academic Year Enrollments')} add />
        <AcademicYearEnrollmentList enrollments={enrollments} />
      </BasePathProvider>
      <BasePathProvider value={`${basePath}`}>
        <Drawer anchor="right" open={Boolean(enrollment)} onClose={() => navigate(basePath)} sx={{ width: '1000px' }}>
          <AcademicYearEnrollmentEditForm
            academicYears={academicYears}
            gradeLevels={gradeLevels}
            enrollment={enrollment}
          />
        </Drawer>
      </BasePathProvider>
    </>
  )
}
