import { Card, CardContent, Grid, Skeleton, Toolbar, CardActions, Button } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function AcademicYearProviderEditFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Card component="form" noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Header title={t('Add Academic Year')} close small />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Skeleton height={60} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={60} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={60} />
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Skeleton height={60} />
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined">
          <Skeleton />
        </Button>
        <Button type="submit" variant="contained">
          <Skeleton />
        </Button>
      </CardActions>
    </Card>
  )
}
