import { Button, Box, CardActions, CardContent, Grid, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export default function NotFound({ basePath }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const MainBox = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.lg,
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
    },
  }))

  const handleClose = () => {
    navigate(`${basePath}/..`)
  }

  return (
    <MainBox sx={{ minHeight: '100%' }}>
      <Toolbar />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">{'Not Found'}</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            {t('The page you are looking for does not exist. Please go back to the previous page.')}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button type="submit" variant="contained" onClick={handleClose} color="primary">
          {t('Back to previous page')}
        </Button>
      </CardActions>
      <CardContent>
        <Grid container spacing={2}></Grid>
      </CardContent>
    </MainBox>
  )
}
