import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getGradeLevels, getRaceTypes } from 'store/lookup'
import { useEffect, useState } from 'react'
import PreviewCard from 'components/PreviewCard'
import FeildSkeleton from '../../../Member/Contact/List/FieldSkeleton'
import { useTranslation } from 'react-i18next'
import NotFound from 'components/NotFound'
import { useBasePath } from 'components/Providers'
import 'utils/global.scss'

const genders = ['Male', 'Female', 'Non-Binary/Undesignated']

const ethnicities = ['Hispanic or Latino', 'Not Hispanic or Latino']

export default function StudentProfile() {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.session.appearance)
  const raceTypes = useSelector(state => state.lookup.raceTypes)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const { t } = useTranslation()
  const student = useSelector(state => state.academic.student.detail)
  const [notFound, setNotFound] = useState(false)
  const basePath = useBasePath()

  useEffect(() => {
    const fetchData = async () => {
      const [raceTypesRes, gradeLevelsRes] = await Promise.all([
        !raceTypes && dispatch(getRaceTypes()),
        !gradeLevels && dispatch(getGradeLevels()),
      ])
      if (raceTypesRes?.error || gradeLevelsRes?.error) {
        setNotFound(true)
      }
    }

    fetchData()
  }, [dispatch, raceTypes, gradeLevels])

  let races = ''
  if (Array.isArray(student?.person?.raceTypeIds)) {
    student?.person?.raceTypeIds.forEach(item => {
      const race = raceTypes?.find(rt => rt.id === item)
      if (race) {
        races = `${races} ${race.name},`
      }
    })
  }

  const profileData = [
    {
      key: 'Full Name',
      value: `${student?.person?.firstName} ${student?.person?.middleName ?? ''} ${student?.person?.lastName}` || '',
    },
    { key: 'State ID', value: student?.stateData?.stateId || '' },
    {
      key: 'Date of Birth',
      value: student?.person?.dateOfBirth ? dayjs(student?.person?.dateOfBirth).format('MM-DD-YYYY') : '',
    },
    { key: 'Email/Username', value: student?.person?.email || '' },

    {
      key: 'Gender',
      value: student?.person?.gender
        ? genders.find(g => g.toLowerCase() === student?.person?.gender.toLowerCase())
        : '',
    },
    {
      key: 'Ethnicity',
      value: student?.person?.ethnicity
        ? ethnicities.find(e => e.toLowerCase() === student?.person?.ethnicity.replaceAll(/-/g, ' ').toLowerCase())
        : '',
    },
    { key: 'Race', value: races || '' },
    {
      key: 'Address',
      value: `${student?.person?.addresses?.[0]?.street || ''} ${student?.person?.addresses?.[0]?.city || ''} ${
        student?.person?.addresses?.[0]?.state || ''
      } ${student?.person?.addresses?.[0]?.zip || ''}`,
    },
  ]

  if (notFound) return <NotFound basePath={`${basePath}/`} />

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mx: 3, mt: 3 }}>
        <PreviewCard title={t('Personal Information')} size="large">
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {profileData.map((item, index) => (
                <Grid key={index} item sm={4} xs={4} md={4}>
                  <Typography variant="body2" className={`grid-table-title-${theme}`} sx={{ fontSize: '12px' }}>
                    {t(item.key)}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {item.value ? item.value : <FeildSkeleton />} &nbsp;
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
