import { Box, LinearProgress } from '@mui/material'
import Wrapper from './Wrapper'
import { search as searchSchoolEnrollments, select as selectSchoolEnrollment } from 'store/settings/school-enrollment'
import { useMembershipType, useSession } from 'components/Providers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select as selectAcademicYearEnrollment } from 'store/academic-year-enrollment'
import { useParams } from 'react-router-dom'

export default function Enrolments() {
  const dispatch = useDispatch()
  const { school, academicYear, district } = useSession()
  const membershipType = useMembershipType()
  const { personId } = useParams()

  const schoolEnrollments = useSelector(state => state.settings.schoolEnrollment.list)

  const selectedSchoolEnrollmentId = useSelector(state => state.settings.schoolEnrollment.selected?.id)

  const selectedSchoolEnrollment = schoolEnrollments?.find(item => item.id === selectedSchoolEnrollmentId)

  const selectedAcademicYearEnrollment = useSelector(state => state.academicYearEnrollment.selected)

  const [hideSchoolEnrollment, setHideSchoolEnrollment] = useState(null)

  const [hideAcademicYeaarEnrollment, setHideAcademicYeaarEnrollment] = useState(null)

  useEffect(() => {
    dispatch(selectSchoolEnrollment(null))
    dispatch(selectAcademicYearEnrollment(null))
  }, [dispatch, personId, membershipType])

  useEffect(() => {
    if (school?.id) {
      if (schoolEnrollments?.length === 1 && schoolEnrollments[0]?.school?.id === school?.id) {
        dispatch(selectSchoolEnrollment(schoolEnrollments[0]))
        setHideSchoolEnrollment(true)
      }
    }
  }, [dispatch, schoolEnrollments?.[0]?.school?.id, schoolEnrollments?.length])

  useEffect(() => {
    if (personId && membershipType) {
      if (!selectedSchoolEnrollment) {
        dispatch(searchSchoolEnrollments({ personId, membershipType }))
      }
    }
  }, [personId])

  useEffect(() => {
    if (academicYear?.id) {
      academicYear?.id && dispatch(selectAcademicYearEnrollment(academicYear))
      setHideAcademicYeaarEnrollment(true)
    }
  }, [dispatch, academicYear?.id, personId])

  if (!district.isServiceProvider) {
    if (!schoolEnrollments?.[0]?.school?.id) {
      return <LinearProgress sx={{ m: 2 }} color="success" />
    }
  }

  return (
    <Box>
      <Wrapper
        selectedSchoolEnrollment={selectedSchoolEnrollment}
        selectedAcademicYearEnrollment={selectedAcademicYearEnrollment}
        schoolEnrollments={schoolEnrollments}
        hideSchoolEnrollment={hideSchoolEnrollment}
        hideAcademicYeaarEnrollment={hideAcademicYeaarEnrollment}
      />
    </Box>
  )
}
