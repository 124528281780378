import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

export const get = createApiAction(
  'academic-year-enrollment/get',
  client.retrieve(`/api/:membershipType/:personId/academic-year-enrollment/:id`),
)
export const save = createApiAction(
  'academic-year-enrollment/save',
  client.save('/api/:membershipType/:personId/academic-year-enrollment'),
)

function foundMemberIndex(state, action) {
  const list = state.list || []
  const { personId } = action.meta.arg
  const member = list.find(item => item.person.id === personId)
  const memberIndex = list.indexOf(member)

  if (member) return { memberIndex, member }
  else throw new Error(`Member with id ${personId} not found.`)
}
const updateAcademicYearEnrollment = (state, action) => {
  const { member, memberIndex } = foundMemberIndex(state, action)
  const { gradeLevelId, gradeSection } = action.payload
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  state.list[memberIndex].academicYearEnrollment = {
    ...state.list[memberIndex].academicYearEnrollment,
    gradeLevelId,
    gradeSection,
  }
}
export default function setAcademicYearEnrollment(builder) {
  builder.addCase(get.fulfilled, updateAcademicYearEnrollment).addCase(save.fulfilled, updateAcademicYearEnrollment)
}
