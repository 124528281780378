import {
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Button,
  Box,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import SharedIcon from '@mui/icons-material/Wifi'
import { useTranslation } from 'react-i18next'

function RoleList({ roles }) {
  const { id } = useParams()
  const url = '/settings/permission'
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card sx={{ minHeight: '80%' }}>
      <Box display="flex" justifyContent="flex-end" sx={{ m: 2 }}>
        <Button
          onClick={() => navigate(`${url}/new`)}
          sx={{ width: '5em' }}
          variant="contained"
          color="warning"
          size="small"
        >
          {t('Add')}
        </Button>
      </Box>
      <CardContent sx={{ p: 0 }}>
        <List>
          {roles.map(role => (
            <ListItem key={role.id} disablePadding>
              <ListItemButton component={Link} to={[url, role.id].join('/')} selected={id == role.id}>
                <ListItemText>{role.name}</ListItemText>
                {role.shared && <SharedIcon color="primary" />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions sx={{ p: 2 }}></CardActions>
    </Card>
  )
}

export default RoleList
