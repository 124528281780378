import { BasePathProvider } from 'components/Providers'
import { Box, Paper } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import CalendarPage from './Page'
import { useTranslation } from 'react-i18next'

export default function Calendar() {
  const basePath = '/calendar'
  const { t } = useTranslation()

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={t('Calendar')} />
      </Box>
      <Paper sx={{ width: '100%', minHeight: '500px', p: 2, mb: 2 }}>
        <CalendarPage />
      </Paper>
    </BasePathProvider>
  )
}
