import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/person/:personId/employment'

export const search = createApiAction('person/employment/get', client.search(`${basePath}`))
export const create = createApiAction('person/membership/create', client.save(`${basePath}`))
export const save = createApiAction('person/employment/update', client.update(`${basePath}/:membershipId`))

export default function setPersonMembershipReducers(builder) {
  builder
    .addCase(create.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      const personEmployments = [...state.employments[+personId]]
      personEmployments.push(action.payload)
      state.employments[+personId] = personEmployments
    })
    .addCase(search.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      if (!state.employments) {
        const temp = {}
        temp[personId] = action.payload
        state.employments = temp
      } else {
        const employments = state.employments
        employments[personId] = action.payload || []
        state.employments = employments
      }
    })
    .addCase(save.fulfilled, (state, action) => {
      const membershipId = action.meta.arg.membershipId
      const personId = action.meta.arg.personId
      const personEmployments = state.employments[+personId]
      const updated = personEmployments.find(item => item.id === +membershipId)
      const updatedIndex = personEmployments.indexOf(updated)
      state.employments[personId][updatedIndex] = action.payload
    })
}
