import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom'
import PersonProfile from './Profile'
import { useTranslation } from 'react-i18next'
import Contacts from '../Contact'
import Employments from '../Employment'
import Campuses from '../Campuses'
import { useSelector } from 'react-redux'
import 'utils/global.scss'
import Schedule from '../Schedule'

const PROFILE = 0
const CONTACTS = 1
const EMPLOYMENT = 2
const CAMPUS = 3
const SCHEDULE = 4

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['profile', 'contacts', 'employment', 'campus', 'schedule'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function PersonDetailTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const permissions = useSelector(state => state.session?.permissions)
  const { personId, '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])
  const basePath = `/person/${personId}`

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        {personId && (
          <Tabs
            value={tabIndex}
            TabIndicatorProps={{ style: { background: '#FF9215', fontFamily: 'Roboto, sans-serif' } }}
          >
            <Tab
              sx={{ color: tabIndex === 0 && '#FF9215 !important' }}
              className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Profile')}
              value={PROFILE}
              component={Link}
              to={`${basePath}/profile`}
            />
            {permissions[`staff.contact`] && (
              <Tab
                sx={{ color: tabIndex === 1 && '#FF9215 !important' }}
                className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Contacts')}
                value={CONTACTS}
                component={Link}
                to={`${basePath}/contacts`}
              />
            )}
            <Tab
              sx={{ color: tabIndex === 2 && '#FF9215 !important' }}
              className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Employment')}
              value={EMPLOYMENT}
              component={Link}
              to={`${basePath}/employment`}
            />
            <Tab
              sx={{ color: tabIndex === 3 && '#FF9215 !important' }}
              className={tabIndex === 3 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Campus')}
              value={CAMPUS}
              component={Link}
              to={`${basePath}/campus`}
            />
            <Tab
              sx={{ color: tabIndex === 4 && '#FF9215 !important' }}
              className={tabIndex === 4 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Schedule')}
              value={SCHEDULE}
              component={Link}
              to={`${basePath}/schedule`}
            />
          </Tabs>
        )}
      </Box>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route path="" element={<PersonProfile />} />
          <Route path="profile/*" element={<PersonProfile />} />
          <Route path="contacts/*" element={<Contacts />} />
          <Route path="employment/*" element={<Employments />} />
          <Route path="campus/*" element={<Campuses />} />
          <Route path="schedule/*" element={<Schedule />} />
        </Route>
      </Routes>
    </Box>
  )
}
