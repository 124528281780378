import {
  Autocomplete,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { SearchIcon } from 'components/Icons'
import { getTeachers } from 'store/lookup'
import dayjs from 'dayjs'
import { getPeriods } from 'store/lookup'
import ListItem from './ListItem'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

const tableHeadCellStyle = {
  fontWeight: '500 !important',
  borderRight: '2px solid white',
  color: 'white',
}

export default function index({
  changeSelectedCourseSession,
  selectedCourseSession,
  filteredCourseSession,
  setSelectedDate,
  selectedDate,
  fetchDate,
  selectedDateFunc,
  setSelectedTeacher,
  selectedTeacher,
  isAdmin,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [que, setQue] = useState(null)
  const { periods } = useSelector(state => state.lookup)
  const { academicYear } = useSelector(state => state.session)
  const teachers = useSelector(state => state.lookup.teachers.rows)
  const [selectedRow, setSelectedRow] = useState(null)
  const academicYearStartDate = useSelector(state => state.session.academicYear.startDate)
  const academicYearEndDate = useSelector(state => state.session.academicYear.endDate)
  const maxDateRule = dayjs(Math.min(new Date(academicYearEndDate).getTime(), new Date().getTime()))
  const attendanceTakenPeriods = useSelector(state => state.academic.schedule.list)?.attendanceTakenPeriods
  const searchQuery = { limit: 10, offset: 0 }

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [periods])

  useEffect(() => {
    if (que) {
      searchQuery.que = que
    }
    teachers || dispatch(getTeachers(searchQuery))
    setSelectedTeacher(null)
  }, [academicYear])

  const applyFilter = async (key, value) => {
    if (value) {
      searchQuery[key] = value
    }
    if (value) dispatch(getTeachers(searchQuery))
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const selectTeacher = (event, value) => {
    if (value) {
      setSelectedTeacher(value)
    } else {
      setSelectedTeacher(null)
    }
  }
  return (
    <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        {isAdmin && (
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              size="small"
              freeSolo
              disablePortal
              options={teachers}
              onInputChange={(event, value, reason) => {
                switch (reason) {
                  case 'reset':
                    break
                  case 'clear':
                    setQue(null)
                    applyFilter('que', null)
                    break
                  case 'input':
                    break
                  default:
                    break
                }
              }}
              onChange={selectTeacher}
              getOptionLabel={option => {
                return `${option?.person?.lastName}, ${option?.person?.firstName}`
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  value={que}
                  fullWidth
                  label={t('Teacher')}
                  onChange={e => {
                    setQue(e.target.value)
                    delayedSearch(e.target.value, applyFilter)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingRight: '10px',
                    },
                    endAdornment: (
                      <InputAdornment position="end" style={{ right: '0' }}>
                        <SearchIcon sx={{ mr: 0 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DatePicker
            label={t('Date')}
            value={dayjs(selectedDate)}
            disabled={isAdmin && !selectedTeacher}
            onChange={e => {
              setSelectedDate(dayjs(e).format('YYYY-MM-DD')), selectedDateFunc()
            }}
            maxDate={maxDateRule}
            minDate={dayjs(academicYearStartDate)}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              field: {
                fullWidth: true,
                InputLabelProps: { shrink: true },
                InputProps: {
                  sx: {
                    height: '45px',
                  },
                },
              },
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: 'orange' }}>
                <TableRow>
                  <TableHeadCell sx={tableHeadCellStyle}>{t('Course Sessions')}</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourseSession?.map((item, index) => (
                  <ListItem
                    key={index}
                    index={index}
                    item={item}
                    changeSelectedCourseSession={changeSelectedCourseSession}
                    selectedCourseSession={selectedCourseSession}
                    selectedDate={selectedDate}
                    fetchDate={fetchDate}
                    periods={periods}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    attendanceTakenPeriods={attendanceTakenPeriods}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}
