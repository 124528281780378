import { Card, CardContent, Grid, Skeleton, Toolbar, CardActions, Button } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function JobPositionFormSkeleton() {
  const { t } = useTranslation()

  return (
    <BasePathProvider>
      <Card data-component="JobPositionFormSkeleton" sx={{ minHeight: '100%' }}>
        <Toolbar />
        <Header title={t('Job Position Edit Form')} small close />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12}>
              <Skeleton variant="rounded" height={35} sx={{ mx: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined">
            <Skeleton />
          </Button>
          <Button type="submit" variant="contained">
            <Skeleton />
          </Button>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
