import { Grid } from '@mui/material'
import MarkingPeriod from './MarkingPeriod'

export default function MarkingPeriodList({ path, markingPeriodProvider, control, append, errors }) {
  return (
    <Grid container spacing={2}>
      {markingPeriodProvider?.markingPeriods?.map((item, index) => (
        <MarkingPeriod
          key={item.name}
          path={path}
          control={control}
          append={append}
          item={item}
          index={index}
          errors={errors}
        />
      ))}
    </Grid>
  )
}
