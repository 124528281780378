import CourseList from './List'
import SearchBar from './SearchBar'

export default function CourseListIndex() {
  return (
    <>
      <SearchBar /> <CourseList />
    </>
  )
}
