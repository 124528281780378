import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search } from 'store/settings/school'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { getCountry } from 'store/lookup'
import SchoolListSkeleton from './List/ListSkeleton'
import SchoolFormIndex from './Form/index.jsx'
import SchoolListIndex from './List'

const baseUrl = '/settings/school'

export default function SchoolPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const schools = useSelector(state => state.settings.school?.list)
  const { country } = useSelector(state => state.lookup)
  // const [school, setSchool] = useState(null)
  const { id } = useParams()

  const [isLoaded, setIsLoaded] = useState()

  useEffect(() => {
    if (!schools) {
      setIsLoaded(false)
      dispatch(search())
    }
  }, [schools])

  useEffect(() => {
    if (!country) {
      setIsLoaded(false)
      dispatch(getCountry())
    }
  }, [country])

  useEffect(() => {
    if (schools && country) {
      setIsLoaded(true)
    }
  }, [schools, country])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'School'} add />
      {isLoaded ? <SchoolListIndex /> : <SchoolListSkeleton />}
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <SchoolFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
