import { Autocomplete, Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import _, { omit, pick } from 'lodash'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { getCountries, getLanguages, resetCityList, resetCountyList } from 'store/lookup'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SubmitButton } from 'components/Buttons'
import { BasePathProvider, useMember, useBasePath } from 'components/Providers'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import SealOfBiliteracyForm from './sealOfBiliteracy'
import StateAssessmentForm from './stateAssessment'
import HealthRelatedInfoForm from './healthRelatedInfo'
import GiftedAndTalentedForm from './giftedAndTalented'
import Section504Form from './section504'
import ResidentInfoForm from './residentInfo'
import ProgramInfoForm from './programInfo'
import ELLForm from './ell'
import SpedForm from './sped'
import { useTranslation } from 'react-i18next'

const initialData = {
  section504: {
    section504: '',
  },
  programInfo: {
    programType: '',
    enrollmentType: '',
    titleIIndicator: '',
    titleIParticipation: '',
    alternativeEducation: '',
    eighthTechnologicalLiteracy: '',
    isStudentRepeatingTheCurrentGradeLevel: '',
  },
  studentInfo: {
    cityOfBirth: '',
    stateOfBirth: '',
    countyOfBirth: '',
    migrantStatus: '',
    countryOfBirth: '',
    homelessStatus: '',
    previousSchool: '',
    immigrantStatus: '',
    studentLivesWith: '',
    firstNativeLanguage: '',
    homelessSupportService: '',
    homelessInstructionalService: '',
    homelessPrimaryNightTimeResidence: '',
    militaryConnectedStudentIndicator: '',
  },
  residentInfo: {
    residentCounty: '',
    residentSchool: '',
    residentDistrict: '',
    districtEntryDate: '',
    residentMunicipalCode: '',
    firstEntryDateIntoUSSchool: '',
  },
  stateAssessment: {
    bridgeYear: '',
    apaMathTest: '',
    apaScienceTest: '',
    apaLanguageTest: '',
    federalHsMathTestingReq: '',
    elaGraduationPathwayIndicator: '',
    mathGraduationPathwayIndicator: '',
  },
  sealOfBiliteracy: {
    biliterate: '',
    worldLanguageAssessed: '',
    worldLanguageAssessment: '',
  },
  giftedAndTalented: {
    specialAptitude: [],
    cognitiveAbility: '',
    giftedAndTalented: '',
    academicAchievement: [],
  },
  healthRelatedInfo: {
    healthInsuranceStatus: '',
    healthInsuranceProvider: '',
  },
}

const initialDataOfEll = {
  ell: {
    status: '',
    titleIIIEllStatus: '',
    programStartDate: '',
    programEndDate: '',
    exemptTakingElaPortion: '',
    njEllInfo: '',
    accommodation: '',
    languageInsEduPrg: '',
  },
}

const initialDataOfSped = {
  referralDate: '',
  parentalConsentEvaluateDate: '',
  eligibilityDeterminationDate: '',
  initialIEPMeetingDate: '',
  earlyIntervention: '',
  parentalConsentObtained: '',
  parentalConsentImpInitialIEP: '',
  annualIEPReviewMeetingDate: '',
  specialEducationClassification: '',
  reevaluationDate: '',
  initialProcessDelayReason: '',
  specialEducationPlacement: '',
  timeRegularProgram: '',
  counselingServices: '',
  occupationalTherapyServices: '',
  physicalTherapyServices: '',
  speechLanguageTherapyServices: '',
  otherRelatedServices: '',
}

export default function StudentFormUsNj({ saveForm, stateDataType, sharedStateDataType }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { part: formPart } = useParams()

  const languages = useSelector(state => state.lookup.languages)
  const member = useMember()
  const countries = useSelector(state => state.lookup.countryList)
  const states = useSelector(state => state.lookup.stateList)
  const counties = useSelector(state => state.lookup.countyList)
  const cities = useSelector(state => state.lookup.cityList)
  const { country, state } = useSelector(state => state.session.district.address)
  const basePath = useBasePath()

  const [residentCountyList, setResidentCountyList] = useState()

  const getResidentCountyList = async () => {
    try {
      const response = await fetch(`/api/lookup/countries?country=${country}&state=${state}`)
      const datas = await response.json()
      setResidentCountyList(datas)
    } catch (err) {
      console.log('---error--->', err.message)
    }
  }

  useEffect(() => {
    residentCountyList || getResidentCountyList()
    countries || dispatch(getCountries())
    languages || dispatch(getLanguages())
  }, [dispatch, member?.id])

  const schema = yup.object().shape({
    studentLivesWith: yup.string(),
  })

  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    let data = {}
    const initialValues =
      formPart === 'ell'
        ? initialDataOfEll
        : formPart === 'personal'
        ? initialData
        : formPart === 'sped' && initialDataOfSped
    _.defaultsDeep(
      data,
      member?.stateData || {
        studentInfo: null,
        section504: null,
        programInfo: null,
        residentInfo: null,
        stateAssessment: null,
        sealOfBiliteracy: null,
        giftedAndTalented: null,
        healthRelatedInfo: null,
      },
      initialValues,
    )
    reset(data)

    if (member?.stateData?.studentInfo) {
      const countryOfBirth = member?.stateData?.studentInfo?.countryOfBirth
      const stateOfBirth = member?.stateData?.studentInfo?.stateOfBirth
      const countyOfBirth = member?.stateData?.studentInfo?.countyOfBirth
      if (countryOfBirth && !stateOfBirth && !countyOfBirth) {
        counties?.find(item => item.county_abbr === countryOfBirth) ||
          dispatch(getCountries({ country: countryOfBirth }))
      } else if (countryOfBirth && stateOfBirth && !countyOfBirth) {
        counties?.find(item => item.county_abbr === countryOfBirth) ||
          dispatch(getCountries({ country: countryOfBirth }))
        states?.find(item => item.state_abbr === stateOfBirth) ||
          dispatch(getCountries({ country: countryOfBirth, state: stateOfBirth }))
      } else if (countryOfBirth && stateOfBirth && countyOfBirth) {
        counties?.find(item => item.county_abbr === countryOfBirth) ||
          dispatch(getCountries({ country: countryOfBirth }))
        states?.find(item => item.state_abbr === stateOfBirth) ||
          dispatch(getCountries({ country: countryOfBirth, state: stateOfBirth }))
        dispatch(getCountries({ country: countryOfBirth, state: stateOfBirth, county: countyOfBirth }))
      }
    }
  }, [member?.stateData?.fetchedAt])

  const onSubmit = async data => {
    if (data?.ell?.programStartDate !== '' && data?.ell?.programStartDate) {
      if (new Date(data?.ell?.programStartDate).getTime() > new Date(data?.ell?.programEndDate).getTime()) {
        return
      }
    }
    let datas = data
    if (formPart === 'personal') {
      datas = omit(data, ['sped', 'ell'])
    }
    if (formPart === 'ell') {
      datas = pick(data, 'ell')
    }
    if (formPart === 'sped') {
      datas = pick(data, 'sped')
    }
    await saveForm(datas)
    navigate(`${basePath}/state-data`)
  }

  const onError = errors => console.error(errors)

  const changedCountry = useWatch({ control, name: 'studentInfo.countryOfBirth' })
  const changedState = useWatch({ control, name: 'studentInfo.stateOfBirth' })
  const changedCounty = useWatch({ control, name: 'studentInfo.countyOfBirth' })

  useEffect(() => {
    if (
      changedCountry &&
      countries?.find(item => item.country_abbr === changedCountry) &&
      !states?.find(item => item.country_abbr === changedCountry)
    ) {
      dispatch(getCountries({ country: changedCountry }))
      dispatch(resetCountyList)
      dispatch(resetCityList)
    }
  }, [changedCountry])

  useEffect(() => {
    if (
      changedCountry &&
      changedState &&
      states?.find(item => item.state_abbr === changedState) &&
      !counties?.find(item => item.state_abbr === changedState)
    ) {
      dispatch(resetCityList)
      dispatch(getCountries({ country: changedCountry, state: changedState }))
    }
  }, [changedState])

  useEffect(() => {
    if (
      changedCountry &&
      changedState &&
      changedCounty &&
      counties?.find(item => item.county_name === changedCounty) &&
      !cities?.find(item => item.county_name === changedCounty)
    ) {
      dispatch(getCountries({ country: changedCountry, state: changedState, county: changedCounty }))
    }
  }, [changedCounty])

  if (!(countries && languages && stateDataType)) return null
  // if (!(countries && languages && stateDataType) || !states || !Array.isArray(states)) return null

  const academicAchievementOption = stateDataType.filter(item => item.field === 'academicAchievement')
  const bridgeYearOption = stateDataType.filter(item => item.field === 'bridgeYear')
  const elaGraduationPathwayIndicatorOption = stateDataType.filter(
    item => item.field === 'elaGraduationPathwayIndicator',
  )

  const enrollmentTypeOption = stateDataType.filter(item => item.field === 'enrollmentType')
  const federalHsMathTestingReqOption = stateDataType.filter(item => item.field === 'federalHsMathTestingReq')
  const giftedAndTalentedOption = stateDataType.filter(item => item.field === 'giftedAndTalented')

  const healthInsuranceStatusOption = stateDataType.filter(item => item.field === 'healthInsuranceStatus')
  const homelessPrimaryNightTimeResidenceOption = stateDataType.filter(
    item => item.field === 'homelessPrimaryNightTimeRes',
  )
  const homelessStatusOption = stateDataType.filter(item => item.field === 'homelessStatus')

  const mathGraduationPathwayIndicatorOption = stateDataType.filter(
    item => item.field === 'mathGraduationPathwayIndicator',
  )
  const militaryConnectedStudentIndicatorOption = stateDataType.filter(
    item => item.field === 'militaryConnectedStudentInd',
  )
  const noAndYesOption = sharedStateDataType.filter(item => item.field === 'noAndYes')

  const programTypeOption = stateDataType.filter(item => item.field === 'programType')
  const residentDistrictOption = stateDataType.filter(item => item.field === 'residentDistrict')
  const residentMunicipalCodeOption = stateDataType.filter(item => item.field === 'residentMunicipalCode')

  const residentSchoolOption = stateDataType.filter(item => item.field === 'residentSchool')
  const specialAptitudeOption = stateDataType.filter(item => item.field === 'specialAptitude')
  const studentLivesWithOption = stateDataType.filter(item => item.field === 'studentLivesWith')

  const titleIIndicatorOption = stateDataType.filter(item => item.field === 'titleIIndicator')
  const worldLanguageAssessmentOption = stateDataType.filter(item => item.field === 'worldLanguageAssessment')
  const ellStatusOption = stateDataType.filter(item => item.field === 'ellStatus')
  const languageInsEduPrgOption = stateDataType.filter(item => item.field === 'ellLanguageInsEduPrg')

  return (
    <BasePathProvider value={`${basePath}/state-data/`}>
      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mb: 4 }}>
        {formPart === 'personal' && (
          <>
            <Header title={t('Student Information')} small close borderBottom />
            <Grid container spacing={2} sx={{ p: 3 }}>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.studentLivesWith"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Student Lives With')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {studentLivesWithOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.countryOfBirth"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        freeSolo
                        error={!!fieldState.error}
                        size="small"
                        {...field}
                        options={countries?.map(c => c.country_abbr) || []}
                        value={member?.stateData?.studentInfo?.countryOfBirth || ''}
                        getOptionLabel={option => {
                          return countries.find(c => c.country_abbr === option)?.country_name || option || ''
                        }}
                        onChange={(event, value) => {
                          setValue('studentInfo.countryOfBirth', value || '')
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('Country of Birth')}
                            error={!!fieldState.error}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.stateOfBirth"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        freeSolo
                        error={!!fieldState.error}
                        size="small"
                        {...field}
                        options={states?.map(s => s.state_abbr) || []}
                        value={member?.stateData?.studentInfo?.stateOfBirth || ''}
                        getOptionLabel={option => {
                          return states?.find(c => c.state_abbr === option)?.state_name || option || ''
                        }}
                        onChange={(event, value) => {
                          setValue('studentInfo.stateOfBirth', value || '')
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('State of Birth')}
                            error={!!fieldState.error}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              {/* <Grid item sm={6} xs={6}>
                  <Controller
                    name="studentInfo.countyOfBirth"
                    control={control}
                    render={({ field: { ...field }, fieldState }) => {
                      return (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          error={!!fieldState.error}
                          size="small"
                          {...field}
                          options={counties?.map(c => c.county_name) || []}
                          value={member?.stateData?.studentInfo?.countyOfBirth || ''}
                          getOptionLabel={option => {
                            return counties?.find(c => c.county_name === option)?.county_name || option || ''
                          }}
                          onChange={(event, value) => {
                            setValue('studentInfo.countyOfBirth', value || '')
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              {...field}
                              fullWidth
                              label={t('County of Birth')}
                              error={!!fieldState.error}
                            />
                          )}
                        />
                      )
                    }}
                  />
                </Grid> */}
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.cityOfBirth"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        freeSolo
                        error={!!fieldState.error}
                        size="small"
                        {...field}
                        options={cities?.map(c => c.city_name) || []}
                        value={member?.stateData?.studentInfo?.cityOfBirth || ''}
                        getOptionLabel={option => {
                          return cities?.find(c => c.city_name === option)?.city_name || option || ''
                        }}
                        onChange={(event, value) => {
                          setValue('studentInfo.cityOfBirth', value || '')
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('City of Birth')}
                            error={!!fieldState.error}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.firstNativeLanguage"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('First (Native) Language')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {languages.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name} {item.originName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.previousSchool"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        label={t('Previous School')}
                        error={!!fieldState.error}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.migrantStatus"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Migrant Status')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {noAndYesOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.immigrantStatus"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Immigrant Status')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {noAndYesOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.homelessStatus"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Homeless Status')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {homelessStatusOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.homelessPrimaryNightTimeResidence"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Homeless Primary Nighttime Residence')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {homelessPrimaryNightTimeResidenceOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.homelessInstructionalService"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Homeless Instructional Service')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {noAndYesOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Controller
                  name="studentInfo.homelessSupportService"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Homeless Support Service')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {noAndYesOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Controller
                  name="studentInfo.militaryConnectedStudentIndicator"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Military Connect Student Indicator')}
                        error={!!fieldState.error}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {militaryConnectedStudentIndicatorOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Resident Information')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <ResidentInfoForm
                control={control}
                residentDistrictOption={residentDistrictOption}
                residentMunicipalCodeOption={residentMunicipalCodeOption}
                residentSchoolOption={residentSchoolOption}
                residentCountyList={residentCountyList}
              />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Program Information')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <ProgramInfoForm
                control={control}
                noAndYesOption={noAndYesOption}
                enrollmentTypeOption={enrollmentTypeOption}
                programTypeOption={programTypeOption}
                titleIIndicatorOption={titleIIndicatorOption}
              />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Seal of Biliteracy')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <SealOfBiliteracyForm
                control={control}
                languages={languages}
                noAndYesOption={noAndYesOption}
                worldLanguageAssessmentOption={worldLanguageAssessmentOption}
              />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('State Assessment')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <StateAssessmentForm
                control={control}
                noAndYesOption={noAndYesOption}
                elaGraduationPathwayIndicatorOption={elaGraduationPathwayIndicatorOption}
                mathGraduationPathwayIndicatorOption={mathGraduationPathwayIndicatorOption}
                bridgeYearOption={bridgeYearOption}
                federalHsMathTestingReqOption={federalHsMathTestingReqOption}
              />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Health Related Information')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <HealthRelatedInfoForm control={control} healthInsuranceStatusOption={healthInsuranceStatusOption} />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Gifted and Talented')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <GiftedAndTalentedForm
                control={control}
                giftedAndTalentedOption={giftedAndTalentedOption}
                setValue={setValue}
                getValues={getValues}
                specialAptitudeOption={specialAptitudeOption}
                noAndYesOption={noAndYesOption}
                academicAchievementOption={academicAchievementOption}
              />
            </Grid>

            <Header small sx={{ m: 3, mt: 5, mb: 1 }} title={t('Section 504')} />
            <Grid container spacing={2} sx={{ px: 2 }}>
              <Section504Form control={control} noAndYesOption={noAndYesOption} />
            </Grid>
          </>
        )}
        {formPart === 'ell' && (
          <>
            <Header title={t('ELL Information')} close small borderBottom />
            <Grid container spacing={2} sx={{ px: 2, pt: 3 }}>
              <ELLForm
                control={control}
                noAndYesOption={noAndYesOption}
                ellStatusOption={ellStatusOption}
                languageInsEduPrgOption={languageInsEduPrgOption}
                getValues={getValues}
              />
            </Grid>
          </>
        )}
        {formPart === 'sped' && (
          <>
            <Header title={t('Special Education Information')} close small borderBottom />
            <Grid container spacing={2} sx={{ px: 2, pt: 3 }}>
              <SpedForm control={control} noAndYesOption={noAndYesOption} />
            </Grid>
          </>
        )}
        {/* {formPart !== 'sped' && ( */}
        <Stack alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ mt: 3, mr: 3 }}>
          <SubmitButton size="small" title="Save" color={'warning'} onClick={handleSubmit(onSubmit)} />
        </Stack>
        {/* )} */}
      </Box>
    </BasePathProvider>
  )
}
