import { Grid } from '@mui/material'

import AddressEditForm from './AddressEditForm'

export default function AddressList({ path, fields, control, append, ...props }) {
  const emptyAddress = { type: '', street: '', line2: '', city: '', state: '', zip: '', country: ''}
  return (
    <Grid container spacing={2}>
      {fields.length === 0 &&  <AddressEditForm
          key={0}
          path={path}
          control={control}
          append={append}
          item={emptyAddress}
          index={0}
          {...props}
        />}
      {fields.map((item, index) => (
        <AddressEditForm
          key={item.id}
          path={path}
          fields={fields}
          control={control}
          append={append}
          item={item}
          index={index}
          {...props}
        />
      ))}
    </Grid>
  )
}
