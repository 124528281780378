import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getGradeLevels } from 'store/lookup'
import { destroy } from 'store/settings/course-session-enrollments'
import Confirm from 'components/Dialog'
import { search } from 'store/settings/course-session-enrollments'
import { get } from 'store/settings/course-session'

const heads = ['Student Name', 'Grade Session', 'Course Grade', 'Action']

export default function SchedulingEnrollSessionEnrolled({ selectedCourseSession, setSelectedCourseSession }) {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const academicYearId = useSelector(state => state.session?.academicYear?.id)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const [confirm, setConfirm] = useState({ status: false, id: null })

  const students = useSelector(state => state.settings?.courseSessionsEnrollments?.list)?.filter(
    cse => cse?.schoolEnrollment?.membership?.type === 'student',
  )

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [academicYearId])

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [academicYearId])

  useEffect(() => {
    if (selectedCourseSession) {
      dispatch(search({ courseSessionId: +selectedCourseSession?.id, academicYearId: +academicYearId }))
    }
  }, [selectedCourseSession])

  const destroyEnrollment = async () => {
    setConfirm({ status: false, id: null })
    await dispatch(destroy({ courseSessionId: +selectedCourseSession?.id, id: confirm?.id }))
    await dispatch(get({ id: +selectedCourseSession.id })).then(response => {
      if (response.payload) {
        setSelectedCourseSession(response.payload)
      }
    })
  }

  if (!(gradeLevels && selectedCourseSession)) return null

  return (
    <Box sx={{ pt: 3, pl: 1 }}>
      <Table>
        <TableHead>
          <TableRow>
            {heads.map((item, index) => (
              <TableHeadCell
                key={index}
                sx={{
                  fontWeight: '500 !important',
                }}
              >
                {t(item)}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {students && students?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                {item?.schoolEnrollment?.membership?.person?.lastName},{' '}
                {item?.schoolEnrollment?.membership?.person?.firstName}
              </TableCell>
              <TableCell>
                {
                  gradeLevels?.find(
                    gl =>
                      gl.id ===
                      item?.schoolEnrollment?.academicYearEnrollments?.find(
                        aye => aye.academicYearId === +academicYearId,
                      )?.gradeLevelId,
                  )?.name
                }
                {
                  item?.schoolEnrollment?.academicYearEnrollments?.find(aye => aye.academicYearId === +academicYearId)
                    ?.gradeSection
                }
              </TableCell>
              <TableCell>{item?.grade}</TableCell>

              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => setConfirm({ status: true, id: item?.id })}
                  size="small"
                  color="warning"
                >
                  {t('Unenroll')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Confirm
        open={confirm.status}
        onClose={() => setConfirm({ status: false, id: null })}
        onConfirm={destroyEnrollment}
      />
    </Box>
  )
}
