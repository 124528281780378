import { Box, Grid, Link, Stack } from '@mui/material'
import { DAYS } from 'constants'
import './Schedule.scss'
import { useTheme } from '@emotion/react'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UnScheduleList from './UnScheduleList'
import ScheduleDays from './ScheduleDays'
import SchedulePeriods from './SchedulePeriods'

const dayListHeight = 50
const periodListWidth = 30

export default function ScheduleTable({ hours, saturday, sunday, unScheduledSessions, scheduledSessions }) {
  const theme = useTheme()

  const permission = useSelector(state => state.session.permissions)

  const scheduleMainWin = useRef(null)

  const [scheduleXY, setScheduleXY] = useState()

  const [daysCount, setDayCount] = useState(5)

  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  useEffect(() => {
    const handleWindowResize = () => {
      setScheduleXY(scheduleMainWin.current.getBoundingClientRect())
    }
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (sunday && saturday) {
      setDayCount(7)
    } else if (saturday || sunday) {
      setDayCount(6)
    } else {
      setDayCount(5)
    }
  }, [saturday, sunday])

  const calculateLeftPosition = dayOfWeek => {
    if (scheduleXY) {
      const width = scheduleXY.width
      if (dayOfWeek === 0) {
        if (saturday) {
          return Math.abs((width / 7) * 6 + 31)
        } else {
          return Math.abs((width / 6) * 5 + 31)
        }
      } else {
        if (saturday && sunday) {
          return Math.abs((width / 7) * (dayOfWeek - 1) + 31)
        } else if (saturday || sunday) {
          return Math.abs((width / 6) * (dayOfWeek - 1) + 31)
        } else {
          return Math.abs((width / 5) * (dayOfWeek - 1) + 31)
        }
      }
    }
  }

  const calculateWidth = () => {
    if (scheduleXY) {
      return Math.abs(scheduleXY.width / daysCount - 1)
    }
  }

  const calculateHeight = (start, end) => {
    const startMinutes = parseInt(start.split(':')[0]) * 60 + parseInt(start.split(':')[1])
    const endMinutes = parseInt(end.split(':')[0]) * 60 + parseInt(end.split(':')[1])
    const heightForOneMinutes = scheduleXY?.height / (hours.length * 60)
    return heightForOneMinutes * (endMinutes - startMinutes)
  }

  const calculateTop = start => {
    const height = scheduleXY?.height
    const periodStartTime = hours[0].split(':')[0]
    const startTime = (parseInt(start.split(':')[0]) - periodStartTime) * 60 + parseInt(start.split(':')[1])
    const heightForOneMinutes = height / (hours.length * 60)
    return Math.abs(heightForOneMinutes * startTime + 57)
  }

  return (
      <Box id="student-detail-page-schedule" sx={{ m: 3, overflow: 'hidden' }} display={'flex'} flexDirection={'row'}>
        {scheduleXY && scheduledSessions && (
          <div
            id="schedule-items"
            style={{ position: 'absolute', minWidth: `${scheduleXY.width}px`, width: `${scheduleXY.width}px` }}
          >
            {scheduledSessions?.map((courseSession, index) => (
              <Box
                className={courseSession?.list?.length > 1 ? 'schedule-session-cell conflict' : 'schedule-session-cell'}
                key={index}
                sx={{
                  position: 'absolute',
                  left: `${calculateLeftPosition(courseSession.dayOfWeek)}px`,
                  width: `${calculateWidth()}px`,
                  height: `${calculateHeight(courseSession.startTime, courseSession.endTime)}px`,
                  top: `${calculateTop(courseSession.startTime)}px`,
                  fontSize: '90%',
                  fontWeight: '200',
                  p: 1,
                  overflow: 'hidden',
                  mb: 2,
                }}
              >
                {courseSession.list.map(item => (
                  <Box key={item.name} className="cell-item">
                    <Box>
                      {hasCourseSessionViewPermission ? (
                        <Link
                          component={NavLink}
                          underline="hover"
                          color="inherit"
                          to={`/settings/course-session/${item.id}`}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <>{item.name}</>
                      )}
                    </Box>
                    <Box>
                      {dayjs(courseSession.startTime, 'HH:mm:ss').format('h:mm a')} -{' '}
                      {dayjs(courseSession.endTime, 'HH:mm:ss').format('h:mm a')}
                    </Box>
                    <Box>
                      {item.room?.name} {item.room?.doorNumber}
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
          </div>
        )}
        <SchedulePeriods periodListWidth={periodListWidth} hours={hours} />
        <Box sx={{ width: 'calc(100% - 30px)' }} className="day-name-row">
          <ScheduleDays saturday={saturday} sunday={sunday} dayListHeight={dayListHeight} />
          <Grid container ref={scheduleMainWin}>
            {hours.map((hour, periodIndex) => (
              <Grid item xs={12} key={hour}>
                <Stack flexDirection={'row'}>
                  {Array.from(Array(daysCount).keys()).map(item => (
                    <Box
                      key={item}
                      id={DAYS[item]}
                      sx={
                        periodIndex % 2 === 0
                          ? {
                              backgroundColor: theme.palette.action.disabled,
                              mr: '1px',
                              marginBottom: '1px',
                              height: '90px',
                            }
                          : {
                              backgroundColor: theme.palette.action.hover,
                              mr: '1px',
                              pr: '1px',
                              marginBottom: '1px',
                              height: '90px',
                            }
                      }
                    ></Box>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
          <UnScheduleList list={unScheduledSessions} permission={hasCourseSessionViewPermission} />
        </Box>
      </Box>
  )
}
