import { Drawer } from '@mui/material'
import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get, search } from 'store/settings/marking-period'
import { useState, useEffect } from 'react'
import { useBasePath } from 'components/Providers'
import { useSelector } from 'react-redux'
import MarkingPeriodFormIndex from './Form'
import MarkingPeriodList from './List'

export default function MarkingPeriodIndex() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const { academicYearId, id } = useParams()

  const [markingPeriod, setMarkingPeriod] = useState()
  const markingPeriods = useSelector(state => state.markingPeriod.list)

  useEffect(() => {
    if (parseInt(academicYearId)) {
      markingPeriods || dispatch(search({ academicYearId }))
    }
  }, [dispatch, academicYearId, markingPeriods?.length])

  useEffect(() => {
    if (id === 'new') {
      setMarkingPeriod({})
      return
    }
    if (id) {
      academicYearId &&
        dispatch(get({ id, academicYearId })).then(res => {
          setMarkingPeriod(res.payload)
        })
      return
    }
    setMarkingPeriod(null)
  }, [dispatch, id, academicYearId])

  return (
    <BasePathProvider value={`${basePath}`}>
      <MarkingPeriodList />
      <Drawer
        PaperProps={{
          sx: {
            width: 450,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(basePath)}
      >
        <MarkingPeriodFormIndex markingPeriod={markingPeriod} />
      </Drawer>
    </BasePathProvider>
  )
}
