import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search as searchFinalGrade } from 'store/academic/parent/final-grades'
import { useParams } from 'react-router-dom'
import FinalGrades from 'components/FinalGrades'
import { search } from 'store/academic/parental-grading-scales'
import { search as getStudentAcademicYears } from 'store/academic/parental-academic-year'
import { LinearProgress } from '@mui/material'

export default function index() {
  const dispatch = useDispatch()
  const { studentId } = useParams()

  const academicYear = useSelector(state => state.academic.parentalAcademicYear?.list?.[0])

  const student = useSelector(state => state.academic.parentStudent?.list)?.find(m => m.id.toString() === studentId)
  const grades = student?.finalGrades
  const gradingScales = useSelector(state => state.academic.parentalGradingScales.gradingScales)

  const markingPeriods = academicYear ? academicYear?.markingPeriods : null
  const schoolEnrollmentId = student?.memberships?.[0].schoolEnrollments?.[0].id
  const courseSessionEnrollmentGrades = grades?.data
  const gpas = grades?.gpas

  useEffect(() => {
    if (studentId && schoolEnrollmentId) {
      dispatch(
        getStudentAcademicYears({
          studentId,
          schoolEnrollmentId,
        }),
      )
    }
  }, [schoolEnrollmentId, studentId])

  useEffect(() => {
    if (!grades && academicYear?.id && studentId && schoolEnrollmentId) {
      dispatch(
        searchFinalGrade({
          studentId: parseInt(studentId),
          schoolEnrollmentId: parseInt(schoolEnrollmentId),
        }),
      )
    }
  }, [dispatch, studentId, schoolEnrollmentId, academicYear?.id])

  useEffect(() => {
    if (!gpas && academicYear?.id && studentId) {
      dispatch(search({ studentId, academicYearId: academicYear?.id }))
    }
  }, [dispatch, academicYear?.id, studentId])

  if (!(courseSessionEnrollmentGrades && markingPeriods && gradingScales && academicYear))
    return <LinearProgress sx={{ m: 2 }} color="success" />

  return (
    <FinalGrades
      courseSessionEnrollmentGrades={courseSessionEnrollmentGrades}
      markingPeriods={markingPeriods}
      gpas={gpas}
      gradingScales={gradingScales}
    />
  )
}
