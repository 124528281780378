import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar, MenuItem } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from '../../../store/settings/building'
import * as yup from 'yup'
import AddressEditForm from '../../../components/AddressEditForm'
import { omit } from 'lodash'
import { BasePathProvider, useBasePath } from 'components/Providers'
import Header from 'components/Header'
import Rooms from './Room/index'
import { useTranslation } from 'react-i18next'

export default function BuildingEditForm({ building, campus, schools, ...props }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { buildingId } = useParams()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    address: yup.object().shape({
      type: yup.string().default('work'),
      street: yup.string().required('Required'),
      line2: yup.string(),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      zip: yup.string().required('Required'),
      country: yup.string().required('Required'),
    }),
    campus: yup.object().shape({
      id: yup.number().nullable(),
      name: yup.string(),
    }),
    school: yup.object().shape({
      id: yup.number().nullable(),
      name: yup.string(),
    }),
  })

  const { control, handleSubmit, getValues, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(building)
  }, [building, reset])

  const onSubmit = async data => {
    const campusId = data.campus.id
    const schoolId = data.school.id
    const address = omit(data.address, 'id')
    const payload = { ...omit(data, ['district', 'campus', 'school', 'address']), campusId, schoolId, address }
    const res = await dispatch(save(payload))
    if (res.payload) {
      data?.id ? navigate(basePath) : navigate(`${basePath}/${res.payload.id}`)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/${buildingId}`}>
      <Toolbar />
      <Header title={t('Building')} subheader={t('Building create/edit')} close />
      <Card data-component="BuildingEditForm" component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      label={t('Building name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Controller
                name="campus.id"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      data-name="campus.id"
                      size="small"
                      label={t('Campus name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    >
                      {campus?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                      <MenuItem value={null}>{'{empty}'}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Controller
                name="school.id"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      data-name="school.id"
                      size="small"
                      label={t('School name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    >
                      {schools.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                      <MenuItem value={null}>{'{empty}'}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Header title={t('Address')} small sx={{ pt: 0, pb: 1, pl: 1, pr: 0 }} />
              <AddressEditForm path="address" control={control} {...props} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={!formState.isDirty}>
            {t('Submit')}
          </Button>
        </CardActions>
      </Card>
      <CardContent>{getValues('id') && <Rooms />}</CardContent>
    </BasePathProvider>
  )
}
