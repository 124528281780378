import { Box, Drawer } from '@mui/material'
import Personal from './PersonalForm'
import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import MemberSearch from 'pages/Member/Search'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import PageHeader from 'components/AppBar/PageHeader'

export default function NewPersonalForm() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { membershipType } = useParams()

  const basePath = `/member/${membershipType}`
  return (
    <BasePathProvider value={basePath}>
      <Box sx={{ my: 2 }}>
        <PageHeader title={t(`${capitalize(membershipType)} List`)} add />
      </Box>
      <MemberSearch />
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={true}
        anchor="right"
        onClose={() => navigate(basePath)}
      >
        <Personal />
      </Drawer>
    </BasePathProvider>
  )
}
