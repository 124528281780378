import { Button, Card, CardActions, CardContent, CardHeader, Grid, Skeleton, Stack, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function CourseSessionEditFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Card sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Stack flexDirection={'row'} sx={{ m: 1 }}>
        <Skeleton variant="circular" width={0} height={80} />
        <CardHeader title={t('Course Session')} subheader="Course Session create/edit" />
      </Stack>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined">
          <Skeleton />
        </Button>
        <Button type="submit" variant="contained">
          <Skeleton />
        </Button>
      </CardActions>{' '}
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
