import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/school'
import { BasePathProvider, useBasePath } from 'components/Providers'
import * as yup from 'yup'
import AddressEditForm from 'components/AddressEditForm'
import { useEffect, useState } from 'react'
import { omit } from 'lodash'
import Header from 'components/Header'
import { enqueueSnackbar } from 'notistack'
import AvatarIcon from '@mui/icons-material/PersonOutlined'

import { search as searchSchool } from 'store/settings/school'
import { useTranslation } from 'react-i18next'

export default function SchoolForm({ school, ...props }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const districtId = useSelector(state => state.session.district.id)

  const [image, setImage] = useState()

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    address: yup.object().shape({
      type: yup.string().default('work'),
      street: yup.string().required('Required'),
      line2: yup.string(),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      zip: yup.string().required('Required'),
      country: yup.string().required('Required'),
    }),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(school)
  }, [school, reset])

  useEffect(() => {
    if (school.id) {
      setImage(school?.file?.location)
    }
  }, [school])

  const onSubmit = async data => {
    const datas = omit(data, 'file')
    const res = await dispatch(save(omit(datas, 'district')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onError = errors => console.error(errors)

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('afile', file)
    formData.append('schoolId', school.id)
    formData.append('districtId', districtId)
    const url = `/api/setting/school/upload`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()
    setImage(data.location)
    dispatch(searchSchool())
  }

  return (
    <BasePathProvider value={`${basePath}/school`}>
      <Card
        data-component="SchoolEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Toolbar />
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'}>
            <IconButton component="label" aria-label="school-logo" disabled={school.id ? false : true}>
              <input hidden accept="image/*" type="file" onChange={handleFile} />
              {image ? (
                <Avatar sx={{ width: 80, height: 80, fontSize: 30 }} src={image}>
                  <AvatarIcon />
                </Avatar>
              ) : (
                <Box sx={{ width: 80, height: 80, borderRadius: '50%', backgroundColor: '#aaa' }} />
              )}
            </IconButton>
            <CardHeader title={t('School')} subheader={t('School create/edit')} />
          </Stack>
          <Header close />
        </Stack>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      label={t('School name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={12}>
              <Header title={t('Address')} small sx={{ pt: 0, pb: 1, pl: 1, pr: 0 }} />
              <AddressEditForm path="address" control={control} {...props} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t("Cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={!formState.isDirty}>
            {t("Submit")}
          </Button>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
