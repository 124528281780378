import { Route, Routes } from 'react-router-dom'
import HealthConditionsPage from './Page'

export default function HealthConditions() {
  return (
    <Routes>
        <Route path="/" element={<HealthConditionsPage />} />
        <Route path="/:id" element={<HealthConditionsPage />} />
    </Routes>
  )
}
