import { useState } from 'react'

import { useDispatch } from 'react-redux'

import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@mui/material'
import { Link, Navigate, useParams } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTranslation } from 'react-i18next'

import * as yup from 'yup'
import yuppassword from 'yup-password'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPassword } from '../../store/session'

import MyAlert from '../../components/Alert'

yuppassword(yup)

export default function ResetForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({ open: false, status: '' })
  const { token } = useParams()

  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required('Required')
        .min(8, 'Your password is too short.')
        .minUppercase(2)
        .minLowercase(2)
        .minSymbols(1)
        .minRepeating(2),
      confirm: yup.string().oneOf([yup.ref('password')], 'Your passwords do not match.'),
    })
    .required()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirm: '',
      token,
    },
  })

  const onSubmit = async data => {
    setState(prev => ({ ...prev, open: false }))
    const res = await dispatch(resetPassword(data))
    if (res.error) {
      setState({ open: true, status: 'error' })
    } else {
      setState({ open: true, status: 'success' })
    }
  }

  const SUCCESS = 'Please check your mailbox to continue to reset your password.'
  const ERROR = 'Please try again later, something went wrong!'

  if (state.status === 'success') {
    return <Navigate to="/" replace={true} />
  }

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <Box
            sx={{
              mt: 4,
              margin: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('Reset Password')}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label={t("New password")}
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
            <Controller
              name="confirm"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label={t("Re-type new password")}
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    sx={{ mb: 2 }}
                  />
                )
              }}
            />
            <MyAlert open={state.open} status={state.status} title={state.status === 'success' ? SUCCESS : ERROR} />
            <Button
              fullWidth
              type="submit"
              variant="contained"
            // sx={{ mt: 0, mb: 0 }}
            >
              {t('Send Reset Link')}
            </Button>
          </Box>
        </CardContent>
        <CardActions sx={{ margin: 1 }}>
          <Grid container>
            <Grid item xs>
              <Link to="/auth/login">{t('Remember your password?')}</Link>
            </Grid>
            <Grid item>
              <Link to="/auth/register">{t('Register')}</Link>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  )
}
