import { Route, Routes } from 'react-router-dom'
import CoursePage from './Page'
import CourseFormIndex from './Form'

export default function Course() {
  return (
    <Routes>
      <Route path="/" element={<CoursePage />}>
        <Route path="/:id" element={<CourseFormIndex />} />
      </Route>
    </Routes>
  )
}