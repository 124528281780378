import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/provider/academic-year'

export const search = createApiAction(
  'provider/marking-period/search',
  client.search(`${basePath}/:academicYearId/marking-period`),
)
export const get = createApiAction(
  'provider/marking-period/get',
  client.retrieve(`${basePath}/:academicYearId/marking-period/:id`),
)
export const save = createApiAction(
  'provider/marking-period/save',
  client.save(`${basePath}/:academicYearId/marking-period`),
)
export const destroy = createApiAction(
  'provider/marking-period/destroy',
  client.destroy(`${basePath}/:academicYearId/marking-period/:id`),
)

function foundAcademicYearIndex(state, action) {
  const rows = state.rows || []
  const { academicYearId } = action.meta.arg
  const academicYear = rows.find(item => item.id === +academicYearId)
  const academicYearIndex = rows.indexOf(academicYear)

  if (academicYear) return { academicYearIndex }
  else throw new Error(`Academic Year with id ${academicYearId} not found.`)
}

const update = (state, action) => {
  const { academicYearIndex } = foundAcademicYearIndex(state, action)
  state.rows[academicYearIndex].markingPeriodProvider = action.payload
}

export default function setMarkingPeriodReducers(builder) {
  builder
    .addCase(search.fulfilled, update)
    .addCase(save.fulfilled, (state, action) => {
      const { academicYearIndex } = foundAcademicYearIndex(state, action)
      state.rows[academicYearIndex].markingPeriodProvider = state.rows[academicYearIndex].markingPeriodProvider?.map(
        item => (item.id === action.payload.id ? action.payload : item),
      )
    })
    .addCase(destroy.fulfilled, (state, action) => {
      const { id } = action.meta.arg
      const { academicYearIndex } = foundAcademicYearIndex(state, action)
      state.rows[academicYearIndex].markingPeriodProvider = state.rows[academicYearIndex].markingPeriodProvider?.filter(
        item => item.id !== +id,
      )
    })
}
