import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/academic/schedule'
import { BasePathProvider, useSession } from 'components/Providers'
import ScheduleTable from 'components/Schedule'
import { Box, Card, LinearProgress } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import Header from 'components/Header'

const baseUrl = '/academic/schedule'

export const Schedule = () => {
  const dispatch = useDispatch()
  const {
    user: { firstName, lastName },
    person: { id: personId },
  } = useSession()

  const [studentCourseSessionEnrollment, setStudentCourseSessionEnrollment] = useState()
  const [scheduledSessions, setScheduledSessions] = useState()
  const [unScheduledSessions, setUnscheduledSessions] = useState()
  const [saturdaySession, setSaturdaySession] = useState(false)
  const [sundaySession, setSundaySession] = useState(false)
  const [startRenderList, setStartRenderList] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [minMaxPeriod, setMinMaxPeriod] = useState()
  const academicYear = useSelector(state => state.session.academicYear)

  const courseEnrollmentFromStore = useSelector(state => state.academic.schedule.list)

  const studentCourseSessionEnrollmentFetch = async () => {
    if (!courseEnrollmentFromStore) {
      const data = await dispatch(search())
      if (data?.payload?.length) {
        setStudentCourseSessionEnrollment(data.payload)
      } else {
        setErrorMessage('There are no Course Enrollments')
      }
    } else {
      setStudentCourseSessionEnrollment(courseEnrollmentFromStore)
    }
  }

  useEffect(() => {
    studentCourseSessionEnrollmentFetch()
  }, [personId, academicYear?.id])

  useEffect(() => {
    if (studentCourseSessionEnrollment?.length > 0) {
      let temp = []
      const rawScheduledSessions = studentCourseSessionEnrollment?.filter(
        item => item.courseSession?.schedules?.length > 0,
      )
      rawScheduledSessions.forEach(item => {
        item.courseSession.schedules.forEach(scheduleItem => {
          const newItem = {
            id: item.courseSession.id,
            name: item.courseSession.course.name + ' - ' + item.courseSession.name,
            startTime: scheduleItem.period.startTime,
            endTime: scheduleItem.period.endTime,
            dayOfWeek: scheduleItem.period.dayOfWeek,
            room: scheduleItem?.room?.id
              ? scheduleItem?.room
              : item?.courseSession?.room?.id
              ? item?.courseSession?.room
              : null,
          }
          temp = addSessionToSchedule(newItem, temp)
        })
      })
      setScheduledSessions(temp)
      setErrorMessage('')
      setUnscheduledSessions(
        studentCourseSessionEnrollment?.filter(item => item.courseSession?.schedules?.length === 0),
      )

      let min = 8
      let max = 16
      studentCourseSessionEnrollment.forEach(item => {
        item.courseSession?.schedules?.forEach(inem => {
          min = Math.min(inem.period.startTime.split(':')[0], min)
          max = Math.max(inem.period.endTime.split(':')[0], max)
        })
      })
      const hours = []
      for (let i = min; i <= max; i++) {
        hours.push(i + ':00:00')
      }
      setMinMaxPeriod(hours)
    } else if (studentCourseSessionEnrollment?.length === 0) {
      setErrorMessage('There is not any Course Enrollment')
    }
  }, [studentCourseSessionEnrollment])

  const addSessionToSchedule = (newItem, storage) => {
    if (storage.length === 0) {
      storage.push({
        startTime: newItem.startTime,
        endTime: newItem.endTime,
        dayOfWeek: newItem.dayOfWeek,
        list: [{ id: newItem.id, name: newItem.name, room: newItem.room }],
      })
      return storage
    }
    let existsIndex = 0
    storage.forEach((item, index) => {
      if (
        item.startTime === newItem.startTime &&
        item.endTime === newItem.endTime &&
        item.dayOfWeek === newItem.dayOfWeek
      ) {
        if (existsIndex === 0) existsIndex = index
      }
    })

    if (existsIndex === 0) {
      storage.push({
        startTime: newItem.startTime,
        endTime: newItem.endTime,
        dayOfWeek: newItem.dayOfWeek,
        list: [{ id: newItem.id, name: newItem.name, room: newItem.room }],
      })
      return storage
    } else {
      const temp = storage[existsIndex]
      temp.list.push({ id: newItem.id, name: newItem.name, room: newItem.room })
      storage[existsIndex] = temp
      return storage
    }
  }

  useEffect(() => {
    if (scheduledSessions?.length > 0) {
      setSundaySession(
        studentCourseSessionEnrollment.filter(item => item.courseSession.schedules?.find(s => s.period.dayOfWeek === 0))
          .length > 0
          ? true
          : false,
      )
      setSaturdaySession(
        studentCourseSessionEnrollment.filter(item => item.courseSession.schedules?.find(s => s.period.dayOfWeek === 6))
          .length > 0
          ? true
          : false,
      )
    }

    setStartRenderList(true)
  }, [scheduledSessions])

  return (
    <BasePathProvider value={baseUrl}>
      <PageHeader title={'Schedule'} />
      <Card sx={{ mt: 2 }} className="schedule-table">
        <Box sx={{ pr: 0.5 }}>
          <Header print printTitle={`Schedule of ${firstName} ${lastName}`} printfunc={() => window.print()} />
        </Box>

        {startRenderList && minMaxPeriod && !errorMessage ? (
          <ScheduleTable
            hours={minMaxPeriod}
            saturday={saturdaySession}
            sunday={sundaySession}
            unScheduledSessions={unScheduledSessions}
            scheduledSessions={scheduledSessions}
          />
        ) : errorMessage ? (
          <Box sx={{ m: 4 }}>{errorMessage}</Box>
        ) : (
          startRenderList === false && <LinearProgress sx={{ m: 2 }} color="success" />
        )}
      </Card>
    </BasePathProvider>
  )
}
