import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { DeleteIcon } from '../../../components/Icons'
import { EditIcon } from '../../../components/Icons'
import Confirm from '../../../components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { destroy } from '../../../store/settings/campus'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function CampusItem({ campus }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const schools = useSelector(state => state.lookup.schools)

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: campus.id }))
  }

  return (
    <TableRow hover data-id={campus.id} data-name={campus.name}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${campus.id}`}>
          {campus.name}
        </Link>
      </TableCell>
      <TableCell>{schools?.find(item => item.id === campus.schoolId)?.name}</TableCell>
      <TableCell>
        {campus.address?.city} {campus.address?.country}
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${campus.id}`} aria-label="edit">
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
