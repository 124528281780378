import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/person/campus/:districtId'

export const search = createApiAction('person/campus/get', client.search(`${basePath}`))

export default function setPersonCampusReducers(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    const { districtId } = action.meta.arg
    const campuses = state.campuses || {}
    campuses[districtId] = action.payload
    state.campuses = campuses
  })
}
