import { forwardRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Confirm({ open, onClose, onConfirm }) {
  const { t } = useTranslation()
  return (
    <Dialog data-component="Confirm" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>Are you sure to delete selected row?</DialogContent>
      <DialogActions>
        <Button aria-label="cancel" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button aria-label="confirm" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
