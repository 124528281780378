import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link, Tooltip } from '@mui/material'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath, useSession } from 'components/Providers'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckBoxOutlined as CheckIcon,
  CheckBoxOutlineBlank as UnCheckIcon,
  Link as LinkIcon,
  List as SessionsIcon,
} from '@mui/icons-material'
import { basePath as courseSessionUrl } from '../../CourseSession'
import { destroy } from 'store/settings/course'

export default function CourseListItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const session = useSession()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item?.id }))
  }

  return (
    <TableRow key={item?.id}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item?.id}`}>
          {item?.name}
        </Link>
      </TableCell>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item?.id}`}>
          {item?.courseSubject?.name}
        </Link>
      </TableCell>
      <TableCell>
          {item?.CourseLevel?.suffix}
      </TableCell>
      <TableCell>
        <IconButton component={NavLink} to={`${courseSessionUrl}?courseId=${item?.id}`}>
          <SessionsIcon />
        </IconButton>
      </TableCell>
      <TableCell>{item?.enabled ? <CheckIcon /> : <UnCheckIcon />}</TableCell>
      <TableCell>
        {item?.districtId !== session.district.id && (
          <Tooltip title={`inherited from provider`}>
            <LinkIcon />
          </Tooltip>
        )}
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item?.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
