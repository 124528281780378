import { combineReducers } from '@reduxjs/toolkit'

import calendarReducer from './calendar'
import academicYearReducer from './academic-year'
import courseReducer from './course.js'
import schoolReducer from './school.js'

const combinedReducer = combineReducers({
  calendar: calendarReducer,
  academicYear: academicYearReducer,
  course: courseReducer,
  school: schoolReducer,
})

export default combinedReducer
