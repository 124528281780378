import { Drawer } from '@mui/material'
import CourseSessionList from './CourseSessionList'
import CourseSessionEditFormWrapper from './CourseSessionEditFormWrapper'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BasePathProvider } from 'components/Providers'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search as getCourses } from 'store/settings/course'
import { search } from 'store/settings/course-session'
import { getSchools } from 'store/lookup'
import SearchBar from './SearchBar'
import Header from 'components/Header'
import { omit } from 'lodash'

export const basePath = `/settings/course-session`

export default function CourseSessionPage() {
  const { courseSessionId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const courses = useSelector(state => state.settings.course.list)
  const schools = useSelector(state => state.lookup.schools)
  const { district, academicYear } = useSelector(state => state.session)
  const { query, list, count } = useSelector(state => state.settings.courseSession)

  useEffect(() => {
    courses?.length || dispatch(getCourses({ ...query, limit: 100, offset: 0 }))
  }, [dispatch, courses?.length])

  useEffect(() => {
    let courseSessionQuery = {
      ...query,
      offset: 0,
      courseId: searchParams.get('courseId'),
      academicYearId: query.academicYearId || academicYear?.id,
    }
    if (!searchParams.get('courseId')) {
      courseSessionQuery = omit(courseSessionQuery, 'courseId')
    }
    query.academicYearId || (academicYear?.id && dispatch(search(courseSessionQuery)))
  }, [dispatch, query?.academicYearId, academicYear?.id])

  useEffect(() => {
    dispatch(getSchools({ include: ['academicYear'] }))
  }, [dispatch, district?.id, academicYear?.id])

  const onAdd = url => {
    if (query.academicYearId || academicYear.id) navigate(url)
    else console.error('------>', url, query.academicYearId)
  }

  return (
    <BasePathProvider value={basePath}>
      <Header title={'Course Session List'} add={onAdd} />
      {courses && schools && <SearchBar courses={courses} schools={schools} courseId={searchParams.get('courseId')} />}
      <CourseSessionList query={query} list={list} count={count} academicYear={academicYear} />
      <Drawer open={Boolean(courseSessionId)} anchor="right" onClose={() => navigate(basePath)}>
        <CourseSessionEditFormWrapper query={query} />
      </Drawer>
    </BasePathProvider>
  )
}
