import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/periods'
import PeriodList from './List'
import PeriodForm from './Form'
import { Box, Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import PeriodListSkeleton from './List/ListSkeleton'
import { BasePathProvider } from 'components/Providers'

const baseUrl = '/settings_/periods'

export default function Period() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const periods = useSelector(state => state.settings.period.list)
  const { id } = useParams()

  useEffect(() => {
    periods?.length || dispatch(search())
  }, [dispatch, Boolean(periods)])

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
        {periods ? <PeriodList periods={periods} /> : <PeriodListSkeleton />}
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(baseUrl)}
        >
          <PeriodForm />
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
