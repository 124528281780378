import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'course-session-period'
const initialState = {
  list: [],
}

export const search = createApiAction(
  'course-session-period/search',
  client.search('/api/setting/course-session/:courseSessionId/schedule'),
)
export const get = createApiAction(
  'course-session-period/get',
  client.retrieve(`/api/setting/course-session/:courseSessionId/schedule/:id`),
)
export const save = createApiAction(
  'course-session-period/save',
  client.save('/api/setting/course-session/:courseSessionId/schedule'),
)
export const destroy = createApiAction(
  'course-session-period/delete',
  client.destroy('/api/setting/course-session/:courseSessionId/schedule/:id'),
)

const updateAcademicYearList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: state => {
      state.list = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateAcademicYearList)
      .addCase(save.fulfilled, updateAcademicYearList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(ay => ay.id !== action.payload.id)
      })
  },
})
export const { reset } = sessionSlice.actions
export default sessionSlice.reducer
