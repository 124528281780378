import {Route, Routes } from 'react-router-dom'
import NurseTabs from './Tabs'
import PageHeader from 'components/AppBar/PageHeader'
import { BasePathProvider } from 'components/Providers'
import { Box, Paper } from '@mui/material' 
import HealthConditions from './HealthConditions'
import Visits from './Visits'

export default function Nurse() {
const basePath = '/nurse'
  return (
    <BasePathProvider value={`${basePath}/`}>
        <Box sx={{ mb: 2}}>
            <PageHeader title={'Nurse'} />
        </Box>
        <Paper sx={{width: '100%', minHeight: '500px', p: 2, mb: 2}}>
            <NurseTabs />
            <Routes>
                <Route path="/">
                    <Route path="" element={<HealthConditions />} />
                    <Route path={"health-conditions/*"} element={<HealthConditions />} />
                    <Route path={"/visits/*"} element={<Visits /> } />

                </Route>
            </Routes>
        </Paper>

    </BasePathProvider>
  )
}
