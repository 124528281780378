import { TableCell, TableRow, Link, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as NavLink, useNavigate } from 'react-router-dom'

export default function GradingListScalesItem({ gradeScale }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const basePath = '/settings_/grading/scales'

  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${gradeScale?.id}`}>
          {gradeScale.name}
        </Link>
      </TableCell>
      <TableCell>
        <Button
          size="small"
          color="warning"
          variant="outlined"
          onClick={() => navigate(`${basePath}/${gradeScale?.id}`)}
        >
          {t('More')}
        </Button>
      </TableCell>
    </TableRow>
  )
}
