import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import { BasePathProvider, useBasePath } from 'components/Providers'
import TabControl from './TabControl'

import StudentHeader from './StudentHeader'

export default function StudentForm() {
  const basePath = useBasePath()
  const student = useSelector(state => state.academic.student.detail)

  return (
    <BasePathProvider value={`${basePath}`}>
        <StudentHeader />
        {!student && <LinearProgress sx={{ position: 'absolute', buttom: 0, width: '100%' }} />}
        <TabControl />
    </BasePathProvider>
  )
}
