import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { CloseIcon, EditIcon, SaveIcon } from 'components/Icons'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { save } from 'store/settings/course-session-enrollments'
import { AiOutlineDelete } from 'react-icons/ai'

const heads = ['Course Name', 'Session Name', 'Enroll Date', 'Unenroll Date', 'Status', 'Action']

export default function SchedulingEnrollStudentListEnrollments({
  selected,
  courseSessionEnrollments,
  setCourseSessionEnrollments,
}) {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [isEdit, setIsEdit] = useState(null)
  const [data, setData] = useState({ startDate: undefined, endDate: undefined })

  useEffect(() => {
    if (!isEdit) {
      setData({ startDate: null, endDate: null })
    }
  }, [isEdit, courseSessionEnrollments])

  useEffect(() => {
    setIsEdit(null)
  }, [courseSessionEnrollments])

  const saveEnrollment = async newData => {
    try {
      const startDate = data?.startDate
        ? dayjs(data?.startDate).format('YYYY-MM-DD')
        : courseSessionEnrollments.find(item => item.id === newData?.id)?.startDate

      const endDate = data?.endDate ? dayjs(data?.endDate).format('YYYY-MM-DD') : null

      const payload = {
        id: newData?.id,
        courseSessionId: newData?.courseSessionId,
        startDate,
        endDate,
      }
      const res = await dispatch(save(payload))
      if (res.payload) {
        let temp = {}
        temp = { ...courseSessionEnrollments.find(item => item.id === newData?.id) }
        const others = courseSessionEnrollments.filter(item => item.id !== newData?.id)
        temp.startDate = payload.startDate
        temp.endDate = payload.endDate
        setCourseSessionEnrollments([...others, temp])
        setIsEdit(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!courseSessionEnrollments && selected) return <LinearProgress sx={{ m: 2 }} color="success" />

  return (
    <Accordion sx={{ mt: 3 }}>
      <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content" id="panel1-header">
        <Typography>{t('Enrollment History')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              {heads.map((item, index) => (
                <TableHeadCell key={index} sx={{ fontWeight: '500 !important' }}>
                  {t(item)}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {courseSessionEnrollments?.map((item, index) => (
              <TableRow key={index} hover>
                <TableCell>{item?.courseSession?.course?.name}</TableCell>
                <TableCell>{item?.courseSession?.name}</TableCell>
                <TableCell>
                  {isEdit === item?.id ? (
                    <DatePicker
                      size="small"
                      defaultValue={dayjs(item?.startDate)}
                      onChange={value => {
                        if (value) {
                          setData({ startDate: dayjs(new Date(value)).format('YYYY-MM-DD'), endDate: data?.endDate })
                        }
                      }}
                      renderInput={params => <TextField value={dayjs(data?.startDate)} {...params} />}
                    />
                  ) : (
                    item?.startDate
                  )}
                </TableCell>
                <TableCell>
                  {isEdit === item?.id && item?.endDate ? (
                    <DatePicker
                      size="small"
                      defaultValue={dayjs(item?.endDate)}
                      onChange={value => {
                        if (value && dayjs(new Date(value)).format('YYYY-MM-DD') !== 'Invalid Date') {
                          setData({ startDate: data?.startDate, endDate: dayjs(new Date(value)).format('YYYY-MM-DD') })
                        } else if (value === null) {
                          setData({ startDate: data?.startDate, endDate: value })
                        }
                      }}
                      renderInput={params => <TextField value={dayjs(data?.endDate)} {...params} />}
                    />
                  ) : (
                    item?.endDate
                  )}
                </TableCell>
                <TableCell>{item.endDate && t('Unenrolled')}&nbsp;</TableCell>
                {isEdit === item?.id ? (
                  <TableCell>
                    <Stack flexDirection={'row'}>
                      <Button color="inherit" size="small" onClick={() => setIsEdit(null)}>
                        <CloseIcon fontSize="small" />
                      </Button>
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => saveEnrollment({ id: item.id, courseSessionId: item.courseSession.id })}
                        disabled={!data?.startDate || data?.startDate === 'Invalid Date'}
                      >
                        <SaveIcon fontSize="small" />
                      </Button>
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsEdit(item.id)
                        setData({ startDate: item?.startDate, endDate: item?.endDate })
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                    {item?.endDate && (
                      <Button size="small" color="inherit">
                        <AiOutlineDelete fontSize={20} />
                      </Button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
