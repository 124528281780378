import { BasePathProvider } from 'components/Providers'
import { useBasePath } from 'components/Providers'
import MarkingPeriodForm from './MPForm'
import MarkingPeriodFormSkeleton from './MPFormSkeleton'

export default function MarkingPeriodFormIndex({ markingPeriod }) {
  const basePath = useBasePath()

  return (
    <BasePathProvider value={`${basePath}`}>
      {markingPeriod ? <MarkingPeriodForm markingPeriod={markingPeriod} /> : <MarkingPeriodFormSkeleton />}
    </BasePathProvider>
  )
}
