import { Route, Routes } from 'react-router-dom'
import GradingScalePage from './Page'
import GradingScaleFormIndex from './Form'

export default function GradingScale() {
  return (
    <Routes>
      <Route path="/" element={<GradingScalePage />}>
        <Route path="/:id" element={<GradingScaleFormIndex />} />
      </Route>
    </Routes>
  )
}
