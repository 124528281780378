import { Box, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


const HELATHCONDITIONS = 0
const VISITS = 1
const HEALTHREPORT = 2
const VISITREPORT = 3

const getTabIndex = path => {
    const [tabName] = path.split('/')
    const i = ['health-conditions', 'visits', 'health-report', 'visit-report'].indexOf(tabName)
    return i < 0 ? 0 : i
}

export default function NurseTabs() {
    const basePath = `/nurse`
    const theme = useSelector(state => state.session.appearance)
    const { t } = useTranslation()
    const { '*': tabName } = useParams()
    const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
    useEffect(() => {
      setTabIndex(getTabIndex(tabName))
    }, [tabName])

    return (
        <Box>
            <Box>
                <Tabs
                    value={tabIndex}
                    TabIndicatorProps={{ style: { background: 'primary.main' } }}
                >
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Health Conditions')}
                      value={HELATHCONDITIONS}
                      component={Link}
                      to={`${basePath}/health-conditions`}
                    />
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Visits')}
                      value={VISITS}
                      component={Link}
                      to={`${basePath}/visits`}
                    />
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Health Report')}
                      value={HEALTHREPORT}
                      component={Link}
                      to={`${basePath}/health-report`}
                    />
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Visit Report')}
                      value={VISITREPORT}
                      component={Link}
                      to={`${basePath}/visit-report`}
                    />
                </Tabs>
            </Box>
        </Box>
    )
}
