import { Card, CardHeader, CardContent, CardActions, List } from '@mui/material'
import PermissionList from './PermissionList'
import { useTranslation } from 'react-i18next'

function RoleList({ role, modules }) {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader title={t("Permissions")} />
      <CardContent>
        <List>
          {modules.map(module => (
            <PermissionList key={module.id} role={role} module={module} />
          ))}
        </List>
      </CardContent>
      <CardActions sx={{ p: 2 }}></CardActions>
    </Card>
  )
}

export default RoleList
