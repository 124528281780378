import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export const countyCodeAssigned = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const fullTimeEquivalency = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const ageGroupTaught = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const districtCodeAssigned = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const jobCode = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const numberClassesTaught = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const titleIProgramStaffAssign = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const jobCodeSubcategory = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const credentialType = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export default function AssignmentCard({ data, district }) {
  const { t } = useTranslation()
  const { personId } = useParams()

  const content = data?.stateData?.assignments?.length > 0 ? data?.stateData?.assignments : [{ districtCodeAssigned: null }]

  return content.map((item, index) => (
    <Grid container key={index}>
      <Grid item xs={12} md={12} sx={{ height: '100%', mb: 3 }}>
        <PreviewCard
          title={t('Assignment Information')}
          size="large"
          buttonNavigate={
            item?.id
              ? `/person/${personId}/employment/${district}/assignment/${data?.id}?index=${item?.id}`
              : `/person/${personId}/employment/${district}/assignment/${data?.id}`
          }
          buttonLabel={item?.id ? t('More') : t('Add')}
          buttonDisabled={data ? false : true}
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('County Code Assigned')}</Typography>
              <Typography variant="subtitle1">
                {countyCodeAssigned.find(d => d.id === item?.countyCodeAssigned)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('District Code Assigned')}</Typography>
              <Typography variant="subtitle1">
                {districtCodeAssigned.find(d => d.id === item?.districtCodeAssigned)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Title I Program Staff Category')}</Typography>
              <Typography variant="subtitle1">
                {titleIProgramStaffAssign.find(d => d.id === item?.titleIProgramStaff)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Full Time Equivalency')}</Typography>
              <Typography variant="subtitle1">
                {fullTimeEquivalency.find(d => d.id === item?.fullTimeEquivalency)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Job Code')}</Typography>
              <Typography variant="subtitle1">{jobCode.find(d => d.id === item?.jobCode)?.name} &nbsp;</Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Job Code Subcategory')}</Typography>
              <Typography variant="subtitle1">
                {jobCodeSubcategory.find(d => d.id === item?.jobCodeSubcategory)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Age Group Taught')}</Typography>
              <Typography variant="subtitle1">
                {ageGroupTaught.find(d => d.id === item?.ageGroupTaught)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Number of Classes Taught')}</Typography>
              <Typography variant="subtitle1">
                {numberClassesTaught.find(d => d.id === item?.numberClassesTaught)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Credential Type')}</Typography>
              <Typography variant="subtitle1">
                {credentialType.find(d => d.id === item?.credentialType)?.name} &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  ))
}
