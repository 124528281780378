import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar, MenuItem } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/building-room'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { useBasePath } from 'components/Providers'
import { capitalize, lowerCase } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function RoomEditForm({ room }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { buildingId } = useParams()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const schema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .transform(value => {
        return value ? value : null
      }),
    type: yup.string().required(),
    doorNumber: yup.string().required(),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: room,
  })

  const onSubmit = async data => {
    const payload = { ...data, buildingId }
    const res = await dispatch(save(payload))
    if (res.payload) {
      navigate(`${basePath}`)
    }
  }

  const Types = ['classroom', 'office', 'meeting-room', 'storage', 'gym']

  const onError = errors => console.error(errors)

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Header title={'Rooms'} close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="doorNumber"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Door Number")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("Type")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {Types?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalize(lowerCase(item))}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Name")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(`${basePath}`)}>
          {t("Cancel")}
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          {t("Submit")}
        </Button>
      </CardActions>
      <Header />
    </Card>
  )
}
