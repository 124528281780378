import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function SchedulingCourseSessionInfo({ selectedCourseSession }) {
  const { t } = useTranslation()
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)

  const getTerm = mp => {
    if (academicYearMarkingPeriods?.length === 1 && mp?.length === 1) {
      return 'Summer'
    } else if (mp?.length === academicYearMarkingPeriods?.length) {
      return 'Full Year'
    } else {
      return mp?.map(item => item.name).toString()
    }
  }

  const getTeacherName = () => {
    const teachers = selectedCourseSession?.courseSessionEnrollments?.filter(
      item => item.roleId !== null && item.roleId !== 'student',
    )

    if (teachers) {
      let list = ''
      teachers?.forEach(teacher => {
        list = list + teacher.lastName + ', ' + teacher.firstName + ' | '
      })
      return list
    } else return null
  }

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      sx={{ backgroundColor: 'rgba(255, 146, 21, 0.04)', mt: 2 }}
    >
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Course Name')}</Typography>
        <Typography variant="body2">{selectedCourseSession?.name}&nbsp;</Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Teacher(s)')}</Typography>
        <Typography variant="body2">
          {getTeacherName()}
          &nbsp;
        </Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Term')}</Typography>
        <Typography variant="body2">{getTerm(selectedCourseSession?.markingPeriods)}&nbsp;</Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Room')}</Typography>
        <Typography variant="body2">&nbsp;</Typography>
      </Box>
      <Box sx={{ borderRight: '1px solid rgba(255, 146, 21, 1)', m: 1, pl: 1, pr: 3 }}>
        <Typography variant="body2">{t('Enrollment')}</Typography>
        <Typography variant="body2">
          {`${selectedCourseSession?.enrollmentCount || 0}/${selectedCourseSession?.quota || 0}`}&nbsp;
        </Typography>
      </Box>
    </Stack>
  )
}
