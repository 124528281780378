import { Grid, InputAdornment, MenuItem, TextField } from '@mui/material'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { debounce, omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseSubjects } from 'store/lookup'
import { search } from 'store/settings/course-session'

export default function SchedulingEnrollStudentFormSearch() {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const subjectArea = useSelector(state => state.lookup.courseSubjects)
  const { query } = useSelector(state => state.settings.courseSession)

  const [que, setQue] = useState(query.que || '')

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  const applyFilter = async (key, value) => {
    if (value && value !== 'null') {
      dispatch(search({ ...filterNullValues(query), [key]: encodeURI(value), offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  useEffect(() => {
    subjectArea || dispatch(getCourseSubjects())
  }, [subjectArea])

  if (!subjectArea) return null

  return (
    <Grid container spacing={2}>
      <Grid item sm={7} xs={7} md={7}>
        <TextField
          value={que}
          fullWidth
          size="small"
          label={t('Search')}
          placeholder={t('Course Name, Subject Area..')}
          onChange={event => {
            setQue(event.target.value)
            delayedSearch(event.target.value, applyFilter)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <CloseIcon
                onClick={() => {
                  setQue('')
                  applyFilter('que', '')
                }}
                sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
              />
            ),
          }}
        />
      </Grid>
      <Grid item sm={5} xs={5} md={5}>
        <TextField
          fullWidth
          select
          size="small"
          label={t('Subject')}
          onChange={e => applyFilter('courseId', e.target.value)}
        >
          <MenuItem value={null}>{t('All')}</MenuItem>
          {subjectArea &&
            subjectArea?.map(item => (
              <MenuItem key={item.value} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  )
}
