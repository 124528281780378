import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const get = createApiAction(
  'academic/student/state-data/get',
  client.retrieve('/api/academic/:type/:memberId/state-data'),
)

export default function setStateDataReducers(builder) {
  builder.addCase(get.fulfilled, (state, action) => {
    state.detail.stateData = action.payload
  })
}
