import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'person-final-grade'
const initialState = {
  list: null,
  count: null,
  query: {}
}

export const search = createApiAction('person/grade/search', client.search('/api/:type/:personId/grade'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearFinalGrades: state => {
      state.list = null
      state.query = {}
      state.count = null
    },
  },
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.query = action.meta.arg
      state.list = action.payload
    })
  },
})

export const { clearFinalGrades } = sessionSlice.actions

export default sessionSlice.reducer
