import { Box, Chip, Link, TableCell, TableRow, Typography } from '@mui/material'
import { orange } from '@mui/material/colors'
import { FavoriteBorderIcon, FavoriteIcon } from 'components/Icons'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { reportFavorite } from 'store/report/reports'

export default function ReportListItem({ item }) {
  const dispatch = useDispatch()
  const baseURL = '/report'

  const favorite = async isFavorite => {
    if (isFavorite) {
      await dispatch(reportFavorite({ id: item?.id, isFavorite: true }))
    } else {
      await dispatch(reportFavorite({ id: item?.id, isFavorite: false }))
    }
  }

  return (
    <TableRow hover>
      <TableCell>
        <Box>
          <Link
            component={NavLink}
            underline="hover"
            color="inherit"
            to={`${baseURL}/run/${item?.id}`}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'none' } }}
          >
            <Typography variant="body1">{item?.name}</Typography>
          </Link>
        </Box>
        <Box>
          <Typography variant="body2">{item?.description}&nbsp;</Typography>
        </Box>
        <Box sx={{ pt: 1 }}>
          {item?.tags && item?.tags.map((item, index) => <Chip key={index} label={item} sx={{ mr: 1 }} />)}
          &nbsp;
        </Box>
      </TableCell>
      <TableCell>
        {item?.reportUserStatistic?.isFavorite ? (
          <FavoriteIcon sx={{ cursor: 'pointer', color: orange[500] }} onClick={() => favorite(false)} />
        ) : (
          <FavoriteBorderIcon sx={{ cursor: 'pointer', color: orange[500] }} onClick={() => favorite(true)} />
        )}
      </TableCell>
      <TableCell>
        {item?.reportUserStatistic?.runAt && dayjs(new Date(item?.reportUserStatistic?.runAt)).format('MM/DD/YYYY')}
      </TableCell>
    </TableRow>
  )
}
