import { Box, Button, Card, Drawer, MenuItem, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { AddIcon } from 'components/Icons'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import CalendarFormIndex from './Form'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCalendarEventType, getCampus, getClients } from 'store/lookup'
import { useEffect, useState } from 'react'
import CalendarListIndex from './List'
import dayjs from 'dayjs'
import { search } from 'store/provider/calendar'

const baseUrl = '/setting/calendar/'

const filterDistrict = [{ value: 'all', name: 'All Districts' }]

export default function CalendarPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { eventId } = useParams()
  const navigate = useNavigate()
  const calendarEventTypes = useSelector(state => state.lookup.calendarEventType)
  const calendar = useSelector(state => state.provider.calendar).list
  const clients = useSelector(state => state.lookup.clients)
  const campuses = useSelector(state => state.lookup.campus)
  const [currentDay, setCurrentDay] = useState(new Date())
  const [districtFilter, setDistrictFilter] = useState('all')
  const [filteredCalendar, setFilteredCalendar] = useState()

  useEffect(() => {
    calendarEventTypes || dispatch(getCalendarEventType())
    clients || dispatch(getClients())
    campuses || dispatch(getCampus())
  }, [clients, calendarEventTypes, campuses])

  useEffect(() => {
    if (currentDay) {
      const startOfMonth = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1)
      const endOfMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0)
      const startDate = dayjs(startOfMonth).format('YYYY-MM-DD')
      const endDate = dayjs(endOfMonth).format('YYYY-MM-DD')
      dispatch(search({ startDate, endDate }))
    }
  }, [currentDay])

  const prevMonth = () => {
    const temp = new Date(currentDay)
    temp.setMonth(currentDay.getMonth() - 1)
    temp.setDate(1)
    setCurrentDay(temp)
  }

  const nextMonth = () => {
    const temp = new Date(currentDay)
    temp.setMonth(currentDay.getMonth() + 1)
    temp.setDate(1)
    setCurrentDay(temp)
  }

  const handleSelectDistrict = e => {
    setDistrictFilter(e.target.value)
  }

  useEffect(() => {
    if (calendar && districtFilter !== 'all') {
      const ids = campuses
        .filter(item => item.districtId === districtFilter)
        .map(function (item) {
          return item.id
        })
      ids.push(districtFilter)
      let temp = new Set()
      ids.forEach(id => {
        calendar.forEach(cal => {
          if (cal.locationIds.includes(id)) {
            temp.add(cal)
          }
        })
      })
      temp = [...temp]
      setFilteredCalendar(temp)
    } else if (calendar) {
      setFilteredCalendar(calendar)
    }
  }, [districtFilter, calendar])

  useEffect(() => {
    if (clients) {
      clients.forEach(client => {
        if (filterDistrict.length < clients.length + 1) {
          filterDistrict.push({
            value: client?.client?.id,
            name: client?.client?.name,
            state: client?.client?.address?.state,
          })
        }
      })
    }
  }, [clients])

  if (!(calendarEventTypes && calendar && clients && campuses)) return null

  if (filteredCalendar === undefined) return null

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Card>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 2, mr: 3 }}>
          <Header />
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
            <TextField
              select
              sx={{ width: '12em', mr: 2 }}
              size="small"
              label={t('Campus')}
              onChange={handleSelectDistrict}
              value={districtFilter}
              color="warning"
            >
              {filterDistrict?.map(item => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  selected={item.value === districtFilter}
                  sx={{ pl: item.value !== 'all' ? 4 : 2 }}
                >
                  {item.abbreviation || item.name}
                  {item.name !== 'All Districts' && `, ${item.state}`}
                </MenuItem>
              ))}
            </TextField>
            <Button
              onClick={() => navigate(`${baseUrl}new`)}
              sx={{ width: '10em' }}
              variant="contained"
              color="warning"
              startIcon={<AddIcon />}
            >
              {t('Add Event')}
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ mr: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CalendarListIndex
              currentDay={currentDay}
              prevMonth={prevMonth}
              nextMonth={nextMonth}
              districts={clients}
              campuses={campuses}
              calendar={filteredCalendar}
            />
          </div>
        </Box>
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(eventId)}
        anchor="right"
        onClose={() => navigate(`${baseUrl}`)}
      >
        <CalendarFormIndex setCurrentDay={setCurrentDay} />
      </Drawer>
    </BasePathProvider>
  )
}
