import { Toolbar, Box, CardContent, Grid, Typography, CardActions, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'
import { useNavigate } from 'react-router-dom'

export default function NotFound({ title, basePath }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(`${basePath}/..`)
  }
  return (
    <Box>
      <Toolbar />
      <Header title={t(title)} close small />
      <Box sx={{ minHeight: '100%' }}>
        <Toolbar />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4">{t('Not Found')}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {t('The page you are looking for does not exist. Please go back to the previous page.')}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" onClick={handleClose} color="primary">
            {t('Back to previous page')}
          </Button>
        </CardActions>
        <CardContent>
          <Grid container spacing={2}></Grid>
        </CardContent>
      </Box>
    </Box>
  )
}
