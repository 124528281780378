import { Route, Routes } from 'react-router-dom'
import SchedulingEnrollCourseFormIndex from './Form'
import SchedulingEnrollCoursePage from './Page'

export default function SchedulingEnrollCourse() {
  return (
    <Routes>
      <Route path="/" element={<SchedulingEnrollCoursePage />}>
        <Route path="/:enrollSession" element={<SchedulingEnrollCourseFormIndex />} />
      </Route>
    </Routes>
  )
}
