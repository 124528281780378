import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'grading-letter'
const initialState = {
  list: null,
}

const basePath = '/api/setting/grading-letter'
export const save = createApiAction('setting/grading-letter/save', client.save(basePath))
export const destroy = createApiAction('setting/grading-letter/destroy', client.destroy(`${basePath}/:id`))

const sessionSlice = createSlice({
  name,
  initialState,
  // extraReducers(builder) {
    // builder.addCase(save.fulfilled, (state, action) => {
    //   const { id } = action.meta.arg
    //   state.list[id] = action.meta.arg
    // })
    // .addCase(destroy.fulfilled, (state, action) => {
    //     const list = state.list || []
    //     const { id } = action.meta.arg
    //     state.list = list.filter(item => item.id !== id)
    //   })
  // },
})
export default sessionSlice.reducer
