import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStudents } from "store/lookup";

export default function StudentSearchBar({
  onSelect = () => {},
  label = 'Select Student',
  size = 'small'
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [que, setQue] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { academicYear } = useSelector(state => state.session);
  const students = useSelector(state => state.lookup.students.rows);
  
  useEffect(() => {
    if (que) {
      dispatch(getStudents({ que, limit: 10 }));
    }
    if (!que) {
      onSelect(null);
    }
  }, [academicYear, que, dispatch]);
  
  const delayedSearch = useMemo(() => 
    debounce((value) => {
      setQue(value);
      applyFilter('que', value);
    }, 500), []);

  const applyFilter = async (key, value) => {
    dispatch(getStudents({ [key]: value || null, offset: 0, limit: 10 }));
  };

  const selectStudent = (event, value) => {
    setSelectedStudent(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      size={size}
      options={students}
      loading={!students.length}
      value={selectedStudent}
      onInputChange={(event, value, reason) => {
        if (reason === 'clear') {
          setQue(null);
          applyFilter('que', null);
        }
      }}
      onChange={selectStudent}
      getOptionLabel={option => `${option?.person?.lastName}, ${option?.person?.firstName}`}
      renderInput={params => (
        <>
          <TextField
            {...params}
            value={que}
            fullWidth
            label={t(label)}
            onChange={e => delayedSearch(e.target.value)}
          />
        </>
      )}
    />
  );
}
