import { TableCell, TableRow } from '@mui/material'

export default function CourseSessionListItem({
  item,
  index,
  setSelectedRow,
  selectedRow,
  changeSelectedCourseSession,
  selectedCourseSession,
  selectedDate,
  fetchDate,
  periods,
  attendanceTakenPeriods,
}) {
  return (
    <TableRow
      hover
      sx={{
        borderBottom: '3px solid white',
        '&:not(:first-of-type)': {
          borderTop: '3px solid white',
        },
      }}
    >
      <TableCell
        value={item.id}
        onClick={() => {
          changeSelectedCourseSession(item?.id, item.periodId)
          setSelectedRow(item)
        }}
        sx={{
          backgroundColor:
            selectedRow &&
            String(item.startTime) === String(selectedRow.startTime) &&
            selectedCourseSession === item.id &&
            String(selectedDate) === String(fetchDate)
              ? 'grey'
              : attendanceTakenPeriods?.[index]?.isChecked === true
              ? '#C8E6C9'
              : '#FECDD2',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        {periods?.[index] && `${periods?.[index]?.name} - ${item?.name}`}
      </TableCell>
    </TableRow>
  )
}
