import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import SearchBar from './SearchBar'
import StudentList from './StudentList'
import ListSkeleton from './ListSkeleton'

function StudentSearch() {
  const studentList = useSelector(state => state.academic.student.list)

  return (
    <Paper sx={{ width: '100%', mb: 2, minHeight: '500px' }}>
      <SearchBar />
      {studentList ? <StudentList /> : <ListSkeleton />}
    </Paper>
  )
}

export default StudentSearch
