import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { getEthnicityTypes, getGenderTypes, getRaceTypes } from 'store/lookup'
import { useEffect } from 'react'
import FeildSkeleton from './FieldSkeleton'
import 'utils/global.scss'

export default function PersonProfile() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { personId } = useParams()
  const personData = useSelector(state => state.person2.details)
  const { raceTypes, ethnicityTypes, genderTypes } = useSelector(state => state.lookup)

  useEffect(() => {
    raceTypes || dispatch(getRaceTypes())
  }, [raceTypes])

  useEffect(() => {
    ethnicityTypes || dispatch(getEthnicityTypes())
  }, [ethnicityTypes])

  useEffect(() => {
    genderTypes || dispatch(getGenderTypes())
  }, [genderTypes])

  if (!(personData && raceTypes && ethnicityTypes && genderTypes)) return null

  const address = Object.values(
    _.pick(
      personData?.addresses?.find(item => item.type === 'home'),
      ['street', 'city', 'state', 'country', 'zip'],
    ),
  ).join(', ')

  const personDetails = [
    { key: 'Full Name', value: `${personData?.firstName} ${personData?.lastName}` },
    { key: 'State ID', value: personData?.membership?.stateId },
    { key: 'Gender', value: genderTypes.find(item => item.id === personData?.gender)?.name },
    { key: 'School Email', value: `${personData?.email || ''}` },
    { key: 'Personal Email', value: `${personData?.user?.email || ''}` },
    { key: 'School Extension', value: personData?.membership?.phoneExtension },
    { key: 'Home Phone', value: `${personData?.phones?.find(item => item.type === 'home')?.number || ''}` },
    { key: 'Cell Phone', value: `${personData?.phones?.find(item => item.type === 'mobile')?.number || ''}` },
    { key: 'Address', value: address },
  ]

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mx: 3, mt: 3 }}>
        <PreviewCard
          title={t('Personal Information')}
          size="large"
          buttonNavigate={`/person/${personId}/profile/details`}
          buttonLabel={t('More')}
          actionChildren={''}
        >
          <Grid container spacing={2}>
            {personDetails.map((item, index) => (
              <Grid key={index} item sm={4} xs={4} md={4}>
                <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                  {t(item.key)}
                </Typography>
                <Typography variant="body1" className={`grid-table-title-value`}>
                  {item.value ? item.value : <FeildSkeleton />} &nbsp;
                </Typography>
              </Grid>
            ))}
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
