import { useSelector } from 'react-redux'
import EmploymentsList from './List'
import { useParams } from 'react-router-dom'

export default function EmploymentsListIndex() {
  const { personId } = useParams()
  const { clients } = useSelector(state => state.lookup)
  const employments = useSelector(state => state.person2.employments)

  if (!(clients && employments && employments[+personId])) return null

  return <EmploymentsList clients={clients} employments={employments[+personId] || []} />
}
