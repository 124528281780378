import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, Grid, MenuItem } from '@mui/material'
import IconProvider from 'components/IconProvider'
import './AttendanceDropdown.css'
import { useEffect } from 'react'

export default function Period({ path, control, index, data, setValue, attendanceStatus, period }) {
  const { t } = useTranslation()

  useEffect(() => {
    if (data?.courseSessionScheduleId) {
      setValue(`${path}.${index}.status.id`, data?.status?.id)
    }
  }, [data?.status?.id])

  return (
    <Grid item xs={12} md={12} key={data?.period?.id}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ p: 0.5 }}>
          <Controller
            name={`${path}.${index}.status.id`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  select
                  size="small"
                  label={`${t(period?.name)}  ${
                    data?.courseSessionEnrollment?.courseSession?.name
                      ? `- ${data?.courseSessionEnrollment?.courseSession?.name}`
                      : ''
                  }`}
                  sx={{ width: '100%' }}
                  color="warning"
                  error={!!fieldState.error}
                  value={value || ''}
                  className="custom-text-field"
                  disabled={!data?.courseSessionScheduleId}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  <MenuItem value="0">Not Taken</MenuItem>
                  {attendanceStatus?.map((statusItem, statusIndex) => (
                    <MenuItem key={statusIndex} value={statusItem.id}>
                      {<IconProvider icon={statusItem.ui?.icon} color={statusItem.ui?.color} />} {statusItem.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
