import { Grid, MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function SealOfBiliteracyForm({ control, languages, noAndYesOption, worldLanguageAssessmentOption }) {
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={6} xs={6} md={6}>
        <Controller
          name="sealOfBiliteracy.biliterate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Biliterate')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="sealOfBiliteracy.worldLanguageAssessed"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('World Language Assessed')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {languages.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name} - {item.originName}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="sealOfBiliteracy.worldLanguageAssessment"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('World Language Assessment')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {worldLanguageAssessmentOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
    </>
  )
}
