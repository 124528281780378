import { IconButton } from '@mui/material'
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import { CloseIcon } from 'components/Icons'

const action = snackbarId => (
  <IconButton onClick={() => closeSnackbar(snackbarId)}><CloseIcon /></IconButton>
)

const snackbarFactory = (variant, duration = 5) => message => {
  const options = { variant, action }
  if (duration)
    options.autoHideDuration = duration * 1000
  else
    options.persist = true
  enqueueSnackbar(message, options)
}

export const error = snackbarFactory('error', 20)
export const warning = snackbarFactory('warning', 10)
export const info = snackbarFactory('info')
export const success = snackbarFactory('success')
