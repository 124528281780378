import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link, Avatar, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { destroy } from '../../../../store/settings/school'
import { setSchool } from 'store/session'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { DeleteIcon, EditIcon, LoginIcon } from 'components/Icons'
import Confirm from 'components/Dialog'

export default function SchoolListItem({ school }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: school.id }))
  }

  const onConnect = () => {
    dispatch(setSchool({ id: school.id }))
  }

  return (
    <TableRow hover data-id={school.id} data-name={school.name}>
      <TableCell sx={{ width: '4em' }}>
        {school?.file?.location ? (
          <Avatar sx={{ width: 48, height: 48 }} src={school?.file?.location} />
        ) : (
          <Box sx={{ width: 48, height: 48, borderRadius: '50%', backgroundColor: '#aaa' }} />
        )}
      </TableCell>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${school.id}`}>
          {school.name}
        </Link>
      </TableCell>
      <TableCell>
        {school.address?.city} {school.address?.country}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={onConnect}>
          <LoginIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${school.id}`} aria-label="edit">
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
