import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { DeleteIcon } from '../../../components/Icons'
import { EditIcon } from '../../../components/Icons'
import Confirm from '../../../components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from '../../../store/settings/building'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function BuildingItem({ building }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: building.id }))
  }

  return (
    <TableRow hover data-id={building.id} data-name={building.name}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${building.id}/room`}>
          {building.name}
        </Link>
      </TableCell>
      <TableCell>
        {building.address?.city} {building.address?.country}
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${building.id}/room`} aria-label="edit">
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
