import { BasePathProvider } from 'components/Providers'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import SchedulingCourseSessionsFormIndex from './Form'
import SchedulingCourseSessionsListIndex from './List'

export default function SchedulingCourseSessionsPage() {
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/course-sessions/`
  const { courseSessionId } = useParams()

  return (
    <BasePathProvider value={`${basePath}`}>
      <SchedulingCourseSessionsListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(courseSessionId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <SchedulingCourseSessionsFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
