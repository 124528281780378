import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardActions, CardContent, Grid, MenuItem, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/grade-level-section'
import { getGradeLevels } from 'store/lookup'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { useBasePath } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Header from 'components/Header'
import { FaSave } from 'react-icons/fa'
import { DeleteIcon } from 'components/Icons'
import { SubmitButton } from 'components/Buttons'
import Confirm from 'components/Dialog'
import { destroy } from 'store/settings/grade-level-section'

export default function GradeSectionEditForm({ gradeSection }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const [confirm, setConfirm] = useState(false)

  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const defaultLunches = useSelector(state => state.lookup.defaultLunches) || []

  const schema = yup.object().shape({
    gradeLevelId: yup.string().required('Required'),
    gradeSection: yup.string().trim().required('Required'),
    seat: yup
      .number()
      .nullable()
      .transform(value => value || null),
    defaultLunchId: yup.string().nullable(),
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { gradeLevelId: gradeSection?.gradeLevel?.id },
  })

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [gradeLevels, dispatch])

  useEffect(() => {
    reset(gradeSection)
  }, [gradeSection, reset])

  const onSubmit = async data => {
    const res = await dispatch(save(_.omit(data, 'gradeLevel')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onDestroy = async () => {
    setConfirm(false)
    await dispatch(destroy({ id: gradeSection?.id }))
    navigate(basePath)
  }

  const onError = errors => console.error(errors)

  return (
    <Card
      data-component="GradeSectionEditForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      sx={{ minHeight: '100%' }}
    >
      <Header title={t('Add Grade Section')} small close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={6}>
            <Controller
              name="gradeSection"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    size="small"
                    label={t('Section Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={6}>
            <Controller
              name="gradeLevelId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Grade Level')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    {gradeLevels?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6} md={6}>
            <Controller
              name="seat"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Maximum Seats')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={6}>
            <Controller
              name="defaultLunchId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Default Lunch')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    {defaultLunches.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
        <Button
          size="small"
          variant="contained"
          color="error"
          disabled={!gradeSection}
          onClick={() => setConfirm(true)}
          startIcon={<DeleteIcon />}
          title="Delete"
        >
          {t('DELETE')}
        </Button>
        <SubmitButton
          size="small"
          color="warning"
          variant="contained"
          // disabled={!formState.isDirty}
          icon={<FaSave />}
          onClick={handleSubmit(onSubmit, onError)}
        >
          {t('SAVE')}
        </SubmitButton>
      </CardActions>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
    </Card>
  )
}
