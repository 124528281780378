import { useDispatch } from 'react-redux'
import { Typography, Box } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import SchoolIcon from '@mui/icons-material/School'
import { impersonate } from 'store/session'

export default function StudentCard({ item }) {
  const dispatch = useDispatch()

  const switchTo = (districtId, schoolId, academicYearId) => {
    if (academicYearId) {
      dispatch(impersonate({ districtId, academicYearId }))
    } else {
      dispatch(impersonate({ districtId, schoolId }))
    }
  }

  return (
    <Box
      sx={{
        width: 230,
        height: 265,
      }}
    >
      <CardMedia
        sx={{ height: 95, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        image={''}
        title={`${item?.client?.name}`}
      >
        {<SchoolIcon sx={{ fontSize: 90 }} />}
      </CardMedia>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
          onClick={() => { switchTo(item?.client?.id) }}
        >
          {`${item.client?.name}`}
        </Typography>

        {item.client?.schools?.map(school => (
          <Typography
            key={school.id}
            gutterBottom
            variant="h7"
            component="div"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
            onClick={() => { switchTo(item.client.id, school?.id, school?.academicYears[0]?.id) }}
          >
            {school.name}
          </Typography>
        ))}
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        ></Typography>
        <Typography variant="body2" color="text.secondary"></Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}></CardActions>
    </Box>
  )
}
