import { omit, pick } from 'lodash'
import { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  Button,
  Stack,
  Card,
  CardContent,
  CardActions,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/SaveOutlined'

import * as action from '../../../store/settings/role'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function RoleEdit({ role }) {
  const { t } = useTranslation()
  const [state, setState] = useState({ id: 0, name: '', enabled: true, shared: false })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    setState(pick(role, ['id', 'name', 'enabled', 'shared']))
  }, [role])

  const handleChange = event => {
    let { name, value, checked } = event.target
    if (['enabled', 'shared'].includes(name)) value = checked
    setState(prev => ({ ...prev, [name]: value }))
  }

  const onDestroyClick = async () => {
    const { payload, error } = await dispatch(action.destroy({ id: state.id }))
    if (payload && !error) navigate('/settings/permission')
  }

  const onSubmit = async () => {
    const id = state.id
    const { payload, error } = await dispatch(id > 0 ? action.update(state) : action.create(omit(state, 'id')))
    if (!error && payload) {
      navigate(['/settings/permission', payload.id].join('/'))
    }
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <TextField
              fullWidth
              margin="normal"
              name="name"
              required
              label={t('Role name')}
              value={state.name || ''}
              onChange={handleChange}
              autoFocus={true}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}
          >
            <FormControl>
              <FormControlLabel
                control={<Switch checked={state.enabled} onChange={handleChange} name="enabled" />}
                label={t('Enabled')}
                labelPlacement="start"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            md={3}
            style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}
          >
            <FormControl>
              <FormControlLabel
                control={<Switch checked={state.shared} onChange={handleChange} name="shared" />}
                label={t('Shared')}
                labelPlacement="start"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            md={6}
            xstyle={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}
          >
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                // sx={{ mt: 2, mb: 2 }}
                onClick={onDestroyClick}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{ minWidth: 160 }}
                onClick={onSubmit}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  )
}

export default RoleEdit
