import { Avatar, Box, Divider, Fab, Grid, IconButton, LinearProgress, MenuItem, Stack, TextField, Typography } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AddIcon, DeleteIcon } from 'components/Icons'
import { SubmitButton } from 'components/Buttons'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { pick } from 'lodash'
import { save } from 'store/settings/district'
import { save as saveSchool } from 'store/settings/school'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/settings/school'
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { enqueueSnackbar } from 'notistack'


const initialValues = {
  type: null,
  countyCode: null,
  districtCode: null,
  schoolCode: null,
  name: null,
  abbreviation: null,
  address: null,
  phone: null,
  fax: null,
  website: null,
  schools: null,
}

export default function SettingsSchoolManagementFormDistrict({ data, schools }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { districtId } = useParams()
  const [image, setImage] = useState()

  const basePath = `/setting/school-management/`

  const schema = yup.object().shape({
    type: yup.string().required('Required'),
    name: yup.string().required('Required'),
    address: yup.object({
      street: yup.string().required('Required'),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      zip: yup.string().required('Required'),
    }),
  })

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data?.id
      ? {
          ...data,
          schools: { ...schools },
          phone: data?.phones?.find(p => p.type === 'work')?.number || null,
          fax: data?.phones?.find(p => p.type === 'other')?.number || null,
        }
      : { ...initialValues },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schools',
  })

  const removeSchool = (index, item) => {
    const result = dispatch(destroy({ id: item.schoolId }))
    if (result?.payload?.id === item.schoolId) {
      remove(index)
    }
  }

  useEffect(() => {
    if (data?.id) {
      reset(data)
      if (data?.phones.length > 0) {
        setValue('phone', data?.phones?.find(p => p.type === 'work')?.number, { shouldDirty: true })
        setValue('fax', data?.phones?.find(p => p.type === 'other')?.number, { shouldDirty: true })
      }
    }
  }, [data])

  useEffect(() => {
    if (schools) {
      setValue('schools', schools)
    }
  }, [schools])

  useEffect(() => {
    if (data?.id) {
      setImage(data?.file?.location)
    }
  }, [data])

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('afile', file)
    formData.append('districtId', +districtId)
    const url = `/api/setting/district/upload`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()
    setImage(data.location)
  }

  const onSubmit = async formData => {
    if (data?.id && formData?.schools?.length > 0) {
      formData?.schools?.forEach(async school => {
        if (!school?.id) {
          await dispatch(saveSchool({ ...school }))
        } else {
          const found = schools.find(item => item.id === school.id)
          if (found?.name !== school?.name) {
            await dispatch(saveSchool({ id: found.id, name: school.name }))
          }
        }
      })
    }

    const payload = pick(formData, [
      'type',
      'countyCode',
      'districtCode',
      'schoolCode',
      'name',
      'abbreviation',
      'website',
    ])

    const address = formData.address
    if (!address?.type) address.type = 'work'
    if (data?.address?.id) {
      address.id = data?.address?.id
    }
    payload.address = address

    const phones = []
    if (formData.phone) {
      const phone = { type: 'work', number: formData.phone }
      const found = data?.phones?.find(p => p.type === 'work')
      if (found && found?.id) {
        phone.id = found?.id
      }
      phones.push(phone)
    }
    if (formData.fax) {
      const fax = { type: 'other', number: formData.fax }
      const found = data?.phones?.find(p => p.type === 'other')
      if (found && found?.id) {
        fax.id = found?.id
      }
      phones.push(fax)
    }
    payload.phones = phones
    if (data?.id) {
      payload.id = data?.id
    }
    const res = await dispatch(save(payload))

    const districtId = res?.payload?.id
    if (!data?.id && districtId && formData?.schools?.length > 0) {
      for (const school of formData.schools) {
        await dispatch(saveSchool({ ...school, districtId }))
      }
    }

    if (res?.payload?.id) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  if (districtId && districtId !== 'new' && !data?.id) return <LinearProgress sx={{ m: 2 }} color="success" />

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SettingsSchoolManagementFormDistrict"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >

        <Header title={+districtId && data?.id ? `Edit District` : t('Add District')} 
          avatar={
            <IconButton component="label" aria-label="district-logo" disabled={districtId ? false : true}>
            <input hidden accept="image/*" type="file" onChange={handleFile} />
            {image ? (
              <Avatar sx={{ width: 80, height: 80, fontSize: 20, objectFit: 'cover'}} src={image}>
                <AvatarIcon />
              </Avatar>
            ):(
              <Avatar sx={{ width: 80, height: 80 }}>
              {data?.name.split(' ')[0]}
            </Avatar>
            )}
          </IconButton>
          }
          sx={{my: 0, mx: 2,}}
          small close borderBottom 
        />

        <Divider />
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid item xs={6} md={6}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Type')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  >
                    <MenuItem value={'charter'}>{t('Charter')}</MenuItem>
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="countyCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('County Code')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="districtCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('District Code')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="schoolCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('School Code')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Name')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="abbreviation"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Abbreviation')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="address.street"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Address')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <Controller
              name="address.city"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('City')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <Controller
              name="address.state"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('State')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <Controller
              name="address.zip"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Zip')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Phone')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="fax"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Fax')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Website')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mx: 3 }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h6">{t('Schools')}</Typography>
            <Fab
              onClick={() => append({ name: '', districtId: +districtId })}
              sx={{ boxShadow: 'none' }}
              size="small"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Stack>
          <Grid container spacing={0} sx={{ mt: 1 }}>
            {fields?.map((item, index) => (
              <Grid key={item.id} item xs={12} md={12}>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Controller
                    name={`schools.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <TextField fullWidth size="small" {...field} label={`Name`} variant="outlined" margin="normal" />
                    )}
                  />
                  <IconButton sx={{ mt: 1 }} aria-label="delete school" onClick={() => removeSchool(index, item)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ mr: 3, mt: 3, mb: 3 }}>
          <SubmitButton
            onClick={handleSubmit(onSubmit)}
            title="Save"
            color="warning"
            startIcon={<FaSave />}
            icon={null}
          ></SubmitButton>
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
