import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const search = createApiAction(
  'academic/parent/final-grades/search',
  client.search('/api/academic/parent/student/:studentId/final-grade'),
)
export default function setStudentFinalGradesReducer(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    const list = state.list || []
    const { schoolEnrollmentId } = action.meta.arg
    const found = list.find(item => item.memberships[0].schoolEnrollments[0].id === schoolEnrollmentId)
    if (found)
      state.list = list.map(item =>
        item.memberships[0].schoolEnrollments[0].id === found.memberships[0].schoolEnrollments[0].id
          ? { ...item, finalGrades: action.payload }
          : item,
      )
  })
}
