import { BasePathProvider } from 'components/Providers'
import StudentAttendanceListIndex from './List'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import StudentAttendanceFormIndex from './Form'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { getPeriods } from 'store/lookup'
import { useDispatch, useSelector } from 'react-redux'
import { clearAttendance } from 'store/settings/attendance'

export default function DailyAttendancePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [student, setStudent] = useState()

  const [dateRange, setDateRange] = useState([dayjs().startOf('week').add(1, 'day'), dayjs()])

  const basePath = `/settings/attendance/student`
  const periods = useSelector(state => state.lookup.periods)

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [periods])

  useEffect(() => {
    dispatch(clearAttendance())
  }, [])

  return (
    <BasePathProvider value={`${basePath}`}>
      <StudentAttendanceListIndex
        student={student}
        setStudent={setStudent}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <StudentAttendanceFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
