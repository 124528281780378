import { useDispatch, useSelector } from 'react-redux'
import SchedulingCoursesForm from './Form'
import { useEffect, useState } from 'react'
import { getCourseLevel, getCourseSubjects, getGradeLevels } from 'store/lookup'
import { search } from 'store/provider/course'
import { search as getGradingScales } from 'store/settings/grading-scale'
import { useParams } from 'react-router-dom'

export default function SchedulingCoursesFormIndex() {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const [selectedCourse, setSelectedCourse] = useState()
  const [courseType, setCourseType] = useState('sced')
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const { query, list } = useSelector(state => state.provider.course)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const gradingScales = useSelector(state => state.settings.gradingScale.list)
  const courseLevel = ['Unweighted', 'College Prep', 'Honors', 'AP Course']

  const data = useSelector(state => state.settings.course.list)?.find(item => item.id === +courseId) || null

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
    courseSubjects || dispatch(getCourseSubjects())
    gradingScales || dispatch(getGradingScales())
    courseLevel || dispatch(getCourseLevel())
    list || dispatch(search({ ...filterNullValues(query), offset: 0 }))
  }, [])

  if (!(courseSubjects && list && gradeLevels && gradingScales && courseLevel)) return null

  // if (!isNaN(+courseId)) setSelectedCourse(+courseId)

  return (
    <SchedulingCoursesForm
      data={data}
      courseType={courseType}
      setCourseType={setCourseType}
      selectedCourse={selectedCourse}
      setSelectedCourse={setSelectedCourse}
    />
  )
}
