import { useDispatch } from 'react-redux'
import PeriodEditForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/settings/periods'    
import PeriodFormSkeleton from './FormSkeleton'

export default function PeriodFormIndex() {
  const dispatch = useDispatch()
  const [period, setPeriod] = useState(null)

  const { id } = useParams()

  useEffect( () => {

    const fetchData = async () => {
      if (id && id !== 'new') {
        const { payload } = await dispatch(get({id}));
        if (payload) {
          setPeriod(payload);
        }
      } else {
          setPeriod({});
      }
    }

    fetchData()
  }, [id])

  return period ? <PeriodEditForm period={period} /> : <PeriodFormSkeleton />

}