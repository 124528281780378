import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const NoRecords = ({ message = "No records found", icon, sx }) => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      sx={{ py: 2, ...sx }} 
    >
      {icon && icon !== 'none' ? (
        <Box>{icon}</Box>
      ) : (
        null
      )}
      <Typography variant="h6" color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
};

NoRecords.propTypes = {
  message: PropTypes.string, 
  icon: PropTypes.node,      
  sx: PropTypes.object,      
};

export default NoRecords;
