import { Box, Button, Grid, CardContent, Table, TableHead, TableRow, Typography, TableBody, TableCell } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import TableHeadCell from 'components/TableHeadCell'
import { search as getNurseVisits } from 'store/nurse/nurse-visit'
import { useDispatch, useSelector } from 'react-redux'
import VisitListItem from './ListItem'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import NoRecords from 'components/NoRecords'
import HealingIcon from '@mui/icons-material/Healing';
import DischargedListItem from './DischargedListItem'
import { useNavigate } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

const headers = ['Student Name', 'Start Time', 'Actions']
const dischargeHeaders = ['Student Name', 'Start Time', 'End Time', 'Duration', 'Disposition', 'Actions']
export default function VisitsList() {
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentDate = dayjs().format('YYYY-MM-DD')
  const nurseVisits = useSelector(state => state.nurse.nurseVisit.list);

  useEffect(() => {
    nurseVisits || dispatch(getNurseVisits({ date: currentDate }));
  }, [dispatch, nurseVisits, currentDate]);

  const onGoingVisits = Array.isArray(nurseVisits) ? nurseVisits.filter(item => !item.endTime) : [];
  const dischargedVisits = Array.isArray(nurseVisits) ? nurseVisits.filter(item => !!item.endTime) : [];

  return (
    <Box sx={{display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'flex-start',}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', alignSelf: 'stretch' }}>
        <Header title={t('Nurse Visit')} small sx={{marginRight: '5px', paddingTop: '5px'}} />
        <Button
          size='small'
          variant='contained'
          color='primary'
          onClick={() => navigate(`${basePath}/new`)}
        >
          {t('New Visit')}
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CardContent sx={{ pr: 0, pl: 0 }}>
            <Box sx={{ backgroundColor: '#5E5959', display: 'flex', padding: '5px', alignItems: 'flex-start', alignSelf: 'stretch', borderRadius: '5px 5px 0px 0px' }}>
              <Typography variant='h6' sx={{ color: 'white' }}>{t('Ongoing Visits')}</Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((item, index) => (
                    <TableHeadCell key={index}>
                      {t(item)}
                    </TableHeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {onGoingVisits && onGoingVisits.length > 0 ? (
                  onGoingVisits.map((item, index) => (
                  <VisitListItem key={index} item={item} />
                  )) 
                ) : (
                <TableRow align="center" >
                  <TableCell colSpan={3} align="center" sx={{ border: 'none' }}> 
                    <NoRecords 
                      message="No Ongoing Visits" 
                      icon={<HealingIcon sx={{ fontSize: 40, mb: 1, color: "grey" }} />}
                    />
                  </TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </CardContent>
          <CardContent sx={{ pr: 0, pl: 0 }}>
            <Box sx={{ backgroundColor: '#5E5959', display: 'flex', padding: '5px', alignItems: 'flex-start', alignSelf: 'stretch', borderRadius: '5px 5px 0px 0px' }}>
              <Typography variant='h6' sx={{ color: 'white' }}>{t('Discharged Visits')}</Typography>
            </Box>
            <Table>
              <TableHead>
                  <TableRow>
                    {dischargeHeaders.map((item, index) => (
                      <TableHeadCell key={index}>
                        {t(item)}
                      </TableHeadCell>
                    ))}
                  </TableRow>
              </TableHead>
              <TableBody>
                {dischargedVisits && dischargedVisits.length > 0 ? (
                  dischargedVisits.map((item, index) => (
                  <DischargedListItem key={index} item={item} />
                  )) 
                ) : (
                <TableRow align="center" >
                  <TableCell colSpan={6} align="center" sx={{ border: 'none' }}> 
                    <NoRecords 
                      message="No Records Found" 
                      icon="none"
                    />
                  </TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </CardContent>
        </Grid>
      </Grid>
    </Box>

  )
}
