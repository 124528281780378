import { MembershipTypeProvider } from '../../components/Providers'
import Person from '../Person'

export default function Staff() {
  return (
    <MembershipTypeProvider type="staff">
      <Person />
    </MembershipTypeProvider>
  )
}
