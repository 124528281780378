import { debounce, omit } from 'lodash'
import { InputAdornment, Stack, TextField, MenuItem } from '@mui/material'
import { search } from 'store/settings/course'
import { getCourseSubjects } from 'store/lookup'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function SearchBar() {
  const dispatch = useDispatch()
  const { query } = useSelector(state => state.settings.course)
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const [que, setQue] = useState(query.que || '')
  const { t } = useTranslation()
  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...query, [key]: value, offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  useEffect(() => {
    dispatch(getCourseSubjects())
  }, [dispatch])

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  return (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
      <TextField
        size="small"
        fullWidth
        value={que}
        placeholder="Search"
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('name', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />
      <TextField
        select
        sx={{ width: '30em' }}
        size="small"
        label={t("Course Subject")}
        placeholder="Course Subject"
        value={query?.subjectId || ''}
        onChange={event => applyFilter('subjectId', event.target.value)}
      >
        <MenuItem value={null}>
          <em>None</em>
        </MenuItem>
        {courseSubjects?.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  )
}
