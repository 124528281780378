import { Route, Routes } from 'react-router-dom'
import AcademicYearProviderPage from './Page'
import AcademicYearPage from './MarkingPeriodProvider/Page'

export default function AcademicYear() {
  return (
    <Routes>
      <Route path="/" element={<AcademicYearProviderPage />}>
        <Route path="/:academicYearId/*" element={<AcademicYearProviderPage />} />
        <Route path="/:academicYearId/:edit" element={<AcademicYearPage />} />
        <Route path="/:academicYearId/full-year/:markingPeriodId" element={<AcademicYearPage />} />
        <Route path="/:academicYearId/summer/:markingPeriodId" element={<AcademicYearPage />} />
      </Route>
    </Routes>
  )
}
