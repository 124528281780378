import { Grid, MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function StateAssessmentForm({
  control,
  // noAndYesOption,
  elaGraduationPathwayIndicatorOption,
  mathGraduationPathwayIndicatorOption,
  bridgeYearOption,
  federalHsMathTestingReqOption,
}) {
  const { t } = useTranslation()

  return (
    <>
      {/* <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="stateAssessment.apaScienceTest"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('APA Science Test')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="stateAssessment.apaLanguageTest"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('APA Language Test')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="stateAssessment.apaMathTest"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('APA Math Test')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid> */}

      <Grid item sm={6} xs={6} md={6}>
        <Controller
          name="stateAssessment.elaGraduationPathwayIndicator"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('ELA Graduation Pathway Indicator')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {elaGraduationPathwayIndicatorOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6} md={6}>
        <Controller
          name="stateAssessment.mathGraduationPathwayIndicator"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Math Graduation Pathway Indicator')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {mathGraduationPathwayIndicatorOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6} md={6}>
        <Controller
          name="stateAssessment.bridgeYear"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Bridge Year')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {bridgeYearOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6} md={6}>
        <Controller
          name="stateAssessment.federalHsMathTestingReq"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Federal HS Math Testing Req')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {federalHsMathTestingReqOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
    </>
  )
}
