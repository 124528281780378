import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

export function Title({ children, ...props }) {
  return (
    <Typography variant="h4" gutterBottom {...props}>
      {children}
    </Typography>
  )
}

export function SubTitle({ children, ...props }) {
  return (
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }} {...props}>
      {children}
    </Typography>
  )
}

export function Body({ children, ...props }) {
  return (
    <Typography variant="body1" gutterBottom {...props}>
      {children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.node,
}

SubTitle.propTypes = {
  children: PropTypes.node,
}

Body.propTypes = {
  children: PropTypes.node,
}
