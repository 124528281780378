import { TableCell, TableRow, Link } from '@mui/material'
import { NavLink } from 'react-router-dom'
import IconProvider from 'components/IconProvider'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export default function StudentAttendanceListItem({ item }) {
  const basePath = '/settings/attendance/student'
  const { district } = useSelector(state => state.session)

  const renderIconProvider = el => {
    return el ? <IconProvider icon={el.status?.ui?.icon} color={el.status?.ui?.color} /> : null
  }
  return (
    <TableRow hover>
      <TableCell>
        <Link
          component={NavLink}
          underline="hover"
          color="inherit"
          to={
            item?.dailyAttendance?.id
              ? `${basePath}/${item?.dailyAttendance?.id}`
              : `${basePath}/new?date=${dayjs
                  .tz(item.date, district.timeZone)
                  .format('YYYY-MM-DD')}&schoolEnrollmentId=${item?.schoolEnrollmentId}`
          }
        >
          {dayjs.tz(item.date, district.timeZone).format('ddd, M/D/YYYY')}
        </Link>
      </TableCell>
      <TableCell>
        <IconProvider icon={item.dailyAttendance?.status?.ui?.icon} color={item.dailyAttendance?.status?.ui?.color} />
      </TableCell>
      <TableCell>{item.dailyAttendance?.note || ''}</TableCell>
      {item.periodAttendance?.map((el, index) => (
        <TableCell key={index}>{renderIconProvider(el)}</TableCell>
      ))}
    </TableRow>
  )
}
