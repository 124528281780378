import { Route, Routes, useNavigate } from 'react-router-dom'
import ReportView from './View'
import { Box } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import { BasePathProvider } from 'components/Providers'
import ReportRun from './Run'
import ReportExportPDF from './PDF'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
const basePath = '/report'

export default function Report() {
  const navigate = useNavigate()
  const { academicYear } = useSelector(state => state.session)

  useEffect(() => {
    navigate('/report')
  }, [academicYear?.id])

  return (
    <BasePathProvider value={basePath}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Reports'} />
      </Box>
      <Routes>
        <Route path="/">
          <Route path="" element={<ReportView />} />
          <Route path="/run/:reportId" element={<ReportRun />} />
          <Route path="/run/pdf/:reportId" element={<ReportExportPDF />} />
        </Route>
      </Routes>
    </BasePathProvider>
  )
}
