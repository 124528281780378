import { useEffect } from 'react'
import FinalGrades from 'components/FinalGrades'
import { useMember } from 'components/Providers'
import { useSelector, useDispatch } from 'react-redux'
import { search } from 'store/academic/grading-scales'
import { search as seachFinalGrades } from 'store/academic/student/final-grades'

export default function Index() {
  const dispatch = useDispatch()
  const member = useMember()
  const permission = useSelector(state => state.session.permissions)
  const academicYearId = useSelector(state => state.session.academicYear.id)

  const finalGrades = useSelector(state => state.academic.student.detail.finalGrades)
  const gradingScales = useSelector(state => state.academic.gradingScales).list
  const markingPeriods = useSelector(state => state.session.academicYear.markingPeriods)

  const courseSessionEnrollmentGrades = finalGrades?.data
  const gpas = finalGrades?.gpas
  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  useEffect(() => {
    courseSessionEnrollmentGrades || dispatch(seachFinalGrades({ type: member?.membership?.type, memberId: member?.id }))
  }, [dispatch, courseSessionEnrollmentGrades, member?.id])

  useEffect(() => {
    gradingScales || dispatch(search())
  }, [dispatch, gradingScales, academicYearId, member?.id])

  if (!(courseSessionEnrollmentGrades && markingPeriods)) return null

  return (
    <FinalGrades
      courseSessionEnrollmentGrades={courseSessionEnrollmentGrades}
      markingPeriods={markingPeriods}
      hasCourseSessionViewPermission={hasCourseSessionViewPermission}
      gpas={gpas}
      gradingScales={gradingScales}
    />
  )
}
