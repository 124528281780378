import { Card, CardContent, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import TableHeadCell from '../../../../components/TableHeadCell'
import DistrictListItem from './ListItem'

export default function DistrictList() {
  const districts = useSelector(state => state.settings.district.list)

  return (
    <Grid container  sx={{ mt: -3 }}>
      <Grid item xs={12} md={12}>
        <Card sx={{ minHeight: '600px' }}>
          <CardContent sx={{ pl: 0, pr: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell>District Name</TableHeadCell>
                  <TableHeadCell>Address</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {districts.map(item => {
                  return <DistrictListItem key={item.id || item.uid} district={item} />
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
