import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'leave-request-type'

const initialState = {
  list: null,
}

export const search = createApiAction(
  'settings/leave-request-type/search',
  client.search('/api/setting/leave-request-type'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export default sessionSlice.reducer
