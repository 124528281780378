import SchedulingEnrollStudentForm from './Form'

export default function SchedulingEnrollStudentFormIndex({
  activeCourseSessionEnrollments,
  setActiveCourseSessionEnrollments,
  selected,
  setSelected,
}) {
  return (
    <SchedulingEnrollStudentForm
      activeCourseSessionEnrollments={activeCourseSessionEnrollments}
      setActiveCourseSessionEnrollments={setActiveCourseSessionEnrollments}
      selected={selected}
      setSelected={setSelected}
    />
  )
}
