import {
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'

export default function StudentListSkeleton() {

  return (
    <Paper data-component="StudentList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell width={'100%'}>
                      <Skeleton variant="rounded" width={'20%'} height={30} />
                    </TableHeadCell>
                    <TableHeadCell ></TableHeadCell>
                    <TableHeadCell ></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(7)].map((_, i) => (
                    <TableRow hover key={i}>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="rounded" height={30} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
