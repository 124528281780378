import { Fragment, useState } from 'react'
import { TextField, Grid, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import Confirm from 'components/Dialog'

export default function AddressEditForm({ path, control, item, index, ...props }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  async function remove(id, index, confirmed = false) {
    try {
      if (!id) {
        props.remove(index)
        return
      }
      if (!confirmed) {
        setOpen(true)
        return
      }
      setOpen(false)

      props.remove(index)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Grid item md={12} key={item?.id}>
      <Grid container spacing={2}>
        <Fragment key={item?.uuid}>
          <Controller name={`${path}.${index}.id`} control={control} render={() => {}} />
          {item.type !== 'home' && (
            <Grid item xs={2} md={2}>
              <Controller
                name={`${path}.${index}.type`}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      select
                      label={t('Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      defaultValue="home"
                      {...field}
                    >
                      <MenuItem value="home">{t('Home')}</MenuItem>
                      <MenuItem value="work">{t('Work')}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}
          <Grid item xs={4} md={item.type !== 'home' ? 5 : 6}>
            <Controller
              name={`${path}.${index}.street`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Address 1')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={item.type !== 'home' ? 5 : 6}>
            <Controller
              name={`${path}.${index}.line2`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Address 2')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name={`${path}.${index}.city`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('City')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Controller
              name={`${path}.${index}.state`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('State')}
                    value={item.state}
                    /* defaultValue={state}
                     */ error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Controller
              name={`${path}.${index}.zip`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Postal Code')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
        </Fragment>
      </Grid>
      <Confirm open={open} onClose={() => setOpen(false)} onConfirm={() => remove(item.id, index, true)} />
    </Grid>
  )
}
