import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/course-session'
import { debounce, omit } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function SchedulingEnrollBySessionSearch({ setSelectedCourseSession }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { academicYear } = useSelector(state => state.session)
  const { query, list } = useSelector(state => state.settings.courseSession)

  const [que, setQue] = useState(query?.que || '')

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  useEffect(() => {
    setQue(undefined)
    setSelectedCourseSession(undefined)
    let courseSessionQuery = {
      ...omit(query, 'courseId'),
      offset: 0,
      academicYearId: query.academicYearId || academicYear?.id,
    }
    dispatch(search(courseSessionQuery))
  }, [academicYear])

  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...filterNullValues(query), [key]: value, offset: 0 }))
    } else {
      dispatch(search({ ...filterNullValues(omit(query, key)), offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  if (!list) return null

  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={list.map(courseSession => courseSession.id)}
      size="medium"
      onChange={(event, value) => {
        setSelectedCourseSession(list.find(courseSession => courseSession.id === value))
      }}
      getOptionLabel={option => {
        const name = list.find(courseSession => courseSession.id === option)?.name
        if (name) return name
        else return ''
      }}
      renderInput={params => (
        <TextField
          {...params}
          value={que || null}
          fullWidth
          label={t('Select Course Session')}
          onChange={e => {
            setQue(e.target.value)
            delayedSearch(e.target.value, applyFilter)
          }}
        />
      )}
    />
  )
}
