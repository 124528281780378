import { IconButton, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { CheckBoxOutlineBlank, CheckBox, EditIcon, DeleteIcon } from 'components/Icons'
import { select, destroy } from 'store/settings/school-enrollment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Confirm from 'components/Dialog'
import { useMembershipType, usePerson, useBasePath } from 'components/Providers'

export default function SchoolEnrollmentItem({ enrollment }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const person = usePerson()
  const basePath = useBasePath()
  const membershipType = useMembershipType()
  const detailPath = `${basePath}/${enrollment?.id}`
  const selected = useSelector(state => state.settings.schoolEnrollment.selected?.id) === enrollment?.id
  const [confirm, setConfirm] = useState(false)

  const onSelectSchoolEnrollment = () => {
    dispatch(select(enrollment))
  }

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: enrollment.id, personId: person.id, membershipType, include: ['student'] }))
  }

  return (
    <TableRow selected={selected}>
      <TableCell sx={{ pr: 0, width: '3em' }}>
        <IconButton color={selected ? 'primary' : 'default'} onClick={onSelectSchoolEnrollment}>
          {selected ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />}
        </IconButton>
      </TableCell>
      <TableCell onClick={onSelectSchoolEnrollment}>{enrollment?.school.name}</TableCell>
      <TableCell onClick={onSelectSchoolEnrollment}>{enrollment?.startDate}</TableCell>
      <TableCell onClick={onSelectSchoolEnrollment}>{enrollment?.endDate}</TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate(detailPath)
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
