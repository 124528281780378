import { useDispatch, useSelector } from 'react-redux'
import ContactsListIndex from './List'
import { BasePathProvider } from 'components/Providers'
import * as action from 'store/person/contact'
import { useEffect } from 'react'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import ContactsListSkeleton from './List/ListSkeleton'
import ContactsFormIndex from './Form'

export default function ContactsPage() {
  const dispatch = useDispatch()
  const { personId, contactId } = useParams()

  const personContacts = useSelector(state => state.person2.contacts)

  const navigate = useNavigate()

  const basePath = `/person/${personId}/contacts`

  useEffect(() => {
    if (!personContacts) {
      dispatch(action.search({ personId: +personId }))
    } else {
      if (!personContacts[+personId]) dispatch(action.search({ personId: +personId }))
    }
  }, [dispatch, personId])

  if (!personContacts) return <ContactsListSkeleton />
  if (!personContacts[+personId]) return <ContactsListSkeleton />

  return (
    <BasePathProvider value={`${basePath}`}>
      <ContactsListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(contactId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <ContactsFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
