import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Toolbar,
  FormControl,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  MenuItem,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { createFilterOptions } from '@mui/material/Autocomplete'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CoursePeriods from './CoursePeriod'
import { save } from 'store/settings/course-session'
import { getCourses } from 'store/lookup'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { pick } from 'lodash'
import { CourseSessionEnrollment } from './CourseSessionEnrollment'
import { useTranslation } from 'react-i18next'

export default function CourseSessionEditForm({ data, roomList }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { courseSessionId } = useParams()
  const { t } = useTranslation()
  const courses = useSelector(state => state.lookup.courses)

  const schema = yup.object().shape({
    course: yup
      .object()
      .shape({
        id: yup.number().required('Required'),
        name: yup.string(),
      })
      .required(),
    name: yup.string().required('Required'),
    credit: yup.number().nullable().transform(value => value || null),
    markingPeriods: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required('Required'),
          enabled: yup.boolean().required('Required'),
        }),
      )
      .min(1, 'Required'),
    buildingId: yup
      .number()
      .nullable()
      .transform(value => value || null),
    buildingRoomId: yup
      .number()
      .nullable()
      .transform(value => value || null),
  })

  const { control, handleSubmit, getValues, setValue, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: data.id,
      course: data.course,
      name: data.name || '',
      credit: data.credit || '',
      buildingId: data.room?.buildingId || '',
      buildingRoomId: data.room?.id || '',
      markingPeriods: data.markingPeriods || [],
    },
  })

  const markingPeriodArrayControl = useFieldArray({ control, name: 'markingPeriods', keyName: 'uuid' })

  const _filterOptions = createFilterOptions()
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state)
    const found = results.find(item => item.id === data.course?.id)
    return found ? results : [data.course, ...results].filter(Boolean)
  }

  const onSubmit = data => {
    const { id, course, markingPeriods, ...payload } = data
    Object.assign(payload, id ? { id } : { courseId: course.id }, {
      markingPeriods: markingPeriods.filter(mp => mp.enabled).map(mp => mp.id),
    })
    dispatch(save(payload)).then(res => {
      if (res.payload) navigate(basePath)
    })
  }

  const onError = errors => console.error(errors)

  useEffect(() => {
    courses || dispatch(getCourses())
  }, [dispatch, courses])

  if (!courses) return null

  return (
    <BasePathProvider value={`${basePath}/${courseSessionId}`}>
      <Toolbar />
      <Header title={t('Course Session')} close />
      <CardContent component="form" id="course-session-form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Controller
              name="course"
              control={control}
              render={({ field: { ref, onChange, ...field }, fieldState }) => {
                return (
                  <Autocomplete
                    size="small"
                    disabled={!!data.id}
                    disablePortal
                    filterOptions={filterOptions}
                    onChange={(event, value) => onChange(pick(value, ['id', 'name', 'enabled']))}
                    value={field.value}
                    options={courses}
                    getOptionLabel={option => {
                      const course = courses?.find(c => c.id === option.id)
                      return course?.name || ''
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option && value ? option.id === value?.id : false
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Course')}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    size="small"
                    label={t('Course Session name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Controller
              name="credit"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Credit')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Controller
              name="buildingRoomId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Building Room')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    onChange={event => {
                      const value = event.target.value
                      field.onChange(value || '')
                      const room = roomList.find(room => room.buildingRoomId === value)
                      setValue('buildingId', room ? room.buildingId : '')
                    }}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {roomList?.map(item => (
                      <MenuItem key={item.buildingRoomId} value={item.buildingRoomId}>
                        {item.buildingName}, {[item.doorNumber, item.roomName].filter(Boolean).join(' - ')}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Paper variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableHeadCell colSpan="4">Marking Periods</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {markingPeriodArrayControl.fields.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.startDate}</TableCell>
                        <TableCell>{item.endDate}</TableCell>
                        <TableCell key={item.uuid} align="right">
                          <Controller
                            name={`markingPeriods.${index}.enabled`}
                            control={control}
                            render={({ field }) => (
                              <FormControl>
                                <FormControlLabel
                                  sx={{ mr: 0 }}
                                  control={
                                    <Switch
                                      checked={field.value}
                                      onChange={e => {
                                        field.onChange(e.target.checked)
                                      }}
                                      name="enabled"
                                    />
                                  }
                                />
                              </FormControl>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t('Cancel')}
        </Button>
        <Button type="submit" form="course-session-form" variant="contained" disabled={!formState.isDirty}>
          {t('Submit')}
        </Button>
      </CardActions>
      <CardContent sx={{ p: 0 }}>{getValues('id') && <CoursePeriods />}</CardContent>
      <CardContent sx={{ p: 0 }}>{<CourseSessionEnrollment />}</CardContent>
    </BasePathProvider>
  )
}
