import { Grid, MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function ProgramInfoForm({
  control,
  noAndYesOption,
  enrollmentTypeOption,
  programTypeOption,
  titleIIndicatorOption,
}) {
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.enrollmentType"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Enrollment Type')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {enrollmentTypeOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.programType"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Program Type')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {programTypeOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.eighthTechnologicalLiteracy"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                label={t('Eighth Technological Literacy')}
                error={!!fieldState.error}
                value={value || 'Not evaluted'}
                defaultValue={'Not evaluted'}
                disabled={true}
                {...field}
                size="small"
                fullWidth
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.alternativeEducation"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Alternative Education')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.isStudentRepeatingTheCurrentGradeLevel"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Is Student Repeating the Current Grade Level')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="programInfo.titleIIndicator"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Title I Indicator')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {titleIIndicatorOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="programInfo.titleIParticipation"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Title I Participation')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
    </>
  )
}
