import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/person/:personId/enrollment/'

export const search = createApiAction('person/enrollment/get', client.search(`${basePath}`))
// export const createSchoolEnrollment = createApiAction(
//   'person/enrollment/createSchoolEnrollment',
//   client.create(`${basePath}/school`),
// )
// export const updateSchoolEnrollment = createApiAction(
//   'person/enrollment/updateSchoolEnrollment',
//   client.save(`${basePath}/school`),
// )
export const createCampusEnrollment = createApiAction(
  'person/enrollment/createCampusEnrollment',
  client.create(`${basePath}campus`),
)
export const updateCampusEnrollment = createApiAction(
  'person/enrollment/updateCampusEnrollment',
  client.save(`${basePath}campus`),
)

export default function setPersonEnrollmentReducers(builder) {
  builder
    .addCase(search.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      if (!state.enrollments) {
        const temp = {}
        temp[personId] = action.payload
        state.enrollments = temp
      } else {
        const enrollments = state.enrollments
        enrollments[personId] = action.payload || []
        state.enrollments = enrollments
      }
    })
    .addCase(createCampusEnrollment.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      const enrollments = state.enrollments
      if (enrollments[personId]) {
        const tempCampusEnrollment = enrollments[personId]?.campusEnrollments
        tempCampusEnrollment.push(action.payload)
        const tempSchoolEnrollment = enrollments[personId]?.schoolEnrollments
        enrollments[personId] = { schoolEnrollments: tempSchoolEnrollment, campusEnrollments: tempCampusEnrollment }
        state.enrollments = enrollments
      } else {
        enrollments[personId] = { schoolEnrollments: [], campusEnrollments: [action.payload] }
      }
    })
    .addCase(updateCampusEnrollment.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      const enrollments = state.enrollments
      const tempCampusEnrollment = enrollments[personId]?.campusEnrollments
      let campusEnrollment = tempCampusEnrollment.find(item => item.id === action.payload.id)
      const campusEnrollmentIndex = tempCampusEnrollment.indexOf(campusEnrollment)
      tempCampusEnrollment[campusEnrollmentIndex] = action.payload
      enrollments[personId].campusEnrollments = tempCampusEnrollment
      state.enrollments = enrollments
    })
  // .addCase(createSchoolEnrollment.fulfilled, (state, action) => {
  //   const membershipId = action.meta.arg.membershipId
  //   const enrollments = state.enrollments
  //   if (enrollments[membershipId]) {
  //     const tempSchoolEnrollment = enrollments[membershipId]?.schoolEnrollments
  //     tempSchoolEnrollment.push(action.payload)
  //     const tempCampusEnrollment = enrollments[membershipId]?.campusEnrollments
  //     enrollments[membershipId] = { schoolEnrollments: tempSchoolEnrollment, campusEnrollments: tempCampusEnrollment }
  //     state.enrollments = enrollments
  //   } else {
  //     enrollments[membershipId] = { schoolEnrollments: [action.payload], campusEnrollments: [] }
  //   }
  // })
  // .addCase(updateSchoolEnrollment.fulfilled, (state, action) => {
  //   const membershipId = action.meta.arg.membershipId
  //   const enrollments = state.enrollments
  //   const tempSchoolEnrollment = enrollments[membershipId]?.schoolEnrollments
  //   let schoolEnrollment = tempSchoolEnrollment.find(item => item.id === action.payload.id)
  //   const schoolEnrollmentIndex = tempSchoolEnrollment.indexOf(schoolEnrollment)
  //   tempSchoolEnrollment[schoolEnrollmentIndex] = action.payload
  //   enrollments[membershipId].schoolEnrollments = tempSchoolEnrollment
  //   state.enrollments = enrollments
  // })
}
