import { InputAdornment, Stack, TextField, MenuItem, Box, LinearProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { search } from 'store/academic/course-session'
import { search as searchStudent } from 'store/academic/student/teacher-student'
import { useTranslation } from 'react-i18next'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { debounce, omit } from 'lodash'

export default function SearchBar() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const query = useSelector(state => state.academic.student.query)
  const [selectedCourseSession, setSelectedCourseSession] = useState('')
  const academicYear = useSelector(state => state.session.academicYear)
  const list = useSelector(state => state.academic.courseSession.list)
  const [que, setQue] = useState(query?.que || '')

  useEffect(() => {
    dispatch(search())
  }, [dispatch, academicYear?.id])

  useEffect(() => {
    dispatch(searchStudent({ ...query }))
  }, [dispatch, academicYear?.id])

  const applyFilter = async (key, value) => {
    let updatedQuery = {}
    const commonProperties = {
      offset: 0,
    }

    if (key === 'courseSessionId' && value === '') {
      updatedQuery = {
        ...omit(query, 'courseSessionId'),
        ...commonProperties,
      }
    } else {
      if (value === '') {
        updatedQuery = {
          ...omit(query, 'que'),
          ...commonProperties,
        }
      } else {
        updatedQuery = {
          ...query,
          [key]: value,
          ...commonProperties,
        }
      }
    }

    dispatch(searchStudent(updatedQuery))
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Search"
          value={que}
          onChange={event => {
            setQue(event.target.value)
            delayedSearch(event.target.value, applyFilter)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <CloseIcon
                onClick={() => {
                  setQue('')
                  applyFilter('que', '')
                }}
                sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
              />
            ),
          }}
        />
        <TextField
          select
          sx={{ width: '16em', mt: 3, marginLeft: 'auto' }}
          size="small"
          label={t('Course Session')}
          placeholder="Course Session"
          value={selectedCourseSession}
          onChange={event => {
            setSelectedCourseSession(event.target.value)
            applyFilter('courseSessionId', event.target.value)
          }}
        >
          <MenuItem value={''}>{t('All')} </MenuItem>
          {list?.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {!list && <LinearProgress sx={{ position: 'absolute', buttom: 0, width: '100%' }} />}
    </Box>
  )
}
