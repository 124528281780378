import { Route, Routes } from 'react-router-dom'
import AcademicYearPage from './Page'

export default function AcademicYear() {
  return (
    <Routes>
      <Route path="/" element={<AcademicYearPage />}>
        <Route path="/:academicYearId" element={<AcademicYearPage />} />
        <Route path="/:academicYearId/marking-period/:id" element={<AcademicYearPage />} />
      </Route>
    </Routes>
  )
}
