import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'grading-scale'
const initialState = {
  list: null,
}

const basePath = '/api/setting/grading-scale'
export const search = createApiAction('setting/grading-scale/search', client.search(basePath))
export const retrieve = createApiAction('setting/grading-scale/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('setting/grading-scale/save', client.save(basePath))
export const destroy = createApiAction('setting/grading-scale/destroy', client.destroy(`${basePath}/:id`))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateGradingScales: (state, action) => {
      state.list = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        // state.list = action.payload.sort((a, b) => b.maxGrade - a.maxGrade)
        state.list = action.payload
      })
      .addCase(save.fulfilled, (state, action) => {
        const list = state.list || []
        const { id } = action.meta.arg
        const others = list.filter(item => item.id !== id)
        state.list = [...others, action.payload].sort((a, b) => b.maxGrade - a.maxGrade)
      })
    // .addCase(destroy.fulfilled, (state, action) => {
    //   const list = state.list || []
    //   const { id } = action.meta.arg
    //   state.list = list.filter(item => item.id !== id)
    // })
  },
})
export const { updateGradingScales } = sessionSlice.actions
export default sessionSlice.reducer
