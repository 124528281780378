import { Box, Drawer, } from '@mui/material'
import { BasePathProvider } from 'components/Providers'
import VisitsList from './List'
import NurseAddVisitFormIndex from './Form'
import { useNavigate, useParams } from 'react-router-dom'

const baseUrl = '/nurse/visits'

export default function VisitsPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
         <VisitsList />
         <Drawer 
           PaperProps={{
            sx: {
              width: 800,
            },
           }}
           open={Boolean(id)}
           anchor='right'
           onClose={() => navigate(baseUrl)}
           >
            <NurseAddVisitFormIndex />
           </Drawer>
      </BasePathProvider>
    </Box>
  )
}
