import { Box } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import GradingListCoefficient from './Coefficient'
import GradingListScales from './Scales'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'store/settings/grading-coefficient'
import { useEffect } from 'react'
import { search } from 'store/settings/grading-scale'

export default function GradingListIndex() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { coefficient } = useSelector(state => state?.settings?.gradingCoefficient)
  const { list: scales } = useSelector(state => state?.settings?.gradingScale)
  const { academicYear } = useSelector(state => state.session)
  // const [scales, setScales] = useState()

  useEffect(() => {
    dispatch(get())
    getScaleList()
  }, [academicYear])

  const getScaleList = async () => {
    await dispatch(search())
  }

  if (!coefficient || !scales) return null

  return (
    <Box>
      <Header title={t('Grading')} small />
      <GradingListCoefficient data={coefficient} />
      <GradingListScales list={scales} />
    </Box>
  )
}
