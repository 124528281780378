import { useState } from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const StyledTextField = styled(TextField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
  },
}))

const GradeTextField = ({ label, value, onBlur, onKeyUp, sx, isActive }) => {
  const [textValue, setTextValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState(null)
  const { t } = useTranslation()

  const schema = yup
    .number()
    .transform(value => {
      if (value || value === 0) return value
      return null
    })
    .integer()
    .min(0, t('must be greater than or equal to 0'))
    .max(100, t('must be less than or equal to 100'))
    .typeError(t('must be a number'))
    .nullable()

  const handleChange = async e => {
    const newValue = e.target.value
    try {
      await schema.validate(newValue)
      setTextValue(newValue)
      setErrorMessage(null)
    } catch (err) {
      setErrorMessage(err.message)
    }
  }
  return (
    <StyledTextField
      disabled={isActive}
      id="outlined-basic"
      size="small"
      variant="outlined"
      type="number"
      label={label}
      value={textValue}
      onChange={handleChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      sx={{ width: 800, ...sx }}
      inputProps={{
        style: { textAlign: 'left' },
      }}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  )
}

export default GradeTextField
