import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'
import { SubmitButton } from 'components/Buttons'
import { Autocomplete, Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import { getLanguages } from 'store/lookup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { save } from 'store/person/employment'
import {
  evaluationOfStaff,
  highestLevelEducationCompleted,
  seProgramContractedServices,
  titleIProgramStaff,
  lepInstructorCredential,
} from '../List/Experience'
import { BasePathProvider } from 'components/Providers'
import { SaveAltIcon } from 'components/Icons'

const initialValues = {
  experience: {
    evaluationOfStaff: null,
    highestLevelEducationCompleted: null,
    yearsPriorExperience: null,
    languagesSpokenTeacher: null,
    seProgramContractedServices: null,
    yearsInNJ: null,
    titleIProgramStaff: null,
    lepInstructorCredential: null,
    yearsInLea: null,
  },
}

export default function ExperienceForm({ data }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const languages = useSelector(state => state.lookup.languages)
  const { clients } = useSelector(state => state.lookup)
  const { district, personId, employmentId } = useParams()
  const basePath = `/person/${personId}/employment/`

  useEffect(() => {
    languages || dispatch(getLanguages())
  }, [dispatch])

  const schema = yup.object().shape({
    districtId: yup.number().default(+district),
  })

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data
      ? { experience: { ...data?.stateData?.experience } }
      : {
          ...initialValues,
        },
  })

  //   const onDestroy = async () => {}

  const onSubmit = async formData => {
    const payload = {
      districtId: district,
      personId,
      data: formData.experience,
      type: 'experience',
      membershipId: employmentId,
    }
    const res = await dispatch(save(payload))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  if (!languages) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="PersonEmploymentForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={t('Experience Information')} small close borderBottom />
        <Grid container spacing={2} sx={{ p: 2 }}>
          {district && (
            <Grid item xs={12} md={12}>
              <Controller
                name="districtId"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('District')}
                      error={!!fieldState.error}
                      {...field}
                      value={+district}
                      disabled={true}
                    >
                      {clients?.map(item => (
                        <MenuItem key={item?.client?.id} value={item?.client?.id}>
                          {item?.client?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.evaluationOfStaff"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Evaluation Of Staff')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {evaluationOfStaff.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.highestLevelEducationCompleted"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Highest Level of Education Completed')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {highestLevelEducationCompleted.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.yearsPriorExperience"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Years of Prior Experience')}
                    error={!!fieldState.error}
                    {...field}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="experience.languagesSpokenTeacher"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <Autocomplete
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disablePortal
                    {...field}
                    size="small"
                    fullWidth
                    options={languages?.map(item => item.id) || []}
                    onChange={(event, value) => {
                      value ? setValue('experience.languagesSpokenTeacher', value) : null
                    }}
                    getOptionLabel={option => {
                      return (
                        (languages.find(item => item.id === option) &&
                          `${languages.find(item => item.id === option)?.name} - ${
                            languages.find(item => item.id === option)?.originName
                          }`) ||
                        option
                      )
                    }}
                    onInputChange={(event, value, reason) => {
                      switch (reason) {
                        case 'reset':
                          break
                        case 'clear':
                          setValue('experience.languagesSpokenTeacher', null)
                          break
                        case 'input':
                          break
                        default:
                          break
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        fullWidth
                        label={t('Languages Spoken Teacher')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="experience.seProgramContractedServices"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('SE Program Contracted Services Category')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {seProgramContractedServices.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.yearsInNJ"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField size="small" fullWidth label={t('Years in NJ')} error={!!fieldState.error} {...field} />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.titleIProgramStaff"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Title I Program Staff Category')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {titleIProgramStaff.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="experience.lepInstructorCredential"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('LEP Instructor Credential Type')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {lepInstructorCredential.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="experience.yearsInLea"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField size="small" fullWidth label={t('Years in LEA')} error={!!fieldState.error} {...field} />
                )
              }}
            />
          </Grid>
        </Grid>

        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 2 }}>
          <SubmitButton
            color="warning"
            title={t('SAVE')}
            icon={<SaveAltIcon />}
            onClick={handleSubmit(onSubmit, onErrors)}
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
