import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'steps'
const initialState = {
  last: null,
}

const basePath = '/api/report'
export const retrieve = createApiAction('api/report/run/retrieve', data => {
  if (data) {
    return client.post(`${basePath}/${data?.id}/run`, { ...data })
  } else {
    return client.post(`${basePath}/${data?.id}/run`)
  }
})

const stepsSlice = createSlice({
  name,
  initialState,

  extraReducers(builder) {
    builder.addCase(retrieve.fulfilled, (state, action) => {
      state.last = action.payload
    })
  },
})

export default stepsSlice.reducer
