import {
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'

export default function GradeLevelSectionListSkeleton() {
  const { t } = useTranslation()

  return (
    <Paper data-component="GradeLevelSectionList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell width={'20%'}>{t('Grade Level')}</TableHeadCell>
                    <TableHeadCell width={'20%'}>{t('Grade Section')}</TableHeadCell>
                    <TableHeadCell width={'20%'}>{t('Seat')}</TableHeadCell>
                    <TableHeadCell width={'10%'}></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell>
                      <Skeleton variant="rounded" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" height={30} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
