import { useState, useEffect } from 'react'
import { Box, Card, Drawer } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import SearchBar from './SearchBar'
import { get } from 'store/provider/academic-year'
import AcademicYearList from './List/List'
import AcademicYearSkeleton from './List/Skeleton'
import AcademicYearEditForm from './Form/Form'
import { useTranslation } from 'react-i18next'
import AcademicYear from './MarkingPeriodProvider/Page'
import Form from './Form/Form'
import PageHeader from 'components/AppBar/PageHeader'

const baseUrl = '/settings/provider/academic-year'

export default function AcademicYearsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [academicYear, setAcademicYear] = useState('')

  let { academicYearId, '*': isEdit } = useParams()

  isEdit = isEdit.split('/')[1]
  const academicYearList = useSelector(state => state.provider.academicYear?.rows)

  useEffect(() => {
    if (academicYearId === 'new') {
      setAcademicYear({})
    } else if (+academicYearId) {
      academicYearId &&
        dispatch(get({ id: academicYearId })).then(res => {
          setAcademicYear(res.payload)
        })
      return
    } else {
      setAcademicYear(null)
    }
  }, [dispatch, academicYearId])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={t('Academic Year')} />
      </Box>
      <Card sx={{ pt: 2 }}>
        <SearchBar />
        {academicYearList ? <AcademicYearList /> : <AcademicYearSkeleton />}
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            width: isEdit === 'edit' || academicYearId === 'new' ? 600 : 1000,
          },
        }}
        open={Boolean(academicYearId)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        {academicYearId === 'new' ? (
          <Form />
        ) : isEdit === 'edit' ? (
          academicYear && <AcademicYearEditForm academicYear={academicYear} />
        ) : (
          <AcademicYear academicYear={academicYear} />
        )}
      </Drawer>
    </BasePathProvider>
  )
}
