import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/person/:personId/contact'
export const search = createApiAction('person/contact/search', client.search(basePath))
export const retrieve = createApiAction('person/contact/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('person/contact/save', client.save(basePath))
export const destroy = createApiAction('person/contact/destroy', client.destroy(`${basePath}/:id`))
export const saveSequences = createApiAction('person/contact/re-order', client.save(`${basePath}/re-order`))

export default function setPersonContactReducers(builder) {
  builder
    .addCase(search.fulfilled, (state, action) => {
      const personId = action.meta.arg.personId
      if (state.contacts) {
        const contacts = state.contacts
        contacts[personId] = action.payload || []
        state.contacts = contacts
      } else {
        const temp = {}
        temp[personId] = action.payload
        state.contacts = temp
      }
    })
    .addCase(save.fulfilled, (state, action) => {
      const list = state.contacts[+action.meta.arg.personId]
      if (!list) {
        state.contacts[action.meta.arg.personId] = action.payload
      } else {
        const temp = [...list.filter(item => item.id !== +action.meta.arg.id), action.payload]
        state.contacts[action.meta.arg.personId] = temp.sort((a, b) => a.id - b.id)
      }
    })
    .addCase(destroy.fulfilled, (state, action) => {
      const list = state.contacts[+action.meta.arg.personId]
      if (list) {
        const temp = [...list.filter(item => item.id !== +action.meta.arg.id)]
        state.contacts[action.meta.arg.personId] = temp.sort((a, b) => a.id - b.id)
      }
    })
    .addCase(retrieve.fulfilled, (state, action) => {
      state.contactDetails = action.payload
      // TODO: contacts içinde yoksa eklenecek
    })
  // .addCase(saveSequences.fulfilled, (state, action) => {})
}
