import { Box, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import Header from 'components/Header'
import TableHeadCell from 'components/TableHeadCell'
import PeriodAttendanceItem from './PeriodAttendanceItem'
import { useTranslation } from 'react-i18next'

export default function PeriodAttendanceList({ newAttendanceList, attendanceStatus, selectedDate }) {
  const { t } = useTranslation()
  return (
    <Box>
      <Header
        title={t(`Period Attendance ( Total :${newAttendanceList?.length} )`)}
        small
        sx={{ paddingLeft: '16px', paddingTop: '10px' }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell> {t('Period')} </TableHeadCell>
                <TableHeadCell> {t('Attendance')} </TableHeadCell>
                <TableHeadCell> {t('Course Session')} </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newAttendanceList?.map(item => {
                return (
                  <PeriodAttendanceItem
                    key={item.id}
                    item={item}
                    attendanceStatus={attendanceStatus}
                    selectedDate={selectedDate}
                  />
                )
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  )
}
