import { Grid, MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function SpedForm({ control, noAndYesOption }) {
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.referralDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Referral Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.parentalConsentEvaluateDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Parental Consent to Evaluate Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.eligibilityDeterminationDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Eligibility Determination Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.initialIEPMeetingDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Initial IEP Meeting Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.earlyIntervention"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Early Intervention")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.parentalConsentObtained"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Parental Consent Obtained")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.parentalConsentImpInitialIEP"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Parental Consent to Implement Initial IEP")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="sped.annualIEPReviewMeetingDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Annual IEP Review Meeting Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.specialEducationClassification"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Special Education Classification")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.reevaluationDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t("Reevaluation Date")}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.initialProcessDelayReason"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Initial Process Delay Reason")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.specialEducationPlacement"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Special Education Placement")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.timeRegularProgram"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Time In Regular Program")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.counselingServices"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Counseling Services")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.occupationalTherapyServices"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Occupational Therapy Services")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.physicalTherapyServices"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Physical Therapy Services")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.speechLanguageTherapyServices"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Speech Language Therapy Services")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Controller
          name="sped.otherRelatedServices"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Other Related Services")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
    </>
  )
}
