import { useTheme } from '@emotion/react'
import { Box, Grid, Stack } from '@mui/material'
import { DAYS } from 'constants'

export default function ScheduleDays({ dayListHeight, saturday, sunday }) {
  const theme = useTheme()

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '1px', height: `${dayListHeight}` }}>
        <Stack flexDirection={'row'}>
          {DAYS.map((item, index) =>
            item !== 'Saturday' && item !== 'Sunday' ? (
              <Box id={item} key={index} sx={{ py: 2, mr: '1px', backgroundColor: theme.palette.action.selected }}>
                {item}
              </Box>
            ) : item === 'Saturday' && saturday ? (
              <Box id={item} key={index} sx={{ py: 2, mr: '1px', backgroundColor: theme.palette.action.selected }}>
                {item}
              </Box>
            ) : (
              item === 'Sunday' &&
              sunday && (
                <Box
                  id={item}
                  key={index}
                  sx={{
                    py: 2,
                    mr: '1px',
                    backgroundColor: theme.palette.action.selected,
                  }}
                >
                  {item}
                </Box>
              )
            ),
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}
