import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SchoolForm from './Form.jsx'
import SchoolFormSkeleton from './FormSkeleton'
import { getCountry } from 'store/lookup'
import { get } from 'store/settings/school'

export default function SchoolFormIndex() {
  const dispatch = useDispatch()

  const { id } = useParams()
  const { country } = useSelector(state => state.lookup)

  const [isLoaded, setIsLoaded] = useState()
  const [school, setSchool] = useState()

  useEffect(() => {
    if (!country) {
      dispatch(getCountry())
    }
  }, [country])

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setSchool(res.payload)
      })
    } else {
      setSchool({})
    }
  }, [id])

  useEffect(() => {
    if (school && country) {
      setIsLoaded(true)
    }
  }, [school, country])

  return isLoaded ? <SchoolForm school={school} /> : <SchoolFormSkeleton />
}
