import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'reports'
const initialState = {
  list: null,
  query: {
    limit: 10,
    offset: 0,
    que: null,
  },
}

const basePath = '/api/report'
export const search = createApiAction('api/reports/search', client.search(`${basePath}`))
export const reportFavorite = createApiAction('api/reports/save', client.update(`${basePath}/:id/favorite`))
export const searchByName = createApiAction('api/reports/searchByName', client.search(`${basePath}/name`))

const reportSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
      state.query.que = action.meta.arg?.que
    })
    builder.addCase(reportFavorite.fulfilled, (state, action) => {
      const list = state.list
      const id = action.meta.arg?.id
      const found = list?.find(item => item.id === id)
      found.reportUserStatistic = { ...found.reportUserStatistic, isFavorite: action?.meta?.arg?.isFavorite || false }
      const merged = [...list.filter(item => item.id !== id), found]

      const favorites = merged
        ?.filter(report => report?.reportUserStatistic && report?.reportUserStatistic?.isFavorite === true)
        .sort((a, b) => new Date(b?.reportUserStatistic?.runAt) - new Date(a?.reportUserStatistic?.runAt))

      const nonFavoritesWithRunAt = merged
        ?.filter(
          report =>
            report?.reportUserStatistic &&
            report?.reportUserStatistic?.isFavorite === false &&
            report?.reportUserStatistic?.runAt,
        )
        .sort((a, b) => new Date(b?.reportUserStatistic.runAt) - new Date(a?.reportUserStatistic.runAt))

      const nonFavoritesWithoutRunAt = merged
        .filter(
          report =>
            !report?.reportUserStatistic ||
            (report?.reportUserStatistic.isFavorite === false && !report?.reportUserStatistic.runAt),
        )
        .sort((a, b) => a.name.localeCompare(b.name))

      state.list = [...favorites, ...nonFavoritesWithRunAt, ...nonFavoritesWithoutRunAt]
    })
  },
})
export default reportSlice.reducer
