import { Box } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import SchoolsPage from './Page'

export default function Schools() {
  return (
    <Box>
      <PageHeader title={'Schools'} />
      <SchoolsPage />
    </Box>
  )
}
