import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { DeleteIcon } from 'components/Icons'
import { EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { CheckBoxOutlined as CheckIcon, CheckBoxOutlineBlank as UnCheckIcon } from '@mui/icons-material'
import { destroy } from 'store/settings/course-session'

export default function CourseSessionItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item.id }))
  }

  return (
    <TableRow key={item.id}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item.course?.name}
        </Link>
      </TableCell>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item.name}
        </Link>
      </TableCell>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item.academicYear?.name}
        </Link>
      </TableCell>
      <TableCell>{item.enabled ? <CheckIcon /> : <UnCheckIcon />}</TableCell>
      <TableCell xsx={{ textAlign: 'right' }}>
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
