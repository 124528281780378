import { useDispatch } from 'react-redux'
import ContactsListIndex from './List'
import { BasePathProvider, MemberProvider, useMember } from 'components/Providers'
import * as action from 'store/member/contact'
import { useEffect, useState } from 'react'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import ContactsListSkeleton from './List/ListSkeleton'
import ContactsFormIndex from './Form'
import NotFound from 'components/NotFound'

export default function ContactsPage() {
  const dispatch = useDispatch()
  const member = useMember()
  const { contactId } = useParams()
  const [notFound, setNotFound] = useState(null)
  const navigate = useNavigate()

  const basePath = `/member/${member?.membership?.type}/${member?.id}/contacts`

  useEffect(() => {
    !member?.contacts &&
      dispatch(action.search({ type: member?.membership?.type, memberId: member?.id })).then(res => {
        res.error && setNotFound(true)
      })
  }, [dispatch, member?.id])

  if (notFound) return <NotFound basePath={`${basePath}`} title={'Parent/Guardian Information'} />

  if (!member?.contacts) return <ContactsListSkeleton />

  return (
    <BasePathProvider value={`${basePath}`}>
      <MemberProvider member={member}>
        <ContactsListIndex />
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(contactId)}
          anchor="right"
          onClose={() => navigate(`${basePath}`)}
        >
          <ContactsFormIndex contactId={contactId} />
        </Drawer>
      </MemberProvider>
    </BasePathProvider>
  )
}
