import { Card, CardContent, Grid, Skeleton, Toolbar, Box, Stack, Button, CardActions } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function FormSkeleton() {
  const { t } = useTranslation()
  return (
    <Box>
      <Card>
        <Toolbar />
        <Header title={t("Personal Information")} small />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={6}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={6}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rounded" height={40} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button></Button>
                <Button></Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>

        </CardActions>
      </Card>
    </Box>
  )
}
