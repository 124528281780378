import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  list: [],
  query: { limit: 10, offset: 0 },
}

export const search = createApiAction('course-session/search', client.search('/api/setting/course-session'))
export const get = createApiAction('course-session/get', client.retrieve(`/api/setting/course-session/:id`))
export const save = createApiAction('course-session/save', client.save('/api/setting/course-session'))
export const destroy = createApiAction('course-session/delete', client.destroy('/api/setting/course-session/:id'))

const updateAcademicYearList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    destroyCourseSessionEnrollment: (state, action) => {
      const id = action.payload
      const list = state.list
      list.forEach((cs, index) => {
        cs.courseSessionEnrollments.forEach((cse, ind) => {
          if (id && id === cse?.id) {
            delete list[index].courseSessionEnrollments[ind]
          }
        })
      })
      state.list = list
    },
    saveCourseSessionEnrollment: (state, action) => {
      const courseSessionId = +action.payload.courseSessionId
      const id = +action.payload.id
      const list = state.list
      let listIndex = 0
      let csIndex = 0
      list.forEach((cs, index) => {
        if (cs.id === courseSessionId) listIndex === index
        cs.courseSessionEnrollments.forEach((cse, ind) => {
          if (id === cse?.id) {
            csIndex = ind
          }
        })
      })
      list[listIndex].courseSessionEnrollments[csIndex] = action.payload
      state.list = list
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        if (!action.meta.arg?.export) {
          state.query = action.meta.arg
          state.count = action.payload.count
          state.list = action.payload.rows
        }
      })
      .addCase(get.fulfilled, updateAcademicYearList)
      .addCase(save.fulfilled, updateAcademicYearList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(ay => ay.id !== action.payload.id)
      })
  },
})

export const { destroyCourseSessionEnrollment, saveCourseSessionEnrollment } = sessionSlice.actions

export default sessionSlice.reducer
