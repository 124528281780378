import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const IconProvider = ({ icon, color }) => {
  const icons = {
    checkMarkIcon: CheckIcon,
    closeIcon: CloseIcon,
  }

  const IconComponent = icons[icon]

  return IconComponent ? <IconComponent style={{ color, marginRight: '10px' }} /> : null
}

export default IconProvider
