import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card } from '@mui/material'
import { useSession } from 'components/Providers'
import { search as seachFinalGrades } from 'store/academic/grade-finalGrade'
import FinalGrades from 'components/FinalGrades'
import { search } from 'store/academic/grading-scales'
import NotFound from 'components/NotFound'
import PageHeader from 'components/AppBar/PageHeader'
import Header from 'components/Header'

export default function GradingReports() {
  const dispatch = useDispatch()
  const { person, academicYear, school } = useSession()
  const grades = useSelector(state => state.academic.grade_finalGrade?.list)
  const [notFound, setNotFound] = useState(false)
  const markingPeriods = academicYear?.markingPeriods
  const courseSessionEnrollmentGrades = grades?.data
  const gpas = grades?.gpas
  const gradingScales = useSelector(state => state.academic.gradingScales).list
  const basePath = '/academic/grading-report'

  useEffect(() => {
    if (academicYear?.id && person?.id) {
      grades ||
        dispatch(seachFinalGrades()).then(res => {
          res.error && setNotFound(true)
        })
    }
  }, [dispatch, grades, academicYear?.id && person?.id])

  useEffect(() => {
    if (academicYear?.id && person?.id) {
      dispatch(search()).then(res => {
        res.error && setNotFound(true)
      })
    }
  }, [dispatch, person?.id, academicYear?.id])

  return (
    <Box sx={{ minHeight: '50%' }}>
      <Box sx={{ my: 2 }}>
        <PageHeader title={'Final Grades'} />
      </Box>
      <Card>
        <Box sx={{ pl: 2, pr: 0.5 }}>
          <Header
            title={`${person?.firstName}   ${person?.lastName} `}
            small
            print
            printTitle={`${person?.firstName}   ${person?.lastName} - ${school?.name} - ${academicYear?.name}`}
            printfunc={() => window.print()}
          />
        </Box>
        {notFound ? (
          <NotFound basePath={`${basePath}`} />
        ) : (
          <FinalGrades
            courseSessionEnrollmentGrades={courseSessionEnrollmentGrades}
            markingPeriods={markingPeriods}
            gpas={gpas}
            gradingScales={gradingScales}
          />
        )}
      </Card>
    </Box>
  )
}
