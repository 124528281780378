import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Route, Routes, useParams, Navigate } from 'react-router-dom'
import { search, reset } from 'store/settings/building-room'
import RoomList from './RoomList'
import { BasePathProvider, useBasePath } from 'components/Providers'

export default function Rooms() {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { buildingId } = useParams()
  const rooms = useSelector(state => state.settings.buildingRoom.list)

  useEffect(() => {
    if (buildingId === 'new') {
      reset()
      return
    }
    if (+buildingId) {
      dispatch(search({ buildingId }))
    }
  }, [buildingId, dispatch])

  return (
    <BasePathProvider value={`${basePath}/room`}>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route index element={rooms && <RoomList rooms={rooms} />} />
          <Route path="room" element={rooms && <RoomList rooms={rooms} />} />
          <Route path="room/:id" element={rooms && <RoomList rooms={rooms} />} />
          <Route path="*" element={<Navigate to={basePath} />} />
        </Route>
      </Routes>
    </BasePathProvider>
  )
}
