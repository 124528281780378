import { Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { GradeItem } from './GradeItem'
import { useTranslation } from 'react-i18next'

const cellWidth = 150

export const GradeList = ({ markingPeriods, courseSessionEnrollments, studentScores }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell xs={7}>{t('Course Name')}</TableHeadCell>
              {markingPeriods?.map((item, index) => (
                <TableHeadCell sx={{ textAlign: 'center', width: `${cellWidth}px` }} key={index} xs={1}>
                  {item.name}
                </TableHeadCell>
              ))}
              <TableHeadCell sx={{ textAlign: 'center', width: `${cellWidth}px` }} xs={1}>
                {t('Final Grades')}
              </TableHeadCell>
            </TableRow>
          </TableHead>
          {courseSessionEnrollments?.map((item, index) => (
            <TableBody key={index}>
              <GradeItem
                item={item}
                grades={studentScores[item.id]}
                markingPeriodCount={studentScores.markingPeriodCount}
                cellWidth={cellWidth}
              />
            </TableBody>
          ))}
        </Table>
      </Grid>
    </Grid>
  )
}
