import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'leave-request'

const initialState = {
  count: null,
  list: null,
  allItems: null, // This is used to store all leave requests for the all academic years
  query: { limit: 10, offset: 0, sortBy: null, sortOrder: null },
}

export const search = createApiAction('settings/leave-request/search', client.search('/api/setting/leave-request'))
export const get = createApiAction('settings/leave-request/get', client.retrieve(`/api/setting/leave-request/:id`))
export const save = createApiAction('settings/leave-request/save', client.save('/api/setting/leave-request'))
export const destroy = createApiAction(
  'settings/leave-request/delete',
  client.destroy('/api/setting/leave-request/:id'),
)
export const searchAll = createApiAction(
  'settings/leave-request/searchAll',
  client.search('/api/setting/leave-request'), // This is used to store all leave requests for the all academic years
)

const updateList = (state, action) => {
  if (Array.isArray(action.payload)) {
    action.payload.forEach(item => {
      const found = state.list?.find(leaveRequest => leaveRequest.id === item.id)
      state.list = found
        ? state.list?.map(leaveRequest => (found.id === leaveRequest.id ? item : leaveRequest))
        : [...state.list, item]
    })
    action.payload.forEach(item => {
      const found = state.allItems?.find(leaveRequest => leaveRequest.id === item.id)
      state.allItems = found
        ? state.allItems?.map(leaveRequest => (found.id === leaveRequest.id ? item : leaveRequest))
        : [...state.allItems, item]
    })
  } else {
    const found = state.list?.find(leaveRequest => leaveRequest.id === action.payload.id)
    state.list = found
      ? state.list?.map(leaveRequest => (found.id === leaveRequest.id ? action.payload : leaveRequest))
      : [...state.list, action.payload]

      state.allItems = found
      ? state.allItems?.map(leaveRequest => (found.id === leaveRequest.id ? action.payload : leaveRequest))
      : [...state.allItems, action.payload]
  }

  // Order the state list by 'leaveDate' column
  state.list?.sort((a, b) => new Date(a.leaveDate) - new Date(b.leaveDate))
  state.count = state.list?.length
}
const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    initLeaveRequest: state => {
      state.list = null
      state.count = null
      state.query = { limit: 10, offset: 0, sortBy: null, sortOrder: null }
    },
    setSearchQuery: (state, action) => {
      state.query = action.payload
    },
    setSearchLimit: (state, action) => {
      state.query.limit = action.payload
    },
    setSearchOffset: (state, action) => {
      state.query.offset = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.query = action.meta.arg || initialState.count.query
        state.count = action.payload.count
        state.list = action.payload.rows
      })
      .addCase(searchAll.fulfilled, (state, action) => {
        state.allItems = action.payload.rows; 
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list?.filter(item => item.id !== action.payload.id)
      })
  },
})
export const { setSearchLimit, setSearchOffset, setSearchQuery, initLeaveRequest } = sessionSlice.actions

export default sessionSlice.reducer
