import { BasePathProvider } from 'components/Providers'
import { Drawer } from '@mui/material'
import GradingFormIndex from './Form'
import { useNavigate, useParams } from 'react-router-dom'
import GradingListIndex from './List'

const baseUrl = '/settings_/grading'

export default function GradingPage() {
  // return <GradingListIndex />
  // const { t } = useTranslation()
  // const dispatch = useDispatch()
  const { type, id } = useParams()
  const navigate = useNavigate()

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <GradingListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 725,
          },
        }}
        open={Boolean(type) && Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <GradingFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
