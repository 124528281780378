import { Card, CardContent, Grid, Skeleton, CardActions, Button } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function GradeSectionFormSkeleton() {
  const { t } = useTranslation()

  return (
    <BasePathProvider>
      <Card data-component="GradeSectionFormSkeleton" sx={{ minHeight: '100%' }}>
      <Header title={t('Add Grade Section')} small close />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            </Grid>
        </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined">
          <Skeleton />
        </Button>
        <Button type="submit" variant="contained" color='warning'>
          <Skeleton />
        </Button>
      </CardActions>
      </Card>
    </BasePathProvider>
  )
}
