import _ from 'lodash'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/document'

export const search = createApiAction('member/document/search', client.search(basePath))
export const retrieve = createApiAction('member/document/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('member/document/save', client.save(basePath))
export const destroy = createApiAction('member/document/destroy', client.destroy(`${basePath}/:id`))

function foundMember(state, action) {
  const list = state.list || []
  const { memberId } = action.meta.arg
  const member = list.find(item => item.id === memberId)
  if (member) return member
  else throw new Error(`Member with id ${memberId} not found.`)
}
const updateDoc = (state, action) => {
  const member = foundMember(state, action)
  const { id } = action.meta.arg
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  const document = member.documents?.find(doc => doc.id === +id)
  member.documents = document
    ? member.documents.map(doc => (doc.id === document.id ? action.payload : doc))
    : [...member.documents, action.payload]
}
export default function setDocumentReducers(builder) {
  builder
    .addCase(search.fulfilled, (state, action) => {
      const member = foundMember(state, action)
      const replace = _.extend({ id: member.id }, member, { documents: action.payload })
      if (member) state.list = state.list.map(item => (item.id === member.id ? replace : item))
      else state.list = [...state.list, replace]
    })
    .addCase(retrieve.fulfilled, updateDoc)
    .addCase(save.fulfilled, updateDoc)
    .addCase(destroy.fulfilled, (state, action) => {
      const { memberId, id } = action.meta.arg
      state.list = state.list.map(member =>
        member.id === memberId ? { ...member, documents: member.documents.filter(doc => doc.id !== id) } : member,
      )
    })
}
