import { Grid, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import PreviewCard from 'components/PreviewCard'
import { getRelationshipTypes } from 'store/lookup'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMember } from 'components/Providers'

export default function ContactsListItem({ item, index, maxSeq }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const member = useMember()
  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)

  useEffect(() => {
    relationshipTypes || dispatch(getRelationshipTypes())
  }, [dispatch])

  if (!(item && relationshipTypes)) return null

  return (
    <PreviewCard
      title={index === 0 ? t('First Contact') : t('Second Contact')}
      size="large"
      buttonLabel={item.relationship ? t('More') : t('Add Contact')}
      buttonNavigate={
        item.relationship
          ? `/member/${member.membership.type}/${member.id}/contacts/${item.id}`
          : `/member/${member.membership.type}/${member.id}/contacts/new?seq=${maxSeq + 1}&guardian=true`
      }
    >
      <Grid container spacing={2}>
        <Grid item sm={4} xs={4} md={4}>
          <Typography variant="caption">{t('Full Name')}</Typography>
          <Typography variant="subtitle1">
            {item?.contact?.title} {item?.contact?.firstName} {item?.contact?.lastName} &nbsp;
          </Typography>
        </Grid>
        <Grid item sm={4} xs={4} md={4}>
          <Typography variant="caption">{t('Relation')}</Typography>
          <Typography variant="subtitle1">
            {relationshipTypes.find(rt => rt.id === item?.relationship)?.name} &nbsp;
          </Typography>
        </Grid>
        <Grid item sm={4} xs={4} md={4}>
          <Typography variant="caption">{t('Phone Number')}</Typography>
          <Typography variant="subtitle1">
            {item?.contact?.phones?.find(ph => ph.type === 'home')?.number || item?.contact?.phones[0]?.number}
          </Typography>
        </Grid>
      </Grid>
    </PreviewCard>
  )
}
