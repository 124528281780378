import { usePerson } from 'components/Providers'
import ProfileEdit from './ProfileEdit'
import { useParams } from 'react-router-dom'

export default function Profile() {
  const person = usePerson()
  const { personId } = useParams()

  return ((person?.id || String(personId) === 'new') && <ProfileEdit />)
} 
