import { Box, Drawer } from '@mui/material'
import SchedulingCourseSessionsForm from './Form'
import SchedulingCourseSessionsFormAddTeacher from './AddTeacher'
import SchedulingCourseSessionsFormAddStudent from './AddStudent'
import { get } from 'store/settings/course-session'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider } from 'components/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/course-session'
import { reset as resetSchedule } from 'store/settings/course-session-schedule'

const initialValues = {
  courseSession: {
    name: null,
    credit: null,
    quota: null,
    course: {
      id: null,
      name: null,
    },
    markingPeriods: null,
    courseLevelId: null,
    gradeSection: null,
  },
}

export default function SchedulingCourseSessionsFormIndex() {
  const { courseSessionId, add } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)

  const courseSession = useSelector(state => state.settings.courseSession.list)?.find(
    item => item.id === +courseSessionId,
  )

  const basePath = `/settings/scheduling/course-sessions/`

  useEffect(() => {
    if (+courseSessionId) {
      dispatch(resetSchedule())
      dispatch(get({ id: courseSessionId }))
    }
  }, [courseSessionId])

  const schema = yup.object().shape({
    courseSession: yup.object().shape({
      name: yup.string().required(),
      credit: yup.number().required(),
    }),
  })

  const { control, getValues, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: courseSession
      ? {
          courseSession: { ...courseSession },
        }
      : { ...initialValues },
  })

  useEffect(() => {
    reset({ courseSession: { ...courseSession } })
    if (courseSession?.markingPeriods && courseSession?.markingPeriods?.length !== academicYearMarkingPeriods?.length) {
      const data = courseSession?.markingPeriods.map(mp => mp.id)
      const courseSessionMarkingPeriods = academicYearMarkingPeriods.map(mp => mp.id)
      courseSessionMarkingPeriods.forEach((mp, index) => {
        if (!data.includes(mp)) {
          courseSessionMarkingPeriods[index] = null
        } else {
          courseSessionMarkingPeriods[index] = academicYearMarkingPeriods.find(aym => aym.id === mp)
        }
      })
      setValue('courseSession.markingPeriods', courseSessionMarkingPeriods, { shouldDirty: true })
    }
  }, [courseSession])

  const onSubmitSessionDetails = async () => {
    const data = getValues('courseSession')
    const markingPeriods = []

    data?.markingPeriods?.forEach(mp => {
      if (mp.id) markingPeriods.push(mp.id)
    })

    const payload = {
      courseId: data?.course.id,
      name: data?.name,
      credit: +data?.credit,
      markingPeriods,
      quota: +data?.quota,
      buildingId: null,
      buildingRoomId: null,
      gradeSection: data?.gradeSection,
    }
    if (+courseSessionId) {
      delete payload.courseId
      const res = await dispatch(save({ id: +courseSessionId, ...payload }))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    } else {
      const res = await dispatch(save({ ...payload }))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    }
  }

  // const onErrors = errors => console.log(errors)

  if (+courseSessionId && !courseSession) return null

  return (
    <BasePathProvider value={`${basePath}${courseSessionId}/`}>
      <Box>
        <SchedulingCourseSessionsForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          onSubmitSessionDetails={onSubmitSessionDetails}
        />
        <Drawer
          PaperProps={{
            sx: {
              width: 600,
            },
          }}
          open={Boolean(add)}
          anchor="right"
          onClose={() => navigate(`${basePath}${courseSessionId}/`)}
        >
          {add === 'teacher' && <SchedulingCourseSessionsFormAddTeacher />}
          {add === 'student' && <SchedulingCourseSessionsFormAddStudent control={control} />}
        </Drawer>
      </Box>
    </BasePathProvider>
  )
}
