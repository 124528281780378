import client from '../../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../../utils/redux-tool'
import setStudentFinalGradesReducer from './final-grades'
import setStudentAttendanceReducer from './attendance'
import setCourseEnrollmentReducers from './course-session-enrollment'

const name = 'parent-student'
const initialState = {
  list: null,
  count: null,
  query: {
    limit: 10,
    offset: 0,
  },
}

export const search = createApiAction('academic/student/search', client.search('/api/academic/student'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload.rows
      state.count = action.payload.count
      state.query = action.meta.arg
    })
    setStudentFinalGradesReducer(builder)
    setStudentAttendanceReducer(builder)
    setCourseEnrollmentReducers(builder)
  },
})

export default sessionSlice.reducer
