import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/campus'
import * as yup from 'yup'
import AddressEditForm from 'components/AddressEditForm'
import { useEffect } from 'react'
import { omit } from 'lodash'
import Header from 'components/Header'
import { useBasePath } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { getGradeLevels } from 'store/lookup'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function CampusEditForm({ campus, ...props }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const schools = useSelector(state => state.lookup.schools)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Required')
      .transform(value => value?.trim())
      .test('minLength', '', value => value?.trim().length >= 1),
    schoolId: yup.number().required('Required'),
    gradeLevelIds: yup.array().nullable(true),
    address: yup.object().shape({
      type: yup.string().default('work'),
      street: yup.string().required('Required'),
      line2: yup.string(),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      zip: yup.string().required('Required'),
      country: yup.string().required('Required'),
    }),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(campus)
  }, [campus, reset])

  const onSubmit = async data => {
    const gradeLevelsIds = []
    data.gradeLevelIds.forEach(item => {
      const temp = gradeLevels.find(g => g.name === item)?.id
      if (temp) gradeLevelsIds.push(temp)
    })
    data.gradeLevelIds = gradeLevelsIds
    const res = await dispatch(save(omit(data, 'district')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onError = errors => console.error(errors)

  if (!gradeLevels) return null

  return (
    <Card
      data-component="CampusEditForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      sx={{ minHeight: '100%' }}
    >
      <Toolbar />
      <CardHeader title={t('Campus')} subheader={t('Campus create/edit')} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    size="small"
                    label={t('Campus name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  />
                )
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="schoolId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('School')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    {schools.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="gradeLevelIds"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <FormControl fullWidth>
                    <InputLabel sx={{ mt: -0.9 }}>Grade Levels</InputLabel>
                    <Select
                      multiple
                      size="small"
                      label={t('Grade Levels')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || []}
                      {...field}
                      renderValue={selected => (
                        <Stack flexDirection={'row'} gap={1}>
                          {selected?.map(select => {
                            const found = gradeLevels?.find(item => item.id === select)
                            return found ? (
                              <Chip key={select} variant="outlined" label={`${found.name}`} value={found.id} />
                            ) : null
                          })}
                        </Stack>
                      )}
                      MenuProps={MenuProps}
                    >
                      {gradeLevels.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>

          <Grid item sm={6} xs={6} md={12}>
            <Header title={t('Address')} small sx={{ pt: 0, pb: 1, pl: 1, pr: 0 }} />

            <AddressEditForm path="address" control={control} {...props} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t('Cancel')}
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          {t('Submit')}
        </Button>
      </CardActions>
    </Card>
  )
}
