import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useBasePath } from 'components/Providers'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { Link as NavLink } from 'react-router-dom'
import { EditIcon, DeleteIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { destroy } from 'store/settings/room'

export default function RoomItem({ room }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: room.id }))
  }

  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${room.id}`}>
          {room.name}
        </Link>
      </TableCell>
      <TableCell align="left" sx={{ pl: 0 }}>
        <IconButton component={NavLink} to={`${basePath}/${room.id}`} aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
