import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'
import setMarkingPeriodReducers from './marking-period'

const name = 'academic-year'
const initialState = {
  rows: null,
  count: 0,
  query: { que: null, limit: 10, offset: 0 },
}

const basePath = '/api/provider/academic-year'
export const search = createApiAction('provider/academic-year/search', client.search(basePath))
export const get = createApiAction('provider/academic-year/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('provider/academic-year/save', client.save(basePath))
export const destroy = createApiAction('provider/academic-year/destroy', client.destroy(`${basePath}/:id`))

const sortByStartDate = item => {
  return item.slice().sort((a, b) => {
    return new Date(b.startDate) - new Date(a.startDate)
  })
}

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        const sortedList = sortByStartDate(action.payload.rows)
        state.rows = sortedList || []
        state.count = action.payload.count
        state.query = action.meta.arg
      })
      .addCase(save.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        let rows = state.rows.filter(x => x.id !== +id)
        rows.push({ id: +id, ...action.payload })
        state.rows = sortByStartDate(rows)
        state.count = state.count + 1
      })
      .addCase(destroy.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        let rows = state.rows.filter(x => x.id !== +id) || []
        state.rows = rows
        state.count = state.count - 1
      })
    setMarkingPeriodReducers(builder)
  },
})

export default sessionSlice.reducer
