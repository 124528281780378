import { Button, Grid, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import CalendarEventList from './ListEvent'
import dayjs from 'dayjs'
import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useEffect, useState } from 'react'
import './Calendar.scss'
import palette from './CalendarColorPalette.json'

moment.locale("en-US");
const localizer = momentLocalizer(moment)

export default function CalendarList({ currentDay, prevMonth, nextMonth, districts, campuses, calendar }) {
  const calendarEventTypes = useSelector(state => state.lookup.calendarEventType)
  const sessionAppearance = useSelector(state => state.session.appearance)
  const appearance =
    sessionAppearance === '' || !sessionAppearance ? 'auto' : sessionAppearance === 'light' ? 'light' : 'dark'
  const [eventsList, setEventsList] = useState()

  useEffect(() => {
    if (calendar) {
      const temp = []
      calendar.forEach(cal => {
        const bgColor = calendarEventTypes?.find(item => item?.id === cal?.eventTypeId)?.bgColor
        const uiEffect = calendarEventTypes?.find(item => item?.id === cal?.eventTypeId)?.uiEffect
        
        const start = moment(cal.startDate).startOf('day'); 
        const end = moment(cal.endDate).endOf('day');

        temp.push({
          title: cal.name,
          start,
          end,
          bgColor,
          uiEffect
        })
      })
      setEventsList(temp)
    }
  }, [calendar, currentDay])

  function eventPropGetter(event) {
    const textColor = palette[appearance][event?.bgColor]?.text
    const backgroundColor = palette[appearance][event?.bgColor]?.background
    const borderColor = palette[appearance][event?.bgColor]?.border
    const displayEffects = event?.uiEffect;

    const style = {
      color: textColor,
      backgroundColor,
      borderRadius: '4px',
      borderLeft: `3px solid ${borderColor}`,
      position: 'relative', 
      overflow: 'hidden',
    };
  
    let className = '';

    if (displayEffects === 'line') {
        className = 'line'; 
      }
  
      if (displayEffects === 'star') {
        className = 'star'; 
      }
    
    return {
      style,
      className
    }
  }

  if (!(calendarEventTypes && districts && eventsList && appearance)) return null

  return (
    <Grid container sx={{ minHeight: '85vh', mt: 2, minWidth: '1000px', maxWidth: '95%', pb: 2, ml: 1 }}>
      <Grid
        item
        xs={3}
        md={3}
        sx={{
          backgroundColor: '#FF9215',
          minHeight: '89%',
          borderRadius: '2px',
          borderRight: '2px solid rgba(0, 0, 0, 0.23)',
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: 'white', mt: 2, mx: 1, p: 0.8, borderTop: '1px solid white', borderBottom: '1px solid white' }}
        >
          {dayjs(currentDay).format('MMMM YYYY')}
        </Typography>
        <CalendarEventList events={calendar} districts={districts} campuses={campuses} />
      </Grid>
      <Grid item xs={9} md={9}>
        <Stack sx={{ height: '100%' }}>
          <Stack
            sx={{ border: '1px solid #000000', ml: 1, p: 0 }}
            justifyContent="flex-start"
            alignItems={'center'}
            flexDirection={'row'}
          >
            <Button onClick={prevMonth}>
              <ChevronLeftIcon color="warning" sx={{ fontSize: 40 }} />
            </Button>
            <Typography variant="h5">{dayjs(currentDay).format('MMMM YYYY')}</Typography>
            <Button onClick={nextMonth}>
              <ChevronRightIcon color="warning" sx={{ fontSize: 40 }} />
            </Button>
          </Stack>
          <Stack sx={{ height: '100%', minHeight: '800px', pl: 1 }}>
            <Calendar
              className={`${appearance}-calendar`}
              localizer={localizer}
              events={eventsList}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%' }}
              components={{
                toolbar: () => null,
              }}
              defaultDate={new Date(currentDay.getFullYear(), currentDay.getMonth(), 1)}
              key={currentDay.getMonth()}
              eventPropGetter={eventPropGetter}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
