import { useParams } from 'react-router-dom'
import SettingsSchoolManagementFormCampus from './Campus'
import SettingsSchoolManagementFormDistrict from './District'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get as getDistrict } from 'store/settings/district'
import { get as getCampus } from 'store/settings/campus'
import { search as searchSchools } from 'store/settings/school'

export default function SettingsSchoolManagementFormIndex() {
  const dispatch = useDispatch()
  let schools = useSelector(state => state.settings.school.list)

  const { districtId, campusId } = useParams()

  const [districtData, setDistrictData] = useState()
  const [campusData, setCampusData] = useState()

  useEffect(() => {
    dispatch(searchSchools())
  }, [dispatch])

  useEffect(() => {
    if (+districtId && districtId !== 'new' && !campusId) {
      dispatch(getDistrict({ id: +districtId })).then(res => {
        setDistrictData(res.payload)
      })
    }
  }, [districtId])

  useEffect(() => {
    if (+campusId && campusId !== 'new' && +districtId && districtId !== 'new') {
      dispatch(getCampus({ id: +campusId })).then(res => {
        setCampusData(res.payload)
      })
    }
  }, [campusId])

  if (!(districtId && schools)) return null

  const schoolList = []

  schools.forEach(item => {
    schoolList.push({ ...item, schoolId: item.id })
  })

  if (districtId && !campusId)
    return (
      <SettingsSchoolManagementFormDistrict
        data={districtData}
        schools={schoolList?.filter(school => school?.district?.id === +districtId)}
      />
    )
  if (districtId && campusId)
    return (
      <SettingsSchoolManagementFormCampus
        data={campusData}
        schools={schoolList?.filter(school => school?.district?.id === +districtId)}
      />
    )
}
