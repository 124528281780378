import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'

export default function ContactsListSkeleton() {
  const titles = ['Primary Parent/Guardian', 'Secondary Parent/Guardian']
  return (
    <>
      {titles.map((item, index) => (
        <Box key={index} sx={{ p: 1, m: 2 }}>
          <PreviewCard title={item} size="large">
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={6}>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Relationship:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Full Name:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Username/Email:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Primary Phone:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Alternate Phone:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
                <Stack flexDirection={'row'} sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography>Preferred Lang:</Typography>
                  </Box>
                  <Box sx={{ width: '65%' }}>
                    <Skeleton variant="rounded" height={24} width={225} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </PreviewCard>
        </Box>
      ))}
    </>
  )
}
