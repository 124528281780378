import { Box, TextField } from '@mui/material'

export default function ReportSearchBar({ que, setQue }) {
  return (
    <Box sx={{ p: 1, pb: 3 }}>
      <TextField
        value={que}
        onChange={e => {
          setQue(e.target.value)
        }}
        size="small"
        fullWidth
        placeholder={'Search by name, description or tags'}
      />
    </Box>
  )
}
