import { useDispatch } from 'react-redux'
import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/grade-level-section'
import * as yup from 'yup'
import { useEffect } from 'react'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Header from 'components/Header'

export default function GradeLevelSectionEditForm({ gradeLevelSection }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    gradeLevelId: yup.string().required('Required'),
    gradeSection: yup.string().trim().required('Required'),
    seat: yup
      .number()
      .nullable()
      .transform(value => value || null),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { gradeLevelId: gradeLevelSection?.gradeLevel?.id },
  })

  useEffect(() => {
    reset(gradeLevelSection)
  }, [gradeLevelSection, reset])

  const onSubmit = async data => {
    const res = await dispatch(save(_.omit(data, 'gradeLevel')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/grade-level-section`}>
      <Card
        data-component="GradeLevelSectionEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Toolbar />{' '}
        <Header
          title={t('Grade Level Section Edit Form')}
          subheader={t('Grade Level Section create/edit')}
          small
          close
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={12}>
              <Controller
                name="gradeLevelId"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      size="small"
                      label={t('Grade Level')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={12}>
              <Controller
                name="gradeSection"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label={t('Grade Section')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={12}>
              <Controller
                name="seat"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Seat')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={!formState.isDirty}>
            {t('Submit')}
          </Button>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
