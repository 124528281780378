import { Paper } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { search } from 'store/report/reports'
import ReportSearchBar from './List/SearchBar'
import ReportList from './List/List'
import { debounce } from 'lodash'

// const baseURL = '/report'

export default function ReportView() {
  const dispatch = useDispatch()
  const { academicYear } = useSelector(state => state.session)

  const { list } = useSelector(state => state.report.reports)
  const [que, setQue] = useState('')

  useEffect(() => {
    list || dispatch(search())
  }, [])

  useEffect(() => {
    delayedSearch(que, searchReport)
  }, [que])

  useEffect(() => {
    dispatch(search())
  }, [academicYear?.id])

  const searchReport = async () => {
    if (que) {
      dispatch(search({ que }))
    } else {
      dispatch(search())
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  if (!list) return null

  return (
    <Paper sx={{ width: '100%', minHeight: '500px', p: 0.5, my: 2 }}>
      <ReportSearchBar que={que} setQue={setQue} searchReport={searchReport} delayedSearch={delayedSearch} />
      <ReportList list={list} />
    </Paper>
  )
}
