import { Grid, TextField, Toolbar, CardActions, Button, CardContent, Box } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import Header from 'components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/marking-period'
import { useTranslation } from 'react-i18next'

export default function MarkingPeriodEditForm({ markingPeriod }) {
  const navigate = useNavigate()
  const basePath = useBasePath()
  const dispatch = useDispatch()
  const { academicYearId, id } = useParams()
  const { t } = useTranslation()

  const academicYear = useSelector(state =>
    state.settings.academicYear.years.find(year => year.id.toString() === academicYearId),
  )

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    startDate: yup
      .date()
      .required('Required')
      .min(
        dayjs(academicYear.startDate),
        'Start date should be later than Academic Year Start Date and Last Marking Peiod End Date',
      )
      .max(dayjs(academicYear.endDate)),
    endDate: yup
      .date()
      .nullable()
      .min(yup.ref('startDate'), 'End date should be later than start date')
      .max(dayjs(academicYear.endDate)),
  })

  const { control, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: markingPeriod?.name || null,
      startDate: markingPeriod?.startDate || null,
      endDate: markingPeriod?.endDate || null,
    },
  })

  useEffect(() => {
    reset(markingPeriod)
  }, [markingPeriod, reset])

  const onSubmit = async (data, event) => {
    event.stopPropagation()
    if (data.startDate) {
      data.startDate = dayjs(data.startDate).format('YYYY-MM-DD')
    }
    if (data.endDate) {
      data.endDate = dayjs(data.endDate).format('YYYY-MM-DD')
    }

    const payload = { name: data.name, startDate: data.startDate, endDate: data.endDate, academicYearId }
    if (markingPeriod?.id) {
      const res = await dispatch(save({ ...payload, id }))
      if (res.payload) {
        navigate(basePath)
      }
    } else {
      const res = await dispatch(save(payload))
      if (res.payload) {
        navigate(basePath)
      }
    }
  }

  const onError = errors => console.error(errors)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
      <Toolbar />
      <Header title={t("Marking Period")} small close />
      <CardContent>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={markingPeriod?.name}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("Marking Period")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      value={value || ''}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <DatePicker
                      fullWidth
                      label={t("Start Date")}
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!fieldState.error,
                          helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                        },
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <DatePicker
                      fullWidth
                      label={t("End Date")}
                      value={field.value ? dayjs(field.value) : ''}
                      onChange={value => {
                        field.onChange(value)
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!fieldState.error,
                          helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                        },
                      }}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t("Cancel")}
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          {t("Submit")}
        </Button>
      </CardActions>
    </Box>
  )
}
