import { Box, Paper, Typography, IconButton } from '@mui/material'
import { DeleteIcon, EditIcon } from 'components/Icons'
import { NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { RiFileUploadLine } from 'react-icons/ri'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search, destroy } from 'store/settings/leave-request'
import dayjs from 'dayjs'
import { useRef, useState, useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import Confirm from 'components/Dialog'
import { setSearchLimit, setSearchOffset, setSearchQuery } from 'store/settings/leave-request'
import _ from 'lodash'

let id = 0
const handleUploadClick = (rowData, fileInputRef) => {
  console.log('Upload clicked for:', rowData)
  id = rowData.id
  fileInputRef.current.click()
}

const handleFile = async event => {
  const file = event.target.files[0]

  const formData = new FormData()
  formData.append('afile', file)
  formData.append('fileUploadedDate', dayjs().format())
  formData.append('id', id)
  const url = `/api/setting/leave-request/upload`
  const res = await window.fetch(url, { method: 'POST', body: formData })
  if (res.status !== 200) {
    enqueueSnackbar(res.statusText, { variant: 'error' })
  }
}

export default function RequestLeaveList({ leaveRequestList, selectedTeacher, academicYear, leaveRequestType }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const [confirm, setConfirm] = useState(false)

  const fileInputRef = useRef(null)
  const leave_request = useSelector(state => state.settings.leaveRequest)
  const { rows, query, count } = {
    rows: leave_request ? leave_request.list : [],
    query: leave_request ? leave_request.query : [],
    count: leave_request ? leave_request?.count : 0,
  }

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id }))
  }

  const handleSortModelChange = params => {
    if (params.length > 0) {
      const sortBy = params[0]?.field
      const sortOrder = params[0]?.sort
      dispatch(setSearchQuery({ ...query, sortBy, sortOrder }))
    }
  }

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const onPaginationModelChange = params => {
    if (params.pageSize !== paginationModel?.pageSize) {
      dispatch(setSearchLimit(params.pageSize))
      dispatch(setSearchOffset(0))
      setPaginationModel({ ...paginationModel, ...params, page: 0 })
    } else {
      dispatch(setSearchLimit(params.pageSize))
      dispatch(setSearchOffset(params.pageSize * params.page))
      setPaginationModel({ ...paginationModel, ...params })
    }
  }

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null && obj[key] !== '' ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  useEffect(
    () => {
      const personId = selectedTeacher?.personId
      if (personId) {
        const filters = {
          personId,
          academicYearProviderId: academicYear && academicYear !== 'All' ? academicYear : null,
          typeId: leaveRequestType && leaveRequestType !== 'All' ? leaveRequestType : null,
        }

        dispatch(search({ ...filterNullValues(query), ...filterNullValues(filters) }))
      }
    },
    [query?.limit, query?.offset, query?.sortBy, query?.sortOrder, selectedTeacher?.personId],
    academicYear,
    leaveRequestType,
  )

  const tableColumns = [
    {
      field: 'academicYear',
      headerName: t('Academic Year'),
      renderCell: params => params.row.academicYearProvider?.name || ' ',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'leaveType',
      headerName: t('Leave Type'),
      renderCell: params => params.row.leaveRequestType?.name,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'leaveDate',
      headerName: t('Leave Date'),
      renderCell: params => params.row.leaveDate,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'note',
      headerName: t('Notes'),
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'document',
      headerName: t('Document'),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: params => (
        <Box marginTop="15px">
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => handleUploadClick(params.row, fileInputRef)}
          >
            <RiFileUploadLine style={{ color: 'orange', fontSize: '1.25rem', marginRight: '8px' }} />
            <Typography style={{ color: 'orange' }}>Upload</Typography>
          </div>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFile} />
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => params.row.approvalStatusId,
    },
    {
      field: 'requestedDate',
      headerName: t('Request Date'),
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => dayjs.tz(params.row.leaveRequestedDate).format('M/D/YYYY h:mm A'),
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: params => (
        <Box>
          <IconButton component={NavLink} to={`${basePath}/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              id = params.row.id
              setConfirm(true)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ]

  if (!rows) return null
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <Box sx={{ p: 1 }}>
        <DataGrid
          // onCellClick={handleCellClick}
          rows={leaveRequestList}
          columns={tableColumns}
          rowCount={count}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={_.uniq([query?.limit, 10, 15, 20, 25, 50, 100])}
          page={query.offset / query.limit}
          pagination
          disableColumnMenu
          disableRowSelectionOnClick
          rowHeight={60}
          sx={{ height: query.limit * 61 + 100 }}
          slotProps={{
            row: {
              style: { cursor: 'pointer' },
            },
          }}
        />
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
      </Box>
    </Paper>
  )
}
