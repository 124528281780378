import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'setting-bell-schedule'
const initialState = {
  count: null,
  list: null,
}

export const search = createApiAction('setting/bell-schedule/search', client.search('/api/setting/bell-schedule'))
export const get = createApiAction('setting/bell-schedule/get', client.retrieve(`/api/setting/bell-schedule/:id`))
export const save = createApiAction('setting/bell-schedule/save', client.save('/api/setting/bell-schedule'))
export const destroy = createApiAction('setting/bell-schedule/delete', client.destroy('/api/setting/bell-schedule/:id'))

const update = (state, action) => {
  const found = state.list.find(item => item.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [action.payload, ...state.list]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
        state.count = action.payload.length
      })
      .addCase(get.fulfilled, update)
      .addCase(save.fulfilled, update)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
