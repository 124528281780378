import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import SchoolListItem from './ListItem'
import { useSelector } from 'react-redux'
import TableHeadCell from 'components/TableHeadCell'

export default function SchoolList() {
  const schools = useSelector(state => state.settings.school?.list)

  return (
    <Paper data-component="SchoolList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell></TableHeadCell>
                    <TableHeadCell>School Name</TableHeadCell>
                    <TableHeadCell>Address</TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schools?.map(item => {
                    return <SchoolListItem key={item.id || item.uid} school={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
