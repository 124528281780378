import { Fragment, useState } from 'react'
import { TextField, Grid, IconButton, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import RemoveIcon from '@mui/icons-material/HighlightOff'
import Confirm from 'components/Dialog'

export default function AddressEditForm({ path, control, item, index, ...props }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  async function remove(id, index, confirmed = false) {
    try {
      if (!id) {
        props.remove(index)
        return
      }
      if (!confirmed) {
        setOpen(true)
        return
      }
      setOpen(false)
      props.remove(index)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Grid item md={12} key={item?.id}>
      <Grid container spacing={2}>
        <Fragment key={item?.uuid}>
          {index > 0 && item?.type && (
            <Grid item xs={3} md={3}>
              <Controller
                name={`${path}.${index}.type`}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label={t('Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      <MenuItem value="home">{t('Home')}</MenuItem>
                      <MenuItem value="work">{t('Work')}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}
          <Grid item xs={6} md={index > 0 ? 9 : 6}>
            <Controller
              name={`${path}.${index}.street`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Street Address')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name={`${path}.${index}.line2`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Street Address 2')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name={`${path}.${index}.city`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('City')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Controller
              name={`${path}.${index}.state`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('State')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3} md={index > 0 ? 3 : 3}>
            <Controller
              name={`${path}.${index}.zip`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Postal Code')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          {index > 0 && (
            <Grid item xs={12} md={1}>
              <IconButton
                onClick={() => {
                  remove(item.id, index)
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          )}
        </Fragment>
      </Grid>
      <Confirm open={open} onClose={() => setOpen(false)} onConfirm={() => remove(item.id, index, true)} />
    </Grid>
  )
}
