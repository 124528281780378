import { debounce, omit } from 'lodash'
import { InputAdornment, Stack, TextField, MenuItem } from '@mui/material'
import { search } from 'store/settings/academic-year'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function SearchBar({ schools }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { query } = useSelector(state => state.academicYear)
  const [que, setQue] = useState(query.que || '')

  const schoolAtSession = useSelector(state => state.session.school)

  const [school, setSchool] = useState('')

  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...query, [key]: value, offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  useEffect(() => {
    setSchool(schoolAtSession?.id)
    applyFilter('schoolId', schoolAtSession?.id)
  }, [schools])

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  return (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search"
        value={que}
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('name', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />
      {!schoolAtSession && school && (
        <TextField
          select
          sx={{ width: '19em' }}
          size="small"
          label={t("School")}
          placeholder="School"
          value={query.schoolId}
          defaultValue={school}
          onChange={event => applyFilter('schoolId', event.target.value)}
        >
          {schools?.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Stack>
  )
}
