import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link, Avatar, Box } from '@mui/material'
import { DeleteIcon } from 'components/Icons'
import { EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/settings/district'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function DistrictListItem({ district }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: district.id }))
  }

  return (
    <TableRow hover data-id={district.id} data-name={district.name}>
      <TableCell sx={{ width: '4em' }}>
        {district?.file?.location ? (
          <Avatar sx={{ width: 48, height: 48 }} src={district?.file?.location} />
        ) : (
          <Box sx={{ width: 48, height: 48, borderRadius: '50%', backgroundColor: '#aaa' }} />
        )}
      </TableCell>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${district.id}`}>
          {district.name}
        </Link>
      </TableCell>
      <TableCell>
        {district.address?.street} {district.address?.city} {district.address?.state} {district.address?.zip}, {district.address?.country}
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${district.id}`} aria-label="edit">
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
