import { Route, Routes } from 'react-router-dom'
import CalendarPage from './Page'
import CalendarFormIndex from './Form'

export default function Calendar() {
  return (
    <Routes>
      <Route path="/" element={<CalendarPage />}>
        <Route path="/:eventId" element={<CalendarFormIndex />} />
        <Route path="/:eventId/*" element={<CalendarFormIndex />} />
      </Route>
    </Routes>
  )
}
