import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Box, Typography, Container, Avatar } from '@mui/material'
import SchoolIcon from '@mui/icons-material/School'

import { getSession, getMembership, setInitialized } from '../store/session'

function Splash({ children }) {
  const initialized = useSelector(state => state.session.initialized)
  const ref = useRef(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const initialize = async () => {
      if (initialized) return
      const qs = new URLSearchParams(window.location.search)
      const approve = qs.get('approve')
      if (approve) localStorage.setItem('approve', approve)

      const res = await dispatch(getSession())
      if (res.payload) {
        await dispatch(getMembership())
      }
      await dispatch(setInitialized(true))
    }

    ref.current = setTimeout(initialize, 500)
    return () => clearTimeout(ref.current)
  }, [initialized, dispatch])

  if (initialized) return children

  return (
    <Container component="main" maxWidth="xs">
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight="100vh">
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <SchoolIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Loading...')}
        </Typography>
      </Box>
    </Container>
  )
}

export default Splash
