import { Box, Link, Stack, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import palette from './CalendarColorPalette.json'

const baseUrl = '/setting/calendar'

export default function CalendarEventList({ events, districts, campuses }) {
  const schools = districts?.flatMap(client => client.client?.schools)
  const clients = districts?.flatMap(client => client.client)
  const calendarEventTypes = useSelector(state => state.lookup.calendarEventType)
  const sessionAppearance = useSelector(state => state.session.appearance)
  const appearance =
    sessionAppearance === '' || !sessionAppearance ? 'auto' : sessionAppearance === 'light' ? 'light' : 'dark'

  const mergeNames = (ids, sources) => {
    return ids
      .map(locationId => {
        const client = sources?.find(client => client?.id === locationId)
        return client ? (client.abbreviation || client.name) : ''
      })
      .join(', ')
  }

  const getLocations = (location, locationIds) => {
    if (location === 'district' && locationIds?.length === clients?.length) {
      return 'All Districts'
    } else if (location === 'district') {
      return mergeNames(locationIds, clients)
    } else if (location === 'school' && locationIds?.length === schools?.length) {
      return 'All Schools'
    } else if (location === 'school') {
      return mergeNames(locationIds, schools)
    } else if (location === 'campus' && locationIds?.length === campuses?.length) {
      return 'All Campuses'
    } else if (location === 'campus') {
      return mergeNames(locationIds, campuses)
    }
  }

  const eventColor = id => {
    const bgColor = calendarEventTypes?.find(item => item?.id === id)?.bgColor
    return palette[appearance][bgColor]
  }

  const getDayCount = (start, end) => {
    return dayjs(end).diff(dayjs(start), 'day')
  }

  return (
    <Box sx={{ p: 2 }}>
      {events.map(item => (
        <Stack key={item?.id} flexDirection={'column'}>
          <Link component={NavLink} underline="none" color="inherit" to={`${baseUrl}/${item?.id}`} sx={{ mb: 1 }}>
            <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{ pr: 1 }}>
              <Typography
                sx={{
                  borderRadius: '50%',
                  backgroundColor: eventColor(item.eventTypeId)?.text || 'white',
                  color: eventColor(item.eventTypeId)?.background || 'black',
                  width: '24px',
                  height: '24px',
                  lineHeight: '24px',
                  verticalAlign: 'center',
                  textAlign: 'center',
                  mt: 1,
                }}
              >
                {dayjs(item?.startDate).format('DD')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'white', pl: 1 }}>
                {item?.name}{' '}
                {getDayCount(item.startDate, item.endDate) > 0 &&
                  `(${getDayCount(item.startDate, item.endDate) + 1} Days)`}
              </Typography>
            </Stack>

            <Stack flexDirection={'row'}>
              <Typography variant="caption" sx={{ pl: 4.2, color: 'white' }}>
                {getLocations(item?.location, item?.locationIds)}
              </Typography>
            </Stack>
          </Link>
        </Stack>
      ))}
    </Box>
  )
}
