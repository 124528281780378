import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'

export default function FeildSkeleton() {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '65%',
        display: 'block',
        height: '24px',
        backgroundColor: theme.palette.action.hover,
        borderRadius: '5px',
      }}
    />
  )
}
