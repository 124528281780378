import { Grid, Paper, Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import ChangePassword from '../../../components/ChangePassword'
import MergeUser from '../../../components/MergeUser'
import Memberships from '../../../components/Widgets/Memberships'
import Clients from '../../../components/Widgets/Clients'

import Title from '../../../components/Title'
import { Fragment } from 'react'

import ProfilePicture from './ProfilePicture'
import PageHeader from 'components/AppBar/PageHeader'

function Profile() {
  const session = useSelector(state => state.session)
  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <PageHeader title={'Proile'}/>
      </Box>      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper>
            <Box sx={{ p: 2, mb: 2, textAlign: 'center' }}>
              <ProfilePicture />
              <Typography>{[session.user.firstName, session.user.lastName].join(' ')}</Typography>
            </Box>
          </Paper>
          <Paper sx={{ mb: 2 }}>
            <ChangePassword />
          </Paper>
          <Paper sx={{ mb: 2 }}>
            <MergeUser />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box>
              <Title>Personal Information</Title>
              <Typography>Personal info comes here!</Typography>
              <Typography>Personal info comes here!</Typography>
              <Typography>Personal info comes here!</Typography>
              <Typography>Personal info comes here!</Typography>
            </Box>
          </Paper>
          <Memberships />
          <Clients />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Profile
