import { Route, Routes } from 'react-router-dom'
import RoomsPage from './Page'

export default function Rooms() {
  return (
    <Routes>
      <Route path="/" element={<RoomsPage />}/>
      <Route path="/:id" element={<RoomsPage />}/>
    </Routes>
  )
}
