import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import TableHeadCell from '../../../components/TableHeadCell'
import CampusItem from './CampusItem'

export default function CampusList() {
  const campuses = useSelector(state => state.settings.campus.list)

  return (
    <Paper data-component="CampusList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Campus Name</TableHeadCell>
                    <TableHeadCell>School</TableHeadCell>
                    <TableHeadCell>Address</TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campuses.map(item => {
                    return <CampusItem key={item.id || item.uid} campus={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
