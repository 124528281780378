import { Card, Grid, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { SubmitButton } from 'components/Buttons'
import { SaveSharp } from 'components/Icons'
import { save } from 'store/settings/grading-coefficient'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const initialValues = {
  ap: null,
  unweighted: null,
  collegePrep: null,
  honors: null,
  minGrade: null,
}

export default function GradingFormCoefficient({ data }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const basePath = '/settings_/grading/'

  const schema = yup.object().shape({
    ap: yup.number().nullable().typeError('AP must be a number').required('AP is required'),
    unweighted: yup.number().nullable().typeError('Unweighted must be a number').required('Unweighted is required'),
    collegePrep: yup
      .number()
      .nullable()
      .typeError('College Prep must be a number')
      .required('College Prep is required'),
    honors: yup.number().nullable().typeError('Honors must be a number').required('Honors is required'),
    minGrade: yup
      .number()
      .nullable()
      .integer('Min Grade must be an integer')
      .typeError('Min Grade must be a number')
      .required('Min Grade is required'),
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data ? { ...data } : { ...initialValues },
  })

  const onSubmit = async formData => {
    const payload = {
      ap: +formData?.ap,
      unweighted: +formData?.unweighted,
      collegePrep: +formData?.collegePrep,
      honors: +formData?.honors,
      minGrade: +formData?.minGrade,
    }
    if (data?.id) {
      payload.id = data?.id
    }

    const res = await dispatch(save({ ...payload }))

    if (res.payload) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  useEffect(() => {
    reset(data)
  }, [data])

  return (
    <BasePathProvider value={`${basePath}`}>
      <Card
        data-component="GradingEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Header title={t('Edit Grading and Weighted GPA')} small close borderBottom />
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid item xs={6} md={6}>
            <Controller
              name="ap"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('AP Course Coefficient')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="honors"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Honors Course Coefficient')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="collegePrep"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('College Prep. Course Coefficient')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="unweighted"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Unweighted Course Coefficient')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="minGrade"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Minimum Failing Grade')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
        <Stack sx={{ mr: 3 }} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <SubmitButton
            size="small"
            type="submit"
            title={t('Save')}
            color="warning"
            icon={<SaveSharp />}
            onClick={handleSubmit(onSubmit, onErrors)}
          />
        </Stack>
      </Card>
    </BasePathProvider>
  )
}
