import { useState } from 'react'

import { useDispatch } from 'react-redux'

import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTranslation } from 'react-i18next'

import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { forgotPassword } from '../../store/session'

import MyAlert from '../../components/Alert'

export default function RegisterForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({ open: false, status: '' })

  const schema = yup
    .object()
    .shape({
      email: yup.string().email().required(),
    })
    .required()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async data => {
    const t = state.open ? 500 : 0
    const sleep = ms => new Promise(r => setTimeout(r, ms))
    setState(prev => ({ ...prev, open: false }))
    const res = await dispatch(forgotPassword(data))
    await sleep(t)
    if (res.error) {
      setState({ open: true, status: 'error' })
    } else {
      setState({ open: true, status: 'success' })
    }
  }

  const SUCCESS = 'Please check your mailbox to continue to reset your password.'
  const ERROR = 'Please try again later, something went wrong!'

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <Box
            sx={{
              mt: 4,
              margin: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('Reset Password')}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    sx={{ mb: 2 }}
                    fullWidth
                    margin="normal"
                    label={t("E-Mail Address")}
                    autoComplete="off"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
            <MyAlert open={state.open} status={state.status} title={state.status === 'success' ? SUCCESS : ERROR} />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              // sx={{ mt: 0, mb: 0 }}
            >
              {t('Send Reset Link')}
            </Button>
          </Box>
        </CardContent>
        <CardActions sx={{ margin: 1 }}>
          <Grid container>
            <Grid item xs>
              <Link to="/auth/login">{t('Remember your password?')}</Link>
            </Grid>
            <Grid item>
              <Link to="/auth/register">{t('Register')}</Link>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  )
}
