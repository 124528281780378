import { useEffect, useState } from 'react'
import { Box, Drawer, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import ScheduleItem from './ScheduleItem'
import { useNavigate, useParams } from 'react-router-dom'
import ScheduleEditForm from './ScheduleEditForm'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { get } from 'store/settings/course-session-period'
import { useDispatch } from 'react-redux'

export default function ScheduleList({ courseSessionPeriods, bellSchedulePeriods, roomList }) {
  const { courseSessionId, scheduleId: id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [schedule, setSchedule] = useState()

  const baseUrl = '/settings/course-session/' + courseSessionId

  useEffect(() => {
    if (+id) {
      dispatch(get({ courseSessionId, id })).then(res => {
        setSchedule(res.payload)
      })
    }
    if (id === 'new') {
      setSchedule({ bellSchedulePeriodId: null, buildingRoomId: null })
    } else setSchedule(null)
  }, [courseSessionId, dispatch, id])

  return (
    <BasePathProvider value={baseUrl + '/schedule'}>
      <Box sx={{ pt: 0, pb: 0, pl: 2, pr: 2 }}>
        <Header title={'Schedules'} sx={{ p: 0 }} small add />
        <Paper variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableHeadCell>Day of Week</TableHeadCell>
                <TableHeadCell>Start Time</TableHeadCell>
                <TableHeadCell>End Time</TableHeadCell>
                <TableHeadCell>Room</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {courseSessionPeriods?.map(item => {
                  return <ScheduleItem key={item?.id} item={item} />
                })}
              </TableBody>
            }
          </Table>
        </Paper>
        <Drawer
          PaperProps={{
            sx: {
              width: 400,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(`${baseUrl}`)}
        >
          {roomList && bellSchedulePeriods && schedule && (
            <ScheduleEditForm schedule={schedule} bellSchedulePeriods={bellSchedulePeriods} roomList={roomList} />
          )}
        </Drawer>
      </Box>
    </BasePathProvider>
  )
}
