import { useEffect, useState } from 'react'
import { search as searchFinalGrade } from 'store/academic/final-grades'
import Header from 'components/Header'
import { BasePathProvider, useBasePath, useSession } from 'components/Providers'
import { useParams } from 'react-router-dom'
import FinalGradeList from './FinalGradeList'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { LinearProgress } from '@mui/material'
import NotFound from 'components/NotFound'

export default function FinalGrade() {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { academicYear } = useSession()
  const { courseSessionId } = useParams()
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    courseSessionId &&
      dispatch(searchFinalGrade({ courseSessionId })).then(res => {
        res.error && setNotFound(true)
      })
  }, [courseSessionId, dispatch])

  const finalGrades = useSelector(state => state.academic.finalGrades.list)

  const markingPeriods = academicYear.markingPeriods

  if (notFound) return <NotFound basePath={basePath} />

  if (!finalGrades?.length || !markingPeriods?.length) return <LinearProgress sx={{ m: 2 }} color="success" />

  return (
    <BasePathProvider value={`${basePath}/final-grade`}>
      <Header title={'Final Grade'} small />
      <Typography sx={{ marginLeft: '15px' }} gutterBottom variant={'h7'}>
        {`Number of registered students : ${finalGrades?.length}`}
      </Typography>
      {finalGrades && markingPeriods && <FinalGradeList datas={finalGrades} markingPeriods={markingPeriods} />}
    </BasePathProvider>
  )
}
