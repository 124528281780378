import { Box } from '@mui/material'
import jsPDF from 'jspdf'
import { useEffect, useState } from 'react'
import JsBarcode from 'jsbarcode'
import _ from 'lodash'

const PDFViewer = ({ pdfUrl }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <iframe src={pdfUrl} width="100%" height="100%" style={{ border: 'none' }} />
    </Box>
  )
}

const replaceVariables = async (text, data) => {
  const compiled = _.template(text)
  const updatedString = compiled(data)
  let parsedJson
  try {
    parsedJson = JSON.parse(updatedString)
  } catch (error) {
    console.error('JSON parse hatası:', error)
    return null
  }

  return parsedJson
}

export default function ReportExportPDF({ data: details, exportNow }) {
  const rotateImage = (image, angle) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = image.height
    canvas.height = image.width

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((angle * Math.PI) / 180)
    ctx.drawImage(image, -image.width / 2, -image.height / 2)

    return canvas.toDataURL('image/png')
  }

  const generateBarcode = studentId => {
    if (studentId) {
      const canvas = document.createElement('canvas')
      JsBarcode(canvas, studentId, {
        format: 'CODE128',
        displayValue: false,
        fontSize: 18,
        width: 2,
        height: 60,
      })
      return canvas
    }
  }

  const imageProcess = async url => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        const reader = new FileReader()
        reader.onloadend = function () {
          resolve(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.onerror = function () {
        reject(new Error('Failed to load image'))
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    })
  }

  const generatePDF = async template => {
    const doc = new jsPDF({
      orientation: template.page.orientation.toLowerCase(),
      unit: 'mm',
      format: [template.page.height, template.page.width],
      dpi: 300,
    })

    doc.setFont(template.font.name, template.font.encoding)
    doc.setFontSize(template.font.size)

    await Promise.all(
      template.elements.map(async element => {
        if (element.type === 'text') {
          doc.setFontSize(element.fontSize || template.font.size)
          doc.setFont(...(element.fontName || template.font.name))

          if (element?.text?.length > 9) {
            doc.setFontSize(element?.fontSizeSmall || element.fontSize || template.font.size)
          }

          if (element.color) {
            doc.setTextColor(element.color.r, element.color.g, element.color.b)
          }
          let text = null
          if (element.textUpperCase) {
            text = element.text.toUpperCase()
          } else {
            text = element.text
          }

          if (!element.rotation) {
            if (element.center) {
              const textWidth = doc.getTextWidth(text)
              const pageWidth = doc.internal.pageSize.width
              const centerX = (pageWidth - textWidth) / 2
              doc.text(text, centerX, element.y)
            } else {
              doc.text(text, element.x, element.y)
            }
          } else {
            doc.text(element.x, element.y, text, null, element.rotation)
          }
        }
        if (element.type === 'barcode') {
          const barcodeCanvas = generateBarcode(element?.text)
          const barcodeImage = barcodeCanvas?.toDataURL('image/png')

          const rotatedBarcode = await new Promise(resolve => {
            const img = new Image()
            img.onload = () => {
              resolve(rotateImage(img, 90))
            }
            img.src = barcodeImage
          })

          doc.addImage(rotatedBarcode, 'PNG', element.x, element.y, element.w, element.h)
        }
        if (element.type === 'image') {
          if (element?.location) {
            const image = await imageProcess(element?.location)
            doc.addImage(image, 'PNG', element.x, element.y, element.w, element.h)
          }
        }
        if (element.type === 'rect') {
          const { x, y, w, h, color } = element
          const { r, g, b } = color
          doc.setFillColor(r, g, b)
          doc.rect(x, y, w, h, 'F')
        }
      }),
    )

    const pdfBlob = doc.output('blob')

    const pdfUrl = URL.createObjectURL(pdfBlob)

    if (exportNow === true) {
      window.open(pdfUrl, '_blank')
    } else {
      return pdfUrl
    }
  }

  const [pdfUrl, setPdfUrl] = useState(null)

  useEffect(() => {
    const fetchPDF = async () => {
      const template = await replaceVariables(details?.template, details?.result[0])
      const url = await generatePDF(template)
      setPdfUrl(url)
    }
    if (details && details?.template && details?.result[0]) {
      fetchPDF()
    }
  }, [])

  if (!details || !details?.template || !details?.result[0]) return null

  return !exportNow && <Box sx={{ height: '100vh' }}>{pdfUrl ? <PDFViewer pdfUrl={pdfUrl} /> : 'Loading PDF...'}</Box>
}
