import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import SchedulingEnrollStudentListItem from './ListItem'

const heads = ['Course Name', 'Session Name', 'Teacher', 'Term', 'Enrollment', 'Action']
export default function SchedulingEnrollStudentList({ activeCourseSessionEnrollments, destroyEnrollment }) {
  const { t } = useTranslation()

  return (
    <Table sx={{ mt: 1 }}>
      <TableHead>
        <TableRow>
          {heads.map((item, index) => (
            <TableHeadCell key={index} sx={{ fontWeight: '500 !important' }}>
              {t(item)}
            </TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {activeCourseSessionEnrollments &&
          activeCourseSessionEnrollments.map((item, index) => (
            <SchedulingEnrollStudentListItem key={index} item={item} destroyEnrollment={destroyEnrollment} />
          ))}
      </TableBody>
    </Table>
  )
}
