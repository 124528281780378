import { Grid } from '@mui/material'
import RequestCard from './RequestCard'

const mockData = [
  { name: 'Sick Days', remaining: '15', rolledOver: '5', used: '0', icon: 'FaHospitalUser' },
  { name: 'Personal Days', remaining: '2', rolledOver: '0', used: '0', icon: 'FaBiking' },
  { name: 'Vacation Days', remaining: '2', rolledOver: '0', used: '0', icon: 'FaUmbrellaBeach' },
  { name: 'Wellness Days', remaining: '1', rolledOver: '0', used: '0', icon: 'FaUmbrella' },
  { name: 'Unpaid Days', remaining: '0', rolledOver: '0', used: '0', icon: 'FaHandHoldingUsd' },
]
export default function RequestPresentation() {
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {mockData.map((item, index) => (
        <Grid key={index} item xs={6} sm={6} md={4} lg={3} xl={2.4}>
          <RequestCard data={item} />
        </Grid>
      ))}
    </Grid>
  )
}
