import { IconButton, Stack, TableCell, TableRow } from '@mui/material'
import Confirm from 'components/Dialog'
import { DeleteIcon, EditIcon } from 'components/Icons'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { destroy } from 'store/settings/district'

const basePath = '/setting/school-management'

export default function SettingsSchoolManagementListItem({ item, selected, setSelected }) {
  const dispatch = useDispatch()

  const [confirm, setConfirm] = useState(false)

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item?.id }))
  }

  return (
    <TableRow
      hover
      sx={{
        backgroundColor: selected === item?.id && 'rgba(100, 100, 100, 0.08)',
        '&:hover': { cursor: 'pointer' },
      }}
      onClick={() => setSelected(item.id)}
    >
      <TableCell>{item?.countyCode}</TableCell>
      <TableCell>{item?.name}</TableCell>
      <TableCell>{item?.abbreviation}</TableCell>
      <TableCell>
        <Stack flexDirection={'row'}>
          <IconButton component={NavLink} to={`${basePath}/district/${item.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setConfirm(true)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
