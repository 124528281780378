import { Card, CardContent, CardHeader, Grid, Skeleton, Stack, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function DistrictEditFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Card sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Stack flexDirection={'row'} sx={{ m: 1 }}>
        <Skeleton variant="circular" width={80} height={80} />
        <CardHeader title={t("District")} subheader="District create/edit" />
      </Stack>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
