import { TableRow, TableCell, Link } from '@mui/material'
import { Link as NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function UnScheduledCourseSessionEnrollments({ item }) {
  const permission = useSelector(state => state.session.permissions)

  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  return (
    <TableRow hover>
      <TableCell>
        {hasCourseSessionViewPermission ? (
          <Link
            component={NavLink}
            to={`/settings/course-session/${item.courseSession.id}`}
            underline="hover"
            color="inherit"
          >
            {item.courseSession?.name}
          </Link>
        ) : (
          <> {item.courseSession?.name} </>
        )}
      </TableCell>
      <TableCell>
        {hasCourseSessionViewPermission ? (
          <Link
            component={NavLink}
            to={`/settings/course-session/${item.courseSession.id}`}
            underline="hover"
            color="inherit"
          >
            {item.courseSession?.course?.name}
          </Link>
        ) : (
          <>{item.courseSession?.course?.name}</>
        )}
      </TableCell>
    </TableRow>
  )
}
