import { CardContent, Grid, Table, TableBody, TableHead, TableRow, TablePagination } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import AcademicYearItem from './Item'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/provider/academic-year'
import _ from 'lodash'

export default function AcademicYearList() {
  const dispatch = useDispatch()

  const { query, rows, count } = useSelector(state => state.provider.academicYear)

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell>Type</TableHeadCell>
                <TableHeadCell>Start Date</TableHeadCell>
                <TableHeadCell>End Date</TableHeadCell>
                <TableHeadCell>Actions</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(item => {
                return <AcademicYearItem key={item.id || item.uid} item={item} />
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={count || 0}
            page={query?.limit ? query?.offset / query?.limit : 0}
            onPageChange={handleChangePage}
            rowsPerPage={query?.limit || 10}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={_.uniq([query?.limit || 0, 10, 15, 20, 25, 50, 100])}
          />
        </CardContent>
      </Grid>
    </Grid>
  )
}
