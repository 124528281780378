import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search, get } from 'store/settings/bell-schedule'
import { getBuilding } from 'store/lookup'
import BellScheduleList from './BellScheduleList'
import BellScheduleEditForm from './BellScheduleEditForm'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'

const baseUrl = '/settings/bell-schedule'

export default function BellSchedule() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const bellSchedules = useSelector(state => state.settings.bellSchedule.list)
  const buildings = useSelector(state => state.lookup.building)

  const { bellScheduleId: id } = useParams()
  const [bellSchedule, setBellSchedule] = useState(null)

  useEffect(() => {
    bellSchedules || dispatch(search())
  }, [dispatch, Boolean(bellSchedules)])

  useEffect(() => {
    buildings || dispatch(getBuilding())
  }, [dispatch, Boolean(buildings)])

  useEffect(() => {
    if (id === 'new') {
      setBellSchedule({})
      return
    }
    if (+id) {
      dispatch(get({ id })).then(res => setBellSchedule(res.payload))
      return
    }
    setBellSchedule(null)
  }, [dispatch, id])

  return (
    <BasePathProvider value={baseUrl}>
      <Header title={'Bell Schedule'} add />
      {bellSchedules && <BellScheduleList bellSchedules={bellSchedules} />}
      <Drawer
        PaperProps={{
          sx: {
            width: 1000,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        {buildings && bellSchedule && <BellScheduleEditForm buildings={buildings} bellSchedule={bellSchedule} />}
      </Drawer>
    </BasePathProvider>
  )
}
