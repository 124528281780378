import DailyAttendanceForm from '../../Utilities/Form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get as getStudentAttendance } from 'store/settings/attendance'
import { useEffect, useState } from 'react'

export default function StudentAttendanceFormIndex() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const basePath = `/settings/attendance/student/`

  const selected = useSelector(state => state.settings.attendance?.selected)
  const [searchParams] = useSearchParams()
  const schoolEnrollmentId = searchParams.get('schoolEnrollmentId')
  const dateParams = searchParams.get('date')

  useEffect(() => {
    if (parseInt(id)) {
      dispatch(getStudentAttendance({ id })).then(() => {
        setIsReady(true)
      })
    } else if (id === 'new') {
      dispatch(
        getStudentAttendance({
          id,
          schoolEnrollmentId: parseInt(schoolEnrollmentId),
          date: dateParams,
        }),
      ).then(() => {
        setIsReady(true)
      })
    }
  }, [id])

  if (!selected) return null
  if (isReady) {
    return <DailyAttendanceForm basePath={basePath} selected={selected} />
  }
}
