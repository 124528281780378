import { combineReducers } from '@reduxjs/toolkit'

import reportReducer from './reports'
import stepsReducer from './steps'

const combinedReducer = combineReducers({
  reports: reportReducer,
  steps: stepsReducer,
})

export default combinedReducer
