import { useParams } from 'react-router-dom'
import ContactsList from './List'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguages } from 'store/lookup'
import { useEffect } from 'react'

export default function ContactsListIndex() {
  const { personId } = useParams()
  const dispatch = useDispatch()

  const personContacts = useSelector(state => state.person2.contacts)[+personId]

  const languages = useSelector(state => state.lookup.languages)

  useEffect(() => {
    languages || dispatch(getLanguages())
  }, [dispatch])

  if(!languages) return null

  return <ContactsList data={personContacts} />
}
