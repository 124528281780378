import { useDispatch, useSelector } from 'react-redux'
import EnrollmentsListIndex from './List'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { getCampus } from 'store/lookup'
import { search as getEnrollments } from 'store/person/enrollment'
import EnrollmentsFormIndex from './Form'
import { BasePathProvider } from 'components/Providers'
import { Drawer } from '@mui/material'

export default function EnrollmentsPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { personId, enrollmentId } = useParams()
  const enrollments = useSelector(state => state.person2?.enrollments && state.person2.enrollments[+personId])
  const campuses = useSelector(state => state.lookup.campus)
  const basePath = `/person/${personId}/campus/`

  useEffect(() => {
    enrollments || dispatch(getEnrollments({ personId: +personId }))
    campuses || dispatch(getCampus())
  }, [dispatch, personId])

  if (!(campuses && enrollments)) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <EnrollmentsListIndex enrollments={enrollments} campuses={campuses} />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(enrollmentId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <EnrollmentsFormIndex enrollments={enrollments} campuses={campuses} />
      </Drawer>
    </BasePathProvider>
  )
}
