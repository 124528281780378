import { Box } from '@mui/material'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import Profile from './Profile'
import Role from './Role'
import AcademicYear from './AcademicYear'
import Course from './Course'
import CourseSession from './CourseSession'
import District from './District'
import Campus from './Campus'
import School from './School'
import Building from './Building'
import BellSchedule from './BellSchedule'
import Period from '../Settings_/Periods'
import GradingScale from './GradingScale'
import GradeLevelSection from './GradeLevelSection/Page'
import JobPosition from './JobPosition/Page'
import ProviderAcademicYear from './AcademicYearProvider'
import Scheduling from './Scheduling'
import SchoolAttendance from './Attendance'
import LeaveRequest from './LeaveRequest'

function Slot() {
  return (
    <Box maxWidth={'100%'}>
      <Outlet />
    </Box>
  )
}

export default function MainPage() {
  return (
    <Box >
      <Routes>
        <Route path="/" element={<Slot />}>
          <Route path="profile" element={<Profile />} />
          {/*<Route path="district" element={<Profile />} /> */}
          <Route path="permission" element={<Role />} />
          <Route path="permission/:id" element={<Role />} />
          <Route path="academic-year/*" element={<AcademicYear />} />
          <Route path="course/*" element={<Course />} />
          <Route path="course-session" element={<CourseSession />} />
          <Route path="course-session/:courseSessionId" element={<CourseSession />} />
          <Route path="course-session/:courseSessionId/schedule/:scheduleId" element={<CourseSession />} />
          <Route path="course-session/:courseSessionId/enrollment/:enrollmentId" element={<CourseSession />} />
          <Route path="district/*" element={<District />} />
          <Route path="campus" element={<Campus />} />
          <Route path="campus/:id" element={<Campus />} />
          <Route path="school/*" element={<School />} />
          <Route path="building/*" element={<Building />} />
          <Route path="building/:buildingId/*" element={<Building />} />
          <Route path="bell-schedule/*" element={<BellSchedule />} />
          <Route path="bell-schedule/:bellScheduleId/*" element={<BellSchedule />} />
          <Route path="period/*" element={<Period />} />
          <Route path="period/:id/*" element={<Period />} />
          <Route path="grading-scale/*" element={<GradingScale />} />
          <Route path="grade-level-section/*" element={<GradeLevelSection />} />
          <Route path="grade-level-section/:id/*" element={<GradeLevelSection />} />
          <Route path="job-position/*" element={<JobPosition />} />
          <Route path="job-position/:id/*" element={<JobPosition />} />
          <Route path="provider/academic-year/*" element={<ProviderAcademicYear />} />
          <Route path="scheduling/*" element={<Scheduling />} />
          <Route path="attendance/*" element={<SchoolAttendance />} />
          <Route path="leave-request/*" element={<LeaveRequest />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Box>
  )
}
