import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useMember } from 'components/Providers'
import { search } from 'store/academic/student/attendance'
import Attendance from 'components/Attendance'

export default function Index() {
  const dispatch = useDispatch()
  const member = useMember()

  const attendanceReports = useSelector(state => state.academic.student.detail.attendance)

  useEffect(() => {
    attendanceReports || dispatch(search({ memberId: member.id }))
  }, [dispatch, member?.id, attendanceReports])

  let absenceRate =
    (attendanceReports?.daily?.absent * 100) /
    (attendanceReports?.daily?.absent + attendanceReports?.daily?.tardy + attendanceReports?.daily?.present)

  absenceRate = isNaN(absenceRate) || !isFinite(absenceRate) ? '-' : absenceRate

  if (!member) return null

  return <Attendance absenceRate={absenceRate} attendanceReports={attendanceReports} />
}
