import {
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import Header from 'components/Header'

export default function AcademicYearSkeleton() {
  return (
    <Box>
      <Header title={'Marking Period List'} small />
      <Paper data-component="MarkingPeriodsList" sx={{ minHeight: '50%' }}>
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ minHeight: '600px' }}>
              <CardContent sx={{ pl: 0, pr: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell sx={{ width: '18em' }}>Marking Periods Name</TableHeadCell>
                      <TableHeadCell sx={{ width: '15em' }}>Start Date</TableHeadCell>
                      <TableHeadCell sx={{ width: '15em' }}>End Date</TableHeadCell>
                      <TableHeadCell sx={{ width: '10em' }}></TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[0, 1, 2].map(item => (
                      <TableRow key={item}>
                        <TableCell sx={{ width: '4em' }}>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
