import { Box, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import UnScheduledCourseSessionEnrollmentItem from './UnScheduledCourseSessionEnrollmentItem'

export default function UnScheduledCourseSessionEnrollments({ unScheduledCourseSessionEnrollments }) {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>{t('Course Name')}</TableHeadCell>
                <TableHeadCell> {t('Course Session Name')} </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unScheduledCourseSessionEnrollments?.map(item => {
                return <UnScheduledCourseSessionEnrollmentItem key={item.id} item={item} />
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  )
}
