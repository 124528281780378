import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import SettingsSchoolManagementListIndex from './List'
import SettingsSchoolManagementFormIndex from './Form'
import { Drawer } from '@mui/material'

export default function SettingsSchoolManagementPage() {
  const navigate = useNavigate()
  const basePath = `/setting/school-management/`
  const { districtId, campusId } = useParams()

  return (
    <BasePathProvider value={`${basePath}`}>
      <SettingsSchoolManagementListIndex />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(districtId) || Boolean(campusId)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <SettingsSchoolManagementFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
