import _ from 'lodash'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/contact'
export const search = createApiAction('member/contact/search', client.search(basePath))
export const retrieve = createApiAction('member/contact/get', client.retrieve(`${basePath}/:id`))
export const save = createApiAction('member/contact/save', client.save(basePath))
export const destroy = createApiAction('member/contact/destroy', client.destroy(`${basePath}/:id`))
export const saveSequences = createApiAction('member/contact/re-order', client.save(`${basePath}/re-order`))

export default function setContactReducers(builder) {
  builder
    .addCase(search.fulfilled, (state, action) => {
      const list = state.list || []
      const { memberId } = action.meta.arg
      const found = list.find(item => item.id === memberId)
      const replace = _.extend({ id: memberId }, found, { contacts: action.payload })
      if (found) state.list = list.map(item => (item.id === found.id ? replace : item))
      else state.list = [...state.list, replace]
    })
    .addCase(save.fulfilled, (state, action) => {
      const list = state.list || []
      const { memberId } = action.meta.arg
      const { id } = action.meta.arg
      const data = _.omit(action.payload, ['personId', 'contactId'])
      let foundMember = list.find(item => item.id === memberId)
      const foundMemberIndex = list.indexOf(foundMember)
      if (!foundMember.contacts) {
        state.list[foundMemberIndex].contacts = [{ ...data }]
      } else {
        let contact = foundMember.contacts.find(item => item.id === id)
        const contactIndex = foundMember.contacts.indexOf(contact)
        if (contactIndex === -1) {
          state.list[foundMemberIndex].contacts = [...foundMember.contacts, { ...data }]
        } else {
          state.list[foundMemberIndex].contacts[contactIndex] = data
        }
      }
    })
    .addCase(destroy.fulfilled, (state, action) => {
      const list = state.list || []
      const { memberId } = action.meta.arg
      const { id } = action.meta.arg
      let found = list.find(item => item.id === memberId)
      const foundIndex = list.indexOf(found)
      found.contacts = found.contacts.filter(item => item.id !== id)
      state.list[foundIndex] = found
    })
    .addCase(retrieve.fulfilled, (state, action) => {
      const list = state.list || []
      const { memberId } = action.meta.arg
      const id = action.meta.arg.id
      let found = list.find(item => item.id === memberId)
      const foundIndex = list.indexOf(found)
      let contact = found.contacts.find(item => item.id === id)
      const contactIndex = found.contacts.indexOf(contact)
      state.list[foundIndex].contacts[contactIndex] = action.payload
    })
    .addCase(saveSequences.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        const list = state.list || []
        const { memberId } = action.meta.arg
        const { newSequences } = action.meta.arg
        // TODO: what if it is null
        let found = list.find(item => item.id === memberId)
        const foundIndex = list.indexOf(found)
        const temp = []
        found.contacts.forEach(item => {
          if (!item.isGuardian) {
            temp.push({ ...item, seq: newSequences.find(ns => ns.id === item.id)?.seq })
          } else {
            temp.push(item)
          }
        })
        temp.sort((a, b) => a.seq - b.seq)
        state.list[foundIndex].contacts = temp
      }
    })
}
