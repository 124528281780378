import { useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Toolbar,
  FormControl,
  FormControlLabel,
  Switch,
  MenuItem,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { save } from 'store/settings/course'
import { useSession } from 'components/Providers'
import Header from 'components/Header'
import { omit, defaults } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function CourseEditForm({ course, subjects, gradeLevels, gradingMethods }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const session = useSession()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    subjectId: yup.number().required('Required'),
    name: yup.string().required('Required'),
    gradeLevelId: yup.string().nullable(),
    gradingMethodId: yup.string().nullable(),
    credit: yup
      .number()
      .nullable()
      .transform(value => value || null),
    code: yup
      .string()
      .nullable()
      .transform(value => value || null),
    enabled: yup.boolean().nullable().default(true),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaults(omit(course, 'courseSubject', 'gradeLevel', 'gradingMethod'), {
      subjectId: course.courseSubject?.id || '',
      gradeLevelId: course.gradeLevel?.id || '',
      gradingMethodId: course.gradingMethod?.id || '',
      name: course.name || '',
      credit: course.credit || '',
      code: course.code || '',
    }),
  })

  const onSubmit = data => {
    dispatch(save(omit(data, 'districtId'))).then(res => {
      if (res.payload) navigate(`${basePath}/..`)
    })
  }

  const onError = errors => console.error(errors)

  return (
    <Card component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Header title={t("Course")} close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6}>
            <Controller
              name="subjectId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    select
                    size="small"
                    label={t("Subject area")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {subjects?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Course name")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Controller
              name="gradeLevelId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label={t("Grade levels")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {gradeLevels?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Controller
              name="gradingMethodId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label={t("Grading methods")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {gradingMethods?.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Controller
              name="credit"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("credit")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="code"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("Course code")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="enabled"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={e => {
                            field.onChange(e.target.checked)
                          }}
                          name="enabled"
                        />
                      }
                      label={t("Enabled")}
                      labelPlacement="start"
                    />
                  </FormControl>
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(`${basePath}/..`)}>
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          disabled={!formState.isDirty || (course.districtId && course.districtId !== session.district.id)}
          variant="contained"
        >
          {t("Submit")}
        </Button>
      </CardActions>
    </Card>
  )
}
