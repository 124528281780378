import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Stack,
  Button,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getSchedules, setSchedules } from 'store/settings/course-session-schedule.js'
import { FaSave } from 'react-icons/fa'
import dayjs from 'dayjs'

const heads = ['Periods', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

export default function SchedulingCourseSessionsFormSetSchedule() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const basePath = `/settings/scheduling/course-sessions/`
  const { courseSessionId } = useParams()

  const theme = useSelector(state => state.session.appearance)

  // const [schedules, setSchedules] = useState()
  const schedule = useSelector(state => state.settings?.schedule)
  const [checkboxState, setCheckboxState] = useState({})

  const handleRowCheckboxChange = periodId => {
    setCheckboxState(prevState => {
      const newState = { ...prevState }
      const allChecked = Object.values(newState[periodId]).every(Boolean)
      Object.keys(newState[periodId]).forEach(day => {
        newState[periodId][day] = !allChecked
      })
      return newState
    })
  }

  const onSubmitSchedules = async () => {
    try {
      const schedule = Object.keys(checkboxState).map(periodId => {
        const days = Object.keys(checkboxState[periodId]).map(day => {
          return {
            day,
            checked: checkboxState[periodId][day],
          }
        })
        return {
          periodId,
          days,
        }
      })

      dispatch(setSchedules({ courseSessionId, schedule }))
      navigate(`${basePath}`)
    } catch (error) {
      console.error('Error submitting schedules:', error)
    }
  }

  useEffect(() => {
    if (schedule.list && schedule.list.length >= 0) {
      const initialCheckboxState = {}
      schedule.list.forEach(item => {
        initialCheckboxState[item.id] = { ...item.days }
      })
      setCheckboxState(initialCheckboxState)
    } else {
      dispatch(getSchedules({ courseSessionId }))
    }
  }, [schedule])

  /// When period name is clicked, all checkboxes in that row should be toggled
  const handleCheckboxChange = (event, periodId, day) => {
    const checked = event.target.checked
    setCheckboxState(prevState => ({
      ...prevState,
      [periodId]: {
        ...prevState[periodId],
        [day]: checked,
      },
    }))
  }

  if (!schedule.list) {
    return null
  }

  return (
    <Box sx={{ pt: 3, pl: 1 }}>
      <Typography variant="h6">{t('Set Schedule')}</Typography>
      <Table
        sx={{
          border: '1px solid',
          borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
          mt: 1,
        }}
      >
        <TableHead>
          <TableRow>
            {heads.map((item, index) => (
              <TableHeadCell
                key={index}
                sx={{
                  textAlign: 'center',
                  fontWeight: '500 !important',
                  backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                }}
              >
                {t(item)}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.list.map((item, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  textAlign: 'center',
                  width: '17%',
                  backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                }}
              >
                <Typography variant="body2">
                  <a href="#" onClick={() => handleRowCheckboxChange(item.id)}>
                    {item?.name}
                  </a>
                </Typography>
                {item?.start && item?.end && (
                  <Typography sx={{ fontSize: '10px', fontWeight: 500 }}>
                    {dayjs(item?.start, 'HH:mm:ss').format('h:mm a')} - {dayjs(item?.end, 'HH:mm:ss').format('h:mm a')}
                  </Typography>
                )}
              </TableCell>
              {Object.keys(item?.days).map((day, ind) => (
                <TableCell key={ind} sx={{ width: '16.7%' }} align="center">
                  <Checkbox
                    size="small"
                    color="warning"
                    checked={checkboxState[item.id]?.[day] || false}
                    onChange={event => handleCheckboxChange(event, item.id, day)}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid>
        <Grid item xs={12} md={12} marginTop={2}>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Button
              onClick={onSubmitSchedules}
              size="small"
              variant="contained"
              color="warning"
              title={t('Save')}
              startIcon={<FaSave />}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
