
import FinalGradeItem from './FinalGradeItem'
import { Grid, Table, TableBody, TableHead, TableRow, TableCell, Box, Avatar } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import CustomAvatar from 'components/Avatar'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function FinalGradeList({ datas, markingPeriods }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ p: 0 }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell sx={{ width: '5em' }}></TableHeadCell>
                <TableHeadCell sx={{ width: '20em' }}>{t('Name')}</TableHeadCell>
                <TableHeadCell sx={{ width: '80em' }}>
                  <Grid container item xs={12} md={12}>
                    {markingPeriods.map(mp => (
                      <Grid item xs={2.4} md={2.4} key={mp.id}>
                        {t(mp.name)}
                      </Grid>
                    ))}
                    <Grid item xs={2.4} md={2.4}>
                      {t('Final Grade')}
                    </Grid>
                  </Grid>
                </TableHeadCell>
              </TableRow>
            </TableHead>
            {datas?.map(data => (
              <TableBody key={data?.id}>
                <TableRow>
                  <TableCell>
                    <Avatar
                      src={get(data, 'file.location')}
                      {...CustomAvatar(`${data?.person?.firstName} ${data?.person?.lastName}`)}
                    />
                  </TableCell>
                  <TableCell>
                    {data?.person?.firstName} {data?.person?.lastName}
                  </TableCell>
                  <TableCell>
                    {data.markingPeriodGrades && (
                      <FinalGradeItem
                        markingPeriodGrades={data.markingPeriodGrades}
                        grade={data.grade}
                        courseSessionMPIds={data.courseSessionMPIds}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </Grid>
      </Grid>
    </Box>
  )
}
