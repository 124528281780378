import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  MobileStepper,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import 'utils/global.scss'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/course'
import { useNavigate, useParams } from 'react-router-dom'
import _, { pick } from 'lodash'
import SchedulingCoursesFormSearchBar from './Search'
import { search } from 'store/provider/course'

const initialValues = {
  parentId: null,
  courseType: null,
  subjectId: null,
  enabled: null,
  localCourseCode: null,
  name: null,
  scedCourseName: null,
  scedSubjectAreaCode: null,
  scedCourseIdentifier: null,
  scedGradeSpan: null,
  scedCourseSequence: null,
  scedCourseType: null,
  gradeLevelId: null,
  credit: null,
  // courseLevelId: null,
  courseLevel: null,
  // gradingMethodId: null,
  gradingScaleId: null,
  description: null,
  exportToReportCard: null,
  exportToTranscript: null,
  reportState: null,
}

const credits = [0, 0.25, 0.5, 1.0, 1.25, 2.0, 2.5, 3.0, 3.5, 3.75, 4.0, 5.0, 6.0, 7.0, 10.0, 12.5, 15.0]

const scedCourseType = [
  { id: 'main', name: 'Main' },
  { id: 'elective', name: 'Elective' },
]

export default function SchedulingCoursesForm({ data, courseType, setCourseType, selectedCourse, setSelectedCourse }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const submitButton = useRef()
  const { courseId } = useParams()

  const gradeLevels = useSelector(state => state.lookup.gradeLevels)

  const gradingScales = useSelector(state => state.settings.gradingScale.list)?.filter(item => item.enabled === true)

  const districtId = useSelector(state => state.session.district.id)
  // const campusId = useSelector(state => state.session.campus.id)
  const theme = useSelector(state => state.session.appearance)
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const courses = useSelector(state => state.settings.course)?.list
  const courseLevel = [
    { id: 'unweighted', name: 'Unweighted' },
    { id: 'collegePrep', name: 'College Prep' },
    { id: 'honors', name: 'Honors' },
    { id: 'ap', name: 'AP Course' },
  ]
  const { query, list } = useSelector(state => state.provider.course)

  useEffect(() => {
    list || dispatch(search({ ...query, offset: 0 }))
  }, [])

  const basePath = `/settings/scheduling/courses/`
  const [activeStep, setActiveStep] = useState(data?.id ? 1 : 0)
  const maxStep = 2

  const handleNext = () => {
    if (activeStep < maxStep) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const schema = yup.object().shape({})

  const { control, handleSubmit, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      data && courseId !== 'new'
        ? {
            ...data,
            gradeLevelId: data.gradeLevel?.id,
            gradingScaleId: data.gradingScale?.id,
            courseLevel: data.courseLevel,
            // gradingMethodId: data.gradingMethod?.id,
            subjectId: data.courseSubject?.id,
          }
        : { ...initialValues },
  })

  useEffect(() => {
    if (courses && courseType === 'sced') {
      const selectedCourseDatas = _.pick(
        list.find(item => item.id === selectedCourse),
        [
          'code',
          'courseType',
          // 'courseLevelId',
          'courseLevel',
          'subjectId',
          'name',
          'enabled',
          'credit',
          'description',
          'localCourseCode',
          'scedCourseSequence',
          'scedSubjectAreaCode',
          'scedCourseName',
          'scedCourseIdentifier',
          'scedCourseType',
          'scedGradeSpan',
          'reportState',
          'exportToTranscript',
          'exportToReportCard',
          'gradeLevelId',
          'gradingScaleId',
          // 'gradingMethodId',
        ],
      )
      selectedCourseDatas.subjectId = list.find(item => item.id === selectedCourse)?.courseSubject?.id
      reset(selectedCourseDatas)
    }
    if (courseType === 'non-sced' && courseId === 'new') {
      reset(initialValues)
    }
  }, [selectedCourse, activeStep])

  // const onDestroy = async () => {}

  const onSubmit = async formData => {
    if (data?.id) {
      formData = pick(formData, [
        'id',
        'code',
        'courseType',
        // 'courseLevelId',
        'courseLevel',
        'subjectId',
        'name',
        'enabled',
        'credit',
        'districtId',
        'description',
        'localCourseCode',
        'scedCourseSequence',
        'scedSubjectAreaCode',
        'scedCourseName',
        'scedCourseIdentifier',
        'scedCourseType',
        'scedGradeSpan',
        'reportState',
        'exportToTranscript',
        'exportToReportCard',
        'gradeLevelId',
        'gradingScaleId',
        // 'gradingMethodId',
      ])
      formData.credit = +formData.credit
      const res = await dispatch(save({ id: data?.id, ...formData }))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    } else {
      formData.parentId = selectedCourse
      formData.districtId = districtId
      formData.credit = +formData.credit
      if (!formData.enabled) formData.enabled = false
      if (!formData.reportState) formData.reportState = false
      if (!formData.exportToReportCard) formData.exportToReportCard = false
      if (!formData.exportToTranscript) formData.exportToTranscript = false
      const res = await dispatch(save(formData))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    }
  }

  const onErrors = errors => console.log(errors)

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SchedulingCoursesForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={data?.id ? `Course Details` : t('Add Course')} small close borderBottom />

        {activeStep === 0 && !data?.id && (
          <SchedulingCoursesFormSearchBar
            getValues={getValues}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            courseType={courseType}
            setCourseType={setCourseType}
          />
        )}
        {activeStep === 1 && (
          <Grid container sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {t('Course Status')}
                </Typography>
                <RadioGroup row sx={{ pl: 2 }}>
                  <Controller
                    name="enabled"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={<Checkbox color="warning" checked={value} />}
                          label={t('Currently Active')}
                          labelPlacement="end"
                          {...field}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="reportState"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={<Checkbox color="warning" checked={value} disabled={true} />}
                          label={t('Report to State')}
                          labelPlacement="end"
                          {...field}
                        />
                      )
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="localCourseCode"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Local Course Code')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Local Course Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedSubjectAreaCode"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Subject Area Code')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}

            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseIdentifier"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Identifier')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseName"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Name')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}

            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedGradeSpan"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Grade Span')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseSequence"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Sequence')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseType"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        select
                        fullWidth
                        size="small"
                        label={t('SCED Course Type')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      >
                        {scedCourseType.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6} md={6}>
              <Controller
                name="subjectId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Subject Area')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {courseSubjects.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gradeLevelId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Grade Level')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {gradeLevels.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="credit"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Credit')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {credits.map((credit, index) => (
                        <MenuItem key={index} value={credit}>
                          {credit}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="courseType"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Course Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {scedCourseType.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="courseLevel"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Course Level')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {courseLevel?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gradingScaleId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Grading Scale')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {gradingScales.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {t('Visibility')}
                </Typography>
                <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{ pl: 2 }}>
                  <Controller
                    name="exportToTranscript"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="warning"
                              checked={value}
                              required
                              size="small"
                              error={!!fieldState.error}
                              {...field}
                            />
                          }
                          label={t('Transcript')}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="exportToReportCard"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="warning"
                              checked={value}
                              required
                              size="small"
                              error={!!fieldState.error}
                              {...field}
                            />
                          }
                          label={t('Report Card')}
                        />
                      )
                    }}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      size="small"
                      label={t('Course Description')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        )}

        <Box>
          <MobileStepper
            variant={!data?.id ? 'dots' : null}
            steps={maxStep}
            position="static"
            activeStep={activeStep}
            sx={{
              bgcolor: 'transparent',
              px: 4,
              '.MuiMobileStepper-dotActive': { backgroundColor: '#FF9215' },
            }}
            nextButton={
              activeStep < maxStep - 1 ? (
                <Button
                  color="warning"
                  size="small"
                  onClick={handleNext}
                  disabled={courseType === 'sced' && !selectedCourse}
                >
                  {t('Next')}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    submitButton.current.click()
                    submitButton.current.disabled = true
                  }}
                  color="warning"
                >
                  {data?.id ? t('Save Course') : t('Add Course')}
                </Button>
              )
            }
            backButton={
              activeStep > 0 && !data?.id ? (
                <Button color="inherit" size="small" onClick={handleBack}>
                  <KeyboardArrowLeft /> {t('Back')}
                </Button>
              ) : (
                <Button disabled={true} />
              )
            }
          />
        </Box>

        <Button ref={submitButton} type="submit" sx={{ display: 'none' }} />
      </Box>
    </BasePathProvider>
  )
}
