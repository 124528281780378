import { Box } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import SchedulingEnrollSessionFormAddStudent from './AddStudent'

export default function SchedulingEnrollCourseForm({ selectedCourseSession, setSelectedCourseSession }) {
  const { t } = useTranslation()

  const basePath = `/settings/scheduling/enroll-session/`

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SchedulingEnrollCourseForm"
        component="form"
        // onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={t('Add Students')} small close borderBottom />
      </Box>
      <SchedulingEnrollSessionFormAddStudent
        selectedCourseSession={selectedCourseSession}
        setSelectedCourseSession={setSelectedCourseSession}
      />
    </BasePathProvider>
  )
}
