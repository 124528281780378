import { omit } from 'lodash'
import { compile, parse } from 'path-to-regexp'

const api_server = '' //process.env.REACT_APP_API_SERVER || ''

class ApiError extends Error {
  constructor(message, status, data) {
    super(message)
    this.status = status
    this.data = data
  }
}

export default async function client(endpoint, { body, method, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' }

  const config = {
    method: method || 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(`${api_server}${endpoint}`, config)
    data = await response.json()
    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new ApiError(response.statusText, response.status, data)
  } catch (err) {
    return Promise.reject(err)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' })
}

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PATCH' })
}

client.delete = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'DELETE' })
}

const parsePath = path => {
  return {
    toPath: compile(path, { encode: encodeURIComponent }),
    keys: parse(path)
      .filter(key => typeof key === 'object')
      .map(key => key.name),
  }
}

client.search = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return async payload => {
    const tempPayloadWithoutArrays = {}
    const tempPayloadArrays = []
    for (const item in payload) {
      if (Array.isArray(payload[item])) {
        tempPayloadArrays.push([item, ...payload[item]])
      } else {
        tempPayloadWithoutArrays[item] = payload[item]
      }
    }
    const params = omit(tempPayloadWithoutArrays, keys)
    const q = params ? new URLSearchParams(params) : null
    tempPayloadArrays.length > 0 &&
      tempPayloadArrays.forEach(item => {
        item.length > 0 &&
          item.forEach(param => {
            item[0] !== param && q.append(item[0], param)
          })
      })
    const qs = q.toString()
    const url = [toPath(payload), qs].filter(Boolean).join('?')
    return await client(url, { ...customConfig, method: 'GET' })
  }
}

client.retrieve = client.search

client.create = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return payload => {
    return client(toPath(payload), {
      ...customConfig,
      body: omit(payload, keys),
      method: 'POST',
    })
  }
}

client.update = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return async payload => {
    return await client(toPath(payload), {
      ...customConfig,
      body: omit(payload, keys),
      method: 'PATCH',
    })
  }
}

client.save = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return async payload => {
    const { id, ...data } = payload
    const url = [toPath(payload), id].filter(Boolean).join('/')
    return await client(url, {
      ...customConfig,
      body: omit(data, keys),
      method: id ? 'PATCH' : 'POST',
    })
  }
}

client.put = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return async payload => {
    const { id, ...data } = payload
    const url = [toPath(payload), id].filter(Boolean).join('/')
    return await client(url, {
      ...customConfig,
      body: omit(data, keys),
      method: 'PUT',
    })
  }
}

client.destroy = function (endpoint, customConfig = {}) {
  const { toPath, keys } = parsePath(endpoint)
  return payload => {
    return client(toPath(payload), {
      ...customConfig,
      body: omit(payload, keys),
      method: 'DELETE',
    })
  }
}
