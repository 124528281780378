import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ScheduleList from './ScheduleList'
import { BasePathProvider } from 'components/Providers'
import { getBellSchedules } from 'store/lookup'
import { getBuilding } from 'store/lookup'
import { search } from 'store/settings/building'
import { search as CourseSessionPeriodSearch, reset } from 'store/settings/course-session-period'

export default function Schedules() {
  const dispatch = useDispatch()
  const { courseSessionId } = useParams()
  const baseUrl = '/settings/course-session/' + courseSessionId + '/schedule'
  const bellSchedules = useSelector(state => state.lookup.bellSchedules)
  const buildingRooms = useSelector(state => state.lookup.building)
  const courseSessionPeriods = useSelector(state => state.courseSessionPeriod.list)

  const roomList = useMemo(() => {
    const newRoomList = []

    buildingRooms?.forEach(item => {
      item.buildingRooms?.forEach(room => {
        const obj = {
          id: room.id,
          doorNumber: room.doorNumber,
          type: room.type,
          name: room.name,
          buildingName: item.name,
        }
        newRoomList.push(obj)
      })
    })

    return newRoomList
  }, [buildingRooms])

  const bellSchedulePeriods = useMemo(() => {
    const newBellSchedulePeriods = []

    bellSchedules?.forEach(bs => {
      bs.bellSchedulePeriods?.forEach(item => {
        if (item.type === 'course-session') {
          const obj = {
            name: bs.name,
            id: item.id,
            startTime: item.startTime,
            endTime: item.endTime,
            dayOfWeek: item.dayOfWeek,
          }
          newBellSchedulePeriods.push(obj)
        }
      })
    })

    return newBellSchedulePeriods
  }, [bellSchedules])

  useEffect(() => {
    buildingRooms || dispatch(getBuilding({ include: ['room'] }))
    bellSchedules || dispatch(getBellSchedules())
    courseSessionPeriods || dispatch(search())
  }, [bellSchedules, buildingRooms, courseSessionPeriods, dispatch])

  useEffect(() => {
    if (courseSessionId === 'new') {
      reset()
      return
    }
    if (+courseSessionId) {
      dispatch(CourseSessionPeriodSearch({ courseSessionId }))
    }
  }, [courseSessionId, dispatch])

  return (
    <BasePathProvider value={baseUrl}>
      <ScheduleList
        courseSessionPeriods={courseSessionPeriods}
        bellSchedulePeriods={bellSchedulePeriods}
        roomList={roomList}
      />
    </BasePathProvider>
  )
}
