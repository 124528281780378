/* eslint:disabled */
import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import 'utils/global.scss'

const GRADESECTIONS = 0
const ROOMS = 1
const PERIODS = 2
const GRADING = 3

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['grade-sections', 'rooms', 'periods', 'grading'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function SettingsTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])
  const basePath = `/settings_`

  return (
    <Box>
      <Box>
        <Tabs
          value={tabIndex}
          TabIndicatorProps={{ style: { background: '#FF9215', fontFamily: 'Roboto, sans-serif' } }}
        >
          <Tab
            sx={{ color: tabIndex === 0 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Grade Sections')}
            value={GRADESECTIONS}
            component={Link}
            to={`${basePath}/grade-sections`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Rooms')}
            value={ROOMS}
            component={Link}
            to={`${basePath}/rooms`}
          />
          <Tab
            sx={{ color: tabIndex === 2 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Periods')}
            value={PERIODS}
            component={Link}
            to={`${basePath}/periods`}
          />
          <Tab
            sx={{ color: tabIndex === 3 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Grading')}
            value={GRADING}
            component={Link}
            to={`${basePath}/grading`}
          />
        </Tabs>
      </Box>
    </Box>
  )
}
