import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClients } from 'store/lookup'
import DistrictCard from './DistrictCard'
import { Grid, Paper } from '@mui/material'
import Title from '../../Title'

function Clients() {
  const { district } = useSelector(state => state.session)
  const { clients } = useSelector(state => state.lookup)
  const dispatch = useDispatch()

  useEffect(() => {
    clients || dispatch(getClients())
  }, [dispatch, district?.id])

  if (!clients) return null

  return (
<Paper sx={{ height: 350, mt: -2, overflow: "auto" }}>
  <Title sx={{ m: 2 }}>Clients</Title>
  <Grid container direction="row" alignItems="center" spacing={2}>
    {clients.map(item => (
      <Grid item key={item.id}>
        <DistrictCard item={item} />
      </Grid>
    ))}
  </Grid>
  <Grid item sm={12} sx={{ minHeight: '30px' }} />
</Paper>
  )
}
export default Clients
