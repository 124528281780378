import { Grid } from '@mui/material'
import Period from './Period'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getPeriods } from 'store/lookup'

export default function PeriodsList({
  path,
  fields,
  control,
  append,
  periods,
  updated,
  attendanceStatus,
  setValue,
  ...props
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [periods])

  return (
    <Grid container spacing={2}>
      {periods?.map((period, index) => (
        <Period
          key={period.id}
          path={path}
          control={control}
          append={append}
          period={period}
          updated={updated}
          index={index}
          data={fields[index]}
          attendanceStatus={attendanceStatus}
          setValue={setValue}
          {...props}
        />
      ))}
    </Grid>
  )
}
