import { Avatar, IconButton, useMediaQuery } from '@mui/material'
import { useMember } from 'components/Providers'
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { enqueueSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import * as action from 'store/member/index'

export default function ProfilePicture() {
  const member = useMember()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('avatar', file)
    const url = `/api/member/${member?.membership?.type}/${member?.id}/avatar`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    } else {
      dispatch(action.retrieve({ id: member.id, type: member.membership.type }))
    }
  }

  if (!(member?.id && member?.membership && member?.person)) return null

  const profilePicture = member?.person?.profilePicture?.location
  return (
    <IconButton component="label" aria-label="person-avatar">
      <input hidden accept="image/*" type="file" onChange={handleFile} />
      {profilePicture ? (
        <Avatar
          sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: 30 }}
          src={profilePicture}
        >
          <AvatarIcon />
          {profilePicture}
        </Avatar>
      ) : (
        <Avatar sx={{ width: isMobile ? 100 : 120, height: isMobile ? 100 : 120, fontSize: '150%' }}>
          {[member.person?.firstName[0], member.person?.lastName[0]].join('')}
        </Avatar>
      )}
    </IconButton>
  )
}
