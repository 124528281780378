import { useEffect, useState } from 'react'
import { Collapse, Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function CollapsibleAlert({ open, status, title, message, sx, ...props }) {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  useEffect(() => setActive(open), [open])

  return (
    <Collapse data-component="Alert" aria-current={active} in={active} sx={sx}>
      <Alert
        severity={status}
        {...props}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => setActive(false)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        <AlertTitle aria-label={title}>{t(title || status)}</AlertTitle>
        {t(message)}
      </Alert>
    </Collapse>
  )
}

CollapsibleAlert.propTypes = {
  open: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  sx: PropTypes.object,
}

export default CollapsibleAlert
