import { Grid, Table, TableBody, TableHead, TableRow, Typography, Box, Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import TableCell from '@mui/material/TableCell'
import TableHeadCell from 'components/TableHeadCell'
import 'utils/global.scss'

export default function Index({ attendanceReports, absenceRate }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box container spacing={2} sx={{ my: 2, mx: 3, width: '800px' }}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" gutterBottom>
          {t('Daily Attendance Summary')}{' '}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '23em', borderBottom: '1px solid orange' }}
              >
                {t('Status')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Present')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Tardy')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Absent')}
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: theme.palette.action.hover }}>
            <TableRow>
              <TableCell xs={1} sx={{ width: '23em' }}>
                {t('Absence Rate')}: {absenceRate} %
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em' }}>
                <Avatar sx={{ bgcolor: 'green' }}>{attendanceReports?.daily?.present ?? '-'}</Avatar>
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em' }}>
                <Avatar sx={{ bgcolor: 'orange' }}>{attendanceReports?.daily?.tardy ?? '-'}</Avatar>
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em' }}>
                <Avatar sx={{ bgcolor: 'red' }}>{attendanceReports?.daily?.absent ?? '-'}</Avatar>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mt: '30px' }}>
        <Typography variant="h6" gutterBottom>
          {t('Course Attendance Summary')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '23em', borderBottom: '1px solid orange' }}
              >
                {t('Course Name')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Present')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Tardy')}
              </TableHeadCell>
              <TableHeadCell
                className="font-weight-500 font-size-14"
                xs={1}
                style={{ width: '5em', borderBottom: '1px solid orange' }}
              >
                {t('Absent')}
              </TableHeadCell>
            </TableRow>
          </TableHead>
          {attendanceReports?.courseSessions?.map((item, index) => (
            <>
              <TableBody key={item.id} sx={index % 2 === 0 ? { backgroundColor: theme.palette.action.hover } : null}>
                <TableRow>
                  <TableCell xs={1} sx={{ width: '23em' }}>
                    {item.course.name}-{item.name}
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em' }}>
                    {item.present ?? '-'}
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em' }}>
                    {item.tardy ?? '-'}
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em' }}>
                    {item.absent ?? '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          ))}
        </Table>
      </Grid>
    </Box>
  )
}
