import { TableCell, Link, Box, Stack } from '@mui/material'

import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'

export default function PeriodItem({ item }) {
  const basePath = useBasePath()
  const { t } = useTranslation()
  return (
    <>
      {item !== null && (
        <>
          {item.dayOfWeek !== null ? (
            <TableCell sx={{ '&:hover': { backgroundColor: 'gray' } }} colSpan={2}>
              <Link component={NavLink} underline="none" color="inherit" to={`${basePath}/${item?.id}`}>
                <Box sx={{ mb: 1, fontStyle: 'italic' }}>{item.name}</Box>
                <Box>{t(startCase(item.type))}</Box>

                <Stack sx={{ mb: 1 }} direction="row" spacing={1}>
                  <Box>{dayjs(item.startTime, 'HH:mm:ss').format('h:mm a')}</Box>

                  <Box>{dayjs(item.endTime, 'HH:mm:ss').format('h:mm a')}</Box>
                </Stack>
              </Link>
            </TableCell>
          ) : (
            <>
              <TableCell colSpan={2}></TableCell>
            </>
          )}
        </>
      )}
    </>
  )
}
