import { STUDENT } from '../../../constants'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
  Box,
  TablePagination,
  Skeleton,
  Stack,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '../../../components/Icons'

import { useBasePath, useMembershipType } from '../../../components/Providers'
import TableHeadCell from '../../../components/TableHeadCell'

import { search } from '../../../store/person'

import SearchBar from './SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

function StaffSearch() {
  const path = useBasePath()
  const membershipType = useMembershipType()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { query, list, count } = useSelector(state => state.person[membershipType])
  const person = useSelector(state =>
    membershipType === 'staff' ? state.person.staff.selected : state.person.student.selected,
  )

  const go = id => {
    navigate(`${path}/${id}`)
  }

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    const offset = limit * page
    dispatch(search({ ...query, limit, offset }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2, minHeight: '500px' }}>
        <SearchBar /> 
        {list.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>&nbsp;</TableHeadCell>
                  <TableHeadCell>{t('Name')}</TableHeadCell>
                  <TableHeadCell>{t('E-Mail')}</TableHeadCell>
                  {membershipType === STUDENT && <TableHeadCell>{t('Degree')}</TableHeadCell>}
                  <TableHeadCell>{t('Address')}</TableHeadCell>
                  <TableHeadCell>&nbsp;</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(item => {
                  let address = item.addresses.find(item => item.type === 'home')
                  return (
                    <TableRow key={item.id} hover selected={person?.id === item.id} small>
                      <TableCell onClick={() => go(item.id)} sx={{ cursor: 'pointer', width: '4em', py: '0.7em' }}>
                        <Avatar
                          sx={{
                            '&:hover': { transform: 'scale(2)', zIndex: 100 },
                            transition: 'transform 0.2s ease-in-out',
                            transform: 'scale(1)',
                            width: 52,
                            height: 52,
                          }}
                          src={get(item, 'memberships.0.profilePicture.location')}
                        >
                          {[item.firstName[0], item.lastName[0]].join('')}
                        </Avatar>
                      </TableCell>
                      <TableCell onClick={() => go(item.id)} sx={{ cursor: 'pointer', width: 'auto' }}>
                        {item.firstName} {item.lastName}
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      {membershipType === STUDENT && (
                        <TableCell>
                          {/* {item?.memberships[0].schoolEnrollments[0]?.academicYearEnrollments[0]?.gradeLevel?.name} */}
                        </TableCell>
                      )}
                      <TableCell>{address ? [address.city, address.state].join(', ') : '-'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <IconButton aria-label="switch" size="small" onClick={() => go(item.id)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={count || 0}
              page={query?.limit ? query?.offset / query?.limit : 0}
              onPageChange={handleChangePage}
              rowsPerPage={query?.limit || 10}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            />
          </TableContainer>
        ) : (
          count === null &&
          Array(10)
            .fill(1)
            .map((el, i) => (
              <Stack sx={{ m: 5 }} flexDirection={'row'} key={i}>
                <Box sx={{ mr: 3, width: '5%' }}>
                  <Skeleton variant="circular" width={40} height={40} />
                </Box>
                <Box sx={{ mr: 3, width: '95%' }}>
                  <Skeleton animation="wave" width={'100%'} height={40} />
                </Box>
              </Stack>
            ))
        )}
      </Paper>
    </Box>
  )
}

export default StaffSearch
