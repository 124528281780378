import { Box, Drawer, Paper, useMediaQuery, useTheme } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import SwitchAcademicYear from 'components/SwitchAcademicYear'
import UserMenu from 'components/UserMenu'
import { useSession } from '../Providers'
import MenuIcon from '@mui/icons-material/Menu';
import AppMenu from 'components/AppMenu'
import { useState } from 'react'

function PageHeader({ title, button }) {
  const { t } = useTranslation()
  const { membership, district, school } = useSession()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
      <Paper>
        <Box display="flex" justifyContent="space-between" width="100%" maxHeight="82px" sx={{ bgcolor: 'background', p: '20px', borderRadius: '5px', alignItems: 'center'}}>
          <Box sx={{ml: -2, display: 'flex', alignItems: 'center'}} >
            {isSmallScreen && (
              <MenuIcon onClick={handleDrawerToggle} sx={{fontSize: '36px', color: 'primary.main' }} />
            )}  
            <Header title={t(`${title}`)}  />
          </Box>
          <Box display="flex">
            {button && (
              <Box display="flex" alignItems="center" flexGrow={{ sm: 1, md: 0, lg: 2 }}>
                {button}
              </Box>
            )}
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center" flexGrow={{ sm: 1, md: 0, lg: 2 }}>
              {school?.id && membership.type === 'staff' && district.isServiceProvider === false && (
                <SwitchAcademicYear />
              )}
            </Box>
            <Box display="flex" alignItems="center" flexGrow={{ sm: 1, md: 0, lg: 1 }} >
              <UserMenu />
            </Box>
          </Box>
          <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // For better performance on mobile
        }}
        sx={{          
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: 'primary.main',
            borderRight: 'none',
            transition: 'width 0.3s'
          },
        }}
        PaperProps={{
          sx: {
            height: '100%',
            overflow: 'auto',
          },
        }}
      >
        <AppMenu />
      </Drawer>
        </Box>
      </Paper>
  
  )
}

export default PageHeader
