import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'academic-course-session'
const initialState = {
  list: null,
}

export const search = createApiAction('academic/course-session/search', client.search('/api/academic/course-session'))
// export const get = createApiAction('academic/course-session/get', client.search('/api/academic/course-session/:id'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export default sessionSlice.reducer
