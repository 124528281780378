import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'final-grades'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'academic/final-grade/search',
  client.search('/api/academic/course-session/:courseSessionId/final-grade'),
)
export const get = createApiAction(
  'academic/final-grade/get',
  client.retrieve(`/api/academic/course-session/:courseSessionId/final-grade/:id`),
)
export const save = createApiAction(
  'academic/final-grade/save',
  client.update('/api/academic/course-session/:courseSessionId/final-grade/:id'),
)
export const destroy = createApiAction(
  'academic/final-grade/delete',
  client.destroy('/api/academic/course-session/:courseSessionId/final-grade/:id'),
)

const update = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        state.list = null
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, update)
      .addCase(save.fulfilled, update)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
