import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";


export default function RequestPolicy() {
  return (
    <Box>
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 600 }} >Punch In/Out Procedures:</Typography>
        <Typography variant="body2" >
          Regular work hours are 7:35am to 3:45pm in schools unless otherwise stated.
          (Work hours in Central Office are 8:00am to 4:30pm).
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary="For every work day, you should have an IN and OUT punch record as well as a Break OUT and Break IN punch record." />
          </ListItem>
          <ListItem>
            <ListItemText primary="When you punch in, you don’t have to press F1 because it is selected as the default." />
          </ListItem>
          <ListItem>
            <ListItemText primary="When you punch out, press F2 before scanning your finger, otherwise it will be marked as an IN record and you will not have an OUT record for that day." />
          </ListItem>
          <ListItem>
            <ListItemText primary="When you punch out for lunch, press F3 and when you return from lunch press F4." />
          </ListItem>
        </List>

        <Typography variant="body2" gutterBottom>
        If your position requires you to travel between campuses, or if you visit another campus for any reason (meeting, coaching, observation, etc.) please use the finger 
        scan machine on that campus. All the finger scan units are connected to a central server. After you are done in that campus, please remember to 
        punch out for the day when you leave.
        </Typography>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1"  sx={{ fontWeight: 600, }} >Leave Requests:</Typography>
          <Typography variant="body2">
            Each and every out-of-district leave requires a Leave Request but you don’t need to request a Leave Request for leaving your campus for in-district meetings.
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
              primary="All leave requests (sick days, half sick days, personal days,
                  PD’s, field trips, etc.) must only be submitted through your campus Database. No other ways (text, email, phone
                  call, etc.) will be accepted as long as Database is working and accessible." />
            </ListItem>
            <ListItem>
              <ListItemText 
              primary="Sick day requests must be put in the
                  system no later than 6:00 AM. There is no approval process for sick days. You have a total of 10 paid sick days
                  and 2 personal days unless otherwise specified due to your effective start date. If you are out sick 3 or more
                  days, please provide medical documentation to your school nurse and upload a copy to your leave request on the
                  database for Human Resources records." />
            </ListItem>
            <ListItem>
              <ListItemText 
              primary="For all leave types other than Sick Days, approval is required (Personal
                Days, PD’s, Field Trips, etc.). Once you have completed the necessary documentation (if there is any), you will
                select the appropriate item from the drop down list. When you submit the request, it will not go to Human
                Resources automatically, because it first requires administrative approval. After it is approved, an email will
                be sent to all related staff members and Human Resources with the confirmed approval. It is your responsibility
                to follow up with your administrator throughout the process until a decision is made." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When you request a half-sick day (afternoon), you may punch out on or after 11:30am, 
                when you request a half-sick day (morning),you are required to punch in by 11:30am as well. The request of
                half days is not allowed on early staff dismissal days. On these days only a full day should be submitted in its place." />
            </ListItem>
            <ListItem>
              <ListItemText primary="For Central Office employees,
                when you request a half-sick day (afternoon), you may punch out on or after 12:00pm, when you request a
                half-sick (morning), you are required to punch in by 12:30pm (No lunch or break is allowed, as this time is
                incorporated in the time you leave or arrive)." />
            </ListItem>
            <ListItem>
              <ListItemText primary="For any work day, you should have either a Leave Request that
                shows you are away from the school or a Punch IN and OUT record. Therefore, please make sure all Leave Requests
                (regardless of the reason) are entered into Database. Otherwise, you will get an automated message. If the
                system is missing one of your Punch IN or OUT records, it will count as a half-sick day. If both are missing, it
                will count as a full-sick day. All Punch IN/OUT records are available in Database. If you have received any
                email notification about a Punch IN/OUT issue (late in, early leave, etc.), please review your records on
                Database before contacting Human Resources." />
            </ListItem>
            <ListItem>
              <ListItemText primary="When submitting any kind of leave request (sick day, ½ sick day,
                Personal, etc.) on the day before or after a scheduled holiday, your day will be automatically switched to an
                unpaid leave day unless a doctor’s note is uploaded within the week, in which case HR Department will make the
                change back to a paid leave day/half day." />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}
