import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'school'
const initialState = {
  list: null,
}

const basePath = '/api/provider/school'
export const search = createApiAction('provider/school/search', client.search(basePath))

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})
export default sessionSlice.reducer
