import { Grid, MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function ResidentInfoForm({
  control,
  residentDistrictOption,
  residentMunicipalCodeOption,
  residentSchoolOption,
  residentCountyList,
}) {
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.residentMunicipalCode"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Resident Municipal Code')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {residentMunicipalCodeOption?.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.residentDistrict"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Resident District')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {residentDistrictOption?.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.residentCounty"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Resident County')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {residentCountyList?.map((item, index) => (
                  <MenuItem key={index} value={item.county_name}>
                    {item.county_name}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.residentSchool"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Resident School')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {residentSchoolOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.districtEntryDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t('District Entry Date')}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <Controller
          name="residentInfo.firstEntryDateIntoUSSchool"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t('First Entry Date Into a US School')}
                error={!!fieldState.error}
                size="small"
                value={dayjs(value || Date.now())}
                defaultValue={dayjs(Date.now())}
                {...field}
              />
            )
          }}
        />
      </Grid>
    </>
  )
}
