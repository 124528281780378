import { useEffect } from 'react'
import $ from 'jquery'
import 'daterangepicker/daterangepicker.css'
import 'daterangepicker'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import './styles.css'
import { InputAdornment, TextField } from '@mui/material'
import dayjs from 'dayjs'

export default function DateRangePicker({ label, startDate, endDate, setDateRange, leaveDates }) {
  useEffect(() => {
    $(function () {
      $('input[name="datetimes"]').daterangepicker(
        {
          timePicker: false,
          autoApply: true,
          startDate,
          endDate,
          locale: {
            format: 'MM/DD/YYYY',
          },
          isInvalidDate: date => {
            const dayOfWeek = date.day()
            return (
              dayOfWeek === 0 ||
              dayOfWeek === 6 ||
              leaveDates?.some(leave => dayjs(leave.leaveDate).isSame(date, 'day'))
            )
          },
          isCustomDate: date => {
            if (leaveDates?.some(leave => dayjs(leave.leaveDate).isSame(date, 'day'))) return 'leaveDatesTaken'
          },
        },
        function (startDate, endDate) {
          setDateRange([dayjs(startDate), dayjs(endDate)])
        },
      )
    })
  }, [startDate, endDate, setDateRange])

  return (
    <TextField
      fullWidth
      type="text"
      size="small"
      label={label || 'Date Range'}
      name="datetimes"
      placeholder="Select a date range"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon className="calendar-icon" />
          </InputAdornment>
        ),
      }}
    />
  )
}
