import { Box, CardContent, Grid, Table, TableHead, TableRow, TableBody, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import HealthConditionItem from './ListItem'
import { useEffect } from 'react'
import { search, clearConditions } from 'store/nurse/nurse-health-condition'
import TableHeadCell from 'components/TableHeadCell'

const headers = ['Visibility', 'Type', 'Explanation', 'Medication', 'Administer', 'Actions']
export default function HealthConditionsList({ selected }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const healthConditions = useSelector(state => state.nurse.healthCondition.list)


  useEffect(() => {
    if (selected) {
      dispatch(search({ schoolEnrollmentId: selected.schoolEnrollmentId }))
    }
  }, [dispatch, selected])

  useEffect(() => {
    dispatch(clearConditions())
  }, [dispatch])

  if (!selected) return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100px', textAlign: 'center', padding: '20px', }}>
        <Typography>
            Please select a student from the seach field above to see or add new conditions for the selected student!
        </Typography>
    </Box>
  )
  
  return (
    <Box sx={{ display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CardContent sx={{ pr: 0, pl: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                    {headers.map((item, index) => (
                        <TableHeadCell key={index}>
                            {t(item)}
                        </TableHeadCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {healthConditions?.map(item => (
                  <HealthConditionItem key={item.id || item.uid} item={item} />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Grid>
      </Grid>
    </Box>
  )
}
