import { useDispatch, useSelector } from 'react-redux'
import { getMembership, setMembership } from '../../store/session'
import { useEffect, useState } from 'react'

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material'
import NextIcon from '@mui/icons-material/ChevronRight'
import CheckIcon from '@mui/icons-material/Check'

import { SubTitle, Body } from '../typo'

function Memberships({ children }) {
  const { membership, district, memberships } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const [listItems, setListItems] = useState()

  useEffect(() => {
    if (!memberships) {
      dispatch(getMembership())
    }
  }, [memberships, dispatch])

  const selectMembership = async (membershipId, campusEnrollmentId) => {
    await dispatch(setMembership({ membershipId, campusEnrollmentId }))
  }

  const MembershipList = () => (
    <TableContainer
      data-component="MembershipList"
      component={Paper}
      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}
    >
      <SubTitle color="primary">Memberships</SubTitle>
      <Body>Please re-connect with any membership listed below.</Body>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>District</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listItems?.map(item => (
            <TableRow
              key={item.id}
              hover={false}
              selected={item.id === membership?.id}
              data-district-name={item.district.name}
            >
              <TableCell>
                {item.id === membership?.id ? (
                  <CheckIcon fontSize="small" color="primary" />
                ) : (
                  <NextIcon fontSize="small" />
                )}
              </TableCell>
              <TableCell>{[item.person.firstName, item.person.lastName].join(' ')}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item?.campusEnrollment?.campus?.name || item?.district?.name}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Button
                  size="small"
                  onClick={() => selectMembership(item.id, item?.campusEnrollment?.id)}
                  startIcon={<NextIcon fontSize="small" />}
                >
                  Connect
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  // const campusEnrollments = []
  // const districtEnrollments = []

  // memberships.forEach(item=>{
  //   if(!item?.campusEnrollments){
  //     districtEnrollments.push(item)
  //   } else {
  //     item?.campusEnrollments?.map(ce => campusEnrollments.push(ce))
  //   }
  // })

  useEffect(() => {
    if (memberships) {
      const temp = []
      memberships.forEach(item => {
        if (item?.district?.isServiceProvider === true) {
          temp.push(item)
        } else if (item?.campusEnrollments?.length) {
          item?.campusEnrollments.forEach(ce => {
            temp.push({
              id: item.id,
              district: item.district,
              person: item.person,
              type: item.type,
              campusEnrollment: ce,
            })
          })
        } else {
          temp.push(item)
        }
      })
      setListItems(temp)
    }
  }, [memberships])

  if (membership && district && children) return children
  return memberships ? <MembershipList /> : null
}
export default Memberships
