import { MembershipTypeProvider } from '../../components/Providers'
import Person from '../Person'

export default function Student() {
  return (
    <MembershipTypeProvider type="student">
      <Person />
    </MembershipTypeProvider>
  )
}
