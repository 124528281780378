import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'role'
const initialState = {
  filter: {},
  search: null,
  count: null,
  running: false,
}

export const search = createApiAction('role/search', client.search('/api/setting/role'))
export const get = createApiAction('role/get', client.retrieve('/api/setting/role/:id'))
export const create = createApiAction('role/create', client.create('/api/setting/role'))
export const update = createApiAction('role/update', client.update('/api/setting/role/:id'))
export const destroy = createApiAction('role/destroy', client.destroy('/api/setting/role/:id'))

export const setRolePermission = createApiAction('role/permission', client.create('/api/setting/role-permission'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.search = action.payload.rows
        state.count = action.payload.count
        state.running = false
      })
      .addCase(create.fulfilled, (state, action) => {
        state.search = [...state.search, action.payload]
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = state.search.map(i => {
          if (i.id === action.payload.id) return action.payload
          return i
        })
      })
      .addCase(destroy.fulfilled, (state, action) => {
        state.search = state.search.filter(i => i.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
