import { Chip, Grid, Typography } from '@mui/material'
import {
  CheckCircleOutlineIcon,
  CloseIcon,
  HomeIcon,
  PhoneIcon,
  PhoneIphoneIcon,
  TranslateIcon,
  WorkOutlineIcon,
} from 'components/Icons'
import PreviewCard from 'components/PreviewCard'
import { useSelector } from 'react-redux'
import FeildSkeleton from './FieldSkeleton'
import { useTranslation } from 'react-i18next'
import 'utils/global.scss'

export function GuardianContact({ data, title, buttonNavigate, buttonLabel }) {
  const languages = useSelector(state => state.lookup.languages)
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)

  return (
    <PreviewCard
      title={title}
      buttonLabel={buttonLabel}
      buttonNavigate={buttonNavigate}
      size="large"
      actionChildren={
        data.id && (
          <>
            {data.emergency && <Chip icon={<CheckCircleOutlineIcon />} label={t('Emergency Call')} color="success" />}
            {!data.emergency && <Chip icon={<CloseIcon />} label={t('Emergency Call')} color="error" />}
            {data.pickup && <Chip icon={<CheckCircleOutlineIcon />} label={t('Pickup')} color="success" />}
            {!data.pickup && <Chip icon={<CloseIcon />} label={t('Pickup')} color="error" />}

            {data.isGuardian && data.hasParentalAccess && (
              <Chip icon={<CheckCircleOutlineIcon />} label={t('SIS Access')} color="success" />
            )}
            {data.isGuardian && !data.hasParentalAccess && (
              <Chip icon={<CloseIcon />} label={t('SIS Access')} color="error" />
            )}
          </>
        )
      }
    >
      <Grid container>
        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Relationship')}
          </Typography>
          <Typography className={`grid-table-title-value`} variant="body1">
            {data?.relationship ? (
              <Typography>
                {data?.relationship?.slice(0, 1).toUpperCase()}
                {data?.relationship?.slice(1)}
              </Typography>
            ) : (
              <FeildSkeleton />
            )}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Full Name')}
          </Typography>
          <Typography variant="body1" className={`grid-table-title-value`}>
            {data?.contact?.firstName || data?.contact?.lastName ? (
              <Typography>
                {data?.contact?.title && data?.contact?.title} {data?.contact?.firstName} {data?.contact?.lastName}
              </Typography>
            ) : (
              <FeildSkeleton />
            )}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Username/Email')}
          </Typography>
          <Typography variant="body1" className={`grid-table-title-value`}>
            {data?.contact?.email ? <Typography>{data?.contact?.email}</Typography> : <FeildSkeleton />}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Primary Phone')}
          </Typography>
          <Typography variant="body1" className={`grid-table-title-value`}>
            {data?.contact?.phones[0] ? (
              <Chip
                icon={
                  data?.contact?.phones[0]?.type === 'home' ? (
                    <HomeIcon />
                  ) : data?.contact?.phones[0]?.type === 'mobile' ? (
                    <PhoneIphoneIcon />
                  ) : data?.contact?.phones[0]?.type === 'work' ? (
                    <WorkOutlineIcon />
                  ) : (
                    <PhoneIcon />
                  )
                }
                label={data?.contact?.phones[0]?.number}
              />
            ) : (
              <FeildSkeleton />
            )}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Alternate Phone')}
          </Typography>
          <Typography variant="body1" className={`grid-table-title-value`}>
            {data?.contact?.phones[1] ? (
              <Chip
                icon={
                  data?.contact?.phones[1]?.type === 'home' ? (
                    <HomeIcon />
                  ) : data?.contact?.phones[1]?.type === 'mobile' ? (
                    <PhoneIphoneIcon />
                  ) : data?.contact?.phones[1]?.type === 'work' ? (
                    <WorkOutlineIcon />
                  ) : (
                    <PhoneIcon />
                  )
                }
                label={data?.contact?.phones[1]?.number}
              />
            ) : (
              <FeildSkeleton />
            )}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={4} md={4}>
          <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
            {t('Preferred Language')}
          </Typography>
          <Typography variant="body1" className={`grid-table-title-value`}>
            {data?.contact?.preferredLanguage ? (
              <Chip
                icon={<TranslateIcon />}
                label={languages?.find(item => item.id === data?.contact?.preferredLanguage)?.name}
              />
            ) : (
              <FeildSkeleton />
            )}
          </Typography>
        </Grid>
      </Grid>
    </PreviewCard>
  )
}
