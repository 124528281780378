import { Route, Routes } from 'react-router-dom'
import SettingsSchoolManagementPage from './Page'
import SettingsSchoolManagementFormIndex from './Form'

export default function SettingsSchoolManagement() {
  return (
    <Routes>
      <Route path="/" element={<SettingsSchoolManagementPage />}>
        <Route path="/district/:districtId" element={<SettingsSchoolManagementFormIndex />} />
        <Route path="/district/:districtId/campus/:campusId" element={<SettingsSchoolManagementFormIndex />} />
      </Route>
    </Routes>
  )
}
