import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { EditIcon, DeleteIcon, LoginIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux' 
import { destroy } from 'store/settings/academic-year'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { setAcademicYear } from 'store/session'

export default function AcademicYearItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item.id }))
  }

  return (
    <TableRow key={item.id}>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item.name}
        </Link>
      </TableCell>
      <TableCell>{item.startDate}</TableCell>
      <TableCell>{item.endDate}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={() => dispatch(setAcademicYear({ id: item.id }))}>
          <LoginIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
