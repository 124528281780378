import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get as getStateDatas } from 'store/academic/student/state-data'
import { useEffect } from 'react'
import StudentFormUsNj from '../../../Member/StateData/Forms/us/nj/student-form'
import EmptyForm from '../../../Member/StateData/Forms/empty'
import { getLanguages, getStateDataType } from 'store/lookup'
import { Box, Drawer, Grid } from '@mui/material'
import PersonalInfoCard from '../../../Member/StateData/PersonalInfoCard'
import SPEDInfoCard from '../../../Member/StateData/SPEDInfoCard'
import ELLInfoCard from '../../../Member/StateData/ELLInfoCard'
import { useMember } from 'components/Providers'

export default function StateData() {
  const { part } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const member = useMember()

  const languages = useSelector(state => state.lookup.languages)
  const stateData = useSelector(state => state.academic.student.detail.stateData)
  const { country, state } = useSelector(state => state.session.district?.address)
  const membershipStateDataType = useSelector(state => state.lookup.stateDataType)?.filter(
    item => item.type === member?.membership?.type,
  )
  const basePath = `/member/${member?.membership?.type}/${member?.id}/state-data`
  const sharedStateDataType = useSelector(state => state.lookup.stateDataType)?.filter(item => item.type === 'shared')

  useEffect(() => {
    stateData || dispatch(getStateDatas({ type: member?.membership?.type, memberId: member?.id }))
  }, [member?.id])

  useEffect(() => {
    membershipStateDataType ||
      dispatch(getStateDataType({ state: state.toLowerCase(), type: member?.membership?.type }))
    languages || dispatch(getLanguages())
  }, [])

  const getFormComponent = () => {
    const key = [country.toLowerCase(), state.toLowerCase(), member?.membership?.type].join('.')
    switch (key) {
      case 'us.nj.student':
        return StudentFormUsNj
      default:
        return EmptyForm
    }
  }

  const StateDataForm = getFormComponent()

  if (!(country && state && member?.id && membershipStateDataType && languages)) return null

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <>
          <Grid item xs={12} md={12}>
            <PersonalInfoCard
              member={{ ...member, stateData }}
              membershipStateDataType={membershipStateDataType}
              languages={languages}
              onlyReadable={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ELLInfoCard
              member={{ ...member, stateData }}
              membershipStateDataType={membershipStateDataType}
              sharedStateDataType={sharedStateDataType}
              onlyReadable={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SPEDInfoCard member={{ ...member, stateData }} onlyReadable={true} />
          </Grid>
        </>
      </Grid>
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(part)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <StateDataForm stateDataType={membershipStateDataType} sharedStateDataType={sharedStateDataType} />
      </Drawer>
    </Box>
  )
}
