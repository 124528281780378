import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Header from 'components/Header'
import { SubmitButton } from 'components/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { create, save } from 'store/person/employment'

import { BasePathProvider } from 'components/Providers'
import { SaveAltIcon } from 'components/Icons'
import {
  districtEntryCode,
  employmentStatus,
  annualSuplement,
  districtEmploymentExitReason,
  employmentSeperationType,
} from '../List/Employment'

const initialValues = {
  districtId: null,
  enrollment: {
    districtEmploymentBeginDate: null,
    baseSalary: null,
    districtEntryCode: null,
    annualSuplement: null,
    employmentStatus: null,

    districtEmploymentExitDate: null,
    districtEmploymentExitReason: null,
    employmentSeperationType: null,
  },
}

export default function EmploymentForm({ data }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clients } = useSelector(state => state.lookup)
  const { district, personId, employmentId } = useParams()
  const memberships = useSelector(state => state.person2.employments)
  const person = useSelector(state => state.person2.list)?.find(item => item.id === +personId)

  const basePath = `/person/${personId}/employment/`

  const searchParams = new URLSearchParams(window.location.search)
  const formtype = searchParams.get('formtype')

  const schema = yup.object().shape({
    districtId: data ? yup.number().default(+district).required('Required') : yup.number().required('Required'),
  })

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data
      ? {
          districtId: +district,
          enrollment: { ...data?.stateData?.enrollment, districtEmploymentBeginDate: data?.startDate },
        }
      : {
          ...initialValues,
        },
  })

  //   const onDestroy = async () => {}

  const onSubmit = async formData => {
    const datas = formData
    if (datas.enrollment.districtEmploymentBeginDate) {
      datas.enrollment.districtEmploymentBeginDate = dayjs(datas?.enrollment?.districtEmploymentBeginDate).format(
        'YYYY-MM-DD',
      )
    }
    if (datas.enrollment.districtEmploymentExitDate) {
      datas.enrollment.districtEmploymentExitDate = dayjs(datas?.enrollment?.districtEmploymentExitDate).format(
        'YYYY-MM-DD',
      )
    }

    datas.startDate = datas?.enrollment?.districtEmploymentBeginDate
    datas.endDate = datas?.enrollment?.districtEmploymentExitDate

    if (+district > 0) {
      const res = await dispatch(
        save({
          districtId: formData.districtId,
          membershipId: employmentId,
          personId,
          data: datas.enrollment,
          type: 'enrollment',
        }),
      )
      if (res.payload) {
        navigate(basePath)
      }
    } else {
      const res = await dispatch(
        create({
          districtId: formData.districtId,
          personId,
          data: datas.enrollment,
          type: 'enrollment',
        }),
      )
      if (res.payload) {
        navigate(basePath)
      }
    }
  }

  const activateEmployment = async () => {
    const formValues = getValues()
    formValues.enrollment.districtEmploymentExitDate = null
    formValues.enrollment.districtEmploymentExitReason = null
    formValues.enrollment.employmentSeperationType = null

    const res = await dispatch(
      save({
        districtId: formValues.districtId,
        membershipId: employmentId,
        personId,
        data: formValues.enrollment,
        type: 'enrollment',
        endDate: null,
      }),
    )
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  if (!clients) return null

  const isExistsMembershipOnClient = clientId => {
    return (
      memberships &&
      memberships[personId] &&
      memberships[personId]?.find(item => item?.districtId === clientId && item.endDate === null)
    )
  }

  if (!person) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="PersonEmploymentForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header
          title={formtype === 'exit' ? t(`Exit ${person?.firstName} ${person?.lastName}`) : t('Employment Information')}
          small
          close
          borderBottom
        />
        <Grid container spacing={2} sx={{ p: 2 }}>
          {!formtype && (
            <>
              <Grid item xs={12} md={12}>
                <Controller
                  name="districtId"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        required
                        size="small"
                        fullWidth
                        select
                        label={t('District')}
                        error={!!fieldState.error}
                        {...field}
                        value={data && +district}
                        disabled={data ? true : false}
                      >
                        {!data &&
                          clients?.map(
                            item =>
                              !isExistsMembershipOnClient(item?.client?.id) && (
                                <MenuItem key={item?.client?.id} value={item?.client?.id}>
                                  {item?.client?.name}
                                </MenuItem>
                              ),
                          )}

                        {data &&
                          clients?.map(item => (
                            <MenuItem key={item?.client?.id} value={item?.client?.id}>
                              {item?.client?.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.districtEmploymentBeginDate"
                  control={control}
                  render={({ field, fieldState }) => {
                    const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                    return (
                      <DatePicker
                        sx={datePickerSx}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: { size: 'small' },
                          field: {
                            fullWidth: true,
                            InputLabelProps: { shrink: true },
                          },
                        }}
                        label={t('District Employment Begin Date')}
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        value={field.value && dayjs(field.value)}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.districtEntryCode"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('District Entry Code')}
                        error={!!fieldState.error}
                        {...field}
                      >
                        {districtEntryCode.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.employmentStatus"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Employment Status')}
                        error={!!fieldState.error}
                        {...field}
                      >
                        {employmentStatus.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.baseSalary"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        label={t('Base Salary')}
                        error={!!fieldState.error}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.annualSuplement"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Annual Suplement')}
                        error={!!fieldState.error}
                        {...field}
                      >
                        {annualSuplement.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            </>
          )}

          {formtype === 'exit' && (
            <>
              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.districtEmploymentExitDate"
                  control={control}
                  render={({ field, fieldState }) => {
                    const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                    return (
                      <DatePicker
                        sx={datePickerSx}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          textField: { size: 'small' },
                          field: {
                            fullWidth: true,
                            InputLabelProps: { shrink: true },
                          },
                        }}
                        label={t('District Employment Exit Date')}
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        value={field.value && dayjs(field.value)}
                        minDate={data?.startDate ? dayjs(data.startDate).add(1, 'day') : null}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.districtEmploymentExitReason"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('District Employment Exit Reason')}
                        error={!!fieldState.error}
                        {...field}
                      >
                        {districtEmploymentExitReason.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="enrollment.employmentSeperationType"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Employment Separation Type')}
                        error={!!fieldState.error}
                        {...field}
                      >
                        {employmentSeperationType.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 2 }}>
          {formtype === 'exit' && data?.endDate && (
            <Button
              color="warning"
              variant="contained"
              startIcon={<SaveAltIcon />}
              sx={{ mr: 2 }}
              onClick={activateEmployment}
            >
              {t('ACTIVATE')}
            </Button>
          )}

          <SubmitButton
            color="warning"
            icon={<SaveAltIcon />}
            title={t('SAVE')}
            onClick={handleSubmit(onSubmit, onErrors)}
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
