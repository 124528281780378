import { BasePathProvider } from 'components/Providers'
import { Autocomplete, Button, Drawer, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import LeaveRequestList from './List'
import LeaveRequestListFilter from './ListFilter.jsx'
import LeaveRequestForm from './Form'
import LeaveRequestPresentation from './RequestPresentation'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'components/Icons'
import { getTeachers } from 'store/lookup'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { search, searchAll, setSearchOffset, initLeaveRequest } from 'store/settings/leave-request'
import { search as searchLeaveRequestType } from 'store/settings/leave-request-type'
import dayjs from 'dayjs'
import { search as searchAcademicYearProvider } from 'store/provider/academic-year'
import { getAcademicYearProviders } from 'store/lookup'

export default function LeaveRequestPage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [que, setQue] = useState(null)
  const [selectedTeacher, setSelectedTeacher] = useState()
  const teachers = useSelector(state => state.lookup.teachers.rows)
  const academicYearsProvider = useSelector(state => state.lookup.academicYearProviders)
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()])
  const [leaveRequestType, setLeaveRequestType] = useState()
  const [academicYear, setAcademicYear] = useState()
  const leaveRequest = useSelector(state => state.settings.leaveRequest)
  const leaveRequestTypeList = useSelector(state => state.settings.leaveRequestType.list)
  const sessionAcademicYear = useSelector(state => state.session.academicYear)

  const basePath = `/settings/leave-request`

  useEffect(() => {
    dispatch(searchAcademicYearProvider())
  }, [])

  useEffect(() => {
    academicYearsProvider || dispatch(getAcademicYearProviders())
    setAcademicYear(sessionAcademicYear.markingPeriodProvider?.academicYearId)
  }, [sessionAcademicYear])

  useEffect(() => {
    leaveRequestTypeList || dispatch(searchLeaveRequestType())
  }, [sessionAcademicYear])

  useEffect(() => {
    teachers || dispatch(getTeachers({ que, limit: 10 }))
  }, [sessionAcademicYear])

  useEffect(() => {
    if (selectedTeacher && sessionAcademicYear) {
      const payload = {
        personId: selectedTeacher?.personId,
        limit: leaveRequest.query?.limit || 10,
        offset: leaveRequest.query?.offset || 0,
      }
      if (leaveRequestType && leaveRequestType !== 'All') {
        payload['typeId'] = leaveRequestType
      }
      if (academicYear && academicYear !== 'All') {
        payload['academicYearProviderId'] = academicYear
      }

      dispatch(setSearchOffset(0))
      dispatch(
        search({
          ...payload,
        }),
      )

      payload['all'] = true
      dispatch(searchAll({
        ...payload,
      }))
    } else {
      dispatch(initLeaveRequest())
    }
  }, [sessionAcademicYear, selectedTeacher, dateRange?.[0], dateRange?.[1]])

  const applyFilter = async (key, value) => {
    dispatch(getTeachers({ [key]: value || null, offset: 0, limit: 10 }))
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const selectTeacher = (event, value) => {
    if (value) {
      setSelectedTeacher(value)
    } else {
      setSelectedTeacher(null)
    }
  }

  return (
    <BasePathProvider value={`${basePath}`}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={4} xs={12}>
          <Autocomplete
            fullWidth
            size="small"
            freeSolo
            disablePortal
            options={teachers || []}
            onInputChange={(event, value, reason) => {
              switch (reason) {
                case 'reset':
                  break
                case 'clear':
                  setQue(null)
                  applyFilter('que', null)
                  break
                case 'input':
                  break
                default:
                  break
              }
            }}
            onChange={selectTeacher}
            getOptionLabel={option => {
              return `${option?.person?.lastName}, ${option?.person?.firstName}`
            }}
            renderInput={params => (
              <TextField
                {...params}
                value={que}
                fullWidth
                label={t('Staff Member')}
                onChange={e => {
                  setQue(e.target.value)
                  delayedSearch(e.target.value, applyFilter)
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingRight: '10px',
                  },
                  endAdornment: (
                    <InputAdornment position="end" style={{ right: '0' }}>
                      <SearchIcon sx={{ mr: 0 }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}></Grid>
        <Grid item md={4} xs={12}>
          <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 0.5, mb: 3 }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={!selectedTeacher}
              sx={{ mr: 2 }}
              onClick={() => navigate(`${basePath}/new`)}
            >
              {t('NEW REQUEST')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <LeaveRequestPresentation />
      <Typography sx={{ textAlign: 'left', mt: '25px', fontSize: '20px' }}>{`${t('Submitted Requests')}`}</Typography>
      <LeaveRequestListFilter
        leaveRequestTypeList={leaveRequestTypeList}
        academicYearsProvider={academicYearsProvider}
        setDateRange={setDateRange}
        selectedTeacher={selectedTeacher}
        leaveRequestType={leaveRequestType}
        setLeaveRequestType={setLeaveRequestType}
        academicYear={academicYear}
        setAcademicYear={setAcademicYear}
      />
      <LeaveRequestList
        leaveRequestList={leaveRequest.list}
        selectedTeacher={selectedTeacher}
        academicYear={academicYear}
        leaveRequestType={leaveRequestType}
      />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <LeaveRequestForm leaveRequestTypeList={leaveRequestTypeList} selectedTeacher={selectedTeacher} />
      </Drawer>
    </BasePathProvider>
  )
}
