import { useState, useMemo, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { omit } from 'lodash'

import {
  TextField,
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  Button,
  Stack,
  Checkbox,
  Divider,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material'

import { debounce } from '@mui/material/utils'
import { createFilterOptions } from '@mui/material/Autocomplete'
import InviteIcon from '@mui/icons-material/Send'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import PhoneList from '../Profile/PhoneList'
import AddressList from '../Profile/AddressList'

import { getPeople } from '../../../store/lookup'
import { saveContact, deleteContact, sendInvitation } from '../../../store/person'
import { PersonProvider, useMembershipType } from '../../../components/Providers'
import Confirm from 'components/Dialog'

const Relative = ({ data, onClose = () => {} }) => {
  const dispatch = useDispatch()
  const membershipType = useMembershipType()
  const [confirm, setConfirm] = useState(false)
  const [relative, setRelative] = useState(data?.contact)
  const people = useSelector(state => state.lookup.person)
  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)
  const { t } = useTranslation()

  const schema = yup
    .object()
    .shape({
      contactId: yup.number().required('Required'),
      relationship: yup.string().required('Required'),
    })
    .required()

  const { control, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      ...data,
      personId: data.personId,
      contactIdId: data.relativeId,
      relationship: data.relationship || '',
      emergency: data.emergency || false,
      notification: data.notification || false,
      pickup: data.pickup || false,
      seq: data.seq || 0,
    },
  })

  useEffect(() => {
    reset({ ...getValues(), contact: relative })
  }, [reset, getValues, relative])

  const fetch = useMemo(
    () =>
      debounce(async (query, callback) => {
        const res = await dispatch(getPeople({ que: query, extend: ['phones', 'addresses'] }))
        if (res.payload) {
          callback(res.payload.rows)
        }
      }, 400),
    [dispatch],
  )

  const searchPeople = query => {
    fetch(query, () => {
      // setPeople(data)
    })
  }

  const _filterOptions = createFilterOptions()
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state)

    const found = results.find(item => item.id === relative?.id)
    if (!found && relative) results.unshift(relative)

    if (results.length === 0 || results[0].id !== 0)
      results.push({
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phones: [],
        addresses: [],
      })

    return results
  }

  const onDestroy = async () => {
    setConfirm(false)
    const data = getValues()
    dispatch(deleteContact({ membershipType, ...data }))
  }

  const inviteHandle = () => {
    if (data.contact.id) {
      dispatch(sendInvitation({ membershipType: 'parent', personId: data.contact.id }))
    }
  }

  const onSubmit = async data => {
    const payload = omit(data, '_id')
    const res = await dispatch(saveContact({ membershipType, ...payload }))
    if (res.payload) {
      reset(res.payload)
      if (!payload.id) onClose()
    } else {
      console.error(res.errors)
    }
  }

  const onErrors = errors => console.log(errors)

  const phoneArrayControl = useFieldArray({ control, name: 'contact.phones', keyName: 'uuid' })
  const addressArrayControl = useFieldArray({ control, name: 'contact.addresses', keyName: 'uuid' })

  const getPrimaryAddress = data => {
    data.forEach(item => {
      if (item.type === 'home') {
        return `${item.street}, ${item.line2}, ${item.city}, ${item.state}, ${item.zip}, ${item.country}`
      }
    })
    if (data?.length) {
      const item = data[0]
      return `${item.street}, ${item.line2}, ${item.city}, ${item.state}, ${item.zip}, ${item.country}`
    }
  }

  const getPrimaryPhone = data => {
    data.forEach(item => {
      if (item.type === 'home') {
        return `${item.number}`
      }
    })
    if (data?.length) {
      const item = data[0]
      return `${item.number}`
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onErrors)} sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Controller
            name={`relationship`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  select
                  size="small"
                  label={t('Relationship')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  {relationshipTypes &&
                    relationshipTypes.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="contactId"
            control={control}
            render={({ field: { ref, onChange, ...field }, fieldState }) => {
              return (
                <Autocomplete
                  size="small"
                  disablePortal
                  filterOptions={filterOptions}
                  onChange={(event, value) => {
                    setRelative(value)
                    onChange(value?.id)
                  }}
                  onInputChange={(event, value, reason) => {
                    switch (reason) {
                      case 'reset':
                        break
                      case 'clear':
                        setRelative(null)
                        value && searchPeople(value)
                        break
                      case 'input':
                      default:
                        value && searchPeople(value)
                        break
                    }
                  }}
                  value={relative}
                  options={people}
                  getOptionLabel={option =>
                    option.id ? `${option?.firstName} ${option?.lastName}` : 'Create new contact'
                  }
                  renderOption={(props, option) =>
                    option?.memberships?.length ? (
                      <Box
                        component="li"
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                        {...props}
                      >
                        <Stack>
                          {option?.memberships[0]?.profilePicture?.location && (
                            <Box>
                              <img
                                loading="lazy"
                                width="60"
                                src={option?.memberships[0]?.profilePicture?.location}
                                alt=""
                              />
                            </Box>
                          )}
                        </Stack>
                        <Stack sx={{ ml: 2 }}>
                          <Typography variant="subtitle1">
                            {option.firstName} {option.lastName}
                          </Typography>
                          {option.memberships.map((item, index) => (
                            <Typography variant="overline" key={index}>
                              {item.type}{' '}
                            </Typography>
                          ))}
                          {option.email && <Typography variant="caption">{option.email}</Typography>}
                          {option.phones && <Typography variant="caption">{getPrimaryPhone(option.phones)}</Typography>}
                          {option.addresses && (
                            <Typography variant="caption">{getPrimaryAddress(option.addresses)}</Typography>
                          )}
                        </Stack>
                      </Box>
                    ) : (
                      <Box
                        component="li"
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                        {...props}
                      >
                        <Typography>Create new contact</Typography>
                      </Box>
                    )
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id
                  }}
                  // getOptionSelected = {(option, value) => option.id === value.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...field}
                      // onFocus={() => setFocused(true)}
                      fullWidth
                      label={t('Contact Person')}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Controller
              name={`emergency`}
              control={control}
              render={({ field: { value, ...field } }) => {
                return (
                  <FormControl>
                    <FormControlLabel label={t('Emergency')} control={<Checkbox checked={value} {...field} />} />
                  </FormControl>
                )
              }}
            />
            <Controller
              name={`notification`}
              control={control}
              render={({ field: { value, ...field } }) => {
                return (
                  <FormControl>
                    <FormControlLabel label={t('Notification')} control={<Checkbox checked={value} {...field} />} />
                  </FormControl>
                )
              }}
            />
            <Controller
              name={`pickup`}
              control={control}
              render={({ field: { value, ...field } }) => {
                return (
                  <FormControl>
                    <FormControlLabel label={t('Pickup')} control={<Checkbox checked={value} {...field} />} />
                  </FormControl>
                )
              }}
            />
          </Stack>
        </Grid>
        <Fragment>
          <Grid item md={2}>
            <Controller
              name={`contact.prefix`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Title')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  ></TextField>
                )
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name={`contact.firstName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('First Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  ></TextField>
                )
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Controller
              name={`contact.middleName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Middle Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  ></TextField>
                )
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name={`contact.lastName`}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Last Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  ></TextField>
                )
              }}
            />
          </Grid>
        </Fragment>
        <Grid item md={6}>
          <Controller
            name={`contact.email`}
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                label={t('E Mail')}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => inviteHandle()} edge="end">
                        <InviteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <PersonProvider person={data.contact}>
          {/* <MembershipTypeProvider type="person"> */}
          <Grid item xs={12}>
            <PhoneList control={control} personId={data.id} path="contact.phones" {...phoneArrayControl} />
            {/* <PhoneEditForm control={control} path="contact.phones" {...phoneArrayControl} /> */}
          </Grid>
          <Grid item xs={12}>
            <AddressList control={control} person={data.id} path="contact.addresses" {...addressArrayControl} />
          </Grid>
          {/* </MembershipTypeProvider> */}
        </PersonProvider>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={() => phoneArrayControl.append({ type: '', number: '' })}>
              + {t('Phone')}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                addressArrayControl.append({ type: '', street: '', line2: '', city: '', state: '', zip: '', country: '' })
              }
            >
              + {t('Address')}
            </Button>
            <Divider orientation="vertical" flexItem />
            {data.id && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirm(true)}
                xonClick={() => onDestroy({ membershipType, ...data })}
              >
                {t('Delete')}
              </Button>
            )}
            {!data.id && (
              <Button variant="contained" color="secondary" onClick={onClose}>
                {t('Cancel')}
              </Button>
            )}
            <Button type="submit" variant="contained" color="primary" sx={{ pl: 5, pr: 5 }}>
              {t('Save')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
    </Box>
  )
}

export default Relative
