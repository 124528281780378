import { Card, CardContent, Grid, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import TableHeadCell from '../../../../components/TableHeadCell'
import GradingScaleListItem from './ListItem'
import { useTranslation } from 'react-i18next'

export default function GradingScaleList() {
  const gradingScales = useSelector(state => state.settings.gradingScale.list)
  const { t } = useTranslation()

  return (
    <Paper data-component="GradingScalesList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>{t('Letter Grade')}</TableHeadCell>
                    <TableHeadCell>{t('Minimum Grade')}</TableHeadCell>
                    <TableHeadCell>{t('Maximum Grade')}</TableHeadCell>
                    <TableHeadCell>{t('GPA')}</TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gradingScales.map(item => {
                    return <GradingScaleListItem key={item.id} item={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}
