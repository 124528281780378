import { useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yuppassword from 'yup-password'

import Button from '@mui/material/Button'
import { Avatar, Typography, Container, Box, Grid, TextField, InputAdornment, IconButton } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Link, Navigate } from 'react-router-dom'

import MyAlert from '../../components/Alert'

import { register } from '../../store/session'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@mui/icons-material'

yuppassword(yup)

export default function RegisterForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({ open: false, status: '', message: '' })
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email().required(),
      password: yup
        .string()
        .required()
        .min(8, 'Your password is too short.')
        .minUppercase(2)
        .minLowercase(2)
        .minSymbols(1)
        .minRepeating(2),
      confirmEmail: yup
        .string()
        .email()
        .required()
        .oneOf([yup.ref('email')], 'Your e-mails do not match.'),
    })
    .required()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      confirmEmail: '',
    },
  })

  const onSubmit = async data => {
    setState(prev => ({ ...prev, open: false }))

    let res = await dispatch(register(data))
    if (res.error) {
      setState({ open: true, status: 'error', message: res.error.data?.error || res.error.message })
    } else {
      // await dispatch(getSession())
      setState({ status: 'success' })
    }
  }

  if (state.status === 'success') return <Navigate to="/" replace={true} />

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" data-label="create-new-user-page-title">
          Create Account
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  autoFocus
                  fullWidth
                  margin="normal"
                  // variant="filled"
                  label={t('First name')}
                  data-label="create-account-form-firstname"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  margin="normal"
                  // variant="filled"
                  label={t('Last name')}
                  data-label="create-account-form-lastname"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  margin="normal"
                  type={!passwordVisibility ? 'password' : 'text'}
                  label={t('Password')}
                  data-label="create-account-form-password"
                  autoComplete="new-password"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setPasswordVisibility(!passwordVisibility)} edge="end">
                          {!passwordVisibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  sx={{}}
                  fullWidth
                  margin="normal"
                  // variant="filled"
                  label={t('E-Mail Address')}
                  data-label="create-account-form-email"
                  autoComplete="off"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
          <Controller
            name="confirmEmail"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  margin="normal"
                  label={t('Confirm E-Mail Address')}
                  data-label="create-account-form-re-email"
                  autoComplete="off"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                  sx={{ mb: 2 }}
                />
              )
            }}
          />
          <MyAlert open={state.open} status={state.status} title={t(state.message)} />
          <Button
            data-label="submit-button-for-create-new-account"
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mb: 2 }}
          >
            {t('Submit')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/auth/login">{t('Already have an account?')}</Link>
            </Grid>
            <Grid item>
              <Link to="/auth/forgot">{t('Forgot password?')}</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
