import { Grid, Table, TableBody, TableHead, TableRow, Typography, Box, Avatar, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import TableCell from '@mui/material/TableCell'
import TableHeadCell from 'components/TableHeadCell'

export default function Index({ attendanceReports, absenceRate }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const styles = {
    boldText: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    cellWithBorder: {
      borderBottom: '1px solid #fff',
    },
  }

  return (
    <Box container spacing={2} sx={{ my: 2, mx: 3, width: '800px' }}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" gutterBottom>
          {t('Daily Attendance Summary')}{' '}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell xs={1} style={{ width: '23em', borderBottom: '2px solid orange' }}>
                {t('Status')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Present')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Tardy')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Absent')}
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: theme.palette.action.hover }}>
            <TableRow>
              <TableCell xs={1} sx={{ width: '23em', ...styles.cellWithBorder }}>
                {t('Absence Rate')}: {absenceRate} %
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                <Avatar sx={{ bgcolor: 'green' }}>{attendanceReports?.daily?.present ?? '-'}</Avatar>
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                <Avatar sx={{ bgcolor: 'orange' }}>{attendanceReports?.daily?.tardy ?? '-'}</Avatar>
              </TableCell>
              <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                <Avatar sx={{ bgcolor: 'red' }}>{attendanceReports?.daily?.absent ?? '-'}</Avatar>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} md={12} sx={{ mt: '30px' }}>
        <Typography variant="h6" gutterBottom>
          {t('Course Attendance Summary')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell xs={1} style={{ width: '23em', borderBottom: '2px solid orange' }}>
                {t('Course Name')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Present')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Tardy')}
              </TableHeadCell>
              <TableHeadCell xs={1} style={{ width: '5em', borderBottom: '2px solid orange' }}>
                {t('Absent')}
              </TableHeadCell>
            </TableRow>
          </TableHead>
          {Array(10)
            .fill()
            .map((_, index) => (
              <TableBody key={index} sx={index % 2 === 0 ? { backgroundColor: theme.palette.action.hover } : null}>
                <TableRow>
                  <TableCell xs={1} sx={{ width: '23em', ...styles.cellWithBorder }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell xs={1} sx={{ width: '5em', ...styles.cellWithBorder }}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      </Grid>
    </Box>
  )
}
