import { useParams } from 'react-router-dom'
import { search as getEnrollments } from 'store/person/enrollment'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import ScheduleList from './List'

export default function ScheduleListIndex({ districtId }) {
  const dispatch = useDispatch()
  const { personId } = useParams()
  const enrollments = useSelector(state => state.person2?.enrollments && state.person2.enrollments[+personId])

  useEffect(() => {
    enrollments || dispatch(getEnrollments({ personId: +personId }))
  }, [districtId])

  if (!districtId || !enrollments) return null

  const schoolEnrollmentId = enrollments?.schoolEnrollments?.find(item => item.school.districtId === districtId)?.id

  if (!schoolEnrollmentId) return null

  return <ScheduleList schoolEnrollmentId={schoolEnrollmentId} />
}
