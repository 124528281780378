import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export default function ELLInfoCard({ member, membershipStateDataType, sharedStateDataType, onlyReadable }) {
  const { t } = useTranslation()

  return (
    <PreviewCard
      title={t('ELL Information')}
      size="large"
      buttonLabel="More"
      buttonNavigate={`/member/${member.membership.type}/${member.id}/state-data/ell`}
      onlyReadable={onlyReadable}
    >
      <Grid container spacing={4}>
        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('ELL Status')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                ?.filter(item => item.field === 'ellStatus')
                ?.find(item => item.code === member?.stateData?.ell?.status)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Title III ELL Status')}</Typography>
          <Typography variant="body1">
            {
              sharedStateDataType
                ?.filter(item => item.field === 'noAndYes')
                ?.find(item => item.code === member?.stateData?.ell?.titleIIIEllStatus)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('ELL Program Start Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.ell?.programStartDate &&
              dayjs(member?.stateData?.ell?.programStartDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('ELL Program Exit Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.ell?.programEndDate &&
              dayjs(member?.stateData?.ell?.programEndDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('ELA Exemption')}</Typography>
          <Typography variant="body1">
            {
              sharedStateDataType
                ?.filter(item => item.field === 'noAndYes')
                ?.find(item => item.code === member?.stateData?.ell?.exemptTakingElaPortion)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('NJ ELL Information')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.ell?.njEllInfo}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('ELL Accommodation')}</Typography>
          <Typography variant="body1">
            {
              sharedStateDataType
                ?.filter(item => item.field === 'noAndYes')
                ?.find(item => item.code === member?.stateData?.ell?.accommodation)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Language Instruction')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                ?.filter(item => item.field === 'ellLanguageInsEduPrg')
                ?.find(item => item.code === member?.stateData?.ell?.languageInsEduPrg)?.description
            }
            &nbsp;
          </Typography>
        </Grid>
      </Grid>
    </PreviewCard>
  )
}
