import { Route, Routes } from 'react-router-dom'
import VisitsPage from './Page'

export default function Visits() {
  return (
    <Routes>
        <Route path="/" element={<VisitsPage />} />
        <Route path="/:id" element={<VisitsPage />} />
    </Routes>
  )
}
