import {
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import dayjs from 'dayjs'
import _, { omit } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/course-session'
import { save as saveCourseSessionEnrollments } from 'store/settings/course-session-enrollments'
import { search as getStudentCourseSessionEnrollment } from 'store/academic/schedule'
import { useNavigate } from 'react-router-dom'

export default function SchedulingEnrollStudentFormCourseSessionLists({
  activeCourseSessionEnrollments,
  setActiveCourseSessionEnrollments,
  selected,
}) {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { academicYear } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const basePath = `/settings/scheduling/enroll-student/`
  const navigate = useNavigate()
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)

  const [selectedCourseSession, setSelectedCourseSession] = useState([])

  const { count, query, list, person } = useSelector(state => state.settings.courseSession)

  useEffect(() => {
    let courseSessionQuery = {
      ...omit(query, 'courseId'),
      offset: 0,
      academicYearId: query.academicYearId || academicYear?.id,
    }
    list | dispatch(search(courseSessionQuery))
  }, [])

  const getTeacherName = session => {
    const lastname = person?.find(
      p => p.id === session?.courseSessionEnrollments?.find(cse => cse.roleId === 'lead-teacher')?.schoolEnrollmentId,
    )?.membership?.person?.lastName
    const firstname = person?.find(
      p => p.id === session?.courseSessionEnrollments?.find(cse => cse.roleId === 'lead-teacher')?.schoolEnrollmentId,
    )?.membership?.person?.firstName
    if (lastname && firstname) {
      return `${lastname}, ${firstname}`
    }
    return null
  }

  const addRemoveCourseSession = e => {
    if (e.target.checked) {
      if (selectedCourseSession) {
        setSelectedCourseSession([...selectedCourseSession, +e.target.value])
      } else {
        setSelectedCourseSession([+e.target.value])
      }
    } else {
      const temp = selectedCourseSession.filter(scs => scs !== +e.target.value)
      setSelectedCourseSession([...temp])
    }
  }

  const enroll = () => {
    selectedCourseSession.forEach(async courseSession => {
      const payload = {
        schoolEnrollmentId: selected?.schoolEnrollmentId,
        startDate: dayjs(new Date()).format('YYYY-MM-DD'),
        endDate: null,
        roleId: null,
        substitute: false,
      }
      await dispatch(saveCourseSessionEnrollments({ courseSessionId: courseSession, ...payload }))
      await dispatch(getStudentCourseSessionEnrollment({ schoolEnrollmentId: selected?.schoolEnrollmentId })).then(
        response => {
          const temp = response.payload?.filter(cse => cse.endDate === null)
          setActiveCourseSessionEnrollments(temp)
        },
      )
      navigate(basePath)
    })
  }

  const filtered = id => {
    if (activeCourseSessionEnrollments?.find(cse => cse.courseSession.id === id)) {
      return false
    }
    return true
  }

  const getTerm = mp => {
    if (academicYearMarkingPeriods?.length === 1 && mp?.length === 1) {
      return 'Summer'
    } else if (mp?.length === academicYearMarkingPeriods?.length) {
      return 'Full Year'
    } else {
      return mp?.map(item => item.name).toString()
    }
  }

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }

  if (!list) return null

  return (
    <Box>
      <TableContainer>
        <Table
          size="small"
          sx={{
            border: '1px solid',
            borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
            mt: 2,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Session Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Teacher')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Term')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Enrollment')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.map(
                (session, index) =>
                  filtered(session.id) && (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Checkbox
                          color="warning"
                          value={session.id}
                          size="small"
                          onClick={e => addRemoveCourseSession(e)}
                        />
                      </TableCell>
                      <TableCell>{session?.name}</TableCell>
                      <TableCell>{getTeacherName(session)}</TableCell>
                      <TableCell>{getTerm(session.markingPeriods)}</TableCell>
                      <TableCell>
                        {session?.courseSessionEnrollments?.length}/{session.quota || '0'}
                      </TableCell>
                    </TableRow>
                  ),
              )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{
            border: '1px solid',
            borderTop: 'none',
            borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
          }}
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={_.uniq([10, 15, 20, 25, 50, 100])}
        />
      </TableContainer>
      <Stack sx={{ mt: 2, mr: 1 }} flexDirection={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <Button
          onClick={enroll}
          color="warning"
          disabled={!selectedCourseSession || selectedCourseSession.length === 0}
        >
          {t('Enroll')}
        </Button>
      </Stack>
    </Box>
  )
}
