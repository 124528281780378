import { useDispatch } from 'react-redux'
import { Button, Card, CardActions, CardContent, Grid, TextField, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/periods'
import * as yup from 'yup'
import { useEffect } from 'react'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Header from 'components/Header'
import { TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { error as errorSnackbar } from 'utils/snackbar'

export default function PeriodEditForm({ period }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    //id: yup.string().required('Required'),
    name: yup.string().trim().required('Required'),
    startTime: yup.string().required('Regular start time required'),
    endTime: yup
      .string()
      .required('Regular end time required')
      .test('endTime', 'Regular end time must be greater than regular start time', function (value) {
        const { startTime } = this.parent
        if (!value || !startTime) {
          return true
        }
        return dayjs(value, 'HH:mm').isAfter(dayjs(startTime, 'HH:mm'))
      }),
    earlyStartTime: yup.string().required('Early dismissal start time required'),
    earlyEndTime: yup
      .string()
      .required('Early dismissal end time required')
      .test('endTime', 'Early dismissal end time must be greater than early dismissal start time', function (value) {
        const { earlyStartTime } = this.parent
        if (!value || !earlyStartTime) {
          return true
        }
        return dayjs(value, 'HH:mm').isAfter(dayjs(earlyStartTime, 'HH:mm'))
      }),
    delayedStartTime: yup.string().required('Delayed opening start time required'),
    delayedEndTime: yup
      .string()
      .required('Delayed opening end time required')
      .test('endTime', 'Delayed opening end time must be greater than delayed opening start time', function (value) {
        const { delayedStartTime } = this.parent
        if (!value || !delayedStartTime) {
          return true
        }
        return dayjs(value, 'HH:mm').isAfter(dayjs(delayedStartTime, 'HH:mm'))
      }),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(period)
  }, [period, reset])

  const onSubmit = async data => {
    const res = await dispatch(save(_.omit(data, 'academicYear', 'period', 'academicYearId')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  // TODO: This is a temporary solution to handle form errors. Errors should be handled by the form library and displayed in the form.
  const onError = errors => {
    console.log(errors)
    errorSnackbar(
      t(
        errors.startTime?.message ||
          errors.endTime?.message ||
          errors.earlyStartTime?.message ||
          errors.earlyEndTime?.message ||
          errors.delayedStartTime?.message ||
          errors.delayedEndTime?.message,
      ),
    )
  }

  return (
    <BasePathProvider value={`${basePath}/periods`}>
      <Card
        data-component="PeriodEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Header title={t('Add Period')} small close />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} md={5}>
              <Controller
                name="name"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      size="small"
                      label={t('Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={4}>
              <Controller
                name="type"
                control={control}
                defaultValue={'course-session'}
                render={({ field: { value, ...field }, fieldState }) => {
                  const lowerCaseValue = value ? value.toLowerCase() : ''
                  return (
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label={t('Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={lowerCaseValue}
                      {...field}
                      select
                    >
                      <MenuItem value={'course-session'}>{t('Course Session')}</MenuItem>
                      <MenuItem value={'break'}>{t('Break')}</MenuItem>
                      <MenuItem value={'lunch'}>{t('Lunch')}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={3}>
              <Controller
                name="affectDaily"
                control={control}
                defaultValue={false}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label={t('Affect Daily Attendance')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || false}
                      {...field}
                      select
                    >
                      <MenuItem value={true}>{t('Yes')}</MenuItem>
                      <MenuItem value={false}>{t('No')}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>

            {/* REGULAR Period Times */}

            <Grid item sm={12} xs={12} md={12}>
              <h3>{t('Regular')}</h3>
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="startTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('Start Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="endTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('End Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            {/* EARLY DISMISSAL Period Times */}

            <Grid item sm={12} xs={12} md={12}>
              <h3>{t('Early Dismissal')}</h3>
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="earlyStartTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('Start Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="earlyEndTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('End Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            {/* DELAYED OPENING Period Times */}

            <Grid item sm={12} xs={12} md={12}>
              <h3>{t('Delayed Opening')}</h3>
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="delayedStartTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('Start Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item sm={6} xs={6} md={6} container rowSpacing={1}>
              <Controller
                name="delayedEndTime"
                control={control}
                render={({ field: { onChange, value, ...field }, fieldState }) => {
                  return (
                    <TimePicker
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      label={t('End Time')}
                      value={value ? dayjs(value, 'HH:mm') : null}
                      onChange={time => onChange(time ? time.format('HH:mm:ss') : '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={!formState.isDirty}>
            {t('Submit')}
          </Button>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
