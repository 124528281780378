import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'academic-schedule'
const initialState = {}

export const search = createApiAction('academic/schedule/search', client.search('/api/academic/schedule'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateAttendanceTakenPeriods: (state, action) => {
      const { periodId, isChecked } = action.payload
      state.list.attendanceTakenPeriods = state.list.attendanceTakenPeriods.map(item =>
        item.periodId === periodId ? { ...item, isChecked } : item,
      )
    },
  },
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})
export const { updateAttendanceTakenPeriods } = sessionSlice.actions

export default sessionSlice.reducer
