import { IconButton, Link, TableCell, TableRow } from '@mui/material'
import Confirm from 'components/Dialog'
import { DeleteIcon, EditIcon } from 'components/Icons'
import { useBasePath } from 'components/Providers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { destroy as deleteCourseSessionEnrollment } from 'store/settings/course-session-enrollments'
import { clearCourseSessionEnrollments } from 'store/course-session-enrollment'

export const CourseSessionEnrolledItem = ({ data, type }) => {
  const basePath = useBasePath()
  const dispatch = useDispatch()
  const detailPath = `${basePath}/${data?.id}`
  const personDetailPath = `/${type}/${data?.schoolEnrollment?.membership?.person?.id}/attendance`
  const { courseSessionId } = useParams()

  const [confirm, setConfirm] = useState(false)
  const [linkPermission, setLinkPermission] = useState(false)

  const permission = useSelector(state => state.session.permissions)

  const onConfirm = () => {
    setConfirm(false)
    dispatch(deleteCourseSessionEnrollment({ courseSessionId, id: data.id }))
  }

  useEffect(() => {
    if (type === 'student' && permission['student.student'] != 0) {
      setLinkPermission(true)
    }
    if (type === 'staff' && permission['staff.staff'] != 0) {
      setLinkPermission(true)
    }
  }, [type])

  return (
    <TableRow>
      <TableCell>
        {linkPermission ? (
          <Link
            component={NavLink}
            underline="hover"
            color="inherit"
            to={personDetailPath}
            onClick={() => dispatch(clearCourseSessionEnrollments())}
          >
            {data?.schoolEnrollment?.membership?.person?.firstName}{' '}
            {data?.schoolEnrollment?.membership?.person?.lastName}
          </Link>
        ) : (
          <>
            {data?.schoolEnrollment?.membership?.person?.firstName}{' '}
            {data?.schoolEnrollment?.membership?.person?.lastName}
          </>
        )}
      </TableCell>
      {data?.schoolEnrollment?.membership?.type === 'staff' && (
        <TableCell>{data?.courseSessionEnrollmentType?.name}</TableCell>
      )}
      <TableCell>{data?.startDate}</TableCell>
      <TableCell>{data?.endDate}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={detailPath}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
