import { Grid, Table, TableBody, TableHead, TableRow, Typography, Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export default function FinalGrade() {
  const { t } = useTranslation()

  const StyledTableHeadCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottom: '2px solid green',
      width: '5em',
    },
  }))

  const StyledTableBodyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: '5em',
      borderRight: '1px solid #fff',
      borderBottom: '1px solid #fff',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      borderBottom: '2px solid green',
    },
  }))

  return (
    <Box container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell xs={1} style={{ width: '23em' }}>
                {t('Course Name')}
              </StyledTableHeadCell>
              <StyledTableHeadCell xs={1}>{t('Credit')}</StyledTableHeadCell>
              {Array(10)
                .fill()
                .map((_, index) => (
                  <StyledTableHeadCell key={index} xs={1}>
                    <Skeleton />
                  </StyledTableHeadCell>
                ))}
              <StyledTableHeadCell xs={1} style={{ borderLeft: '2px solid green' }}>
                {t('Final Grade')}
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(10)
              .fill()
              .map((_, index) => (
                <StyledTableRow key={index}>
                  <StyledTableBodyCell xs={1} sx={{ width: '23em' }}>
                    <Skeleton />
                  </StyledTableBodyCell>
                  <StyledTableBodyCell xs={1}>
                    <Skeleton />
                  </StyledTableBodyCell>
                  {Array(10)
                    .fill()
                    .map((_, index) => (
                      <StyledTableBodyCell key={index}>
                        <Skeleton />
                      </StyledTableBodyCell>
                    ))}
                  <StyledTableBodyCell
                    sx={{
                      borderLeft: '2px solid green',
                    }}
                  >
                    <Skeleton />
                  </StyledTableBodyCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableRow>
            <StyledTableBodyCell xs={1} style={{ width: '23em' }}>
              <Typography>{t('Average GPA and Credit Total')}:</Typography>
            </StyledTableBodyCell>
            <StyledTableBodyCell xs={1}>
              <Typography>
                <Skeleton />
              </Typography>
            </StyledTableBodyCell>
            {Array(10)
              .fill()
              .map((_, index) => (
                <StyledTableBodyCell key={index} xs={1}>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </StyledTableBodyCell>
              ))}
            <StyledTableBodyCell xs={1} style={{ borderLeft: '2px solid green' }}>
              <Typography>
                <Skeleton />
              </Typography>
            </StyledTableBodyCell>
          </TableRow>
        </Table>
      </Grid>
    </Box>
  )
}
