import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'setting-building-room'
const initialState = {
  count: null,
  list: [],
}

export const search = createApiAction('building-room/search', client.search('/api/setting/building/:buildingId/room'))
export const get = createApiAction('building-room/get', client.retrieve(`/api/setting/building/:buildingId/room/:id`))
export const save = createApiAction('building-room/save', client.save('/api/setting/building/:buildingId/room'))
export const destroy = createApiAction(
  'building-room/delete',
  client.destroy('/api/setting/building/:buildingId/room/:id'),
)

const updateRoom = (state, action) => {
  const found = state.list.find(item => item.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [action.payload, ...state.list]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: state => {
      state.list = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows
        state.count = action.payload.count
      })
      .addCase(get.fulfilled, updateRoom)
      .addCase(save.fulfilled, updateRoom)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})
export const { reset } = sessionSlice.actions
export default sessionSlice.reducer
