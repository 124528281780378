import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

export const districtEmploymentExitReason = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const districtEntryCode = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const annualSuplement = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const employmentSeperationType = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export const employmentStatus = [
  { id: 'no', name: 'No' },
  { id: 'yes', name: 'Yes' },
]

export default function EnrollmentCard({ data, district }) {
  const { t } = useTranslation()
  const { personId } = useParams()

  const exitEmployeeButton = {
    buttonLabel: "Exit Employee",
    buttonNavigate: `/person/${personId}/employment/${district}/enrollment/${data?.id}?formtype=exit`,
    buttonDisabled: false,
    buttonColor: "error",
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mb: 3 }}>
        <PreviewCard
          title={t('Employment Information')}
          size="large"
          buttonNavigate={
            data?.id
              ? `/person/${personId}/employment/${district}/enrollment/${data?.id}`
              : `/person/${personId}/employment/${district || 0}/enrollment/new`
          }
          buttonLabel={data ? t('More') : t('Add')}
          otherButton={data?.id && !data?.endDate && exitEmployeeButton}
          actionChildren={
            data?.endDate && (
              <Box sx={{ width: '100%', mx: 1 }}>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontWeight: '600' }}>{t('Exit Information')}</Typography>
                  <Button
                    component={Link}
                    to={`/person/${personId}/employment/${district}/enrollment/${data?.id}?formtype=exit`}
                    size="small"
                    color={'warning'}
                    variant="contained"
                  >
                    {t('More')}
                  </Button>
                </Stack>
                <Divider sx={{ mx: -3, my: 1 }} />
                <Grid container spacing={2}>
                  <Grid item sm={4} xs={4} md={4}>
                    <Typography variant="caption">{t('District Employment Exit Date')}</Typography>
                    <Typography variant="subtitle1">
                      {data?.endDate && dayjs(data?.endDate).format('L')} &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={4} md={4}>
                    <Typography variant="caption">{t('District Employment Exit Reason')}</Typography>
                    <Typography variant="subtitle1">
                      {
                        districtEmploymentExitReason.find(
                          d => d.id === data?.stateData?.enrollment?.districtEmploymentExitReason,
                        )?.name
                      }{' '}
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={4} md={4}>
                    <Typography variant="caption">{t('Employment Separation Type')}</Typography>
                    <Typography variant="subtitle1">
                      {
                        employmentSeperationType.find(
                          d => d.id === data?.stateData?.enrollment?.employmentSeperationType,
                        )?.name
                      }
                      &nbsp;
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('District Employment Begin Date')}</Typography>
              <Typography variant="subtitle1">
                {data?.startDate && dayjs(data?.startDate).format('L')} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('District Entry Code')}</Typography>
              <Typography variant="subtitle1">
                {districtEntryCode.find(d => d.id === data?.stateData?.enrollment?.districtEntryCode)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Employment Status')}</Typography>
              <Typography variant="subtitle1">
                {employmentStatus.find(d => d.id === data?.stateData?.enrollment?.employmentStatus)?.name} &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Base Salary')}</Typography>
              <Typography variant="subtitle1">{data?.stateData?.enrollment?.baseSalary} &nbsp;</Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Annual Suplement')}</Typography>
              <Typography variant="subtitle1">
                {annualSuplement.find(d => d.id === data?.stateData?.enrollment?.annualSuplement)?.name} &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
