import { createElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { FaHospitalUser, FaBiking, FaUmbrellaBeach, FaUmbrella, FaHandHoldingUsd } from 'react-icons/fa'

const iconMap = {
  FaHospitalUser,
  FaBiking,
  FaUmbrellaBeach,
  FaUmbrella,
  FaHandHoldingUsd,
}

export default function RequestCard({ data }) {
  const IconComponent = iconMap[data.icon]

  return (
    <Box sx={{display: 'flex', alignItems: 'flex-start', gap: '10px', alignSelf: 'stretch'}}>
      <Box sx={{ p: '10px', border: '2px solid', borderColor: 'primary.main', borderRadius: '4px',  display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', flex: '1 0 0'}}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Grid item xs={9}>
              <Box sx={{ fontSize: 'body1' }}>{data.name}</Box>
            </Grid>
            <Grid item xs={9} sx={{ mx: 0, px: 0 }}>
              <Grid
                container
                alignItems="center"
                style={{
                  marginTop: '15px',
                  marginRight: '0px',
                  paddingRight: '0px',
                }}
              >
                <Grid item>
                  <Typography variant="h6" style={{ marginRight: '4px' }}>
                    {`${data.remaining} Days`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ paddingTop: '10px', color: 'gray' }}>
                    {`Remaining`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ mx: 0, px: 0 }}>
            <Box sx={{ fontSize: '45px', display: 'flex', alignItems: 'center', color: 'primary.light', mt: 3, mx: 0, p: 0 }}>
              {IconComponent && createElement(IconComponent)}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ fontSize: '0.7rem', marginLeft: '16px' }}>
              <span style={{ fontSize: '1.2rem' }}>{data.rolledOver}</span> Rolled Over |{' '}
              <span style={{ fontSize: '1.2rem' }}>{data.used}</span> Used
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
