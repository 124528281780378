import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { DeleteIcon, EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/academic/grading-policy'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function GradingItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item.id, courseSessionId: item.courseSession.id }))
  }
  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item.type}
        </Link>
      </TableCell>
      <TableCell>{item.weight * 100}%</TableCell>
      <TableCell>{item.courseSession.name}</TableCell>
      <TableCell>{item.courseSession.course.name}</TableCell>
      <TableCell>{item.courseSession.course.gradeLevel.name}</TableCell>

      <TableCell align="right">
        <IconButton
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item.id}`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
