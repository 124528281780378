import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/nurse/nurse-health-condition'
import { BasePathProvider, useBasePath } from 'components/Providers'
import NurseAddConditionForm from './Form'
import NurseConditionFormSkeleton from './FormSkeleton'

export default function NurseAddConditionFormIndex({selected}) {
  const dispatch = useDispatch()
  const [healthCondition, setHealthCondition] = useState(null)
  const basePath = useBasePath()

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setHealthCondition(res.payload)
      })
    } else {
        setHealthCondition(null)
    }
  }, [id])

  if (healthCondition || id === 'new') {
    return (
      <BasePathProvider value={`${basePath}`}>
        <NurseAddConditionForm healthCondition={healthCondition} selected={selected} />
      </BasePathProvider>
    )
  }

  if (!healthCondition) {
    return < NurseConditionFormSkeleton />
  }
}
