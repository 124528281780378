import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

export default function UnScheduleList({ list, permission }) {
  if (list.length > 0) {
    return (
      <Grid item xs={12} sx={{ mt: 3, pb: 5 }}>
        <Typography variant="h5">Other Sessions</Typography>
        <Stack className="unschedule-session-list" sx={{ mt: 2 }}>
          {list.map((item, index) => (
            <Box key={index}>
              {permission ? (
                <Link
                  component={NavLink}
                  to={`/settings/course-session/${item.courseSession.id}`}
                  underline="hover"
                  color="inherit"
                >
                  {item?.courseSession?.course?.name} - {item?.courseSession?.name}
                </Link>
              ) : (
                <>
                  {item?.courseSession?.course?.name} - {item?.courseSession?.name}
                </>
              )}
            </Box>
          ))}
        </Stack>
      </Grid>
    )
  }
}
