import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yuppassword from 'yup-password'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'

import Title from './Title'
import { Box, TextField } from '@mui/material'

import { changePassword } from '../store/session'
import MyAlert from './Alert'

yuppassword(yup)

export default function ChangePassword() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({ open: false, status: '', message: '' })

  const schema = yup
    .object()
    .shape({
      current: yup.string().required('Required'),
      password: yup
        .string()
        .required('Required')
        .min(8, 'Your password is too short.')
        .minUppercase(2)
        .minLowercase(2)
        .minSymbols(1)
        .minRepeating(2),
      confirm: yup.string().oneOf([yup.ref('password')], 'Your passwords do not match.'),
    })
    .required()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      current: '',
      password: '',
      confirm: '',
    },
  })

  const onSubmit = async data => {
    setState(prev => ({ ...prev, open: false }))
    const res = await dispatch(changePassword(data))
    if (res.error) {
      setState({ open: true, status: 'error', message: res.error.data?.error || res.error.message })
    } else {
      setState({ open: true, status: 'success', message: 'Your password is updated!' })
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ p: 2 }}>
      <Title>Change Password</Title>
      <Controller
        name="current"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              autoFocus
              fullWidth
              margin="normal"
              type="password"
              // variant="filled"
              label={t("Current password")}
              data-label="current-password"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...field}
            />
          )
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              fullWidth
              margin="normal"
              type="password"
              // variant="filled"
              label={t("New password")}
              data-label="new-password"
              autoComplete="new-password"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...field}
            />
          )
        }}
      />
      <Controller
        name="confirm"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              fullWidth
              margin="normal"
              type="password"
              // variant="filled"
              label={t("Re-type new password")}
              data-label="new-re-password"
              autoComplete="new-password"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...field}
              sx={{ mb: 2 }}
            />
          )
        }}
      />
      <MyAlert open={state.open} status={state.status} title={t(state.message)} />
      <Button data-label="submit-change-password-form" fullWidth type="submit" variant="contained" sx={{ mb: 2 }}>
        {t("Submit")}
      </Button>
    </Box>
  )
}
