import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  list: null,
}

export const search = createApiAction('building/search', client.search('/api/setting/building'))
export const get = createApiAction('building/get', client.retrieve(`/api/setting/building/:id`))
export const save = createApiAction('building/save', client.save('/api/setting/building'))
export const destroy = createApiAction('building/delete', client.destroy('/api/setting/building/:id'))

const updateList = (state, action) => {
  const found = state.list.find(item => item.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [action.payload, ...state.list]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows
        state.count = action.payload.count
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
