import { BasePathProvider } from 'components/Providers'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import SchedulingEnrollCourseListIndex from './List'
import SchedulingEnrollCourseFormIndex from './Form'
import { useState } from 'react'

export default function SchedulingEnrollCoursePage() {
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/enroll-session/`
  const { enrollSession } = useParams()
  const [selectedCourseSession, setSelectedCourseSession] = useState()

  return (
    <BasePathProvider value={`${basePath}`}>
      <SchedulingEnrollCourseListIndex
        selectedCourseSession={selectedCourseSession}
        setSelectedCourseSession={setSelectedCourseSession}
      />
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(enrollSession)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <SchedulingEnrollCourseFormIndex selectedCourseSession={selectedCourseSession} setSelectedCourseSession={setSelectedCourseSession} />
      </Drawer>
    </BasePathProvider>
  )
}
