import { useEffect } from 'react'
import { CardContent, Grid, TextField, Toolbar, MenuItem, Button, CardActions, Box } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/bell-schedule'
import * as yup from 'yup'
import { omit } from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider, useBasePath } from 'components/Providers'
import Periods from './Period'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function BellScheduleEditForm({ bellSchedule, buildings }) {
  const { bellScheduleId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    building: yup.object().shape({
      id: yup
        .number()
        .nullable()
        .transform(value => value || null),
    }),
  })

  const { control, handleSubmit, getValues, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(bellSchedule)
  }, [bellSchedule, reset])

  const onSubmit = async data => {
    const buildingId = data.building.id
    const payload = { ...omit(data, ['building', 'bellSchedulePeriods']), buildingId }
    const res = await dispatch(save(payload))
    if (res.payload) {
      data?.id ? navigate(basePath) : navigate(`${basePath}/${res.payload.id}`)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/${bellScheduleId}`}>
      <Toolbar />
      <Header title={t("Bell Schedule")} close />
      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      label={t("Bell Schedule Name")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="building.id"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t("Buildings")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>{' '}
                      {buildings?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t("Cancel")}
          </Button>
          <Button type="submit"  variant="contained" disabled={!formState.isDirty}>
            {t("Submit")}
          </Button>
        </CardActions>
      </Box>
      <CardContent sx={{ p: 0 }}>{getValues('id') && <Periods />}</CardContent>
    </BasePathProvider>
  )
}
