import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'job-position'
const initialState = {
  list: null,
}

export const search = createApiAction('job-position/search', client.search('/api/setting/job-position'))
export const get = createApiAction('job-position/get', client.retrieve(`/api/setting/job-position/:id`))
export const save = createApiAction('job-position/save', client.save('/api/setting/job-position'))
export const destroy = createApiAction('job-position/delete', client.destroy('/api/setting/job-position/:id'))

const update = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  if (state.list) {
    state.list = found
      ? state.list?.map(item => (found.id === item.id ? action.payload : item))
      : [...state.list, action.payload]
  }
}

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, update)
      .addCase(save.fulfilled, update)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})
export default sessionSlice.reducer
