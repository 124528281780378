import { BasePathProvider } from 'components/Providers'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import SchedulingEnrollStudentListIndex from './List'
import SchedulingEnrollStudentFormIndex from './Form'
import { useEffect, useState } from 'react'
import { search as getStudentCourseSessionEnrollment } from 'store/settings/course-session-enrollment'
import { useDispatch } from 'react-redux'

export default function SchedulingEnrollStudentPage() {
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/enroll-student/`
  const { enrollId } = useParams()
  const [selected, setSelected] = useState()
  const [activeCourseSessionEnrollments, setActiveCourseSessionEnrollments] = useState()
  const [courseSessionEnrollments, setCourseSessionEnrollments] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (selected) {
      dispatch(
        getStudentCourseSessionEnrollment({
          schoolEnrollmentId: selected?.schoolEnrollmentId,
        }),
      ).then(response => {
        const active = response.payload?.list?.filter(cse => cse.endDate === null)
        const inactive = response.payload?.list
        setActiveCourseSessionEnrollments(active)
        setCourseSessionEnrollments(inactive)
      })
    } else {
      setActiveCourseSessionEnrollments(undefined)
      setCourseSessionEnrollments(undefined)
    }
  }, [selected])

  useEffect(() => {
    if (courseSessionEnrollments) {
      setActiveCourseSessionEnrollments(courseSessionEnrollments.filter(cse => cse.endDate === null))
    }
  }, [courseSessionEnrollments])

  return (
    <BasePathProvider value={`${basePath}`}>
      <SchedulingEnrollStudentListIndex
        courseSessionEnrollments={courseSessionEnrollments}
        setCourseSessionEnrollments={setCourseSessionEnrollments}
        activeCourseSessionEnrollments={activeCourseSessionEnrollments}
        setActiveCourseSessionEnrollments={setActiveCourseSessionEnrollments}
        selected={selected}
        setSelected={setSelected}
      />
      {selected && (
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(enrollId)}
          anchor="right"
          onClose={() => navigate(`${basePath}`)}
        >
          <SchedulingEnrollStudentFormIndex
            activeCourseSessionEnrollments={activeCourseSessionEnrollments}
            setActiveCourseSessionEnrollments={setActiveCourseSessionEnrollments}
            selected={selected}
            setSelected={setSelected}
          />
        </Drawer>
      )}
    </BasePathProvider>
  )
}
