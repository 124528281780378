import { CardContent, Grid, Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'

export default function AcademicYearProviderListSkeleton() {
  return (
    <Box data-component="AcademicYearList" sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
            <CardContent sx={{ pl: 0, pr: 0, minHeight: '600px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Type</TableHeadCell>
                    <TableHeadCell>Start Date</TableHeadCell>
                    <TableHeadCell>End Date</TableHeadCell>
                    <TableHeadCell>Actions</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[0, 1, 2].map(item => (
                    <TableRow key={item}>
                      <TableCell sx={{ width: '4em' }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
        </Grid>
      </Grid>
    </Box>
  )
}
