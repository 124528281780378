import { Box } from '@mui/material'
import StudentSearchBar from './Search'
import StudentsAttendanceList from './List'

export default function AttendanceListIndex({ setStudent, date, setDate }) {
  return (
    <Box>
      <StudentSearchBar date={date} setDate={setDate} />
      <StudentsAttendanceList setStudent={setStudent} date={date}/>
    </Box>
  )
}
