import { useTheme } from '@emotion/react'
import { Box, Stack } from '@mui/material'
import dayjs from 'dayjs'

export default function SchedulePeriods({ periodListWidth, hours }) {
  const theme = useTheme()

  return (
    <Box className="period-list" sx={{ width: `${periodListWidth}px`, marginTop: '57px', marginRight: '1px' }}>
      {hours.map((hour, index) => (
        <Stack
          className="session-period-hours"
          height={'90px'}
          width={`${periodListWidth}px`}
          sx={
            index % 2 === 0
              ? { backgroundColor: theme.palette.action.disabled, marginBottom: '1px' }
              : { backgroundColor: theme.palette.action.hover, marginBottom: '1px' }
          }
          key={index}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {dayjs(hour, 'HH:mm:ss').format('h a')}
        </Stack>
      ))}
    </Box>
  )
}
