import { useState, useEffect } from 'react'
import { Drawer } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { BasePathProvider } from 'components/Providers'
import Header from 'components/Header'
import AcademicYearList from './List/AYList'
import AcademicYearSkeleton from './List/AYSkeleton'
import AcademicYearEditForm from './Form/AYForm'
import { useNavigate, useParams } from 'react-router-dom'
import SearchBar from './SearchBar'
import { getSchools } from 'store/lookup'
import { get } from 'store/settings/academic-year'
import { clearMarkingPeriods } from 'store/settings/marking-period'

const baseUrl = '/settings/academic-year'

export default function AcademicYearsPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { academicYearId } = useParams()
  const [academicYear, setAcademicYear] = useState('')

  const schools = useSelector(state => state.lookup.schools)
  const academicYearList = useSelector(state => state.academicYear.years)
  const { query } = useSelector(state => state.academicYear)

  useEffect(() => {
    dispatch(clearMarkingPeriods())
  }, [dispatch, academicYearId])

  useEffect(() => {
    schools?.length || dispatch(getSchools())
  }, [dispatch, schools?.length])

  const getSchool = () => {
    return schools && schools.find(item => item.id === query.schoolId)
  }

  useEffect(() => {
    if (academicYearId === 'new') {
      setAcademicYear({ school: getSchool() })
      return
    }
    if (academicYearId) {
      academicYearId &&
        dispatch(get({ id: academicYearId })).then(res => {
          setAcademicYear(res.payload)
        })
      return
    }
    setAcademicYear(null)
  }, [dispatch, academicYearId])

  const onAdd = url => {
    if (query.schoolId) navigate(url)
    else console.error('------>', url, query.schoolId)
  }

  let isLoading = true
  if (!academicYearList || !schools?.length) {
    isLoading = true
  } else {
    isLoading = false
  }

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'Academic Year List'} add={onAdd} />
      {schools && <SearchBar schools={schools} />}
      {!isLoading ? <AcademicYearList /> : <AcademicYearSkeleton />}
      <Drawer
        PaperProps={{
          sx: {
            width: 520,
          },
        }}
        open={Boolean(academicYearId)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        {academicYear && <AcademicYearEditForm academicYear={academicYear} />}
      </Drawer>
    </BasePathProvider>
  )
}
