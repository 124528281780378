import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/nurse/nurse-visit'
import { BasePathProvider, useBasePath } from 'components/Providers'
import NurseVisitFormSkeleton from './FormSkeleton'
import NurseAddVisitForm from './Form'
import NurseFinalizeVisitForm from './FormFinalize'

export default function NurseAddVisitFormIndex() {
  const dispatch = useDispatch()
  const [nurseVisit, setNurseVisit] = useState(null)
  const basePath = useBasePath()
  const location = useLocation();
  const action = location.state?.action;

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setNurseVisit(res.payload)
      })
    } else {
        setNurseVisit(null)
    }
  }, [dispatch, id])

  if (( action === 'edit' && nurseVisit) || id === 'new' ) {
    return (
      <BasePathProvider value={`${basePath}`}>
        <NurseAddVisitForm nurseVisit={nurseVisit} />
      </BasePathProvider>
    );
  }

  if (action === 'endVisit' && nurseVisit) {
    return (
      <BasePathProvider value={`${basePath}`}>
        <NurseFinalizeVisitForm nurseVisit={nurseVisit} />
      </BasePathProvider>
    );
  }

  if (!nurseVisit) {
    return < NurseVisitFormSkeleton />
  }
}
