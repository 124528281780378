import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'healthCondition'
const initialState = {
    list: null,
  }

export const search = createApiAction('healthCondition/search', client.search('/api/nurse/health-condition'))

export const destroy = createApiAction(
  'healthCondition/delete',
  client.destroy('/api/nurse/health-condition/:id'),
)

export const get = createApiAction(
  'healthCondition/get',
  client.retrieve(`/api/nurse/health-condition/:id`),
)

export const save = createApiAction(
  'healthCondition/save',
  client.save('/api/nurse/health-condition'),
)

const update = (state, action) => {
  const found = state.list?.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [...state.list, action.payload]
}

  const sessionSlice = createSlice({
    name,
    initialState,
    reducers: {
      clearConditions: state => {
        state.list = null
      },
    },
    extraReducers(builder) {
      builder
        .addCase(search.fulfilled, (state, action) => {
          state.list = action.payload})
       .addCase(get.fulfilled, update)
       .addCase(save.fulfilled, update)
        .addCase(destroy.fulfilled, (state, action) => {
          state.list = state.list.filter(item => item.id !== action.payload.id)
        })
    },
  })
  
  export const { clearConditions } = sessionSlice.actions
  export default sessionSlice.reducer