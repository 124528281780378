import { TextField, Grid, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

export default function PhoneEditForm({ path, control, item, index }) {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} md={12} key={item?.id}>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <Controller
            name={`${path}.${index}.type`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  select
                  size="small"
                  label={t('Type')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  <MenuItem value="home">{t('Home')}</MenuItem>
                  <MenuItem value="mobile">{t('Mobile')}</MenuItem>
                  <MenuItem value="work">{t('Work')}</MenuItem>
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={9} md={9}>
          <Controller
            name={`${path}.${index}.number`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={index > 0 ? t('Alternate Number') : t('Primary Number')}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
