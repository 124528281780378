import { Card, CardContent, Grid, Skeleton, Toolbar, CardActions, Button } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function PeriodFormSkeleton() {
  const { t } = useTranslation()

  return (
    <BasePathProvider>
      <Card data-component="PeriodFormSkeleton" sx={{ minHeight: '100%' }}>
        <Toolbar />
        <Header title={t('Add Period')} small close />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12} md={5}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={12} xs={12} md={4}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={12} xs={12} md={3}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3}>
                <Skeleton variant="rounded" height={30} sx={{ marginTop: '30px', marginLeft: '8px' }} />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {/* This empty item serves as an offset. */}
              </Grid>
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3}>
                <Skeleton variant="rounded" height={30} sx={{ marginTop: '30px', marginLeft: '8px' }} />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {/* This empty item serves as an offset. */}
              </Grid>
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3}>
                <Skeleton variant="rounded" height={30} sx={{ marginTop: '30px', marginLeft: '8px' }} />
              </Grid>
              <Grid item xs={9} sm={9} md={9}>
                {/* This empty item serves as an offset. */}
              </Grid>
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" height={30} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined">
            <Skeleton />
          </Button>
          <Button type="submit" variant="contained">
            <Skeleton />
          </Button>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
