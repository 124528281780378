import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  Box,
  Toolbar,
  CardActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import Header from 'components/Header'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'

export default function AcademicYearFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Card component="form" noValidate sx={{ minHeight: '100%' }}>
      <Toolbar />
      <Header title={t("Academic Year")} close small />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Skeleton />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Skeleton />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Box>
            <Header title={'Marking Period List'} small add />
            <Paper data-component="MarkingPeriodsList" sx={{ minHeight: '50%' }}>
              <Grid container spacing={2} sx={{ marginTop: 0 }}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ minHeight: '600px' }}>
                    <CardContent sx={{ pl: 0, pr: 0 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeadCell sx={{ width: '18em' }}>Marking Periods Name</TableHeadCell>
                            <TableHeadCell sx={{ width: '15em' }}>Start Date</TableHeadCell>
                            <TableHeadCell sx={{ width: '15em' }}>End Date</TableHeadCell>
                            <TableHeadCell sx={{ width: '10em' }}></TableHeadCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[0, 1, 2].map(item => (
                            <TableRow key={item}>
                              <TableCell sx={{ width: '4em' }}>
                                <Skeleton />
                              </TableCell>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                              <TableCell>
                                <Skeleton />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined">
          {' '}
          <Skeleton />
        </Button>
        <Button type="submit" variant="contained">
          <Skeleton />
        </Button>
      </CardActions>
    </Card>
  )
}
