import { Box } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import SchedulingEnrollStudentFormSearch from './Search'
import SchedulingEnrollStudentFormCourseSessionLists from './CourseSessionLists'

export default function SchedulingEnrollStudentForm({
  activeCourseSessionEnrollments,
  setActiveCourseSessionEnrollments,
  selected,
}) {
  const { t } = useTranslation()

  const basePath = `/settings/scheduling/enroll-student/`

  if (!selected) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box sx={{ overflowY: 'scroll', border: 'none' }}>
        <Header title={t('Enroll by Student - Add Session')} small close borderBottom />
      </Box>
      <Box sx={{ m: 3 }}>
        <SchedulingEnrollStudentFormSearch />
        <SchedulingEnrollStudentFormCourseSessionLists
          activeCourseSessionEnrollments={activeCourseSessionEnrollments}
          setActiveCourseSessionEnrollments={setActiveCourseSessionEnrollments}
          selected={selected}
        />
      </Box>
    </BasePathProvider>
  )
}
