import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

import setContactReducers from './contact'
import setStateDataReducers from './state-data'
import setDocumentReducers from './document'
import setPersonalReducers from './personal'
import setAcademicYearEnrollment from './academic-year-enrollment'
import setCourseEnrollmentReducers from './course-session-enrollment'
import setSchoolEnrollmentReducers from './school-enrollment'
import setAttendanceReportReducer from './attendance-report'
import setFinalGradeReducer from './final-grades'
import setRolesReducers from './roles'
import setScheduleReducers from './schedule'

const name = 'membership'
const initialState = {
  list: null,
  count: null,
  query: {
    other: {},
    staff: { type: 'staff', limit: 10, offset: 0 },
    student: { type: 'student', limit: 10, offset: 0 },
    parent: { type: 'parent', limit: 10, offset: 0 },
  },
  loading: 0,
}

export const search = createApiAction('member/search', client.search('/api/member/:type'))
export const retrieve = createApiAction('member/get', client.retrieve(`/api/member/:type/:id`))
export const save = createApiAction('member/save', client.save('/api/member/:type'))
export const destroy = createApiAction('member/destroy', client.destroy('/api/member/:type/:id'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        state.loading += 1
      })
      .addCase(search.fulfilled, (state, action) => {
        state.loading -= 1
        state.list = action.payload.rows
        state.count = action.payload.count
        const { type } = action.meta.arg
        state.query = { ...state.query, [type]: action.meta.arg }
      })
      .addCase(search.rejected, state => {
        state.loading -= 1
      })
      .addCase(retrieve.pending, state => {
        state.loading += 1
      })
      .addCase(retrieve.fulfilled, (state, action) => {
        state.loading -= 1
        const list = state.list || []
        const found = list.find(item => item.id === action.payload.id)
        if (found)
          state.list = list.map(item => (item.id === action.payload.id ? _.extend(found, action.payload) : item))
        else state.list = [...list, action.payload]
      })
      .addCase(retrieve.rejected, state => {
        state.loading -= 1
      })
    setContactReducers(builder)
    setStateDataReducers(builder)
    setDocumentReducers(builder)
    setPersonalReducers(builder)
    setAcademicYearEnrollment(builder)
    setCourseEnrollmentReducers(builder)
    setSchoolEnrollmentReducers(builder)
    setAttendanceReportReducer(builder)
    setFinalGradeReducer(builder)
    setRolesReducers(builder)
    setScheduleReducers(builder)
  },
})

export default sessionSlice.reducer
