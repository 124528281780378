import { Box } from '@mui/material'
import { SessionProvider } from 'components/Providers'
import SchoolEnrolments from './SchoolEnrollment'
import CourseEnrolments from './CourseEnrollment'
import AcademicYearEnrollment from './AcademicYearEnrollment'

export default function Wrapper({
  hideSchoolEnrollment,
  hideAcademicYeaarEnrollment,
  schoolEnrollments,
  session,
  selectedSchoolEnrollment,
  selectedAcademicYearEnrollment,
}) {

  return (
    <Box>
      {!hideSchoolEnrollment && schoolEnrollments && <SchoolEnrolments enrollments={schoolEnrollments} />}
      <SessionProvider
        session={{
          ...session,
          schoolEnrollment: selectedSchoolEnrollment,
        }}
      >
        {!hideAcademicYeaarEnrollment && selectedSchoolEnrollment && <AcademicYearEnrollment />}
        <SessionProvider
          session={{
            ...session,
            schoolEnrollment: selectedSchoolEnrollment,
            academicYear: selectedAcademicYearEnrollment,
          }}
        >
          {selectedSchoolEnrollment && selectedAcademicYearEnrollment && <CourseEnrolments />}
        </SessionProvider>
      </SessionProvider>
    </Box>
  )
}
