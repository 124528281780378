import { useParams } from 'react-router-dom'
import { get } from 'store/settings/district'
import { getCountry } from 'store/lookup'

import DistrictEditForm from './Form'
import DistrictEditFormSkeleton from './FormSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

export default function DistrictFormIndex() {
  const dispatch = useDispatch()

  const { id } = useParams()
  const { country } = useSelector(state => state.lookup)

  const [isLoaded, setIsLoaded] = useState()
  const [district, setDistrict] = useState()

  useEffect(() => {
    if (!country) {
      dispatch(getCountry())
    }
  }, [country])

  useEffect(() => {
    if (id && id !== 'new') {
      setIsLoaded(false)
      dispatch(get({ id })).then(res => {
        setDistrict(res.payload)
      })
    } else {
      setDistrict({})
    }
  }, [id])

  useEffect(() => {
    if (district && country) {
      setIsLoaded(true)
    }
  }, [district, country])

  return isLoaded ? <DistrictEditForm district={district} /> : <DistrictEditFormSkeleton />
}
