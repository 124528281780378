import { useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'

function Invitation() {
  const { token } = useParams()
  useEffect(() => {
    localStorage.setItem('approve', token)
  }, [token])

  return <Navigate to="/" replace={true} />
}

export default Invitation
