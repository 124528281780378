import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { get } from 'store/settings/course'
import { getCourseSubjects } from 'store/lookup'
import CourseEditFormSkeleton from './FormSkeleton'
import CourseEditForm from './Form'

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  minWidth: theme.breakpoints.values.sm,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export default function CourseFormIndex() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const gradingMethods = useSelector(state => state.lookup.gradingMethods)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const [course, setCourse] = useState(null)

  useEffect(() => {
    if (!courseSubjects) {
      dispatch(getCourseSubjects())
    }
  }, [dispatch, courseSubjects])

  useEffect(() => {
    const prepare = async id => {
      if (id === 'new') return setCourse({ enabled: true })
      const { payload } = await dispatch(get({ id }))
      payload && setCourse(payload)
    }
    prepare(id)
  }, [dispatch, id])

  if (!courseSubjects || !course) return <CourseEditFormSkeleton />

  return (
    <Root>
      <CourseEditForm
        course={course}
        subjects={courseSubjects}
        gradingMethods={gradingMethods}
        gradeLevels={gradeLevels}
      />
    </Root>
  )
}
