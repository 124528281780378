import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const search = createApiAction(
  'academic/student/final-grades/search',
  client.search('/api/academic/:type/:memberId/final-grades'),
)

export default function setStudentFinalGradesReducer(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    state.detail.finalGrades = action.payload
  })
}
