import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  // Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { AddIcon, ExpandMoreIcon } from 'components/Icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import EnrollmentCard from './Employment'
import ExperienceCard from './Experience'
import AssignmentCard from './Assignment'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

export default function EmploymentsList({ clients, employments }) {
  const { t } = useTranslation()
  const { personId } = useParams()
  const serviceProviderDistrictId = useSelector(state => state.session.district.id)
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(undefined)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  if (clients?.length < 1 && employments) return null

  const activeMemberships = employments?.filter(
    ms => ms.endDate === null && ms.districtId !== serviceProviderDistrictId,
  )
  const inactiveMemberships = employments?.filter(
    ms => ms.endDate !== null && ms.districtId !== serviceProviderDistrictId,
  )

  return (
    <Box>
      <Box sx={{ m: 3 }}>
        <Stack alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ mb: 3 }} flexDirection={'row'} gap={1}>
          <Button
            startIcon={<AddIcon />}
            size="small"
            variant="contained"
            color="warning"
            onClick={() => navigate(`/person/${personId}/employment/0/enrollment/new`)}
          >
            {t('Add Employment')}
          </Button>
        </Stack>

        {employments && employments?.length === 0 && (
          <>
            <EnrollmentCard />
            <ExperienceCard />
            <AssignmentCard />
          </>
        )}

        {activeMemberships && activeMemberships.length >= 1 && (
          <Typography variant="h6" sx={{ my: 2, fontWeight: 'bold' }}>
            Active Employments
          </Typography>
        )}

        {activeMemberships &&
          activeMemberships.length >= 1 &&
          activeMemberships.map((item, index) => (
            <>
              <Accordion
                key={index}
                expanded={expanded === undefined && index === 0 ? true : expanded === `client_${item.id}`}
                onChange={handleChange(`client_${item.id}`)}
                sx={{ boxShadow: 'none' }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container>
                    <Grid item sm={3} xs={3} md={3}>
                      <Typography sx={{ flexShrink: 0 }}>{item?.district?.name}</Typography>
                    </Grid>
                    <Grid item sm={9} xs={9} md={9}>
                      <Typography sx={{ ml: 6 }}>Employed since {dayjs(item?.startDate).format('L')}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <EnrollmentCard data={item} district={item.districtId} />
                  <ExperienceCard data={item} district={item.districtId} />
                  <AssignmentCard data={item} district={item.districtId} />

                  <Stack alignItems={'flex-end'} sx={{ mb: 3 }}>
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        navigate(`/person/${personId}/employment/${item.districtId}/assignment/${item.id}`)
                      }
                    >
                      {t('Add Assignment')}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </>
          ))}

        {activeMemberships &&
          activeMemberships.length >= 1 &&
          inactiveMemberships &&
          inactiveMemberships.length >= 1 && <Divider sx={{ mt: 5, mb: 3 }} />}

        {inactiveMemberships && inactiveMemberships.length >= 1 && (
          <Box sx={{ m: 3, ml: 0 }}>
            <Typography variant="h6" sx={{ my: 2, fontWeight: 'bold' }}>
              Inactive Employments
            </Typography>
          </Box>
        )}

        {inactiveMemberships &&
          inactiveMemberships.length >= 1 &&
          inactiveMemberships.map((item, index) => (
            <>
              <Accordion
                sx={{ boxShadow: 'none' }}
                key={index}
                expanded={expanded === `client_${item.id}`}
                onChange={handleChange(`client_${item.id}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container>
                    <Grid item sm={3} xs={3} md={3}>
                      <Typography sx={{ flexShrink: 0 }}>
                        {clients.find(c => c.client.id === item.districtId)?.client?.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={9} md={9}>
                      <Typography sx={{ ml: 6 }}>Employed since {dayjs(item?.startDate).format('L')}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <EnrollmentCard data={item} district={item.districtId} />
                  <ExperienceCard data={item} district={item.districtId} />
                  <AssignmentCard data={item} district={item.districtId} />

                  <Stack alignItems={'flex-end'} sx={{ mb: 3 }}>
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        navigate(`/person/${personId}/employment/${item.districtId}/assignments/${item.id}`)
                      }
                    >
                      {t('Add Assignment')}
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
      </Box>
    </Box>
  )
}
