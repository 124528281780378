import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search, get } from '../../../store/settings/building'
import BuildingList from './BuildingList'
import BuildingEditForm from './BuildingEditForm'
import Header from '../../../components/Header'
import { BasePathProvider } from '../../../components/Providers'
import { getCampus, getSchools, getCountry } from '../../../store/lookup'

const baseUrl = '/settings/building'

export default function Building() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [building, setBuilding] = useState(null)
  const { buildingId } = useParams()
  const { campus, schools, country } = useSelector(state => state.lookup)
  const buildings = useSelector(state => state.settings.building.list)

  useEffect(() => {
    buildings || dispatch(search())
  }, [dispatch, Boolean(buildings)])

  useEffect(() => {
    campus || dispatch(getCampus())
  }, [dispatch, Boolean(campus)])

  useEffect(() => {
    schools || dispatch(getSchools())
  }, [dispatch, Boolean(schools)])

  useEffect(() => {
    country || dispatch(getCountry())
  }, [dispatch, country])

  useEffect(() => {
    if (buildingId === 'new') {
      setBuilding({})
      return
    }
    if (buildingId) {
      dispatch(get({ id: buildingId })).then(res => {
        setBuilding(res.payload)
      })
      return
    }
    setBuilding(null)
  }, [dispatch, buildingId])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'Building'} add />
      <BuildingList buildings={buildings} />
      <Drawer
        PaperProps={{
          sx: {
            width: 700,
          },
        }}
        open={Boolean(buildingId && building)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        {campus && schools && country && building && (
          <BuildingEditForm building={building} campus={campus} schools={schools} />
        )}
      </Drawer>{' '}
    </BasePathProvider>
  )
}
