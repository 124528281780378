import { useEffect } from 'react'
import { Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { search } from 'store/academic/course-session'
import CourseSessionItem from './CourseSessionItem'
import { useDispatch, useSelector } from 'react-redux'
import Header from 'components/Header'

export default function CourseList() {
  const dispatch = useDispatch()
  const academicYear = useSelector(state => state.session.academicYear)
  const list = useSelector(state => state.academic.courseSession.list)

  useEffect(() => {
    dispatch(search())
  }, [dispatch, academicYear?.id])

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Header small title={`Course Session   ( Total :${list?.length} ) `} sx={{ marginLeft: '0px' }} />
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map(item => (
            <CourseSessionItem key={item.id} item={item} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
