import { MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getAcademicYears } from '../store/lookup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setAcademicYear } from '../store/session'
import { useSession } from './Providers'

export default function SwitchAcademicYear() {
  const { t } = useTranslation()
  const academicYears = useSelector(state => state.lookup.academicYears)
  const dispatch = useDispatch()
  const { academicYear } = useSession()

  const selectAcademicYear = id => {
    academicYear?.id !== id && dispatch(setAcademicYear({ id }))
  }

  useEffect(() => {
    academicYears ? !academicYear && selectAcademicYear(academicYears[0]?.id) : dispatch(getAcademicYears())
  }, [dispatch, academicYear?.id])

  return (
    <TextField
      select
      sx={{ width: '10em', mr: 1 }}
      size="small"
      label={t('Academic Year')}
      placeholder="Academic Year"
      value={academicYear?.id || (academicYears && academicYears[0]?.id)}
      InputLabelProps={{ shrink: true }}
    >
      {academicYears?.map(item => (
        <MenuItem key={item.id} value={item.id} onClick={() => selectAcademicYear(item.id)}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
