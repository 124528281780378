import Header from 'components/Header'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ProfilePicture from './ProfilePicture'

// TODO: Avatar area on header

const basePath = '/person'

export default function PersonHeader() {
  const navigate = useNavigate()
  const { personId, '*': tabName } = useParams()
  const person = useSelector(state => state.person2?.list)?.find(item => item.id === +personId)
  const ids = useSelector(state => state.person2.list?.map(p => p.id))

  const page = dir => {
    if (!(ids && person)) return
    const idx = ids.indexOf(person?.id)
    const n = idx + dir
    if (n < 0 || n >= ids.length) return
    if (tabName) {
      navigate([basePath, ids[n], tabName].join('/'))
    } else navigate([basePath, ids[n]].join('/'))
  }

  if (!person) return null

  return (
    <Header
      prev={() => page(-1)}
      next={() => page(1)}
      avatar={<ProfilePicture />}
      title={`${person.firstName} ${person.lastName}`}
      close
    />
  )
}
