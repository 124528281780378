import { useDispatch } from 'react-redux'
import GradingScaleForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { retrieve } from 'store/settings/grading-scale'
import GradingScaleFormSkeleton from './FormSkeleton'

export default function GradingScaleFormIndex() {
  const dispatch = useDispatch()
  const [scale, setScale] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(retrieve({ id })).then(res => {
        setScale(res.payload)
      })
    } else {
      setScale(null)
    }
  }, [id])

  if (id && id !== 'new' && !scale) return <GradingScaleFormSkeleton />

  return <GradingScaleForm scale={scale} />
}
