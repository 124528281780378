import { Box, Button, Stack } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LiaFileExportSolid } from 'react-icons/lia'
import SchedulingCourseSessionsSearchBar from './Search'
import SchedulingCourseSessionsList from './List'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/course-session'
import { useEffect } from 'react'
import { omit } from 'lodash'
import { getCourseSessionsEnrollmentType } from 'store/lookup'

export default function SchedulingCourseSessionsListIndex() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { academicYear } = useSelector(state => state.session)
  const { count, query, list } = useSelector(state => state.settings.courseSession)
  const staffRole = useSelector(state => state.lookup?.courseSessionsEnrollmentType)
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)

  useEffect(() => {
    let courseSessionQuery = {
      ...omit(query, 'courseId'),
      offset: 0,
      academicYearId: query.academicYearId || academicYear?.id,
    }

    dispatch(search(courseSessionQuery))
  }, [academicYear])

  useEffect(() => {
    staffRole || dispatch(getCourseSessionsEnrollmentType())
  }, [])

  const basePath = '/settings/scheduling/course-sessions/'

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...query, offset: 0, limit }))
  }

  const getTerm = mp => {
    if (academicYearMarkingPeriods?.length === 1 && mp?.length === 1) {
      return 'Summer'
    } else if (mp?.length === academicYearMarkingPeriods?.length) {
      return 'Full Year'
    } else {
      return mp?.map(item => item.name).toString()
    }
  }

  const exportCSV = data => {
    if (data) {
      const headers = ['No', 'Course Name', 'Session Name', 'Teacher', 'Term', 'Enrollment']

      const csvRows = data.map((courseSession, index) => {
        const teachers = courseSession.courseSessionEnrollments
          .map(cse => `${cse.firstName} ${cse.lastName}`)
          .join(' - ')

        const term = getTerm(courseSession.markingPeriods).replaceAll(',', ' - ')

        return [
          index + 1,
          courseSession?.course?.name || ' ',
          courseSession?.name.replaceAll('#', ' ') || ' ',
          teachers || ' ',
          term || ' ',
          `${courseSession?.enrollmentCount || 0}/${courseSession.quota || 0}` || ' ',
        ].join(',')
      })

      const csvContent = 'data:text/csv;charset=utf-8,' + [headers.join(','), ...csvRows].join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'course-session-list.csv')
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
  }

  const exportNow = () => {
    const filteredQuery = Object.fromEntries(Object.entries(query).filter(([key, value]) => key && value !== null))
    dispatch(search({ ...filteredQuery, limit: count, offset: 0, export: true })).then(response => {
      exportCSV(response?.payload?.rows)
    })
  }

  if (!(list && staffRole)) return null

  return (
    <Box>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Header title={t('Course Sessions')} small />
        <Box>
          <Button
            startIcon={<LiaFileExportSolid />}
            size="small"
            variant="outlined"
            color="warning"
            onClick={exportNow}
            sx={{ mr: 2 }}
          >
            {t('Export')}
          </Button>
          <Button size="small" variant="contained" color="warning" onClick={() => navigate(`${basePath}new`)}>
            {t('Add Session')}
          </Button>
        </Box>
      </Stack>
      <SchedulingCourseSessionsSearchBar />
      <SchedulingCourseSessionsList
        list={list}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  )
}
