import { TableCell, TableRow, Link } from '@mui/material'
import { NavLink } from 'react-router-dom'
import IconProvider from 'components/IconProvider'

export default function ListItem({ item, setStudent, date }) {
  const basePath = '/settings/attendance/daily'

  const renderIconProvider = el => {
    return el ? <IconProvider icon={el.status?.ui?.icon} color={el.status?.ui?.color} /> : null
  }
  return (
    <TableRow hover>
      <TableCell>
        <Link
          component={NavLink}
          underline="hover"
          color="inherit"
          to={`${basePath}/${item.dailyAttendance?.id || 'new'}?date=${date}&schoolEnrollmentId=${
            item?.schoolEnrollmentId
          }`}
          onClick={() => setStudent(item)}
        >
          {item.person?.lastName} {item.person?.firstName}
        </Link>
      </TableCell>
      <TableCell>{renderIconProvider(item.dailyAttendance)}</TableCell>
      <TableCell>{item.dailyAttendance?.note || ''}</TableCell>
      {item.periodAttendance?.map((el, index) => (
        <TableCell key={index}>{renderIconProvider(el)}</TableCell>
      ))}
    </TableRow>
  )
}
