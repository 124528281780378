import _ from 'lodash'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/state-data'
export const get = createApiAction('member/state-data/get', client.retrieve(basePath))
export const save = createApiAction('member/state-data/save', client.update(basePath))

export default function setStateDataReducers(builder) {
  builder.addCase(get.fulfilled, (state, action) => {
    const list = state.list || []
    const { memberId } = action.meta.arg
    const found = list.find(item => item.id === memberId)
    const replace = _.extend({ id: memberId }, found, { stateData: { ...action.payload, fetchedAt: Date.now() } })
    if (found) state.list = list.map(item => (item.id === found.id ? replace : item))
    else state.list = [...state.list, replace]
  })
  builder.addCase(save.fulfilled, (state, action) => {
    const list = state.list || []
    const { memberId } = action.meta.arg
    const found = list.find(item => item.id === memberId)
    const replace = _.extend({ id: memberId }, found, { stateData: { ...action.payload, fetchedAt: Date.now() } })
    if (found) state.list = list.map(item => (item.id === found.id ? replace : item))
    else state.list = [...state.list, replace]
  })
}
