import { useParams } from 'react-router-dom'
import CalendarForm from './Form'
import { useSelector } from 'react-redux'

export default function CalendarFormIndex({ setCurrentDay }) {
  const { eventId } = useParams()

  const data = useSelector(state => state.provider.calendar)?.list?.find(item => item.id === +eventId)
  const clients = useSelector(state => state.lookup.clients)
  const campuses = useSelector(state => state.lookup.campus)

  if (eventId !== 'new' && data === null) return null

  return <CalendarForm data={data} districts={clients} campuses={campuses} setCurrentDay={setCurrentDay} />
}
