import { useEffect, useState } from 'react'
import { Box, Drawer, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import RoomItem from './RoomItem'
import { useNavigate, useParams } from 'react-router-dom'
import RoomEditForm from './RoomEditForm'
import Header from 'components/Header'
import { useBasePath } from 'components/Providers'
import { useDispatch } from 'react-redux'
import { get } from 'store/settings/building-room'

export default function RoomList({ rooms }) {
  const { buildingId, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [room, setRoom] = useState()
  const basePath = useBasePath()

  useEffect(() => {
    if (+id) {
      dispatch(get({ buildingId, id })).then(res => {
        setRoom(res.payload)
      })
    }
    if (id === 'new') {
      setRoom({ name: '', type: '', doorNumber: '' })
    } else setRoom(null)
  }, [buildingId, dispatch, id])

  return (
    <Box sx={{ minHeight: '50%', maxHeight: '60vh', overflow: 'auto' }}>
      <Header title={'Rooms'} sx={{ p: 0 }} small add />
      <Paper variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeadCell>Door Number</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {rooms?.map(item => {
                return <RoomItem key={item?.id} item={item} />
              })}
            </TableBody>
          }
        </Table>
      </Paper>
      <Drawer open={Boolean(id)} anchor="right" onClose={() => navigate(`${basePath}`)}>
        {room && <RoomEditForm room={room} />}
      </Drawer>
    </Box>
  )
}
