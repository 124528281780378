import { useDispatch } from 'react-redux'
import RoomEditForm from './Form'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/settings/room'
import RoomFormSkeleton from './FormSkeleton'
import { BasePathProvider, useBasePath } from 'components/Providers'

export default function RoomFormIndex() {
  const dispatch = useDispatch()
  const [room, setRoom] = useState(null)
  const basePath = useBasePath()

  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setRoom(res.payload)
      })
    } else {
      setRoom(null)
    }
  }, [id])

  return (
    <BasePathProvider value={`${basePath}`}>
      {room || id === 'new' ? <RoomEditForm room={room} /> : <RoomFormSkeleton />}
    </BasePathProvider>
  )
}
