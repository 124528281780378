import { Drawer } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/grading-scale'
import GradingScaleFormIndex from './Form'
import { useNavigate, useParams } from 'react-router-dom'
import GradingScaleListIndex from './List'
import GradingScaleListSkeleton from './List/ListSkeleton'

const baseUrl = '/settings/grading-scale'

export default function GradingScalePage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  const gradingScales = useSelector(state => state.settings.gradingScale.list)
  const academicYearId = useSelector(state => state.session.academicYear.id)

  useEffect(() => {
    gradingScales || dispatch(search())
  }, [academicYearId])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={t('Grading Scales')} add />
      {gradingScales ? <GradingScaleListIndex /> : <GradingScaleListSkeleton />}
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <GradingScaleFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
