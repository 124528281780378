import { Box, Button } from "@mui/material";
import HealthConditionsList from "./List";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
// import NurseStudentSearchBar from "./Search";
import StudentSearchBar from "components/StudentSearch";
import { useNavigate } from "react-router-dom";
import { useBasePath } from "components/Providers";

export default function HealthConditionsListIndex({selected, setSelected}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const basePath = useBasePath()


    return (
        <Box>
            <Box>
                <Header title={t('Health Conditions')} small sx={{ paddingTop: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', gap: 2, pt: 2}}>
              <Box sx={{flexGrow: 1}}>
              <StudentSearchBar onSelect={setSelected} selected={selected} />
              </Box>
              <Button
                size='medium'
                variant='contained'
                color='primary'
                disabled={!selected}
                onClick={() => navigate(`${basePath}/new`)}
            >
                {t('Add Condition')}
            </Button>
            </Box>
            <HealthConditionsList 
              selected={selected}
            />
        </Box>
    )
}