import { useMemo, useState } from 'react'
import { debounce, omit } from 'lodash'
import { InputAdornment, Stack, TextField, MenuItem } from '@mui/material'
import { search } from 'store/settings/course-session'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'

export default function SearchBar({ courses, schools, courseId }) {
  const dispatch = useDispatch()
  const query = useSelector(state => state.settings.courseSession.query)
  const schoolAtSession = useSelector(state => state.session.school)
  const academicYearAtSession = useSelector(state => state.session.academicYear)
  const [selectedSchool, setSelectedSchool] = useState('')
  const [academicYear, setAcademicYear] = useState('')
  const [que, setQue] = useState(query.que || '')
  const { t } = useTranslation()
  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...query, [key]: encodeURI(value), offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  return (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search"
        value={que}
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('name', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />

      <Autocomplete
        openOnFocus
        sx={{ width: '37em' }}
        size="small"
        options={courses || []}
        getOptionLabel={option => option.name}
        defaultValue={courses.find(item => item.id === parseInt(courseId)) || null}
        onChange={(event, newValue) => {
          if (newValue) {
            applyFilter('courseId', newValue.id)
          } else {
            applyFilter('courseId', null)
          }
        }}
        renderInput={params => <TextField {...params} label={t("Course")} />}
      />

      {!schoolAtSession?.id && (
        <TextField
          select
          sx={{ width: '19em' }}
          size="small"
          label={t("School")}
          placeholder="School"
          value={query.school}
          onChange={event => {
            setSelectedSchool(event.target.value)
            applyFilter('academicYearId', event.target.value.academicYears[0].id)
            setAcademicYear(event.target.value.academicYears[0].id)
          }}
        >
          {schools?.map(item => (
            <MenuItem key={item.id} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}

      {!academicYearAtSession?.id && (
        <TextField
          select
          sx={{ width: '23em' }}
          size="small"
          label={t("Academic Year")}
          placeholder="Academic Year"
          value={query.academicYearId}
          defaultValue={academicYear}
          onChange={event => applyFilter('academicYearId', event.target.value)}
        >
          {selectedSchool?.academicYears?.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Stack>
  )
}
