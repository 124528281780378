import { TableCell, styled, tableCellClasses } from '@mui/material'

const TableHeadCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 500,
    },
  }
})

export default TableHeadCell
