import { Box, Grid, Fab, LinearProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'

import Relative from './Relative'
import { Title } from '../../../components/typo'
import { getContacts } from '../../../store/person'
import { useMembershipType, usePerson } from '../../../components/Providers'
import { getRelationshipTypes } from 'store/lookup'

function Contacts() {
  const [newContact, setNewContact] = useState(null)
  const person = usePerson()
  const membershipType = useMembershipType()
  const contacts = useSelector(state =>
    membershipType === 'student' ? state?.person?.student?.selected?.contacts : state?.person?.staff?.selected?.contacts,
  )
  const relationshipTypes = useSelector(state => state?.lookup?.relationshipTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    relationshipTypes || dispatch(getRelationshipTypes())
  }, [dispatch, relationshipTypes])

  useEffect(() => {
    contacts || dispatch(getContacts({ membershipType, personId: person?.id }))
  }, [dispatch, membershipType, person?.id, contacts])

  return (
    <Box sx={{ p: 2 }}>
      {newContact && (
        <Grid container spacing={2}>
          <Grid item md={0.75} sm={1} xs={12} sx={{ textAlign: 'right' }}>
            <Title>0.</Title>
          </Grid>
          <Grid item md={11.25} sm={11} xs={12}>
            <Relative data={newContact} onClose={() => setNewContact(false)} />
          </Grid>
        </Grid>
      )}
      {contacts ? (
        contacts.map((data, i) => (
          <Grid key={i} container spacing={2}>
            <Grid item md={0.75} sm={1} xs={12} sx={{ mt: 2, textAlign: 'right' }}>
              <Title>{i + 1}.</Title>
            </Grid>
            <Grid item md={11.25} sm={11} xs={12}>
              <Relative data={data} key={data.id || data._id} />
            </Grid>
          </Grid>
        ))
      ) : (
        <LinearProgress sx={{ m: 2 }} color="success" />
      )}
      <Fab
        color="primary"
        aria-label="add"
        // onClick={() => setData(prev => ([...prev, { _id: makeId(), personId: person.id }]))}
        onClick={() => setNewContact({ personId: person.id })}
      >
        <AddIcon />
      </Fab>
      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
    </Box>
  )
}

export default Contacts
