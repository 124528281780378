import { Box } from '@mui/material'
import StaffListItem from './StaffListItem'

const emptyValues = {
  relationship: null,
  contact: {
    firstName: null,
    lastName: null,
    email: null,
    seq: 0,
    phones: [{ type: null, number: null }],
    addresses: [{ type: null, street: null, line2: null, city: null, state: null, zip: null, country: null }],
  },
}

export default function ContactsList({ data }) {
  const otherContacts = []
  if (data.length > 0) {
    data.forEach(item => {
      otherContacts.push(item)
    })
  }
  while (otherContacts.length < 2) {
    otherContacts.push(emptyValues)
  }

  let maxSeq = 0
  data.map(item => (maxSeq = Math.max(item.seq, maxSeq)))

  return (
    <Box sx={{ mt: 1 }}>
      {otherContacts?.map((item, index) => (
        <Box sx={{ m: 3 }} key={item.id}>
          <StaffListItem item={item} index={index} maxSeq={maxSeq} />
        </Box>
      ))}
    </Box>
  )
}
