import { Box, Drawer } from '@mui/material'
import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import PersonSearch from './Search'
import PersonDetail from './Person'
import PersonEditForm from './Person/Form/PersonForm'
import PageHeader from 'components/AppBar/PageHeader'

export default function Person() {
  const basePath = `/person`
  const navigate = useNavigate()
  const { personId } = useParams()

  return (
    <BasePathProvider value={basePath}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Employees'} />
      </Box>
      <PersonSearch />
      <Drawer
        PaperProps={{
          sx: {
            minWidth: 800,
            width: personId !== 'new' ? 'auto' : 600,
          },
        }}
        open={Boolean(personId)}
        anchor="right"
        onClose={() => navigate(basePath)}
      >
        {personId !== 'new' && <PersonDetail />}
        {personId === 'new' && <PersonEditForm />}
      </Drawer>
    </BasePathProvider>
  )
}
