import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import Header from 'components/Header'
import { Box, Grid, TextField, MenuItem, Typography, LinearProgress } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PeriodAttendanceList from './PeriodAttendanceList'
import UnScheduledCourseSessionEnrollments from './UnScheduledCourseSessionEnrollmentList'
import { search as searchSchoolEnrollment } from 'store/settings/school-enrollment'
import { search as searchPersonAttendanceDaily, save } from 'store/person-attendance-daily'
import { search as searchPersonAttendancePeriod } from 'store/person-attendance-period'
import { search as searchCourseSessionEnrollment } from 'store/course-session-enrollment'
import dayjs from 'dayjs'
import { getAttendanceStatus } from 'store/lookup'
import { BasePathProvider, useBasePath, useMembershipType, useSession } from 'components/Providers'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'

export default function Attendence() {
  const basePath = useBasePath()
  const dispatch = useDispatch()
  const membershipType = useMembershipType()
  const { academicYear } = useSession()
  const { personId } = useParams()

  const [selectedDate, setSelectedDate] = useState(
    dayjs(academicYear.endDate).isBefore(Date.now())
      ? dayjs(academicYear.endDate).format('YYYY-MM-DD')
      : dayjs(Date.now()).format('YYYY-MM-DD'),
  )

  const dayOfWeek = new Date(selectedDate).getDay()

  const attendanceStatus = useSelector(state => state.lookup.attendanceStatus)

  const schoolEnrollmentFromStore = useSelector(state => state.settings.schoolEnrollment.list?.[0])

  const dailyAttendanceFromStore = useSelector(state => state.personAttendanceDaily.list)

  const periodAttendanceFromStore = useSelector(state => state.personAttendancePeriod?.list)

  const courseSessionEnrollmentFromStore = useSelector(state => state.courseSessionEnrollment.list)

  const [dailyAttendance, setDailyAttendance] = useState(dailyAttendanceFromStore)

  const [schoolEnrollment, setSchoolEnrollment] = useState()

  const [periodAttendanceList, setPeriodAttendanceList] = useState(periodAttendanceFromStore)

  const [courseSessionEnrollments, setCourseSessionEnrollments] = useState(courseSessionEnrollmentFromStore)

  const { control, setValue } = useForm({
    defaultValues: { dailyAttendanceId: dailyAttendance?.[0]?.status?.id || null },
  })

  useEffect(() => {
    attendanceStatus || dispatch(getAttendanceStatus())
  }, [dispatch, Boolean(attendanceStatus)])

  useEffect(() => {
    if (personId && membershipType) {
      if (!schoolEnrollmentFromStore) {
        dispatch(searchSchoolEnrollment({ personId, membershipType })).then(res => {
          setSchoolEnrollment(res.payload[0])
        })
      } else {
        setSchoolEnrollment(schoolEnrollmentFromStore)
      }
    }
  }, [personId])

  useEffect(() => {
    if (schoolEnrollment?.id && personId && selectedDate && membershipType) {
      if (!periodAttendanceFromStore) {
        dispatch(
          searchPersonAttendancePeriod({
            membershipType,
            personId,
            date: dayjs(selectedDate).format('YYYY-MM-DD'),
            schoolEnrollmentId: schoolEnrollment?.id,
          }),
        ).then(res => {
          setPeriodAttendanceList(res.payload)
        })
      }
    } else {
      setPeriodAttendanceList(periodAttendanceFromStore)
    }
  }, [dispatch, schoolEnrollment?.id, membershipType, selectedDate])

  useEffect(() => {
    if (schoolEnrollment?.id && personId && selectedDate && membershipType) {
      if (!dailyAttendanceFromStore) {
        dispatch(
          searchPersonAttendanceDaily({
            membershipType,
            personId,
            date: dayjs(selectedDate).format('YYYY-MM-DD'),
            schoolEnrollmentId: schoolEnrollment?.id,
          }),
        ).then(res => {
          setDailyAttendance(res.payload)
        })
      } else {
        setDailyAttendance(dailyAttendanceFromStore)
      }
    }
  }, [dispatch, schoolEnrollment?.id, membershipType, selectedDate, dailyAttendance?.id])

  useEffect(() => {
    if (schoolEnrollment?.id && personId && membershipType) {
      if (!courseSessionEnrollmentFromStore?.length) {
        dispatch(
          searchCourseSessionEnrollment({
            membershipType,
            personId,
            schoolEnrollmentId: schoolEnrollment?.id,
            include: ['schedule'],
          }),
        ).then(res => {
          setCourseSessionEnrollments(res.payload)
        })
      } else {
        setCourseSessionEnrollments(courseSessionEnrollmentFromStore)
      }
    }
  }, [dispatch, schoolEnrollment?.id, membershipType])

  useEffect(() => {
    setValue('dailyAttendanceId', dailyAttendance?.[0]?.status?.id)
  }, [dailyAttendance?.[0]?.status?.id, setValue])

  const [unScheduledCourseSessionEnrollments, scheduledCourseSessionEnrollments] = useMemo(() => {
    const unScheduledCourseSessions = []
    const currentCourseSessionEnrollments = []

    if (!(periodAttendanceFromStore?.length || courseSessionEnrollmentFromStore?.length)) return [[], []]

    if (courseSessionEnrollments?.length) {
      for (const item of courseSessionEnrollments) {
        if (item.courseSession.schedules?.length) {
          for (const schedule of item.courseSession.schedules) {
            if (schedule.period && schedule.period.dayOfWeek === dayOfWeek) {
              currentCourseSessionEnrollments.push({
                courseSessionEnrollmentId: item.id,
                name: `${item.courseSession.course.name}  -  ${item.courseSession.name} `,
                period: schedule.period.seq,
              })
            }
          }
        } else {
          unScheduledCourseSessions.push(item)
        }
      }
    }

    if (periodAttendanceList?.length) {
      for (const pa of periodAttendanceList) {
        let id = pa.courseSessionEnrollment.id
        let period = pa.period

        let cse = currentCourseSessionEnrollments.find(
          cse => cse.courseSessionEnrollmentId === id && cse.period === period,
        )

        if (cse) {
          cse.attendanceId = pa.id
          cse.statusId = pa.status.id
        } else {
          currentCourseSessionEnrollments.push({
            name: `${pa.courseSessionEnrollment.courseSession.course.name}- ${pa.courseSessionEnrollment.courseSession.name}
`,
            id: pa?.id,
            attendanceId: pa.id,
            dayOfWeek,
            period: pa.period,
            statusId: pa.status.id,
          })
        }
      }
    }
    return [unScheduledCourseSessions, currentCourseSessionEnrollments.sort((a, b) => a.period - b.period)]
  }, [dayOfWeek, periodAttendanceList, courseSessionEnrollments, personId])

  const handleChange = statusId => {
    if (dailyAttendance?.[0]?.id) {
      if (statusId && personId && membershipType) {
        dispatch(
          save({
            membershipType,
            personId,
            id: dailyAttendance?.[0]?.id,
            statusId,
          }),
        ).then(res => {
          setDailyAttendance(res.payload)
        })
      }
    } else {
      if (statusId && schoolEnrollment.id && personId && membershipType && selectedDate) {
        dispatch(
          save({
            membershipType,
            personId,
            schoolEnrollmentId: schoolEnrollment.id,
            statusId,
            date: dayjs(selectedDate).format('YYYY-MM-DD'),
          }),
        ).then(res => {
          setDailyAttendance(res.payload)
        })
      }
    }
  }

  if (!courseSessionEnrollmentFromStore || !dailyAttendance || !periodAttendanceList || !schoolEnrollment) {
    return <LinearProgress sx={{ m: 2 }} color="success" />
  }

  console.log(scheduledCourseSessionEnrollments)

  return (
    <BasePathProvider value={`${basePath}/attendance`}>
      <Box>
        <Header title={t('Attendance')} small sx={{ paddingLeft: '16px', paddingTop: '10px' }} />
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={6} md={1}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ marginLeft: '15px', display: 'flex', flexDirection: 'column' }}
            >
              <span style={{ marginTop: '0.5em' }}>{t('Date')}:</span>
            </Typography>
          </Grid>
          <Grid item xs={6} md={5}>
            <DatePicker
              value={dayjs(selectedDate)}
              slotProps={{ textField: { size: 'small' } }}
              sx={{ width: '14em' }}
              onChange={e => {
                setSelectedDate(e)
              }}
            />
          </Grid>

          <Grid item xs={6} md={1.8}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ marginLeft: '15px', display: 'flex', flexDirection: 'column' }}
            >
              <span style={{ marginTop: '0.5em' }}>{t('Daily Attendance')} :</span>
            </Typography>
          </Grid>
          {attendanceStatus && (
            <Grid item xs={6} md={4.2}>
              <Controller
                name="dailyAttendanceId"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      sx={{ width: '14em', marginLeft: '25px' }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      defaultValue={dailyAttendance?.[0]?.status?.name}
                      value={value || null}
                      {...field}
                      onChange={event => {
                        const value = event.target.value
                        handleChange(value)
                      }}
                    >
                      {attendanceStatus?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            {
              <PeriodAttendanceList
                attendanceStatus={attendanceStatus}
                newAttendanceList={scheduledCourseSessionEnrollments}
                selectedDate={selectedDate}
              />
            }
          </Grid>
          {unScheduledCourseSessionEnrollments && (
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Header
                  title={t(
                    `Unscheduled Course Session Enrollments ( Total :${unScheduledCourseSessionEnrollments?.length} )`,
                  )}
                  small
                  sx={{ paddingLeft: '16px', paddingTop: '10px' }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {unScheduledCourseSessionEnrollments && (
                    <UnScheduledCourseSessionEnrollments
                      unScheduledCourseSessionEnrollments={unScheduledCourseSessionEnrollments}
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Box>
    </BasePathProvider>
  )
}
