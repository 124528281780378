import { Paper, Box, Grid, Card, CardContent, Table, TableHead, TableRow, TableBody } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import Header from 'components/Header'
import MarkingPeriodItem from './MPItem'
import { useSelector } from 'react-redux'
import { BasePathProvider } from 'components/Providers'
import { useBasePath } from 'components/Providers'
import { useParams } from 'react-router-dom'
export default function MarkingPeriodList() {
  const basePath = useBasePath()

  const markingPeriods = useSelector(state => state.markingPeriod.list)
  const { academicYearId } = useParams()

  return (
    <BasePathProvider value={`${basePath}/marking-period`}>
      <Box>
        {parseInt(academicYearId) ? (
          <Header title={'Marking Period List'} small add />
        ) : (
          <Header title={'Marking Period List'} small />
        )}
        <Paper sx={{ minHeight: '50%' }}>
          <Grid container spacing={2} sx={{ marginTop: 0 }}>
            <Grid item xs={12} md={12}>
              <Card sx={{ minHeight: '600px' }}>
                <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell sx={{ width: '20em' }}>Marking Periods</TableHeadCell>
                        <TableHeadCell sx={{ width: '13em' }}>Start Date</TableHeadCell>
                        <TableHeadCell sx={{ width: '13em' }}>End Date</TableHeadCell>
                        <TableHeadCell sx={{ width: '14em' }}>&nbsp;</TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {markingPeriods?.map(item => (
                        <MarkingPeriodItem key={item.id} item={item} />
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </BasePathProvider>
  )
}
