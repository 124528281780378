import { useEffect, useState } from 'react'
import { Box, Card, CardContent, Drawer, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useNavigate, useParams } from 'react-router-dom'
import PeriodEditForm from './PeriodForm'
import Header from 'components/Header'
import { useBasePath } from 'components/Providers'
import { useDispatch } from 'react-redux'
import { get } from 'store/settings/bell-schedule-period'
import { DAYS as days } from 'constants'
import PeriodItem from './PeriodItem'
import { useTranslation } from 'react-i18next'

export default function PeriodList({ periods, bellScheduleTypes }) {
  const { bellScheduleId, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [period, setPeriod] = useState()
  const [reSortedPeriod, setReSortedPeriod] = useState([])
  const basePath = useBasePath()
  const { t } = useTranslation()

  useEffect(() => {
    if (+id) {
      dispatch(get({ bellScheduleId, id })).then(res => {
        setPeriod(res.payload)
      })
    }
    if (id === 'new') {
      setPeriod({ endTime: '', startTime: '', type: 'course-session', dayOfWeek: null, name: '' })
    } else setPeriod(null)
  }, [bellScheduleId, dispatch, id])

  const sortByStartTime = data => {
    return data.sort(function (a, b) {
      const ax = a.startTime.split(':')[0] * 60 + a.startTime.split(':')[1]
      const bx = b.startTime.split(':')[0] * 60 + b.startTime.split(':')[1]
      return ax - bx
    })
  }

  useEffect(() => {
    const tempObj = {
      monday: sortByStartTime(periods.filter(day => day.dayOfWeek === 1)),
      tuesday: sortByStartTime(periods.filter(day => day.dayOfWeek === 2)),
      wednesday: sortByStartTime(periods.filter(day => day.dayOfWeek === 3)),
      thursday: sortByStartTime(periods.filter(day => day.dayOfWeek === 4)),
      friday: sortByStartTime(periods.filter(day => day.dayOfWeek === 5)),
      saturday: sortByStartTime(periods.filter(day => day.dayOfWeek === 6)),
      sunday: sortByStartTime(periods.filter(day => day.dayOfWeek === 0)),
    }
    const tempArray = []

    let rowCount = Math.max(
      tempObj.monday.length,
      tempObj.tuesday.length,
      tempObj.wednesday.length,
      tempObj.thursday.length,
      tempObj.friday.length,
      tempObj.saturday.length,
      tempObj.sunday.length,
    )

    for (let i = 0; i < rowCount; i++) {
      let row = []
      let empty = { endTime: '', startTime: '', type: 'course-session', dayOfWeek: null }
      row.push(
        tempObj.monday[i] || empty,
        tempObj.tuesday[i] || empty,
        tempObj.wednesday[i] || empty,
        tempObj.thursday[i] || empty,
        tempObj.friday[i] || empty,
      )
      if (tempObj.saturday.length > 0) {
        row.push(tempObj.saturday[i] || empty)
      }
      if (tempObj.sunday.length > 0) {
        row.push(tempObj.sunday[i] || empty)
      }
      tempArray.push(row)
    }
    setReSortedPeriod(tempArray)
  }, [periods])

  return (
    <Box sx={{ minHeight: '50%', maxHeight: '60vh', overflow: 'auto' }}>
      <Header title={'Periods'} small add />
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0, paddingTop: '0px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell colSpan={2}>{t(days[1])}</TableHeadCell>
                    <TableHeadCell colSpan={2}>{t(days[2])}</TableHeadCell>
                    <TableHeadCell colSpan={2}>{t(days[3])}</TableHeadCell>
                    <TableHeadCell colSpan={2}>{t(days[4])}</TableHeadCell>
                    <TableHeadCell colSpan={2}>{t(days[5])}</TableHeadCell>
                    {periods.filter(day => {
                      return day.dayOfWeek === 6
                    }).length > 0 && <TableHeadCell colSpan={2}>{t(days[6])}</TableHeadCell>}
                    {periods.filter(day => day.dayOfWeek === 0).length > 0 && (
                      <TableHeadCell colSpan={2}>{t(days[0])}</TableHeadCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reSortedPeriod?.map(
                    (itemRow, rowIndex) =>
                      itemRow.length > 0 && (
                        <TableRow key={rowIndex} hover>
                          {itemRow.map((cell, cellIndex) => (
                            <PeriodItem key={cell?.id || `${rowIndex}-${cellIndex}`} item={cell} />
                          ))}
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Drawer open={Boolean(id)} anchor="right" onClose={() => navigate(`${basePath}`)}>
        {days && period && bellScheduleTypes && (
          <PeriodEditForm days={days} period={period} bellScheduleTypes={bellScheduleTypes} />
        )}
      </Drawer>
    </Box>
  )
}
