import { Route, Routes } from 'react-router-dom'
import SchedulingCourseSessionsFormIndex from './Form'
import SchedulingCourseSessionsPage from './Page'

export default function SchedulingCourseSessions() {
  return (
    <Routes>
      <Route path="/" element={<SchedulingCourseSessionsPage />}>
        <Route path="/:courseSessionId" element={<SchedulingCourseSessionsFormIndex />} />
        <Route path="/:courseSessionId/:add" element={<SchedulingCourseSessionsFormIndex />} />
        <Route path="/:courseSessionId/:add/:courseSessionEnrollmentId" element={<SchedulingCourseSessionsFormIndex />} />
      </Route>
    </Routes>
  )
}
