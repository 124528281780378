import { Route, Routes } from 'react-router-dom'
import Tabs from './Tabs'
import { BasePathProvider } from 'components/Providers'
import { Box, Paper } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import LeaveRequestPage from './LeaveRequests'
import PunchRecords from './PunchRecords'

const basePath = '/leave-request'
export default function LeaveRequest() {
  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Leave Request'} />
      </Box>
      <Paper sx={{ width: '100%', p: 2 }}>
        <Tabs />
        <Routes>
          <Route path="/">
            <Route path="" element={<LeaveRequestPage />} />
            <Route path="/*" element={<LeaveRequestPage />} />
            <Route path="leave-request/*" element={<LeaveRequestPage />} />
            <Route path="punch-records/*" element={<PunchRecords />} />
          </Route>
        </Routes>
      </Paper>
    </BasePathProvider>
  )
}
