import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'academic-attendance-period'
const initialState = {}

export const search = createApiAction(
  'academic/course-session/:courseSessionId/attendance/search',
  client.search('/api/academic/course-session/:courseSessionId/attendance'),
)

export const searchStudent = createApiAction(
  'academic/attendance/period/search',
  client.search('/api/academic/attendance/period'),
)

export const save = createApiAction(
  'academic/course-session/:courseSessionId/attendance/save',
  client.save('/api/academic/course-session/:courseSessionId/attendance/'),
)

export const update = createApiAction(
  'academic/course-session/:courseSessionId/attendance/update',
  client.update('/api/academic/course-session/:courseSessionId/attendance/:id'),
)

export const destroy = createApiAction(
  'academic/course-session/:courseSessionId/attendance/delete',
  client.destroy('/api/academic/course-session/:courseSessionId/attendance/:id'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export default sessionSlice.reducer
