/* eslint:disabled */
import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import 'utils/global.scss'

const CALENDAR = 0
const ACADEMICYEAR = 1
const SCHOOLACCESS = 2
const SCHOOLMANAGEMENT = 3

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['calendar', 'academic-year', 'school-access', 'school-management'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function SettingsTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])
  const basePath = `/setting`

  return (
    <Box>
      <Box>
        <Tabs
          value={tabIndex}
          TabIndicatorProps={{ style: { background: '#FF9215', fontFamily: 'Roboto, sans-serif' } }}
        >
          <Tab
            sx={{ color: tabIndex === 0 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Calendar')}
            value={CALENDAR}
            component={Link}
            to={`${basePath}/calendar`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Academic Year')}
            value={ACADEMICYEAR}
            component={Link}
            to={`${basePath}/academic-year`}
            disabled={true}
          />
          <Tab
            sx={{ color: tabIndex === 2 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('School Access')}
            value={SCHOOLACCESS}
            component={Link}
            to={`${basePath}/school-access`}
            disabled={true}
          />
          <Tab
            sx={{ color: tabIndex === 3 && '#FF9215 !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('School Management')}
            value={SCHOOLMANAGEMENT}
            component={Link}
            to={`${basePath}/school-management`}
          />
        </Tabs>
      </Box>
    </Box>
  )
}
