import { useEffect } from 'react'
import { TextField, Grid, MenuItem } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getCountry } from '../store/lookup'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export default function AddressEditForm({ path, control }) {
  const countries = useSelector(state => state.lookup.country)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    countries || dispatch(getCountry())
  }, [dispatch, countries])

  return (
    <Grid item sm={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Controller
            name={`${path}.street`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t("Address 1")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Controller
            name={`${path}.line2`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t("Address 2")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Controller
            name={`${path}.city`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t("City")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          {' '}
          <Controller
            name={`${path}.state`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t("State")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          {' '}
          <Controller
            name={`${path}.zip`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t("Postal Code")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Controller
            name={`${path}.country`}
            control={control}
            render={({ field: { value, ...field }, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  data-name={`${path}.country`}
                  size="small"
                  label={t("Country")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={value || ''}
                  {...field}
                >
                  {countries?.map(country => (
                    <MenuItem key={country.name} value={country.code}>
                      {country.code} - {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

AddressEditForm.propTypes = {
  path: PropTypes.string,
  control: PropTypes.object,
}
