import { Paper } from '@mui/material'
import SearchBar from './SearchBar'
import PersonList from './PersonList'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/person/index'
import { useEffect } from 'react'

export default function PersonSearch() {
  const dispatch = useDispatch()

  const { list, query } = useSelector(state => state.person2)

  useEffect(() => {
    list || dispatch(search({ limit: query?.limit, offset: 0 }))
  }, [])

  return (
    <Paper sx={{ width: '100%', mb: 2, minHeight: '500px' }}>
      <SearchBar />
      <PersonList />
    </Paper>
  )
}
