import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'course-session-enrollment'
const initialState = {
  list: null,
  person: null,
}

export const search = createApiAction(
  'settings/course-session-enrollment',
  client.search('/api/setting/course-session-enrollment'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload.list
      state.person = action.payload.person
    })
  },
})
export default sessionSlice.reducer
