import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { SubmitButton } from 'components/Buttons'
import { DeleteIcon, SaveAltIcon } from 'components/Icons'
import { useState } from 'react'
import { destroy, save } from 'store/provider/calendar'
import { useNavigate } from 'react-router-dom'
import Confirm from 'components/Dialog'
// import DistrictLocations from './Districts'
// import CalendarCampuses from './Campuses'
import { omit } from 'lodash'
import CalendarListDistricts from './District'
import CalendarListCampuses from './Campus'

const initialValues = {
  name: null,
  eventTypeId: null,
  startDate: null,
  endDate: null,
  // startTime: null,
  // endTime: null,
  location: null,
  locationIds: null,
}
const basePath = '/setting/calendar'

export default function CalendarForm({ data, districts, campuses, setCurrentDay }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const calendarEventTypes = useSelector(state => state.lookup.calendarEventType)
  const [location, setLocation] = useState(data?.location || 'district')
  const [confirm, setConfirm] = useState(false)
  // const [checked, setChecked] = useState([])
  const schools = districts?.flatMap(client => client.client?.schools)
  const clients = districts?.flatMap(client => client.client)
  // const [campusIds, setCampusIds] = useState([])

  const schema = yup.object().shape({
    name: yup.string().trim().required('Required'),
    startDate: yup.date().required('Required'),
    endDate: yup
      .date()
      .nullable()
      .test('valid-dates', 'End date should be later than start date', function (value) {
        return value ? this.parent.startDate <= value : true
      }),
    eventTypeId: yup
      .string()
      .required('required'),
  })

  const { control, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data
      ? {
          ...data,
          // startTime: dayjs(`${data?.startDate}T${data?.startTime}`),
          // endTime: dayjs(`${data?.startDate}T${data?.endTime}`),
        }
      : { ...initialValues },
  })

  const onDestroy = async () => {
    setConfirm(false)
    dispatch(destroy({ id: data?.id }))
    navigate(`${basePath}/`)
  }

  const onSubmit = async formData => {
    let locationIds = null
    if (location === 'district') {
      // formData.locationIds = checked
      locationIds = formData?.locationIds.filter(id => clients.some(obj => obj.id === id))
    } else if (location === 'school') {
      locationIds = formData?.locationIds.filter(id => schools.some(obj => obj.id === id))
    } else if (location === 'campus') {
      // formData.locationIds = campusIds
      locationIds = formData?.locationIds.filter(id => campuses.some(obj => obj.id === id))
    }
    formData.locationIds = locationIds
    formData.location = location
    formData.startDate = dayjs(formData.startDate).format('YYYY-MM-DD')
    formData.endDate = dayjs(formData.endDate).format('YYYY-MM-DD')

    // if (formData?.startTime) formData.startTime = `${dayjs(new Date(formData.startTime)).format('HH:mm')}:00`
    // if (formData?.endTime) formData.endTime = `${dayjs(new Date(formData.endTime)).format('HH:mm')}:00`
    if (data?.id) formData = omit(formData, 'districtId')
    const res = await dispatch(save(formData))
    if (res.payload) {
      const date = new Date()
      date.setMonth(new Date(formData.startDate).getMonth())
      date.setFullYear(new Date(formData.startDate).getFullYear())
      setCurrentDay(date)
      navigate(`${basePath}/`)
    }
  }

  const onErrors = errors => console.log(errors)

  // useEffect(() => {
  //   if (data && data?.location === 'district') {
  //     setChecked(data?.locationIds)
  //   }
  //   // if (data && data?.location === 'campus') {
  //   //   setCampusIds(data?.locationIds)
  //   // }
  // }, [data])

  if (!calendarEventTypes) return null

  // const handleAllDistricts = event => {
  //   if (location === 'district') {
  //     const all = clients.map(function (item) {
  //       return item.id
  //     })
  //     if (event.target.checked) {
  //       setChecked([...all])
  //       setValue('locationIds', all)
  //     } else {
  //       setChecked([])
  //       resetField('locationIds')
  //     }
  //   }
  // }

  // const handleDistrict = event => {
  //   const value = +event.target.value
  //   if (event.target.checked && !checked.includes(value)) {
  //     const temp = [...checked, value]
  //     setChecked(temp)
  //     setValue('locationIds', temp)
  //   } else if (!event.target.checked && checked.includes(value)) {
  //     const temp = checked.filter(item => item !== value)
  //     setChecked(temp)
  //     setValue('locationIds', temp)
  //   }
  // }

  const dataGrouped = list => {
    if (list) {
      const temp = list.reduce((acc, item) => {
        if (!acc || !acc[item?.districtId]) {
          acc[item?.districtId] = []
        }
        acc[item?.districtId].push(item)
        return acc
      }, {})
      return temp
    } else return null
  }

  return (
    <BasePathProvider>
      <Box
        data-component="EventEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={data ? `${t('Event')}: ${data?.name}` : t('Add New Event')} small close borderBottom />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={6} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label={t('Event Name')}
                    error={!!fieldState.error}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="eventTypeId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    required
                    size="small"
                    fullWidth
                    select
                    label={t('Event Type')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {calendarEventTypes.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => {
                const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                return (
                  <DatePicker
                    required
                    sx={datePickerSx}
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { size: 'small' },
                      field: {
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    label={t('Start Date')}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    value={field.value && dayjs(field.value)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => {
                const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                return (
                  <DatePicker
                    required
                    sx={datePickerSx}
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { size: 'small' },
                      field: {
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    label={t('End Date')}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    value={field.value && dayjs(field.value)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl fullWidth sx={{ p: 1, pl: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Typography>{t('Apply Event To')}:</Typography>
              <RadioGroup row sx={{ pt: 2 }}>
                <FormControlLabel
                  value="district"
                  control={<Radio checked={location === 'district'} disabled={data?.id ? true : false} />}
                  label={t('District')}
                  onChange={e => setLocation(e.target.value)}
                />
                <FormControlLabel
                  value="campus"
                  control={<Radio checked={location === 'campus'} disabled={data?.id ? true : false} />}
                  label={t('Campus')}
                  onChange={e => setLocation(e.target.value)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* {location === 'district' && (
            <DistrictLocations
              checked={checked}
              clients={clients}
              handleAllDistricts={handleAllDistricts}
              handleDistrict={handleDistrict}
              data={data}
            />
          )} */}

          {location === 'district' && (
            <CalendarListDistricts
              setValue={setValue}
              getValues={getValues}
              control={control}
              data={clients}
              title="Select District"
              type="district"
              clients={clients}
            />
          )}

          {location === 'campus' && (
            <CalendarListCampuses
              setValue={setValue}
              getValues={getValues}
              control={control}
              data={dataGrouped(campuses)}
              campuses={campuses}
              title="Select Campus"
              type="campus"
              clients={clients}
            />
          )}
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 2, mb: 3 }}>
          {data?.id && (
            <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
              sx={{ mr: 1 }}
              onClick={() => setConfirm(true)}
            >
              {t('DELETE')}
            </Button>
          )}
          <SubmitButton
            color="warning"
            icon={<SaveAltIcon />}
            title={t('SAVE')}
            onClick={handleSubmit(onSubmit, onErrors)}
          />
        </Stack>
      </Box>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
    </BasePathProvider>
  )
}
