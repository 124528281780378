import { Card, CardContent, Grid, Skeleton, Toolbar } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export default function CourseEditFormSkeleton() {
  const { t } = useTranslation()
  return (
    <Card sx={{ minHeight: '100%' }}>
     <Toolbar />
      <Header title={t("Course")} close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} sx={{ mt: 3 }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
