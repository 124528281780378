/* eslint-disable no-unused-vars */
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { Button, Card, CardActions, CardContent, Grid, TextField, Toolbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'
import { SubmitButton } from 'components/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { save } from 'store/settings/grading-scale'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const initialValue = {
  letterGrade: null,
  minGrade: null,
  maxGrade: null,
  gpa: null,
}
const StyledTextField = styled(TextField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
  },
}))

export default function GradingScaleForm({ scale }) {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const schema = yup.object().shape({
    letterGrade: yup.string().trim().max(2).required(t('Required')),
    gpa: yup.number().integer().min(0).max(4).typeError(t('GPA must be a number')).required(t('Required')),
    minGrade: yup
      .number()
      .integer()
      .min(0, t('Minimum grade greater than or equal to 0'))
      .typeError(t('Minimum grade must be a number'))
      .required(t('Required'))
      .lessThan(yup.ref('maxGrade'), t('Minimum grade must be less than maximum grade')),
    maxGrade: yup
      .number()
      .integer()
      .max(100, t('Maximum grade must be a less than or equal to 100'))
      .typeError(t('Maximum grade must be a number'))
      .required(t('Required')),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (id !== 'new') reset(scale)
    else reset(initialValue)
  }, [id])

  const onSubmit = async data => {
    const res = await dispatch(save(data))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/grading-scale`}>
      <Card
        data-component="GradingScaleEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Toolbar />
        <Header title={t('Grading Scale Edit Form')} small close />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={6}>
              <Controller
                name="letterGrade"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Letter Grade')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Controller
                name="gpa"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <StyledTextField
                      fullWidth
                      type="number"
                      size="small"
                      label={t('GPA')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Controller
                name="minGrade"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <StyledTextField
                      fullWidth
                      type="number"
                      size="small"
                      label={t('Minimum Grade')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Controller
                name="maxGrade"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <StyledTextField
                      fullWidth
                      type="number"
                      size="small"
                      label={t('Maximum Grade')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <Button variant="outlined" onClick={() => navigate(basePath)}>
            {t('Cancel')}
          </Button>
          <SubmitButton type="submit" disabled={!formState.isDirty}>
            {t('Submit')}
          </SubmitButton>
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
