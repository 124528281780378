import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search } from 'store/settings/grade-level-section'
import GradeLevelSectionList from './List'
import GradeLevelSectionEditFormIndex from './Form'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import GradeLevelSectionListSkeleton from './List/ListSkeleton'

const baseUrl = '/settings/grade-level-section'

export default function GradeLevelSection() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const gradeLevelSections = useSelector(state => state.settings.gradeLevelSection.list)
  const { id } = useParams()

  useEffect(() => {
    gradeLevelSections?.length || dispatch(search())
  }, [dispatch, Boolean(gradeLevelSections)])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'Grade Level Section'} add />
      {gradeLevelSections ? (
        <GradeLevelSectionList gradeLevelSections={gradeLevelSections} />
      ) : (
        <GradeLevelSectionListSkeleton />
      )}
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <GradeLevelSectionEditFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
