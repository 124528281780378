import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type/:memberId/attendance-report'

export const retrieve = createApiAction('member/attendance-report/get', client.retrieve(basePath))

export default function setAttendanceReportReducer(builder) {
  builder.addCase(retrieve.fulfilled, (state, action) => {
    const list = state.list || []
    const { memberId } = action.meta.arg
    const found = list.find(item => item.id === memberId)
    if (found) state.list = list.map(item => (item.id === found.id ? { ...item, attendanceReport: action.payload } : item))
  })
}