import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import {
  TextField,
  Checkbox,
  IconButton,
  MenuItem,
  InputAdornment,
  CardHeader,
  Box,
  Toolbar,
  CardContent,
  Grid,
  CardActions,
  Button,
  FormControlLabel,
} from '@mui/material'
import BrowseIcon from '@mui/icons-material/FolderOpenOutlined'
import { styled } from '@mui/material/styles'
import { CloseIcon } from '../../../components/Icons'
import { useNavigate } from 'react-router-dom'
import { saveFile } from '../../../store/membership'
import { useBasePath, useMembershipType, usePerson } from '../../../components/Providers'
import { DatePicker } from '@mui/x-date-pickers'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export default function DocumentEdit({ document }) {
  const [state, setState] = useState({
    name: '',
    documentType: '',
    approved: false,
    shared: false,
    expirationDate: null,
    document: '',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const membershipType = useMembershipType()
  const person = usePerson()
  const basePath = useBasePath()
  useEffect(() => {
    setState(document)
  }, [document])
  const { t } = useTranslation()

  const dateRuleMax = dayjs(Date.now() + 24 * 60 * 60 * 365 * 1000 * 10)

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('document', file)
    const url = `/api/${membershipType}/${person.id}/membership/${person.memberships[0]?.id}/file/upload`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()

    setState(prev => ({
      ...prev,
      fileId: data.id,
      name: data.name,
    }))
  }

  const onSubmit = async event => {
    event.preventDefault()
    const payload = {
      membershipType,
      personId: person.id,
      membershipId: person.memberships[0].id,
      ...state,
    }
    const res = await dispatch(saveFile(payload))
    if (res.payload) {
      navigate(basePath)
    }
  }

  return (
    <Root component="form" elevation="0" onSubmit={onSubmit} noValidate>
      <Toolbar />
      <CardHeader
        title={t('Documents')}
        subheader=""
        action={
          <IconButton onClick={() => navigate(basePath)}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              label={t('Name')}
              value={state?.name || ''}
              onChange={event => setState(prev => ({ ...prev, name: event.target.value }))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      component="label"
                      aria-label="toggle password visibility"
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <input hidden accept="image/*,application/*," type="file" onChange={handleFile} />
                      <BrowseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              select
              size="small"
              label={t('Document Type')}
              value={state?.documentType || ''}
              onChange={event =>
                setState(prev => ({
                  ...prev,
                  documentType: event.target.value,
                }))
              }
            >
              <MenuItem value="Address Proof">{t('Address Proof')}</MenuItem>
              <MenuItem value="Transcript">{t('Transcript')}</MenuItem>
              <MenuItem value="Photograph">{t('Photograph')}</MenuItem>
              <MenuItem value="Diploma">{t('Diploma')}</MenuItem>
              <MenuItem value="">{t('Other')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={state?.approved || false}
                  onChange={event =>
                    setState(prev => ({
                      ...prev,
                      approved: event.target.checked,
                    }))
                  }
                />
              }
              label={t('Approved')}
              labelPlacement="start"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <DatePicker
              label={t('Expiration Date')}
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
                field: {
                  fullWidth: true,
                  size: 'small',
                  InputLabelProps: { shrink: true },
                },
              }}
              fullWidth
              value={state?.expirationDate && dayjs(state?.expirationDate)}
              onChange={value => setState(prev => ({ ...prev, expirationDate: value }))}
              minDate={dayjs(Date.now())}
              maxDate={dateRuleMax}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  label={t('Approved')}
                  checked={state?.shared || false}
                  onChange={event =>
                    setState(prev => ({
                      ...prev,
                      shared: event.target.checked,
                    }))
                  }
                />
              }
              label={t('Shared')}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(basePath)
          }}
        >
          {t('Cancel')}
        </Button>
        <Button type="submit" variant="contained">
          {t('Submit')}
        </Button>
      </CardActions>
    </Root>
  )
}
