import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import { search } from 'store/settings/room'
import RoomsList from './List'
import RoomListSkeleton from './List/ListSkeleton'
import RoomForm from './Form'
import { useEffect } from 'react'
import { BasePathProvider } from 'components/Providers'

const baseUrl = '/settings_/rooms'

export default function RoomPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rooms = useSelector(state => state.settings.room?.list)
  const { id } = useParams()

  useEffect(() => {
    rooms || dispatch(search())
  }, [dispatch, rooms])

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
        {rooms ? <RoomsList rooms={rooms} /> : <RoomListSkeleton />}
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(baseUrl)}
        >
          <RoomForm />
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
