import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  List,
  ListItem,
  ListItemText,
  Switch,
  ListSubheader,
  MenuItem,
  Select,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import NoAccessIcon from '@mui/icons-material/VisibilityOffOutlined'
import ReadOnlyIcon from '@mui/icons-material/VisibilityOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'

import { setRolePermission } from '../../../store/settings/role'

import { Title, Body } from '../../../components/typo'

function ListHeader({ module }) {
  return (
    <ListSubheader
      sx={{
        backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
        pt: 1,
        pb: 1,
      }}
    >
      <Title sx={{ mb: 0 }}>{module.name}</Title>
      <Body>{module.description}</Body>
    </ListSubheader>
  )
}

function PermAction({ perm, role }) {
  const [value, setValue] = useState(perm.value)
  const dispatch = useDispatch()

  useEffect(() => {
    setValue(perm.value || 0)
  }, [role.id, perm.id, perm.value])

  const save = async value => {
    const payload = { role: role.id, permission: perm.id, value }

    if (value !== null ) {
      const { error, payload: res } = await dispatch(setRolePermission(payload))
      if (!error) {
        setValue(res.value)
      }
    }
  }

  const handleSelectChange = event => {
    save(event.target.value)
  }

  const handleSwitchChange = (event, value) => {
    save(value ? 1 : 0)
  }

  const handleToggleButtonChange = (event, value) => {
      save(value)
  }

  if (perm.type === 'xmodel')
    return (
      <FormControl xsx={{ m: 1, p: 0, minWidth: 120 }} size="small" margin="dense">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          value={value}
          label={null}
          onChange={handleSelectChange}
        >
          <MenuItem value={0}>No Access</MenuItem>
          <MenuItem value={1}>Read Only</MenuItem>
          <MenuItem value={15}>Full</MenuItem>
        </Select>
      </FormControl>
    )
  if (perm.type === 'model')
    return (
      <ToggleButtonGroup size="small" exclusive value={value} onChange={handleToggleButtonChange} color="primary">
        <ToggleButton value={0}>
          <NoAccessIcon />
        </ToggleButton>
        <ToggleButton value={1}>
          <ReadOnlyIcon />
        </ToggleButton>
        <ToggleButton value={15}>
          <EditIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    )
  return <Switch checked={value ? true : false} onChange={handleSwitchChange} />
}

function PermissionCheck({ perm, role }) {
  return (
    <ListItem
      sx={{ borderBottom: '1px solid #eee', ':last-child': { borderBottom: 0 } }}
      secondaryAction={<PermAction perm={perm} role={role} />}
    >
      <ListItemText primary={perm.name} secondary={perm.description} />
    </ListItem>
  )
}

function PermissionList({ role, module }) {
  return (
    <List subheader={<ListHeader module={module} />}>
      {module.permissions.map(perm => (
        <PermissionCheck key={perm.id} role={role} perm={perm} />
      ))}
    </List>
  )
}

export default PermissionList
