import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import AcademicYearEnrollmentItem from './Item'
import { STUDENT } from 'constants'
import { useMembershipType } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function AcademicYearEnrollmentList({ enrollments }) {
  const membershipType = useMembershipType()
  const { t } = useTranslation()

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('Academic Year')}</TableCell>
          {membershipType === STUDENT && (
            <TableCell sx={{ width: '32em' }}>
              {t('Grade Level')} / {t('Section')}
            </TableCell>
          )}
          <TableCell sx={{ width: '8em' }}>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {enrollments?.map(enrollment => (
          <AcademicYearEnrollmentItem key={enrollment.id} enrollment={enrollment} />
        ))}
      </TableBody>
    </Table>
  )
}
