import { Button, CardActions, CardContent, Grid, TextField, Toolbar, MenuItem, Box } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { save } from 'store/settings/course-session-period'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { isInteger } from 'lodash'
import { DAYS as days } from 'constants'
import { useTranslation } from 'react-i18next'

export default function ScheduleEditForm({ schedule, bellSchedulePeriods, roomList }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { courseSessionId } = useParams()
  const { t } = useTranslation()
  const baseUrl = '/settings/course-session/' + courseSessionId

  const schema = yup.object().shape({
    bellSchedulePeriodId: yup.number(),
    buildingRoomId: yup
      .number()
      .nullable()
      .transform(value => {
        return isInteger(value) ? value : null
      }),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: schedule.id,
      bellSchedulePeriodId: schedule.period?.id || '',
      buildingRoomId: schedule.room?.id || '',
    },
  })

  const onSubmit = async data => {
    const payload = { ...data, courseSessionId }
    const res = await dispatch(save(payload))
    if (res.payload) {
      navigate(`${baseUrl}`)
    }
  }

  const onError = errors => console.error(errors)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
      <Toolbar />
      <Header title={'Schedule'} close />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="bellSchedulePeriodId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("Bell Schedule Period")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {bellSchedulePeriods?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}, {days[item.dayOfWeek]} - {item.startTime}
                      </MenuItem>
                    ))}
                    <MenuItem value={null}>{'{empty}'}</MenuItem>
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6} md={12}>
            <Controller
              name="buildingRoomId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("Building Room")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {roomList?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.buildingName} - {item.doorNumber} - {item.name}
                      </MenuItem>
                    ))}
                    <MenuItem value={null}>{'{empty}'}</MenuItem>
                  </TextField>
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => navigate(`${baseUrl}`)}>
          {t("Cancel")}
        </Button>
        <Button type="submit" variant="contained" disabled={!formState.isDirty}>
          {t("Submit")}
        </Button>
      </CardActions>
      <Header />
    </Box>
  )
}
