import { useParams } from 'react-router-dom'
import EnrollmentsForm from './Form'

export default function EnrollmentsFormIndex({ enrollments, campuses }) {
  const { enrollmentId } = useParams()

  return (
    <EnrollmentsForm campusEnrollments={enrollments?.campusEnrollments} data={enrollments.campusEnrollments.find(ce => ce.id === +enrollmentId)} campuses={campuses} />
  )
}
