import { Route, Routes } from 'react-router-dom'
import ContactsPage from './Page'
import ContactsFormIndex from './Form'

export default function Contacts() {
  return (
    <Routes>
      <Route path="/" element={<ContactsPage />}>
        <Route path="/:contactId" element={<ContactsFormIndex />} />
      </Route>
    </Routes>
  )
}
