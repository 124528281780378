import { useMemo } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import { Routes, Route, Outlet, BrowserRouter, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { SnackbarProvider } from 'notistack'

import { SessionProvider } from './components/Providers'

import ConfirmPage from './pages/confirm'
import AuthPage from './pages/auth'
import MainPage from './pages/MainPage'
import Splash from './pages/SplashPage'
import { compileTheme } from './theme'

function App() {
  const session = useSelector(state => state.session)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const mode = session.appearance || (prefersDarkMode ? 'dark' : 'light');

  const theme = useMemo(() => {
    return compileTheme(session, mode);
}, [session, mode]);


  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider
          maxSnack={5}
          preventDuplicate={true}
          TransitionProps={{ direction: 'left' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <CssBaseline />
          <Splash>
            <BrowserRouter>
              <Routes>
                <Route element={<Outlet />}>
                  <Route path="confirm/*" element={<ConfirmPage />} />
                  <Route path="auth/*" element={<AuthPage />} />
                  {/* prettier-ignore */}
                  <Route path="*" element={session.user ? <SessionProvider session={session}><MainPage /></SessionProvider> : <Navigate to="/auth" />} />
                </Route>
              </Routes>
            </BrowserRouter>
            {/* <LoginPage><MainPage/></LoginPage> */}
          </Splash>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
