import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { search, get } from 'store/settings/campus'
import CampusList from './CampusList'
import CampusEditForm from './CampusEditForm'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { getCountry, getSchools } from 'store/lookup'

const baseUrl = '/settings/campus'

export default function Campus() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const campuses = useSelector(state => state.settings.campus?.list)
  const schools = useSelector(state => state.lookup.schools)
  const { country } = useSelector(state => state.lookup)
  const [campus, setCampus] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    schools || dispatch(getSchools({ include: ['district'] }))
  }, [])

  useEffect(() => {
    campuses || dispatch(search())
  }, [dispatch, Boolean(campuses)])

  useEffect(() => {
    country || dispatch(getCountry())
  }, [dispatch, Boolean(country)])

  useEffect(() => {
    if (+id) {
      dispatch(get({ id })).then(res => {
        setCampus(res.payload)
      })
    } else {
      setCampus({})
    }
  }, [dispatch, id])

  if (!(schools && country)) return null

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Header title={'Campus'} add />
      {campuses && <CampusList campuses={campuses} />}
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id && campus)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        {campus && country && <CampusEditForm campus={campus} />}{' '}
      </Drawer>{' '}
    </BasePathProvider>
  )
}
