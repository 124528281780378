import { useEffect, useState } from 'react'
import { Checkbox, IconButton, TableCell, TableRow, } from '@mui/material'
import { DeleteIcon } from 'components/Icons'
import { EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { destroy } from 'store/nurse/nurse-health-condition'
import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { getNurseConditionTypes } from 'store/lookup'


export default function HealthConditionItem({ item }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const nurseConditionTypes = useSelector(state => state.lookup.nurse.conditionType)

  const conditionTypeName = nurseConditionTypes?.find(
    condition => condition.id === item.conditionTypeId
  )?.name;

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: item.id }))
  }

  useEffect(() => {
    nurseConditionTypes ||
      dispatch(getNurseConditionTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, nurseConditionTypes])

  return (
    <TableRow hover>
      <TableCell>
        <Checkbox 
          checked={Boolean(item.visibleToStaff)}
          disabled
        />
      </TableCell>
      <TableCell> {conditionTypeName || item.conditionTypeId}</TableCell>
      <TableCell> {item.explanation}</TableCell>
      <TableCell> {item.medication}</TableCell>
      <TableCell> {item.administer}</TableCell>
      
      <TableCell align="left">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton component={NavLink} to={`${basePath}/${item.id}`} aria-label="edit">
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
