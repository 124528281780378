import { Box, Card, CardContent, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import BellScheduleItem from './BellScheduleItem'

export default function BellScheduleList({ bellSchedules }) {
  return (
    <Box sx={{ minHeight: '50%' }}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Bell Schedule Name</TableHeadCell>
                    <TableHeadCell>Building Name</TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                    <TableHeadCell></TableHeadCell>
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {bellSchedules?.map(item => {
                      return <BellScheduleItem key={item.id} item={item} />
                    })}
                  </TableBody>
                }
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
