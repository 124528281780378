import { Grid, MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function HealthRelatedInfoForm({ control, healthInsuranceStatusOption }) {
  const { t } = useTranslation()

  return (
    <>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="healthRelatedInfo.healthInsuranceStatus"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t("Health Insurance Status")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {healthInsuranceStatusOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <Controller
          name="healthRelatedInfo.healthInsuranceProvider"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                label={t("Health Insurance Provider")}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              />
            )
          }}
        />
      </Grid>
    </>
  )
}
