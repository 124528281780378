import { createSlice } from '@reduxjs/toolkit'
import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'
import _ from 'lodash'
import setPersonContactReducers from './contact'
import setPersonEmploymentReducers from './employment'
import setPersonEnrollmentReducers from './enrollment'
import setPersonCampusReducers from './campus'
const name = 'person2'
const initialState = {
  list: null,
  count: null,
  query: {
    que: null,
    limit: 10,
    offset: 0,
    district: null,
    jobPositionId: null,
    status: null,
    sortBy: null,
    sortOrder: null,
  },
  details: null,
  contacts: null,
  contactDetails: null,
  // memberships: null,
  employments: null,
  employmentsFetch: false,
  enrollments: null,
  campuses: null,
}

export const search = createApiAction('person/search', client.search('/api/person'))
export const retrieve = createApiAction('person/get', client.retrieve(`/api/person/:id`))
export const save = createApiAction('person/save', client.save('/api/person'))
export const create = createApiAction('person/create', client.save('/api/person'))
export const destroy = createApiAction('person/destroy', client.destroy('/api/person/:id'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchPersonQuery: (state, action) => {
      state.query = action.payload
    },
    setSearchPersonLimit: (state, action) => {
      state.query.limit = action.payload
    },
    setSearchPersonOffset: (state, action) => {
      state.query.offset = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        if (!action.meta.arg?.export) {
          state.list = action.payload.rows
          state.count = action.payload.count
        }
      })
      .addCase(retrieve.fulfilled, (state, action) => {
        state.details = action.payload
        const list = state.list || []
        const { id } = action.payload
        const found = list.find(item => item.id === id)
        const foundIndex = list.indexOf(found)
        if (foundIndex > -1) {
          const temp = _.pick(action.payload, [
            'id',
            'title',
            'firstName',
            'lastName',
            'middleName',
            'suffix',
            'email',
            'profilePicture',
            'memberships',
            'roles',
          ])
          state.list[foundIndex] = temp
        } else {
          state.list = [...list, action.payload]
        }
      })
      .addCase(save.fulfilled, (state, action) => {
        state.details = action.payload
        const list = state.list || []
        const { id } = action.payload
        const found = list.find(item => item.id === id)
        const foundIndex = list.indexOf(found)
        if (foundIndex > -1) {
          const temp = _.pick(action.payload, [
            'id',
            'title',
            'firstName',
            'lastName',
            'middleName',
            'suffix',
            'email',
            'file',
            'memberships',
            'profilePicture',
            'roles',
          ])
          state.list[foundIndex] = temp
        } else {
          state.list = [...list, action.payload]
        }
      })
      .addCase(create.fulfilled, (state, action) => {
        state.list = [...state.list, action.payload]
      })
    setPersonContactReducers(builder)
    setPersonEmploymentReducers(builder)
    setPersonEnrollmentReducers(builder)
    setPersonCampusReducers(builder)
  },
})

export const { setSearchPersonQuery, setSearchPersonLimit, setSearchPersonOffset } = sessionSlice.actions

export default sessionSlice.reducer
