import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, LinearProgress /* Toolbar */ } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as action from 'store/member/index'
import { BasePathProvider, MemberProvider, useBasePath, useMembershipType } from 'components/Providers'
import { useEffect, useState } from 'react'
import TabControl from './TabControl'
import Personal from './Personal'
import { Drawer } from '@mui/material'
import PersonHeader from './PersonHeader'
import FormSkeleton from './FormSkeleton'
import NotFound from 'components/NotFound'

const MainBox = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  [theme.breakpoints.down('lg')]: {
    width: '100vw',
  },
}))

export default function Person() {
  const basePath = useBasePath()
  const dispatch = useDispatch()
  const membershipType = useMembershipType()
  const { memberId, '*': tabName } = useParams()
  const member = useSelector(state => state.member.list?.find(m => m.id === memberId))
  const { loading } = useSelector(state => state.member)
  const navigate = useNavigate()
  const [isReady, setIsReady] = useState(false)
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (memberId) {
      dispatch(action.retrieve({ id: memberId, type: membershipType })).then(res => {
        res.payload && setIsReady(!!res.payload)
        res.error && setNotFound(true)
      })
    }
  }, [dispatch, memberId])

  if (!member) {
    if (notFound) return <NotFound basePath={`${basePath}/${memberId}`} />
    return <FormSkeleton />
  }

  return (
    <BasePathProvider value={`${basePath}/${memberId}`}>
      <MemberProvider member={member}>
        <MainBox>
          {/* <Toolbar /> */}
          <PersonHeader />
          {!!loading && <LinearProgress sx={{ position: 'absolute', buttom: 0, width: '100%' }} />}
          <TabControl />
          <Drawer
            PaperProps={{
              sx: {
                width: 650,
              },
            }}
            open={Boolean(tabName === 'person')}
            anchor="right"
            onClose={() => navigate(`${basePath}/${memberId}/profile`)}
          >
            {isReady ? <Personal member={member} /> : <FormSkeleton />}
          </Drawer>
        </MainBox>
      </MemberProvider>
    </BasePathProvider>
  )
}
