import { Route, Routes } from 'react-router-dom'
import GradingPage from './Page'

export default function Grading() {
  return (
    <Routes>
      <Route path="/" element={<GradingPage />} />
      <Route path="/:type/:id" element={<GradingPage />} />
    </Routes>
  )
}
