import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { useBasePath, useMembershipType, usePerson } from 'components/Providers'
import { destroy } from 'store/academic-year-enrollment'
import { useDispatch, useSelector } from 'react-redux'
import { CheckBoxOutlineBlank, CheckBox, EditIcon, DeleteIcon } from 'components/Icons'
import { select } from 'store/academic-year-enrollment'
import Confirm from 'components/Dialog'
import { STUDENT } from 'constants'

export default function AcademicYearEnrollmentItem({ enrollment }) {
  const dispatch = useDispatch()
  const membershipType = useMembershipType()
  const person = usePerson()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const detailPath = `${basePath}/${enrollment.id}`
  const selected = useSelector(state => state.academicYearEnrollment.selected?.id) === enrollment.academicYear.id
  const [confirm, setConfirm] = useState(false)

  const onSelectAcademicYearEnrollment = () => {
    dispatch(select(enrollment.academicYear))
  }

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: enrollment.id, membershipType, personId: person.id }))
  }

  return (
    <TableRow selected={selected}>
      <TableCell onClick={onSelectAcademicYearEnrollment}>
        <IconButton color={selected ? 'primary' : 'default'} onClick={onSelectAcademicYearEnrollment}>
          {selected ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />}
        </IconButton>
        {enrollment.academicYear.name}
      </TableCell>
      {membershipType === STUDENT && (
        <TableCell onClick={onSelectAcademicYearEnrollment}>
          {[enrollment.gradeLevel?.name, enrollment.gradeSection].filter(Boolean).join(' ')}
        </TableCell>
      )}
      <TableCell>
        <IconButton
          color="default"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          colot="default"
          onClick={() => {
            navigate(detailPath)
          }}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
