import { Grid, MenuItem, Paper, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/leave-request'
import { useEffect } from 'react'

export default function ListFilter({
  leaveRequestTypeList,
  leaveRequestType,
  setLeaveRequestType,
  academicYear,
  setAcademicYear,
  selectedTeacher,
  academicYearsProvider,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const leaveRequest = useSelector(state => state.settings.leaveRequest)

  useEffect(() => {
    if (selectedTeacher) {
      const payload = {
        personId: selectedTeacher?.personId,
        limit: leaveRequest.query?.limit || 10,
        offset: leaveRequest.query?.offset || 0,
      }
      if (leaveRequestType && leaveRequestType !== 'All') {
        payload['typeId'] = leaveRequestType
      }
      if (academicYear && academicYear !== 'All') {
        payload['academicYearProviderId'] = academicYear
      }

      dispatch(
        search({
          ...payload, 
        }),
      )
    }
  }, [leaveRequestType, academicYear])

  return (
    <Paper sx={{ width: '100%', py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            select
            disabled={!selectedTeacher}
            size="small"
            fullWidth
            sx={{ pr: 1 }}
            label={t('Academic Year')}
            placeholder="Academic Year')}"
            color="warning"
            onChange={event => {
              setAcademicYear(event.target.value)
            }}
            value={academicYear || 'All'}
          >
            <MenuItem value="All">All</MenuItem>
            {academicYearsProvider &&
              academicYearsProvider.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            disabled={!selectedTeacher}
            size="small"
            fullWidth
            sx={{ pr: 1 }}
            label={t('Leave Type')}
            placeholder="Leave Type')}"
            color="warning"
            onChange={event => {
              setLeaveRequestType(event.target.value)
            }}
            value={leaveRequestType || 'All'}
          >
            <MenuItem value="All">All</MenuItem>
            {leaveRequestTypeList &&
              leaveRequestTypeList?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </Paper>
  )
}
