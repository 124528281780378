import {
  Autocomplete,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  MenuItem,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { CloseIcon, DeleteIcon } from 'components/Icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import {
  save as saveCourseSessionEnrollment,
  destroy as deleteCourseSessionEnrollment,
} from 'store/settings/course-session-enrollments'
import Confirm from 'components/Dialog'
import { debounce, omit } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function CourseSessionEnrolledEditForm({ data, fetchPersonList }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { courseSessionId } = useParams()
  const { enrollmentId: id } = useParams()

  const [confirm, setConfirm] = useState(false)
  const [minStartDate, setMinStartDate] = useState(dayjs(useSelector(state => state.session?.academicYear?.startDate)))
  const [maxEndDate, setMaxEndDate] = useState(dayjs(useSelector(state => state.session?.academicYear?.endDate)))
  const [submitButton, setSubmitButton] = useState(false)
  const [person, setPerson] = useState()

  const [personList, setPersonList] = useState()

  const searchParams = new URLSearchParams(window.location.search)
  const type = searchParams.get('type')

  const courseSessionEnrollmentType = useSelector(state => state.lookup?.courseSessionsEnrollmentType)

  useEffect(() => {
    if (data) {
      setPersonList(data.list)
    }
  }, [data])

  const schema = yup.object().shape({
    schoolEnrollmentId: yup.number().required('Required'),
    startDate: yup
      .date()
      .required('Required')
      .test(
        'valid-dates-start',
        'Start date should be later than school enrollment date or academic year start date',
        function (startValue) {
          return validatorStartDate(startValue, this.parent.schoolEnrollmentId)
        },
      ),
    endDate: yup.date().nullable().min(yup.ref('startDate'), 'End date should be later than start date'),
    roleId: yup.string().nullable(),
  })

  const { control, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      schoolEnrollmentId: 0,
      courseSessionId,
      startDate: dayjs(Date.now()),
      endDate: null,
      roleId: data.list[0]?.courseSessionEnrollmentType?.id || courseSessionEnrollmentType?.[0].id,
    },
  })
  const onSubmit = async data => {
    setSubmitButton(true)
    let payload = {
      ...data,
      startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      endDate: (data.endDate && dayjs(data.endDate).format('YYYY-MM-DD')) || null,
    }
    if (isNaN(parseInt(id))) {
      payload = omit(payload, id)
    } else {
      payload = omit(payload, 'schoolEnrollmentId')
      payload = { ...payload, id }
    }
    const res = id && (await dispatch(saveCourseSessionEnrollment({ ...payload })))
    if (res.payload) {
      navigate(`/settings/course-session/${courseSessionId}`)
    }
    setSubmitButton(false)
  }

  const onError = errors => console.error(errors)

  const onConfirm = () => {
    setConfirm(false)
    dispatch(deleteCourseSessionEnrollment({ courseSessionId, id: parseInt(id) })).then(
      ({ payload }) => {
        if (payload) {
          navigate(`/settings/course-session/${courseSessionId}`)
        }
      },
    )
  }

  const validatorStartDate = (value, schoolEnrollmentId) => {
    if (!isNaN(parseInt(data.type))) {
      return true
    } else {
      const personSchoolEnrollmentStartDate = new Date(
        data?.list?.find(
          item => item?.memberships[0]?.schoolEnrollments[0]?.id === schoolEnrollmentId,
        ).memberships[0].schoolEnrollments[0].startDate,
      ).getTime()
      const formStartDate = new Date(value).getTime()
      if (personSchoolEnrollmentStartDate < formStartDate) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (!isNaN(parseInt(data.type))) {
      setValue('schoolEnrollmentId', data.list[0].memberships[0].schoolEnrollments[0]?.id || null)
      setValue('startDate', dayjs(data.list[0].startDate) || null)
      if (data.list[0].endDate) {
        setValue('endDate', dayjs(data.list[0].endDate))
      }

      setPerson({
        firstName: data.list[0].firstName,
        lastName: data.list[0].lastName,
      })
      if (
        new Date(data.list[0].memberships[0].schoolEnrollments[0].startDate).getTime() >
        new Date(minStartDate).getTime()
      ) {
        setMinStartDate(dayjs(new Date(data.list[0].memberships[0].schoolEnrollments[0].startDate).getTime()))
        setMaxEndDate(null)
      }
    }
  }, [data])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
      <Toolbar />
      <CardHeader
        title={
          type === 'staff' || data.list[0].memberships?.[0].membershipType === 'staff'
            ? 'Enrollment For Teacher'
            : 'Enrollment For Student'
        }
        subheader="Course Session Enrollments"
        action={
          <IconButton onClick={() => navigate(`/settings/course-session/${courseSessionId}`)}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {data && personList && (
            <Grid sm={12} xs={12} item>
              <Controller
                name="schoolEnrollmentId"
                control={control}
                render={({ field: { ref, onChange, ...field }, fieldState }) => {
                  return (
                    <Autocomplete
                      disablePortal
                      openOnFocus
                      autoHighlight
                      onChange={(event, value) => {
                        if (value) {
                          setPerson({ firstName: value.firstName, lastName: value.lastName })
                          onChange(value.memberships[0].schoolEnrollments[0].id)
                        } else {
                          setPerson(null)
                        }
                      }}
                      size="small"
                      options={personList}
                      defaultValue={(!isNaN(parseInt(id)) && personList[0]) || null}
                      value={person || null}
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      readOnly={!isNaN(parseInt(id))}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case 'reset':
                            break
                          case 'clear':
                            setValue('schoolEnrollmentId', null)
                            setPerson(null)
                            fetchPersonList(null)
                            break
                          case 'input':
                            fetchPersonList(value)
                            break
                          default:
                            fetchPersonList(value)
                            break
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Persons')}
                          inputRef={ref}
                          disabled={!isNaN(parseInt(id))}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Grid>
          )}
          {(type === 'staff' || data.list[0].memberships?.[0].membershipType === 'staff') && (
            <Grid item sm={12} xs={12}>
              <Controller
                name="roleId"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label={t('Teacher Enrollment Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value}
                      defaultValue={courseSessionEnrollmentType[0].id}
                      {...field}
                    >
                      {courseSessionEnrollmentType?.map(item => (
                        <MenuItem key={item.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}{' '}
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}
          <Grid sm={6} xs={6} item sx={{ mt: 1 }}>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    fullWidth
                    label={t('Start Date')}
                    value={field.value ? dayjs(field.value) : dayjs(Date.now())}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    minDate={minStartDate}
                    maxDate={maxEndDate}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        error: !!fieldState.error,
                        helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                      },
                    }}
                  />
                )
              }}
            />
          </Grid>

          <Grid sm={6} xs={6} item sx={{ mt: 1 }}>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    fullWidth
                    label={t('End Date')}
                    defaultValue={null}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    minDate={minStartDate}
                    maxDate={maxEndDate}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        error: !!fieldState.error,
                        helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                      },
                    }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
        {!isNaN(parseInt(id)) && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setConfirm(true)
            }}
          >
            Delete
          </Button>
        )}
        <Button variant="outlined" onClick={() => navigate(`/settings/course-session/${courseSessionId}`)}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={debounce(() => {
            handleSubmit(onSubmit)()
          }, 200)}
          variant="contained"
          disabled={!formState.isDirty || submitButton}
        >
          {t('Submit')}
        </Button>
      </CardActions>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </Box>
  )
}
