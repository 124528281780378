import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'staff'
const initialState = {
  count: null,
  list: null,
}

export const search = createApiAction('campus/search', client.search('/api/setting/campus'))
export const get = createApiAction('campus/get', client.retrieve(`/api/setting/campus/:id`))
export const save = createApiAction('campus/save', client.save('/api/setting/campus'))
export const destroy = createApiAction('campus/delete', client.destroy('/api/setting/campus/:id'))

const updateList = (state, action) => {
  const found = state?.list?.find(item => item.id === action.payload.id)
  if (found) {
    state.list = found
      ? state?.list?.map(item => (found.id === item.id ? action.payload : item))
      : [action.payload, ...state.list]
  } else {
    const list = state.list
    state.list = [...list, action.payload]
  }
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows
        state.count = action.payload.count
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export default sessionSlice.reducer
