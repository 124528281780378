import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchFiles, getFile } from '../../../store/membership'
import DocumentList from './DocumentList'
import { BasePathProvider, useMembershipType, usePerson, useBasePath } from '../../../components/Providers'
import Header from '../../../components/Header'
import { Drawer, LinearProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import DocumentEdit from './DocumentEdit'
import { useTranslation } from 'react-i18next'

export default function Documents() {
  const membershipType = useMembershipType()
  const person = usePerson()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentPath = useBasePath()
  const basePath = `${currentPath}/documents`
  const files = useSelector(state => state.membership.files)
  const [file, setFile] = useState(null)
  const { id } = useParams()
  const { t } = useTranslation()
  const [isLoaded, setIsLoaded] = useState()

  useEffect(() => {
    setIsLoaded(false)
    if (person?.memberships) {
      dispatch(
        searchFiles({
          membershipType,
          personId: person.id,
          membershipId: person.memberships[0].id,
        }),
      ).then(setIsLoaded(true))
    }
  }, [dispatch, id, membershipType, person?.id, person?.memberships])

  useEffect(() => {
    setFile(null)
    if (id === 'new') {
      setFile({})
    } else if (id) {
      dispatch(
        getFile({
          membershipType,
          personId: person.id,
          id,
          membershipId: person.memberships[0].id,
        }),
      ).then(res => setFile(res.payload))
    }
  }, [dispatch, id, membershipType, person?.id, person?.memberships])

  return (
    <BasePathProvider value={basePath}>
      {!isLoaded ? (
        <LinearProgress sx={{ m: 2 }} color="success" />
      ) : (
        <>
          <Header small title={t('Documents')} add />
          <DocumentList files={files} />
          <Drawer anchor="right" open={Boolean(file)} onClose={() => navigate(basePath)} sx={{ width: '1000px' }}>
            <DocumentEdit document={file} onClose />
          </Drawer>
        </>
      )}
    </BasePathProvider>
  )
}
