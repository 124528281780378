import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'grading-scale'
const initialState = {
  list: null,
}

export const search = createApiAction(
  'academic/grading-scale/search',
  client.search('/api/academic/grading-scale'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export default sessionSlice.reducer
