import { Route, Routes } from "react-router-dom";
import DistrictPage from "./Page";
import DistrictFormIndex from "./Form";

export default function District() {
  return (
    <Routes>
      <Route path="/" element={<DistrictPage />}>
        <Route path="/:id" element={<DistrictFormIndex />} />
      </Route>
    </Routes>
  )
}
