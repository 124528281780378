import { Fragment } from 'react'
import { Grid } from '@mui/material'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export default function MarkingPeriod({ path, control, item, index, errors }) {
  const { t } = useTranslation()
  return (
    <Fragment key={item?.name}>
      <Grid item sm={12} md={1.8} xs={12} container alignItems="center">
        <Controller
          name={`${path}.${index}.name`}
          control={control}
          defaultValue={item.name}
          value={item.name}
          render={({ field }) => {
            return <div>{field.value}</div>
          }}
        />
      </Grid>
      <Grid item sm={12} md={5.1} xs={12}>
        <Controller
          name={`${path}.${index}.startDate`}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DatePicker
                fullWidth
                label={t('Start Date')}
                value={field.value ? dayjs(field.value) : null}
                onChange={field.onChange}
                error={errors || !!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: errors || !!fieldState.error,
                    helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                  },
                }}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} md={5.1} xs={12}>
        <Controller
          name={`${path}.${index}.endDate`}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DatePicker
                fullWidth
                label={t('End Date')}
                value={field.value ? dayjs(field.value) : null}
                onChange={field.onChange}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: errors || !!fieldState.error,
                    helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                  },
                }}
              />
            )
          }}
        />
      </Grid>
    </Fragment>
  )
}
