import { useSelector, useDispatch } from 'react-redux'

import { appearance } from '../../store/session'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { LightMode, DarkMode, SettingsBrightness } from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

export default function LoginForm() {
  const { i18n } = useTranslation()
  const mode = useSelector(state => state.session.appearance)
  const dispatch = useDispatch()

  const handleModeChange = (event, mode) => {
    dispatch(appearance(mode))
  }

  const handleLangChange = (event, lang) => {
    i18n.changeLanguage(lang)
  }

  const languages = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Spanish' },
  }

  return (
    <>
      <Box sx={{ position: 'absolute', left: 0, bottom: 0, margin: 3 }}>
        <ToggleButtonGroup value={i18n.resolvedLanguage} exclusive onChange={handleLangChange} size="small">
          {Object.keys(languages).map(lang => (
            <ToggleButton value={lang} key={lang}>
              {lang}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ position: 'absolute', right: 0, bottom: 0, margin: 3 }}>
        <ToggleButtonGroup value={mode} exclusive onChange={handleModeChange} size="small">
          <ToggleButton value="">
            <SettingsBrightness fontSize="small" />
          </ToggleButton>
          <ToggleButton value="light">
            <LightMode fontSize="small" />
          </ToggleButton>
          <ToggleButton value="dark">
            <DarkMode fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  )
}
