import { Route, Routes } from 'react-router-dom'
import { BasePathProvider } from 'components/Providers'
import { Box, Paper } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'
import SettingsSchoolManagement from './SchoolManagement'
import SettingsCalendar from './Calendar'
import SettingsTabs from './Tabs'
import SettingsAcademicYear from './AcademicYear'
import SettingsSchoolAccess from './SchoolAccess'

export default function Settings2() {
  const basePath = '/setting'

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ mb: 2 }}>
        <PageHeader title={'Settings'} />
      </Box>
      <Paper sx={{ width: '100%', minHeight: '500px', p: 2, mb: 2 }}>
        <SettingsTabs />
        <Routes>
          <Route path="/">
            <Route path="" element={<SettingsCalendar />} />
            <Route path="/calendar/*" element={<SettingsCalendar />} />
            <Route path="/academic-year/*" element={<SettingsAcademicYear />} />
            <Route path="/school-access/*" element={<SettingsSchoolAccess />} />
            <Route path="/school-management/*" element={<SettingsSchoolManagement />} />
          </Route>
        </Routes>
      </Paper>
    </BasePathProvider>
  )
}
