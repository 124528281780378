import { Box } from '@mui/material'
import { Fragment } from 'react'
import PageHeader from '../../components/AppBar/PageHeader'
import WidgetLoader from '../../components/WidgetLoader'

export default function Dashboard() {
  return (
    <Fragment>
      <Box sx={{pb: 2}}>
        <PageHeader title={'Dashboard'} />
      </Box>
      <WidgetLoader />
    </Fragment>
  )
}
